import React from "react";
import { range } from "lodash";
import { Placeholder } from "semantic-ui-react";

import PropTypes from "prop-types";

/**
 * Paragraph placeholder for text (loader)
 * @returns JSX
 */
const ParagraphPlaceholder = ({ numberOfLines, className, testId }) => {
  const numbers = range(numberOfLines);
  return (
    <Placeholder className={className} data-testid={testId}>
      <Placeholder.Paragraph>
        {numbers.map((number) => (
          <Placeholder.Line key={number} />
        ))}
      </Placeholder.Paragraph>
    </Placeholder>
  );
};

ParagraphPlaceholder.propTypes = {
  numberOfLines: PropTypes.number.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string
};

ParagraphPlaceholder.defaultProps = {
  className: "",
  testId: ""
};

export default ParagraphPlaceholder;
