import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Placeholder } from "semantic-ui-react";
import Slider from "react-slick";
import { findIndex, noop } from "lodash";
import "./GraphPreview.scss";

import CustomNextArrow from "components/NextArrow/NextArrow";
import CustomPrevArrow from "components/PrevArrow/PrevArrow";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import CONFIG from "configs/config";

import {
  getSelectedImages,
  getSelectedSmartOptionForImage,
  isMultiSelectMode,
  isSavingMultipleImages
} from "containers/ImageListView/selectors";
import { applyMultipleImageChanges } from "containers/ImageListView/actions";
import { imageListType } from "utils/customPropTypes";
import ProtectedImageRenderer from "components/ProtectedImageRenderer/ProtectedImageRenderer";

/**
 * SANITIZE SCREEN
 * Preview of original/sanitized graph identified by the system
 * @returns JSX
 */
const GraphPreview = ({
  selectedGraphs,
  isLoading,
  selectedSmartOption,
  isSelectMode,
  isSavingMultipleImages,
  applyMultipleImageChanges,
  currentImagePreviewing,
  setPreviewingImage
}) => {
  const slideRef = useRef(null);
  const onSliderChange = (current, next) => {
    if (next !== current) {
      setPreviewingImage(selectedGraphs[next] || selectedGraphs[current]);
    }
  };
  const imageSliderSettings = {
    className: "preview-slider-container",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 1,
    focusOnSelect: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    beforeChange: onSliderChange
  };

  useEffect(() => {
    if (slideRef.current) {
      const idx = findIndex(selectedGraphs, { id: currentImagePreviewing.id });
      if (idx !== -1) {
        slideRef.current?.slickGoTo(idx, true);
      }
    }
  }, [currentImagePreviewing]);

  const onApplyChanges = () => {
    if (selectedSmartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET) {
      applyMultipleImageChanges({ hasReset: true });
    } else {
      applyMultipleImageChanges({});
    }
  };

  const firstImage = selectedGraphs[0] || {};

  const imageSrc = (image) =>
    image.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT &&
    image.redactedImageUrl
      ? image.redactedImageUrl
      : image.modifiedImageUrl || image.originalImageUrl;

  return (
    <section className="editor-section__editor">
      <div className="header-stripe editor-header">
        <span className="editor-header__title">
          {CONFIG.LABELS.PREVIEW_SANITIZED_GRAPHS}
        </span>
        {!isLoading && firstImage.id && isSelectMode && (
          <CustomActionButton
            label="Apply"
            className="ml-2 apply-btn p-2"
            onClickfn={onApplyChanges}
            isLoading={isSavingMultipleImages}
            disabled={!selectedSmartOption}
            size="small"
            data-testid="apply-button"
          />
        )}
      </div>
      <div className="main-block editor-container image-preview-container">
        {isLoading ? (
          <Placeholder />
        ) : selectedGraphs.length > 1 ? (
          <Slider ref={slideRef} {...imageSliderSettings}>
            {selectedGraphs.map((image) => {
              return (
                <div key={image.id} className="slider-block">
                  <ProtectedImageRenderer
                    className="slider-image-preview"
                    src={imageSrc(image)}
                    alt=""
                  />
                </div>
              );
            })}
          </Slider>
        ) : selectedGraphs.length ? (
          <ProtectedImageRenderer
            className="image-preview"
            src={imageSrc(firstImage)}
            alt=""
          />
        ) : (
          <div className="no-preview-msg">
            {CONFIG.LABELS.SELECT_GRAPH_TO_PREVIEW}
          </div>
        )}
      </div>
    </section>
  );
};

GraphPreview.propTypes = {
  selectedGraphs: PropTypes.arrayOf(imageListType).isRequired,
  isLoading: PropTypes.bool,
  selectedSmartOption: PropTypes.string,
  isSelectMode: PropTypes.bool,
  isSavingMultipleImages: PropTypes.bool,
  applyMultipleImageChanges: PropTypes.func,
  currentImagePreviewing: imageListType,
  setPreviewingImage: PropTypes.func
};

GraphPreview.defaultProps = {
  isLoading: false,
  selectedSmartOption: "",
  isSelectMode: false,
  isSavingMultipleImages: false,
  applyMultipleImageChanges: noop,
  currentImagePreviewing: {},
  setPreviewingImage: noop
};

function mapStateToProps(state) {
  return {
    selectedGraphs: getSelectedImages(state),
    selectedSmartOption: getSelectedSmartOptionForImage(state),
    isSelectMode: isMultiSelectMode(state),
    isSavingMultipleImages: isSavingMultipleImages(state)
  };
}

export default connect(mapStateToProps, { applyMultipleImageChanges })(
  GraphPreview
);
