import { createSelector } from "reselect";
import { map } from "lodash";

import { AUTO_SANITIZE, DELETE_FILE } from "../types";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import { configureLoadingState } from "containers/Common/reducers/loading";
import CONFIG from "configs/config";

const homeWindow = (state) => state.home;

export const getFilesList = createSelector(homeWindow, (hw) =>
  map(hw.filesInProcess, (item) => {
    return {
      ...item
    };
  })
);

export const getDisclaimerStatus = createSelector(
  homeWindow,
  (hw) => hw.showDisclaimer
);

configureLoadingState([DELETE_FILE, AUTO_SANITIZE]);

export const isDeletingDocument = createLoadingSelector([DELETE_FILE]);

export const isAutoSanitizing = createLoadingSelector([AUTO_SANITIZE]);

export const isAutoSanitized = createSelector(
  homeWindow,
  (hw) => hw.autoSanitized
);

export const isMoveToHomeFromPreview = createSelector(
  homeWindow,
  (hw) => hw.moveToHomeFromPreview
);

export const getFilesWithOpenStatusForPolling = createSelector(
  getFilesList,
  (filesList) => {
    const filesWithOpenStatus = filesList.filter((file) =>
      CONFIG.CONSTANTS.LIST_OF_OPEN_STATUS_TO_POLL.includes(file.pptStatus)
    );
    return filesWithOpenStatus;
  }
);

export const getFilesToRetry = createSelector(
  [getFilesList, (_, payload) => payload],
  (filesListFromStore, payloadResponse) => {
    return (payloadResponse || filesListFromStore).filter(
      (file) =>
        CONFIG.CONSTANTS.LIST_OF_OPEN_STATUS_TO_RETRY.includes(
          file.pptStatus
        ) &&
        (file.extractionRetried || 0) < CONFIG.CONSTANTS.MAX_FILE_RETRY_COUNT
    );
  }
);
