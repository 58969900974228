import axiosInstance from "configs/axios";
import { map } from "lodash";
import CONFIG from "configs/config";
import { extractApiResponse } from "utils/helpers";

const URI_GET_PREVIEW_SLIDES = "/Presentation/getsanitizedpresentationpreview/";
const URI_GET_PREVIEW_SLIDE = "Presentation/getsanitizedslidepreview/";

export function PreviewSlides(data = {}) {
  this.highlightedSlide = data.highlightedSlide;
  this.sanitizedSlide = data.sanitizedSlide;
  this.slideIndex = data.slideNumber;
  this.slideNumber = data.pptSlideNumber;
  this.status = data.status;
  this.isIdentified = data.isIdentified;
}

// Multiple Slides
export function getPreviewSlidesAPI(pptId, startingSlideNo) {
  return axiosInstance
    .get(
      `${URI_GET_PREVIEW_SLIDES}${pptId}/${
        startingSlideNo ? startingSlideNo : 0
      }/${CONFIG.CONSTANTS.PREVIEW_DOCUMENT_SETTINGS.THUMBNAILS_COUNT}`
    )
    .then((response) =>
      map(extractApiResponse(response), (result) => new PreviewSlides(result))
    );
}

// Single Slide
export function getPreviewSlideAPI(pptId, slideId) {
  return axiosInstance
    .get(`${URI_GET_PREVIEW_SLIDE}${pptId}/${slideId}`)
    .then((response) => new PreviewSlides(extractApiResponse(response)));
}
