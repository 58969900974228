import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./PageHeader.scss";
import CONFIG from "configs/config";

import UserProfileImage from "containers/User/User";

// Images
import brandLogo from "assets/images/bcg-logo-white.svg";
import brandLogoBeta from "assets/images/bcg-logo-beta.png";
import { AALinkName } from "analytics/AnalyticsCapture";

/**
 * COMMON HEADER
 * Page header with Logo and User
 * @returns JSX
 */
const PageHeader = ({ onHomePageRedirect }) => (
  <header className="page-header">
    <Link
      to="/"
      className="page-header__section-left"
      onClick={() => {
        AALinkName("TAMS_Logo", "Link_click");
        onHomePageRedirect();
      }}
    >
      <div className="page-header__logo">
        <Image src={brandLogo} alt="BCG" verticalAlign="middle" />
      </div>
      <span className="page-header__brand-text">
        <Image src={brandLogoBeta} alt="TAMS-Beta" verticalAlign="middle" />
      </span>
    </Link>
    <div className="page-header__section-right">
      <UserProfileImage />
    </div>
  </header>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  isBeta: PropTypes.bool,
  onHomePageRedirect: PropTypes.func
};

PageHeader.defaultProps = {
  title: CONFIG.APP.TOOL_NAME,
  isBeta: CONFIG.APP.isBeta,
  onHomePageRedirect: noop
};

export default PageHeader;
