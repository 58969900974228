import { get } from "lodash";
import CONFIG from "configs/config";

/**
 * Get OKTA token from localstorage
 * @returns {String} OKTA Token
 */
export const getOktaTokenFromLocalStorage = () =>
  window.localStorage.getItem(CONFIG.STORAGE.OKTA_TOKE_KEY);

/**
 * Get OKTA token from localstorage
 * @returns {Object} OKTA Token
 */
export const getOktaTokenFromLocalStorageAsJSON = () => {
  const oktaToken = getOktaTokenFromLocalStorage();
  return oktaToken ? JSON.parse(oktaToken) : {};
};

/**
 * Get User HRID from local storage
 * @returns {String} HRID
 */
export const getHrIdFromLocalStorage = () =>
  get(getOktaTokenFromLocalStorageAsJSON(), "accessToken.claims.HRID", 0);
