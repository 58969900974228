import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import { find, groupBy, noop } from "lodash";
import "./TextAreaWithHighlight.scss";
import CONFIG from "configs/config";

import RenderIdentifiedPortion from "components/RenderIdentifiedPortion";
import ParagraphPlaceholder from "components/ParagraphPlaceholder";

import { getElementId } from "utils/common";
import { getCurrentWordWithBlock } from "utils/helpers";

/**
 * SANITIZE SCREEN
 * Shows text with highlighted text
 * @returns JSX
 */
const TextAreaWithHighlight = ({
  currentText,
  currentWord,
  setCurrentWord,
  showLoader,
  savedData,
  currentGroup,
  setCurrentGroupId,
  isTableText
}) => {
  const [lastActiveElement, setActiveElement] = useState({});
  const [currentHoverId, setCurrentHoverId] = useState("");

  const { text, portions } = currentText;
  const groupedPortions = groupBy(portions, "groupId");

  useEffect(() => {
    if (currentWord.id && currentGroup.id !== currentWord.groupId) {
      setCurrentGroupId(currentWord.groupId);
    }
    if (lastActiveElement.entityMappingId && !currentWord.id) {
      setActiveElement({});
    }
  }, [currentWord.id]);

  const onWordSelect = (metaWord) => {
    if (metaWord.entityMappingId !== currentWord.entityMappingId) {
      setCurrentWord(
        getCurrentWordWithBlock(metaWord, currentText, isTableText)
      );
      if (currentGroup.id !== metaWord.groupId) {
        setCurrentGroupId(metaWord.groupId);
      }
    }
  };

  const onWordHover = (portion) => {
    if (currentHoverId !== portion.entityMappingId) {
      setCurrentHoverId(portion.entityMappingId);
    }
  };

  const onClickGroup = (groupId) => {
    if (groupId && groupId !== currentGroup.id) {
      setCurrentGroupId(groupId);
      const firstIdentifiedPortion = find(groupedPortions[groupId], {
        isIdentified: true
      });
      if (
        firstIdentifiedPortion &&
        !firstIdentifiedPortion.isGroupManuallyEdited
      ) {
        setCurrentWord(
          getCurrentWordWithBlock(
            firstIdentifiedPortion,
            currentText,
            isTableText
          )
        );
      } else {
        setCurrentWord({});
      }
    }
  };

  useEffect(() => {
    if (
      currentWord.entityMappingId &&
      currentWord.isArrowClick &&
      lastActiveElement.entityMappingId !== currentWord.entityMappingId
    ) {
      setActiveElement(currentWord);
      document
        .getElementById(
          getElementId(
            currentWord.id,
            CONFIG.CONSTANTS.AUTO_SCROLL_PREFIX.WORD_HIGHLIGHT_LEFT
          )
        )
        ?.scrollIntoView({ block: "center" });
    }
  }, [currentWord, currentWord.isArrowClick]);

  return (
    <section className="text-area">
      {showLoader ? (
        <ParagraphPlaceholder
          numberOfLines={5}
          className="mt-2"
          testId="text-area-loader"
        />
      ) : (
        text && (
          <SimpleBar
            className="text-area-selector"
            data-testid="text-area-with-highlight"
          >
            {Object.keys(groupedPortions).map((gId) => {
              const portions = groupedPortions[gId];
              const manuallEdited = portions[0].isGroupManuallyEdited;
              let para;
              if (manuallEdited) {
                para = portions.map(({ id, text, separator }) => (
                  <React.Fragment key={id}>
                    {text}
                    {separator}
                  </React.Fragment>
                ));
              } else {
                para = portions.map((portion) => {
                  let part = null;
                  const { isIdentified, text, separator } = portion;
                  // When part of a word or multiple words identified as sensitive
                  if (isIdentified) {
                    part = (
                      <RenderIdentifiedPortion
                        portion={portion}
                        currentWord={currentWord}
                        savedData={savedData}
                        onWordSelect={onWordSelect}
                        onWordHover={onWordHover}
                        currentHoverId={currentHoverId}
                      />
                    );
                  } else {
                    part = <>{text}</>;
                  }
                  return (
                    <React.Fragment key={portion.id}>
                      {part}
                      {separator}
                    </React.Fragment>
                  );
                });
              }
              return (
                <p
                  key={gId}
                  className={`grouped-paragraph ${
                    currentGroup.id === gId ? "active-group" : ""
                  } ${manuallEdited ? "manually-edited" : ""}`}
                  onClick={() => {
                    onClickGroup(gId);
                  }}
                >
                  <span key={gId}>{para}</span>
                </p>
              );
            })}
          </SimpleBar>
        )
      )}
    </section>
  );
};

TextAreaWithHighlight.propTypes = {
  currentText: PropTypes.object,
  currentWord: PropTypes.object,
  showLoader: PropTypes.bool,
  setCurrentWord: PropTypes.func,
  savedData: PropTypes.array,
  currentGroup: PropTypes.object,
  setCurrentGroupId: PropTypes.func,
  isTableText: PropTypes.bool
};

TextAreaWithHighlight.defaultProps = {
  currentText: {},
  currentWord: {},
  savedData: [],
  setCurrentWord: noop,
  currentGroup: {},
  setCurrentGroupId: noop,
  isTableText: false
};

export default TextAreaWithHighlight;
