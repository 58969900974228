import { isEmpty, keyBy, map, pick } from "lodash";
import { combineReducers } from "redux";
import { RESET_IMAGE_TO_ORIGINAL } from "containers/SmartSuggestion/types";
import { resourceReducer } from "redux/sagas/networkResource";
import CONFIG from "configs/config";
import * as at from "../types";
import { canApplySmartOption } from "utils/helpers";

function selectedImages(state = [], { type, payload }) {
  switch (type) {
    case at.IMAGE_FETCH_IMAGELIST_REQUESTED:
      return [];
    case at.IMAGE_SET_CURRENT_IMAGE:
      return [payload.id];
    case at.IMAGE_SELECT_IMAGE:
      return [...state, payload];
    case at.IMAGE_DESELECT_IMAGE:
      return state.filter((imageId) => imageId !== payload);
    case at.IMAGE_RESET_SELECTED_IMAGES:
    case at.IMAGE_RESET_IMAGE_STATE:
      return [];
    case at.IMAGE_SELECT_ALL_IMAGES:
      return payload.map((img) => img.id);
    case at.EXIT_MULTISELECT_IMAGE: {
      return state[0] ? [state[0]] : state;
    }
    default:
      return state;
  }
}

function imageDimensionDict(state = {}, { type, payload }) {
  switch (type) {
    case at.IMAGE_STORE_IMAGE_DIMENSION:
      return { ...state, ...payload };
    default:
      return state;
  }
}

function selectedSmartOption(state = "", action) {
  switch (action.type) {
    case at.SELECT_SMART_OPTION_FOR_IMAGE:
      return action.payload;
    case RESET_IMAGE_TO_ORIGINAL:
      return CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET;
    case at.TOGGLE_MULTISELECT_IMAGE:
    case at.IMAGE_FETCH_IMAGELIST_REQUESTED:
      return "";
    default:
      return state;
  }
}

function imageList(state, action) {
  switch (action.type) {
    case at.IMAGE_REPLACE_IMAGELIST: {
      return { data: action.payload };
    }

    case at.IMAGE_UPDATE_IMAGELIST: {
      if (!isEmpty(action.payload)) {
        const responseByKey = keyBy(action.payload, "id");
        const updatedStateData = state.data.map((img) => {
          if (responseByKey[img.id]) {
            return {
              ...responseByKey[img.id],
              ...pick(img, ["redactedImageUrl", "imageType"])
            };
          }
          return img;
        });
        return { ...state, data: updatedStateData };
      }
      return state;
    }

    case at.IMAGE_RESET_MANUALLY_EDITED_IMAGE_SUCCEEDED: {
      if (action.args && action.args[0].length) {
        const requestPayload = action.args[0][0];
        const list = state.data;
        return {
          data: list.map((i) =>
            requestPayload.id === i.id ? { ...i, ...requestPayload } : i
          )
        };
      }
      return state;
    }

    case at.IMAGE_SET_CURRENT_IMAGE: {
      const { id, isGraph, isArrowClick = false } = action.payload;
      if (id) {
        const newData = state.data.map((img) => {
          if (img.id === id) {
            const canApply = canApplySmartOption(img);
            if (canApply) {
              return {
                ...img,
                smartOption: !isGraph
                  ? CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH
                  : CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT
              };
            }
            return {
              ...img,
              isArrowClick
            };
          }
          return img;
        });
        return {
          ...state,
          data: newData
        };
      }
      return state;
    }

    case at.IMAGE_RESET_IMAGE_STATE:
      return { data: [] };

    default:
      return resourceReducer(at.IMAGE_FETCH_IMAGELIST, {
        reset: true
      })(state, action);
  }
}

function isMultiSelect(state = false, { type }) {
  switch (type) {
    case at.EXIT_MULTISELECT_IMAGE:
    case at.TOGGLE_MULTISELECT_IMAGE_OFF:
      return false;
    case at.TOGGLE_MULTISELECT_IMAGE:
      return !state;
    default:
      return state;
  }
}

function currentEditedImageDataUri(state = "", action) {
  switch (action.type) {
    case at.IMAGE_SET_CURRENT_EDITED_IMAGE_DATA_URI:
      return action.payload;
    case at.IMAGE_SET_CURRENT_IMAGE:
    case at.TOGGLE_MULTISELECT_IMAGE:
    case at.IMAGE_FETCH_IMAGELIST_REQUESTED:
      return "";
    default:
      return state;
  }
}

function stampedImages(state = {}, { type, payload = {} }) {
  switch (type) {
    case at.IMAGE_FETCH_AUTO_EDITED_IMAGES_SUCCEEDED:
      return { ...state, ...payload.data };

    // extract already saved stamped image in the store, to avoid calling the api again when rechecking the same option
    case at.IMAGE_FETCH_IMAGELIST_SUCCEEDED:
      if (payload?.data.length) {
        const newState = { ...state };
        payload.data.forEach((image) => {
          if (
            image.savedSmartOption ===
              CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP &&
            image.modifiedImageUrl
          ) {
            newState[image.id] = {
              url: image.modifiedImageUrl,
              key: image.uploadObjectKey
            };
          }
        });
        return newState;
      }
      return state;
    default:
      return state;
  }
}

function individualImageSaveStatus(state = [], { type, args = [] }) {
  switch (type) {
    case at.IMAGE_SAVE_UPLOAD_TO_S3_REQUESTED:
    case at.IMAGE_SAVE_UPDATED_IMAGES_REQUESTED:
      if (args[0] && args[0].length) {
        return [...state, ...map(args[0], "id")];
      }
      return state;

    case at.IMAGE_SAVE_UPLOAD_TO_S3_SUCCEEDED:
    case at.IMAGE_SAVE_UPDATED_IMAGES_SUCCEEDED:
    case at.IMAGE_SAVE_UPLOAD_TO_S3_FAILED:
    case at.IMAGE_SAVE_UPDATED_IMAGES_FAILED: {
      if (args[0] && args[0].length) {
        const ids = map(args[0], "id");
        return state.filter((id) => !ids.includes(id));
      }
      return state;
    }

    default:
      return state;
  }
}

export default combineReducers({
  imageList,
  selectedImages,
  selectedSmartOption,
  isMultiSelect,
  imageDimensionDict,
  currentEditedImageDataUri,
  stampedImages,
  individualImageSaveStatus
});
