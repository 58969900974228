import React from "react";
import PropTypes from "prop-types";
import { Header, List } from "semantic-ui-react";
import { isArray, map } from "lodash";

const Pages = ({ currentPageData }) => {
  if (!currentPageData) {
    return null;
  }
  return (
    <div className="user-guide__description-container">
      <Header as="h1">{currentPageData.HEADER}</Header>
      <List as="ol" ordered>
        {currentPageData.CONTENTS.map((content, index) => {
          if (isArray(content)) {
            return map(content, (subContent, key) => (
              <List.Item key={index + key} as="li">
                {subContent.item}
                <List.List as="ol">
                  {subContent.subItems.map((item, idx) => {
                    return (
                      <List.Item as="li" key={idx} value="■">
                        {item}
                      </List.Item>
                    );
                  })}
                </List.List>
              </List.Item>
            ));
          }
          return (
            <List.Item key={index} as="li">
              {content}
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

Pages.propTypes = {
  currentPageData: PropTypes.object
};

export default Pages;
