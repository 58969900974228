/**
 * An utility reducer which will listen the three (REQUESTED, SUCCEEDED, FAILED) different types actions from the configured list of resources to be track
 * and make an entry into the redux store as bool for the respective resources.
 *
 * For eg: If a action type (FETCH_USER) is configured using "configureLoadingState" fn,
 * all the actions with FETCH_USER_REQUESTED will make an entry into state as true (indicating FETCH_USER is loading),
 * and reset to false when any of the following acitons are triggered (FETCH_USER_SUCCEEDED/FETCH_USER_FAILED)
 *
 * Please also read about "createLoadingSelector", which can be used to select the loading state from store
 */

import CONFIG from "configs/config";

export const LIST_OF_RESOURCE_TO_TRACK = [];

/**
 * Configure the list of resources to be tracked as part of loading resources.
 * @param {array} resourceList list of resources to be tracked
 *
 */
export const configureLoadingState = (resourceList) => {
  if (!resourceList.length || !Array.isArray(resourceList)) {
    return;
  }
  resourceList.map((resource) => LIST_OF_RESOURCE_TO_TRACK.push(resource));
};

export default (state = {}, action) => {
  const { type } = action;
  const regEx = new RegExp(
    `(.*)_(${CONFIG.CONSTANTS.REQUESTED}|${CONFIG.CONSTANTS.SUCCEEDED}|${CONFIG.CONSTANTS.FAILED})$`
  );
  const matches = regEx.exec(type);

  // not a *_REQUESTED / *_SUCCEEDED / *_FAILED actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  if (!LIST_OF_RESOURCE_TO_TRACK.includes(requestName)) {
    return state;
  }

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUESTED
    // and false when receiving GET_TODOS_SUCCEEDED / GET_TODOS_FAILED
    [requestName]: requestState === CONFIG.CONSTANTS.REQUESTED
  };
};
