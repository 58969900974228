// ---------------- FILE EXTENSION -------------- //
export const PPTX = "pptx";

// ---------------- TEXT PARSING -------------- //
export const WORD_SEPARATOR = " ";
export const WORD_ID_SEPARATOR = ",";
export const WORD_ID_SEPARATOR_WITH_SPACE = ", ";

// ---------------- API CALL STATES -------------- //
export const REQUESTED = "REQUESTED";
export const SUCCEEDED = "SUCCEEDED";
export const FAILED = "FAILED";

// ---------------- SLIDE IMAGE -------------- //
export const SLIDE_IMAGE_RATIO = {
  STANDARD: "4:3",
  WIDESCREEN: "16:9",
  OTHER: "other"
};

export const SLIDE_IMAGE_TYPE = {
  STANDARD: "standard-4-3",
  WIDESCREEN: "wide-16-9"
};

// ---------------- SMART OPTIONS -------------- //
export const IMAGE_SMART_OPTION = {
  PATCH: "patch",
  BLUR: "blur",
  STAMP: "stamp",
  MANUALEDIT: "manual-edit",
  RESET: "reset",
  SMARTEDIT: "smart-edit"
};

export const IMAGE_SMART_OPTION_MAPPER = {
  1: IMAGE_SMART_OPTION.BLUR,
  2: IMAGE_SMART_OPTION.STAMP,
  3: IMAGE_SMART_OPTION.PATCH,
  4: IMAGE_SMART_OPTION.MANUALEDIT,
  5: IMAGE_SMART_OPTION.RESET,
  6: IMAGE_SMART_OPTION.SMARTEDIT
};

export const IMAGE_SMART_OPTION_REMAPPER = {
  [IMAGE_SMART_OPTION.BLUR]: 1,
  [IMAGE_SMART_OPTION.STAMP]: 2,
  [IMAGE_SMART_OPTION.PATCH]: 3,
  [IMAGE_SMART_OPTION.MANUALEDIT]: 4,
  [IMAGE_SMART_OPTION.RESET]: 5,
  [IMAGE_SMART_OPTION.SMARTEDIT]: 6
};

export const IMAGE_EDITOR_AIML_DATA_MAPPER = {
  stamp: 0,
  text: 1,
  "text-background": 2,
  "selected-blur-pixelate": 3,
  blur: 4,
  pixelate: 5
};

export const DERIVED_IMAGE_TYPE = {
  PORTRAIT: "portrait",
  LOGO: "logo",
  GENERIC: "generic"
};

// ---------------- SLIDE LEVEL STATUS MAPPING -------------- //
export const SLIDE_PROGRESS_STATUS = {
  0: "Unsanitized",
  1: "Partially Sanitized/In Progress",
  2: "Sanitized",
  3: "Manually Sanitized (Ignored)"
};

export const SLIDE_PROGRESS_STATUS_PREVIEW_THUMBNAILS = {
  0: "Unsanitized",
  1: "Partially Sanitized/In Progress",
  2: "Sanitized",
  3: "Manually Sanitized"
};

export const PREVIEW_DOCUMENT_SETTINGS = {
  THUMBNAILS_COUNT: 7
};

export const RESET_SLIDE_STATUS = "Reset slide status";
export const MARK_SLIDE_AS_SANITIZED = "Mark slide as sanitized";

// ---------------- S3 UPLOAD FILE TYPES -------------- //
export const S3_UPLOAD_FILE_TYPE = {
  PPT: 1,
  IMAGES: 2
};

// ---------------- IMAGE EDITOR OPTIONS -------------- //
export const IMAGE_EDITOR = {
  BLUR_INITIAL_VALUE_SMART: 25,
  BLUR_INITIAL_VALUE_MANUAL: 1,

  // Color Picker (Also using in text background, first element)
  COLOR_CODES: [
    "#9A9A9A",
    "#29BA74",
    "#E71C57",
    "#6E6F73",
    "#3EAD92",
    "#197A56",
    "#03522D",
    "#D4DF33",
    "#03522D",
    "#670F31",
    "#295E7E",
    "#A8B21C",
    "#30C1D7",
    "#E71C57",
    "#D4DF33",
    "#29BA74"
  ]
};

// ---------------- FILE STATUS -------------- //
export const UPLOAD_FILE_STATUSES = {
  UPLOADING: "Uploading",
  UPLOADED: "Uploaded",
  UPLOAD_FAILED: "UploadFailed",
  EXTRACTING: "Extracting",
  EXTRACTED: "Extracted",
  EXTRACTION_FAILED: "ExtractionFailed",
  AI_PROCESSING: "AIProcessing",
  AI_COMPLETED: "AICompleted",
  AI_FAILED: "AIFailed",
  RECOMMENDATIONS_APPLIED: "RecommendationsApplied",

  // EXTRA STATUS for FE
  SANITIZED: "Sanitized",
  IN_PROGRESS: "In Progress"
};

export const DISPLAY_FILE_STATUS = {
  [UPLOAD_FILE_STATUSES.UPLOADING]: "Uploading",
  [UPLOAD_FILE_STATUSES.UPLOADED]: "Uploaded",
  [UPLOAD_FILE_STATUSES.UPLOAD_FAILED]: "Upload failed",
  [UPLOAD_FILE_STATUSES.EXTRACTING]: "Preparing for Sanitization",
  [UPLOAD_FILE_STATUSES.EXTRACTED]: "Preparing for Sanitization",
  [UPLOAD_FILE_STATUSES.EXTRACTION_FAILED]: "Preparing for Sanitization failed",
  [UPLOAD_FILE_STATUSES.AI_PROCESSING]: "Generating Smart Recommendations",
  [UPLOAD_FILE_STATUSES.AI_COMPLETED]: "Generating Smart Recommendations",
  [UPLOAD_FILE_STATUSES.AI_FAILED]: "Recommendations Generation failed",
  [UPLOAD_FILE_STATUSES.RECOMMENDATIONS_APPLIED]: "Ready for Sanitization"
};

export const FAILED_STATUSES = [
  UPLOAD_FILE_STATUSES.EXTRACTION_FAILED,
  UPLOAD_FILE_STATUSES.AI_FAILED,
  UPLOAD_FILE_STATUSES.UPLOAD_FAILED
];

export const SUCCESS_STATUSES = [UPLOAD_FILE_STATUSES.RECOMMENDATIONS_APPLIED];

export const ALLOWED_STATUS_TO_DELETE = [
  ...FAILED_STATUSES,
  ...SUCCESS_STATUSES
];

// ---------------- FILE STATUS POLLING OPTIONS -------------- //
export const LIST_OF_OPEN_STATUS_TO_POLL = [
  UPLOAD_FILE_STATUSES.UPLOADING,
  UPLOAD_FILE_STATUSES.UPLOADED,
  UPLOAD_FILE_STATUSES.EXTRACTING,
  UPLOAD_FILE_STATUSES.EXTRACTED,
  UPLOAD_FILE_STATUSES.AI_PROCESSING,
  UPLOAD_FILE_STATUSES.AI_COMPLETED
];

export const POLL_INTERVAL = 5000; // in ms, 5 secs

// ---------------- FILE STATUS RETRY OPTIONS -------------- //
export const LIST_OF_OPEN_STATUS_TO_RETRY = [
  UPLOAD_FILE_STATUSES.EXTRACTION_FAILED
];

export const MAX_FILE_RETRY_COUNT = 3;
export const FILE_RETRY_INTERVAL = 2 * 60 * 1000; // in ms

export const RETRY_BATCH_SIZE = 3;
export const RETRY_BATCH_INTERVAL = 3 * 60 * 1000; // in ms

// ---------------- DIFFERENT TYPE OF SECTIONS -------------- //
export const EDITOR_SECTIONS = {
  TEXT: "Text",
  IMAGES: "Images",
  GRAPHS: "Graphs",
  TABLES: "Tables"
};

export const DATE_TIME_FORMATS = {
  SERVER_DATE_TIME: "YYYY-MM-DDThh:mm:ss:SZ",
  LOCAL_DATE: "DD-MMM-YYYY",
  LOCAL_DATE_TIME: "DD-MMM-YYYY h:mm:ss A"
};

export const USER_GUIDE_TRIGGERS = {
  HOME: "home",
  SANITIZE: "sanitize",
  PREVIEW: "preview"
};

export const USER_GUIDE_DISPLAY_TEXT = {
  [USER_GUIDE_TRIGGERS.HOME]: "Home",
  [USER_GUIDE_TRIGGERS.SANITIZE]: "Sanitize",
  [USER_GUIDE_TRIGGERS.PREVIEW]: "Preview"
};

export const AUTO_SCROLL_PREFIX = {
  WORD_HIGHLIGHT_LEFT: "word-highlight-left",
  TEXT_GROUP_PREVIEW: "text-group-preview"
};
