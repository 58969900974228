import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Image, Placeholder } from "semantic-ui-react";
import { noop, omit } from "lodash";
import "./SuggestionFooter.scss";
import CONFIG from "configs/config";

import resetGreen from "assets/images/reset-green.svg";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

const SuggestionFooter = ({
  currentEntity,
  resetToOriginal,
  showLoader,
  canReset,
  textId
}) => {
  let { id: pptId } = useParams();
  if (showLoader) {
    return (
      <Placeholder
        className="suggestion-footer"
        data-testid="s-footer-loader"
      />
    );
  }
  return (
    <div className="suggestion-footer">
      <a
        className={`suggestion-footer__reset-button ${!canReset && "disabled"}`}
        data-testid="suggestion-footer-button"
        onClick={(e) => {
          e.preventDefault();
          if (canReset) {
            resetToOriginal({
              ...omit(currentEntity, ["updatedText"]),
              pptId,
              ...(textId ? { textId } : {})
            });
          }
          onAAClickEvents("Reset_To_Original");
        }}
      >
        <Image
          className="suggestion-footer__icon mr-1"
          src={resetGreen}
          alt=""
          verticalAlign="middle"
        />
        {CONFIG.LABELS.RESET_TO_ORIGINAL}
      </a>
    </div>
  );
};

SuggestionFooter.propTypes = {
  currentEntity: PropTypes.object,
  resetToOriginal: PropTypes.func,
  showLoader: PropTypes.bool,
  canReset: PropTypes.bool,
  textId: PropTypes.string
};

SuggestionFooter.defaultProps = {
  currentEntity: {},
  resetToOriginal: noop,
  showLoader: false,
  canReset: false,
  textId: ""
};

export default SuggestionFooter;
