import PropTypes from "prop-types";
import axios from "axios";
import axiosInstance from "configs/axios";
import CONFIG from "configs/config";
import oktaAuth from "configs/okta/oktaAuth";
import { getHrIdFromLocalStorage } from "utils/localStorage/localStorage";
import { extractApiResponse } from "utils/helpers";
import { getPeopleIdsFormat } from "utils/common";

const GET_SIGNED_TOKEN_URI = "/Presentation/getsignedurl";

function User({ hr_id, given_name, family_name, email }) {
  return {
    hrId: hr_id || getHrIdFromLocalStorage(),
    name: `${given_name} ${family_name}`,
    email
  };
}

export const userType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(User)
]);

export function getOktaUserInfo() {
  return oktaAuth.getUser().then((data) => new User(data));
}

export const getUserProfilePic = (hrId) => {
  const accessToken = oktaAuth.getAccessToken();
  const additionConfigs = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "x-api-key": CONFIG.SERVER.PEOPLE_X_API_KEY,
      psid: ""
    }
  };
  let data = {
    query: `query {
              employees(ids:${getPeopleIdsFormat(hrId)}) {
                picture
                id
              }
            }`
  };
  return axios
    .post(CONFIG.SERVER.USER_PROFILE_API, data, additionConfigs)
    .then((response) => {
      if (response.data) {
        const {
          data: {
            data: { employees }
          }
        } = response;

        if (employees && employees.length > 0) {
          const { picture } = employees[0];
          return picture;
        }
      }
      return null;
    })
    .catch(() => {
      return null;
    });
};

export const getSignedToken = () => {
  return axiosInstance.get(GET_SIGNED_TOKEN_URI).then((response) => {
    const { domain, signedUrl, expiry } = extractApiResponse(response);
    const qs = (signedUrl || "").split("?")[1];
    return {
      domain: domain,
      qs,
      expiry: Number(expiry) * 1000 // in milliseconds
    };
  });
};
