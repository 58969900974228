import {
  find,
  isEmpty,
  sortBy,
  memoize,
  groupBy,
  some,
  first,
  last,
  get,
  forEach,
  map,
  findIndex,
  clamp,
  isUndefined,
  join,
  filter,
  sortedUniq,
  pull,
  size,
  pick
} from "lodash";

import CONFIG from "configs/config";
import { indexOfObject } from "utils/common";

/**
 * Return first text from the list if available
 */
export function getActiveText(textList) {
  if (!textList.length) {
    return {};
  }
  return first(textList);
}

/**
 * Return the currently selected word's identified Block
 *
 * @param {Array} identifiedBlocks
 * @param {String} mappingId
 * @returns {Object} identified block
 */
export function getCurrentIdentifiedBlockForWord(identifiedBlocks, mappingId) {
  return (
    find(identifiedBlocks, (block) =>
      block.entityMappingId.includes(mappingId)
    ) || {}
  );
}

/**
 *
 * @param {Object} currentText
 * @param {Number} selectWordAtIndex
 * @returns {Object} current word data (portion and addition data merged from identified block like, recommendation)
 */
export function getActiveWord(
  { portions, identifiedBlocks = [], isArrowClick = false },
  selectWordAtIndex = 0
) {
  const filteredBlocks = identifiedBlocks.filter(
    (block) => !block.skipHighlight
  );
  if (filteredBlocks && filteredBlocks.length) {
    const block =
      selectWordAtIndex === -1
        ? last(filteredBlocks)
        : get(filteredBlocks, `[${selectWordAtIndex}]`, {});
    return {
      ...getPortionByEntityBlock(portions, block),
      entity: block.entity,
      recommendations: block.recommendations,
      isArrowClick
    };
  }
  return {};
}

/**
 * Return portin matching current block
 *
 * @param {Array} portions
 * @param {Object} block
 * @returns {Object} found portion
 */
export function getPortionByEntityBlock(portions, block) {
  return find(portions, (port) => block.entityMappingId.includes(port.id));
}

/**
 * Return current word data with entity and recommendation from identified block
 *
 * @param {Object} word
 * @param {Object} text
 * @param {Boolean} withTextId
 * @returns {Object} word data
 */
export function getCurrentWordWithBlock(word, text, withTextId = false) {
  return {
    ...word,
    ...pick(getCurrentIdentifiedBlockForWord(text.identifiedBlocks, word.id), [
      "entity",
      "recommendations"
    ]),
    ...(withTextId ? { textId: text.id } : {})
  };
}

/**
 * Look for the first portion from unedited paragraph, if all of them are edited, go with default (first paragraph)
 * @param {Array} portions
 * @returns {String} groupId
 */
export function getFirstNonEditedPortionGroup(portions) {
  let portion;
  const hasPortion = some(portions, (port) => {
    if (!port.isGroupManuallyEdited) {
      portion = port;
      return true;
    }
    return false;
  });
  if (hasPortion) {
    return get(portion, "groupId", "");
  }
  return getFirstPortionGroup(portions);
}

/**
 * First portion's GroupId
 * @param {Array} portions
 * @returns {String}
 */
export function getFirstPortionGroup(portions = []) {
  return first(portions)?.groupId;
}

/**
 * Trim string
 *
 * @param {String} text
 * @param {Boolean} trim
 * @returns {String}
 */
export function getDisplayText(text, trim = false) {
  return trim ? text.trim() : text;
}

/**
 * Edited paragraph length including space between paragraph
 *
 * @param {Object} paraById
 * @returns {Number}
 */
export function getEditedParaLength(paraById) {
  let len = 0;
  forEach(paraById, (para) => {
    if (para) {
      len += para.length + 1; // 1 for extra space used to join te para
    }
  });
  return len > 0 ? len - 1 : 0;
}

/**
 * Create text merging portion's text or updated based on updated property
 *
 * @param {Array} portions
 * @param {Boolean} updated whether to use portion.text or portion.updatedText
 * @returns {String} concatinated portion text
 */
export function getDisplayTextFromPortion(portions = [], updated = true) {
  let text = "";
  portions.forEach((port) => {
    text +=
      (updated && port.isUpdated ? port.updatedText || "" : port.text) +
      port.separator;
  });
  return text.trim();
}

/**
 * Return Portion object when manual edit
 *
 * @param {Object} portion
 * @param {String} updatedText
 * @returns {Object} updated portion with default properties
 */
function getUpdatedManualPortion(portion, updatedText) {
  return {
    ...portion,
    isGroupManuallyEdited: true,
    isUpdated: true,
    isReset: false,
    updatedText,
    isFromSuggestion: false
  };
}

/**
 * Reset each portion and its default property when reseting manual changes
 *
 * @param {Array} portions
 * @param {String} groupId
 * @returns {Array} updated portions
 */
export function getManualResetPortions(portions, groupId) {
  return portions.map((port) =>
    port.groupId === groupId
      ? {
          ...port,
          isGroupManuallyEdited: false,
          isUpdated: false,
          isReset: false,
          updatedText: null,
          isFromSuggestion: false
        }
      : port
  );
}

/**
 * Merge Text with Recommendations data (received from recommendation service)
 * Extract Identified blocks (which is, portion object  processed by identified entity),
 * which will be used to derive identified texts (to highlight on ui), update portions updated text
 *
 * @param {Object} text
 * @param {String} suggestions
 * @returns {Object} updated text (with identifiedBlocks)
 */
export const getCurrentTextWithSuggestions = memoize((text, suggestions) => {
  if (isEmpty(suggestions) || text.id !== suggestions.id) {
    return text;
  }
  const { entities } = suggestions;
  const { portions } = text;
  // No suggestions, so no highlight is required
  if (!entities.length) {
    return text;
  }
  const sortedEntities = sortBy(entities, (entity) => entity.startPos);
  const identifiedBlocks = [];
  forEach(sortedEntities, ({ entity, entityIds = [], recommendations }) => {
    let entityIdStr = entityIds.join(",");
    let filteredPortions;
    // When more than one word identified as one sensitive entity (for eg: ABC Corportion)
    if (entityIds.length > 1) {
      filteredPortions = filter(
        portions,
        (port) => port.identifiedEntityGroup === entityIdStr
      );
    } else {
      // When one entity identified in a single portion (BCG)
      filteredPortions = filter(
        portions,
        (port) =>
          entity === port.text && port.identifiedEntityGroup === entityIdStr
      );
    }
    const firstPortion = filteredPortions[0] || {};
    identifiedBlocks.push({
      entityMappingId: map(filteredPortions, (p) => p.id || p.parentId), // save the portion ids, and use it to match with selected portion (widely used)
      parentId:
        entityIds.length === 1 && firstPortion[0]?.parentId
          ? firstPortion.parentId
          : entityIdStr,
      entity, // identified entity text (ABC Corportation, or BCG)
      skipHighlight: !!firstPortion.isGroupManuallyEdited, // flag, used to skip highlighting when travelling between word through smart suggestion arrows
      groupId: firstPortion.groupId, // GroupId of the first portion
      recommendations // Recommendation list for the current entity (received from recommendation api)
    });
  });
  const updatedPortions = portions.map((portion) => {
    if (portion.identifiedEntityGroup) {
      const portionBlock = find(identifiedBlocks, (block) =>
        block.entityMappingId.includes(portion.id)
      );
      const mappingId = get(portionBlock, "entityMappingId", []);
      portion.entityMappingId = mappingId.join(",");
      // Group two or more portion as same when two or more portions identified together but fall under different group
      // save the original group id and use that while saving to BE (refer api/text.js)
      if (mappingId.length > 1 && portionBlock.groupId !== portion.groupId) {
        portion.originalGroupId = portion.groupId;
        portion.groupId = portionBlock.groupId;
      }
    }
    return portion;
  });
  return {
    ...text,
    identifiedBlocks,
    portions: updatedPortions
  };
});

/**
 * Return the identified portion ids if available else, return all the portion ids
 * Used for text highlight
 *
 * @param {Object} suggestions
 * @returns {Array} portion id array
 */
export function getIdentifiedPortionIds(suggestions) {
  const { portions = [], entities = [] } = suggestions;
  if (entities.length) {
    const ids = join(map(sortedUniq(entities), (entity) => entity.entityIds))
      .toString()
      .split(CONFIG.CONSTANTS.WORD_ID_SEPARATOR);
    return ids;
  }
  return sortedUniq(portions.map((port) => port.id));
}

/**
 * Return the sanitized data for the current portion (if available)
 *
 * @param {Array} sanitizedData
 * @param {Object} portion
 * @returns {Object} sanitized (saved) portion data from store
 */
export function getSanitizedWord(sanitizedData, portion) {
  return find(sanitizedData, { entityMappingId: portion.entityMappingId });
}

/**
 * Return highlight className (css) based on the save data or current word
 *
 * @param {Object} currentWord
 * @param {Object} portion
 * @param {Array} savedData
 * @returns {String} className to apply on word
 */
export function getHighlightClassName(currentWord, portion, savedData) {
  const visibility = getSanitizedWord(savedData, portion)
    ? "visited"
    : "not-visited";
  return portion.entityMappingId === currentWord.entityMappingId
    ? `current-${visibility}`
    : visibility;
}

/**
 * Reture updated text list with identifiedBlocks for entire text list (Used in Table Text Manual edit)
 *
 * @param {Array} list text list array
 * @param {*} suggestions suggestions for entire text list
 * @returns {Array} updated text list
 */
export function attachIdentifiedBlocks(list, suggestions) {
  return map(list, (text) => {
    const suggetionsForText = suggestions[text.id];
    if (suggetionsForText) {
      return {
        ...getCurrentTextWithSuggestions(text, suggetionsForText),
        dirty: true
      };
    }
    return text;
  });
}

/**
 * Return then Prev word (inside the current text), else prev text if available, else empty object
 *
 * @param {Array} textList
 * @param {Object} currentText
 * @param {Object} currentWord
 * @returns {Object} prevWord or text object
 */
export function getPrevWordOrText(textList, currentText, currentWord) {
  const { identifiedBlocks = [] } = currentText;
  const filteredBlocks = identifiedBlocks.filter(
    (block) => !block.skipHighlight
  );
  if (!isEmpty(currentWord)) {
    const currentWordIdx = findIndex(filteredBlocks, (block) =>
      block.entityMappingId.includes(currentWord.id)
    );

    const clampWordIdx = clamp(
      currentWordIdx - 1,
      0,
      filteredBlocks.length - 1
    );

    const prevWord = get(filteredBlocks, `[${clampWordIdx}]`, false);
    if (prevWord && clampWordIdx !== currentWordIdx) {
      return getActiveWord(currentText, clampWordIdx);
    }
  }

  const currentIdx = indexOfObject(textList, currentText, "id");
  const clampTextIdx = clamp(currentIdx - 1, 0, textList.length - 1);
  const prevText = get(textList, `[${clampTextIdx}]`, {});

  if (prevText.id !== currentText.id) {
    return prevText;
  }
  return {};
}

/**
 * Return then Next word (inside the current text), else next text if available, else empty object
 *
 * @param {Array} textList
 * @param {Object} currentText
 * @param {Object} currentWord
 * @returns {Object} next word or text object
 */
export function getNextWordOrText(textList, currentText, currentWord) {
  const { identifiedBlocks = [] } = currentText;
  const filteredBlocks = identifiedBlocks.filter(
    (block) => !block.skipHighlight
  );
  if (!isEmpty(currentWord)) {
    const currentWordIdx = findIndex(filteredBlocks, (block) =>
      block.entityMappingId.includes(currentWord.id)
    );

    const clampWordIdx = clamp(
      currentWordIdx + 1,
      0,
      filteredBlocks.length - 1
    );
    const nextWord = get(filteredBlocks, `[${clampWordIdx}]`, false);

    if (nextWord && clampWordIdx !== currentWordIdx) {
      return getActiveWord(currentText, clampWordIdx);
    }
  }

  const currentIdx = indexOfObject(textList, currentText, "id");
  const clampTextIdx = clamp(currentIdx + 1, 0, textList.length - 1);
  const nextText = get(textList, `[${clampTextIdx}]`, {});

  if (nextText.id !== currentText.id) {
    return nextText;
  }
  return {};
}

/**
 * Group updated paragraph by groupId
 *
 * @param {Object} currentText
 * @returns {Object} grouped para by groupId
 */
export function getGroupedParaByGroupId(currentText) {
  const gp = groupBy(currentText.portions, "groupId");
  const group = {};
  Object.keys(gp).forEach((gId) => {
    group[gId] = getDisplayTextFromPortion(gp[gId]);
  });
  return group;
}

/**
 * Group original paragraph by groupId
 *
 * @param {Object} currentText
 * @returns {Object} grouped para by groupId
 */
export function getGroupedOriginalParaByGroupId(currentText) {
  const gp = groupBy(currentText.portions, "groupId");
  const group = {};
  Object.keys(gp).forEach((gId) => {
    group[gId] = getDisplayTextFromPortion(gp[gId], false);
  });
  return group;
}

/**
 * Group portion by groupId
 *
 * @param {Array} portions
 * @returns {Object} grouped portion by groupId
 */
export function getGroupedPortionByGroupId(portions) {
  const gp = groupBy(portions, "groupId");
  const group = {};
  Object.keys(gp).forEach((gId) => {
    group[gId] = gp[gId];
  });
  return group;
}

/**
 * Update portion properties when reset to original
 * Update isUpdated, updatedText, isReset based on the payload value
 *
 * @param {Array} portions
 * @param {Object} payload
 * @param {Boolean} isReset
 * @returns {Array} updated portion with updatedText
 */
export function getUpdatedPortionForReset(portions, payload, isReset) {
  const { entityMappingId } = payload;
  const wordIdList = entityMappingId.split(CONFIG.CONSTANTS.WORD_ID_SEPARATOR);
  return portions.map((portion) => {
    if (wordIdList.includes(portion.id)) {
      pull(wordIdList, portion.id);
      const newPortion = {
        ...portion,
        isFromSuggestion: false,
        updatedText: isReset ? portion.text : null,
        isUpdated: isReset,
        isReset: isReset
      };
      return newPortion;
    } else {
      return portion;
    }
  });
}

/**
 * Update portion properties when selecting smart suggestion or adding custom type text
 * Update isUpdated, updatedText, isFromSuggestion based on the payload value
 *
 * @param {Array} portions
 * @param {Object} payload
 * @param {Boolean} isReset
 * @returns {Array} updated portion with updatedText
 */
export function getUpdatedPortionForReplacementText(
  portions,
  payload,
  isFromSuggestion
) {
  const { updatedText, entityMappingId } = payload;
  if (!updatedText || !entityMappingId) {
    return portions;
  }
  const newWordList = updatedText.trim().split(CONFIG.CONSTANTS.WORD_SEPARATOR);
  const wordIdList = entityMappingId.split(CONFIG.CONSTANTS.WORD_ID_SEPARATOR);

  let wordInc = 0;
  const noOfPortions = wordIdList.length;
  const hasMoreNewWords = newWordList.length > noOfPortions;
  return portions.map((portion) => {
    if (wordIdList.includes(portion.id)) {
      pull(wordIdList, portion.id);
      const newPortion = {
        ...portion,
        isFromSuggestion,
        isUpdated: true,
        isReset: false,
        updatedText: newWordList[wordInc]
          ? hasMoreNewWords && wordIdList.length === 0
            ? newWordList.slice(wordInc).join(CONFIG.CONSTANTS.WORD_SEPARATOR)
            : newWordList[wordInc]
          : ""
      };
      wordInc++;
      return newPortion;
    } else {
      return portion;
    }
  });
}

/**
 * Return updated portion in case of manual edit on the current paragaraph
 *
 * @param {Array} portions
 * @param {Object} modifiedParaByGroup modified para by Group id
 * @returns {Array} updated portions
 */
export function getUpdatedPortionFromManualEdit(portions, modifiedParaByGroup) {
  const groupedPortions = getGroupedPortionByGroupId(portions);
  let newPortions = [];
  forEach(groupedPortions, (originalGroup, key) => {
    const updatedPara = modifiedParaByGroup[key];
    if (!isUndefined(updatedPara)) {
      const parentGroups = groupBy(originalGroup, "parentId");
      const noOfPortionsForGroup = size(parentGroups);
      const wordsList = updatedPara.split(CONFIG.CONSTANTS.WORD_SEPARATOR);
      const hasMoreNewWords = wordsList.length > noOfPortionsForGroup;
      let idx = 0;
      forEach(parentGroups, (parentPortion) => {
        parentPortion.map((port, i) => {
          let updatedText = "";
          if (i === 0) {
            if (wordsList[idx]) {
              // if new para has more words and current portion is the last portion of the current group,
              // attach the remaning words to the current portion
              if (hasMoreNewWords && noOfPortionsForGroup === idx + 1) {
                updatedText = wordsList
                  .slice(idx)
                  .join(CONFIG.CONSTANTS.WORD_SEPARATOR);
              } else {
                updatedText = wordsList[idx];
              }
            }
          }
          newPortions.push(getUpdatedManualPortion(port, updatedText));
        });
        idx++;
      });
    } else {
      newPortions = [...newPortions, ...originalGroup];
    }
  });
  return newPortions;
}
