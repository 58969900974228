import axiosInstance from "configs/axios";
import { get, map } from "lodash";
import { extractApiResponse } from "utils/helpers";

// URIs
const URI_GET_SLIDES = "/Slide/getallslides/";
const URI_GET_SLIDE = "/Slide/getslides/";
const URI_GET_HIGHLIGHTED_SLIDE = "/Slide/gethighlightedslide";
const URI_GET_PRESENTATION = "/Presentation/getbyppt/";
const URI_POST_USER_MARK_COMPLE = "/Slide/updatestatus";
const URI_GET_DOCUMENT_SUMMARY = "/Presentation/getSanitizationSummarybyppt/";
const URL_GET_DOWNLOAD_URL =
  "/Presentation/getsanitizedpresentationdownloadlink/";

// Slides Constructor for slides response
export function Slides(pptId, result) {
  this.pptId = pptId;
  this.slideId = result.slideId;
  this.slideIndex = result.slideNumber;
  this.slideNumber = result.pptSlideNumber;
  this.image = result.slideUrl;
  this.dynamicSlideUrl = result.dynamicSlideUrl;
  this.identified = result.isIdentified;
  this.sanitizationProgress = result.sanitizationProgress;
  this.prevSanitizationProgress = result.sanitizationProgress;
  this.totalGraphs = result.totalGraphs;
  this.totalImages = result.totalImages;
  this.totalTexts = result.totalTexts;
  this.totalTables = result.totalTables;
  this.identifiedCount = {
    text: result.identifiedTexts,
    images: result.identifiedImages,
    graphs: result.identifiedGraphs,
    tables: result.identifiedTables
  };
}

export function getSlidesByPptId(pptId) {
  return axiosInstance
    .get(`${URI_GET_SLIDES}${pptId}`)
    .then((response) =>
      map(extractApiResponse(response), (result) => new Slides(pptId, result))
    );
}

export function getSlideByPptId(pptId, slideIndex) {
  return axiosInstance
    .get(`${URI_GET_SLIDE}${pptId}/${slideIndex}/1`)
    .then(({ data }) =>
      map(data?.result, (result) => new Slides(pptId, result))
    );
}

export function Presentation(result) {
  if (result) {
    this.pptId = result.pptId;
    this.pptName = result.pptTitle;
    this.pptStatus = result.pptStatus;
    this.completedSlides = result.completedSlides;
    this.totalSlides = result.totalSlides;
    this.lastElementInfo = result.lastElementInfo || {};
  }
}

export function getPresentationDetailsByPptId(pptId) {
  return axiosInstance
    .get(`${URI_GET_PRESENTATION}${pptId}`)
    .then(({ data }) => {
      const pptData = get(data, "result");
      return pptData ? new Presentation(pptData) : {};
    });
}

export function markSlideAsSanitized(data) {
  return axiosInstance
    .post(`${URI_POST_USER_MARK_COMPLE}`, data)
    .then(({ data }) => {
      return data.result;
    });
}

function HighlightElement(payload) {
  this.pptId = payload.pptId;
  this.slideId = payload.slideId;
  this.textIds = payload.portionIds;
  this.imageIds = payload.imageIds;
  this.graphIds = payload.graphIds;
  this.tableIds = payload.tableIds;
  this.tableCellIds = payload.tableCellIds;
}

export function highlightElementOnSlide(payload) {
  return axiosInstance
    .post(`${URI_GET_HIGHLIGHTED_SLIDE}`, new HighlightElement(payload))
    .then(({ data }) => data.result);
}

export function DocumentSummary(result) {
  this.totalSlides = result.totalSlides;
  this.sanitizedSlides = result.sanitizedSlides;
  this.pendingSlides = result.pendingSlides;
  this.text = result.text;
  this.image = result.images;
  this.graph = result.graphs;
  this.table = result.table;
  this.total = result.total;
}

export function getDocumentSummary(pptId) {
  return axiosInstance
    .get(`${URI_GET_DOCUMENT_SUMMARY}${pptId}`)
    .then(({ data }) => new DocumentSummary(data.result));
}

export function getDownloadUrl(pptId) {
  return axiosInstance
    .get(`${URL_GET_DOWNLOAD_URL}${pptId}`)
    .then(({ data }) => data.result);
}
