import { createSelector } from "reselect";
import { configureLoadingState } from "containers/Common/reducers/loading";

import { createLoadingSelector } from "containers/Common/selectors/loading";
import {
  EDITOR_FETCH_PPT_DETAILS,
  EDITOR_FETCH_SLIDES,
  GET_PREVIEW_SLIDE
} from "../types";
import CONFIG from "configs/config";

// Configure loading state reducer to capture resource required
configureLoadingState([
  EDITOR_FETCH_PPT_DETAILS,
  EDITOR_FETCH_SLIDES,
  GET_PREVIEW_SLIDE
]);

export const isFetchingPpt = createLoadingSelector([
  EDITOR_FETCH_PPT_DETAILS,
  EDITOR_FETCH_SLIDES
]);

export const isFetchingPreviewSlide = createLoadingSelector([
  GET_PREVIEW_SLIDE
]);

const sanitizeWindow = (state) => state.sanitizeWindow;

export const getSlidesList = createSelector(
  sanitizeWindow,
  (sw) => sw.slidesList.data
);

export const getIdentifiedSlides = createSelector(getSlidesList, (allSlides) =>
  allSlides.filter((slide) => slide.identified)
);
export const getPptDetails = createSelector(
  sanitizeWindow,
  (sw) => sw.pptDetails.data
);

export const getActiveSection = createSelector(
  sanitizeWindow,
  (sw) => sw.activeSection
);

export const isGraphSection = createSelector(
  getActiveSection,
  (active) => active === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
);

export const getCurrentSlide = createSelector(
  sanitizeWindow,
  (sw) => sw.currentSlide
);

export const getSlideImageType = createSelector(
  sanitizeWindow,
  (sw) => sw.slideImageType
);

export const showManualEditModal = createSelector(
  sanitizeWindow,
  (sw) => sw.isManualEditModalShown
);

export const getCurrentTextGroup = createSelector(
  sanitizeWindow,
  (sw) => sw.currentTextGroup
);

export const getPreviewSlideSelector = createSelector(
  sanitizeWindow,
  (sw) => sw.previewSlide
);

export const getIsFilterIdentified = createSelector(
  sanitizeWindow,
  (sw) => sw.isFilterIdentified
);
