import { call, put, takeLatest, select } from "redux-saga/effects";
import ToastService from "utils/toast";
import * as at from "../types";

import { getDownloadUrlSelector, getCurrentPptDetails } from "../selectors";
import { rsGetDownloadUrl, rsGetDocumentSummary } from "./resourceSagas";
import { downloadFile, getCFImageSrcWithoutCache } from "utils/helpers";
import { handleCatchedError } from "utils/sagaUtils";
import { getSignedTokenFromStore } from "containers/User/selectors";
import { autoSaveDirtyChanges } from "containers/Sanitize/sagas";
import { cleanDownloadUrlAction, getDocumentSummaryAction } from "../actions";
import CONFIG from "configs/config";

function* watchSummarySaga() {
  /**
   * Listen toggle summary modal action, and basis the paylaod trigger summary data or clear download url
   * @param {Boolean} action.payload show or hide summary modal
   */
  yield takeLatest(
    at.TOGGLE_SUMMARY_MODAL,
    function* onToggleSummaryModal({ payload }) {
      if (payload) {
        yield put(getDocumentSummaryAction());
      } else {
        yield put(cleanDownloadUrlAction());
      }
    }
  );

  /**
   * Get document summary when opening download modal
   */
  yield takeLatest(at.GET_DOCUMENT_SUMMARY, function* documentSummary() {
    yield* autoSaveDirtyChanges();
    const pptId = yield select(getCurrentPptDetails);
    yield call(rsGetDocumentSummary, pptId);
  });

  /**
   * Get download url of the ppt
   */
  yield takeLatest(at.GET_DOWNLOAD_URL, function* getDownload() {
    const downloadUrl = yield select(getDownloadUrlSelector);
    if (downloadUrl.data) {
      const signedToken = yield select(getSignedTokenFromStore);
      const url = getCFImageSrcWithoutCache(downloadUrl.data, signedToken);
      downloadFile(url);
    } else {
      try {
        const pptId = yield select(getCurrentPptDetails);
        const response = yield call(rsGetDownloadUrl, pptId);
        const signedToken = yield select(getSignedTokenFromStore);
        const url = getCFImageSrcWithoutCache(response, signedToken);
        if (url) {
          downloadFile(url);
        } else {
          ToastService.error(CONFIG.ERROR.API.DOWNLOAD_DOCUMENT_FAILED);
        }
      } catch (e) {
        yield call(handleCatchedError, e);
      }
    }
  });
}

export default function* documentSummarySaga() {
  yield call(watchSummarySaga);
}
