import React from "react";
import { Button, Form, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

const ProgressBar = ({
  totalSlides,
  completedSlides,
  showGoTo,
  goToSlideNo,
  goToSlide,
  setGoToSlideNo
}) => {
  const handleGoToSlide = (goToSlideNo) => {
    goToSlide(goToSlideNo);
    onAAClickEvents("Slide_Navigator");
  };
  return (
    <section className="slider__progress-bar">
      {!!totalSlides && (
        <div className="p-2">
          {showGoTo && (
            <Form className="d-inline mr-4">
              <div className="goto-slide slider__goto-slide">
                <label>Slide</label>
                <Input
                  label={
                    <Button
                      type="submit"
                      primary
                      size="mini"
                      onClick={() => handleGoToSlide(goToSlideNo)}
                    >
                      Go
                    </Button>
                  }
                  labelPosition="right"
                  type="number"
                  size="mini"
                  value={goToSlideNo}
                  onChange={(event) =>
                    setGoToSlideNo(
                      event.target.value === ""
                        ? event.target.value
                        : Number(event.target.value)
                    )
                  }
                />
              </div>
            </Form>
          )}

          <span
            className="slider__progress-bar-title"
            data-testid="progress-status"
          >{`Progress - ${completedSlides ? completedSlides : 0} of ${
            totalSlides ? totalSlides : 0
          } slides`}</span>
        </div>
      )}
    </section>
  );
};

ProgressBar.propTypes = {
  totalSlides: PropTypes.number,
  completedSlides: PropTypes.number,
  showGoTo: PropTypes.bool,
  goToSlideNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goToSlide: PropTypes.func,
  setGoToSlideNo: PropTypes.func
};

ProgressBar.defaultProps = {
  totalSlides: 0,
  completedSlides: 0,
  goToSlideNo: 0,
  goToSlide: noop,
  setGoToSlideNo: noop
};

export default ProgressBar;
