import * as at from "../types";
import {
  TEXT_STORE_CURRENT_TEXT,
  TEXT_SELECT_SMART_SUGGESTION,
  TEXT_DESELECT_SMART_SUGGESTION,
  TEXT_SET_REPLACEMENT_TEXT
} from "containers/TextListView/types";
import {
  TABLE_DESELECT_SMART_SUGGESTION,
  TABLE_EXIT_MANUAL_EDIT,
  TABLE_SELECT_SMART_SUGGESTION,
  TABLE_SET_REPLACEMENT_TEXT
} from "containers/TableManualEdit/types";

export default function smartSuggestions(state = [], { type, payload = {} }) {
  switch (type) {
    case TABLE_SELECT_SMART_SUGGESTION:
    case TEXT_SELECT_SMART_SUGGESTION: {
      const {
        entity,
        updatedText,
        entityMappingId,
        defaultSelection = false
      } = payload;

      if (entity) {
        // Remove previously entered option
        const newState = state.filter(
          (word) => word.entityMappingId !== entityMappingId
        );
        newState.push({
          entity,
          updatedText,
          entityMappingId,
          isFromSuggestion: true,
          defaultSelection
        });
        return newState;
      }
      return state;
    }

    case TABLE_DESELECT_SMART_SUGGESTION:
    case TEXT_DESELECT_SMART_SUGGESTION: {
      const { entity, entityMappingId } = payload;

      if (entity) {
        return state.filter((word) => word.entityMappingId !== entityMappingId);
      }
      return state;
    }

    case at.RESET_TABLE_TEXT_TO_ORIGINAL:
    case at.RESET_TEXT_TO_ORIGINAL: {
      const { entity, entityMappingId } = payload;
      const newState = state.filter(
        (word) => word.entityMappingId !== entityMappingId
      );
      newState.push({
        entity,
        updatedText: entity,
        entityMappingId,
        isFromSuggestion: false,
        hasReset: true
      });
      return newState;
    }

    case TABLE_SET_REPLACEMENT_TEXT:
    case TEXT_SET_REPLACEMENT_TEXT: {
      const { entity, updatedText, entityMappingId } = payload;

      if (entity) {
        // Remove previously selected suggestion if any.
        const newState = state.filter(
          (word) => word.entityMappingId !== entityMappingId
        );

        if (updatedText) {
          newState.push({
            entity,
            updatedText,
            entityMappingId,
            isFromSuggestion: false
          });
        }
        return newState;
      }
      return state;
    }

    case TABLE_EXIT_MANUAL_EDIT:
    case TEXT_STORE_CURRENT_TEXT:
      return [];

    case at.SET_UPDATED_SUGGESTION_VALUES:
      return [...state, ...payload];

    default:
      return state;
  }
}
