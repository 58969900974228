import PropTypes from "prop-types";

import * as home from "api/home";
import * as sanitize from "api/sanitize";
import * as text from "api/text";
import * as image from "api/image";
import * as table from "api/table";
import * as tableText from "api/tableManualEdit";

export const fileListType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(home.FileList)
]);

export const slideType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(sanitize.Slides)
]);

export const presentationType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(sanitize.Presentation)
]);

export const textListType = PropTypes.instanceOf(text.TextList);

export const sensitiveEntitiesType = PropTypes.instanceOf(
  text.SensitiveEntities
);

export const suggestionsTypes = PropTypes.instanceOf(text.Suggestions);

export const imageListType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(image.ImagesBySlide)
]);

export const tableListType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(table.TablesBySlide)
]);

export const documentSummaryType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.instanceOf(sanitize.DocumentSummary)
]);

export const tableTextListType = PropTypes.instanceOf(tableText.TableTextList);
