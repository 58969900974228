import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Header, Icon, Modal } from "semantic-ui-react";
import { noop } from "lodash";
import "./SummaryModal.scss";
import CONFIG from "configs/config";

import { documentSummaryType, presentationType } from "utils/customPropTypes";

import ParagraphPlaceholder from "components/ParagraphPlaceholder";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import CloseIcon from "assets/images/close_grey_small.svg";
import { AALinkName, onAAClickEvents } from "analytics/AnalyticsCapture";

/**
 * SUMMARY POPUP
 * Shows summary of PPT (Total, Sanitized and Pending) at slide level.
 * Shows download option if open through download button.
 * @returns
 */
const SummaryModal = ({
  toggleSummaryModal,
  documentSummary,
  isPreviewDocShown,
  isFetchingDocumentSummary,
  getDownloadUrlAction,
  isFetchingDownloadUrl,
  pptDetails
}) => {
  const onHandleDownload = () => {
    getDownloadUrlAction();
    onAAClickEvents(pptDetails.pptName, "Download_doc_complete");
  };
  return (
    <Modal
      size="tiny"
      open
      centered
      className={`summary ${isPreviewDocShown ? "summary-margin" : ""}`}
    >
      <Modal.Content>
        <div className="summary__header">
          <Header as="h2">{CONFIG.LABELS.DOCUMENT_SUMMARY}</Header>
          <img
            className={
              isFetchingDownloadUrl ? "summary__close-icon--disabled" : ""
            }
            onClick={() => {
              if (!isFetchingDownloadUrl) {
                toggleSummaryModal(false);
              }
            }}
            src={CloseIcon}
            alt="close-icon"
          />
        </div>

        {isFetchingDocumentSummary ? (
          <ParagraphPlaceholder numberOfLines={4} />
        ) : (
          <Grid className="summary__description">
            <Grid.Row className="summary__head-desc-row">
              <Grid.Column computer={8} className="bold summary__head-desc">
                {CONFIG.LABELS.TOTAL_SLIDES}
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                computer={8}
                className="summary__head-desc"
              >
                {documentSummary.totalSlides}
              </Grid.Column>
              <Grid.Column computer={8} className="bold summary__head-desc">
                {CONFIG.LABELS.SANITIZED_SLIDES}
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                computer={8}
                className="summary__head-desc"
              >
                {documentSummary.sanitizedSlides}
              </Grid.Column>
              <Grid.Column computer={8} className="bold summary__head-desc">
                {CONFIG.LABELS.PENDING_SLIDES}
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                computer={8}
                className="summary__head-desc"
              >
                {documentSummary.pendingSlides}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <div className="summary__notes">
          <p>
            <Icon name="info circle" /> {CONFIG.LABELS.DELETE_NOTE}
          </p>
          <p>
            <Icon className="info circle" /> {CONFIG.LABELS.HYPERLINK_NOTE}
          </p>
        </div>
        <div className="summary__actions">
          <div>
            <Button
              disabled={isFetchingDocumentSummary || isFetchingDownloadUrl}
              secondary
              onClick={() => {
                AALinkName("Close Download Summary window", "Link_click");
                toggleSummaryModal(false);
              }}
            >
              {CONFIG.LABELS.CLOSE}
            </Button>
            <CustomActionButton
              disabled={isFetchingDocumentSummary}
              className="summary__download-btn"
              label={isFetchingDownloadUrl ? "Processing" : "Download"}
              isLoading={isFetchingDownloadUrl}
              onClickfn={() => onHandleDownload()}
              noApplyIcon
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

SummaryModal.propTypes = {
  toggleSummaryModal: PropTypes.func,
  documentSummary: PropTypes.objectOf(documentSummaryType),
  isFetchingDocumentSummary: PropTypes.bool,
  isPreviewDocShown: PropTypes.bool,
  getDownloadUrlAction: PropTypes.func,
  isFetchingDownloadUrl: PropTypes.bool,
  pptDetails: presentationType
};

SummaryModal.defaultProps = {
  isPreviewDocShown: false,
  documentSummary: {},
  getDownloadUrlAction: noop,
  pptDetails: {},
  isFetchingDownloadUrl: false
};

export default SummaryModal;
