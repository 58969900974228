import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/sanitize";
import * as at from "../types";

export const rsGetDocumentSummary = resourceSaga(
  at.GET_DOCUMENT_SUMMARY,
  api.getDocumentSummary
);

export const rsGetDownloadUrl = resourceSaga(
  at.GET_DOWNLOAD_URL,
  api.getDownloadUrl
);
