import React from "react";
import { Header, Placeholder } from "semantic-ui-react";
import "./FileListLoader.scss";
import CONFIG from "configs/config";

const FileListLoader = () => {
  const loaderCardCount = [1, 2, 3];

  return (
    <div className="file-list-loader">
      <Header as="h4" data-testid="header">
        {CONFIG.LABELS.ALL_DOCUMENTS_LABEL}
      </Header>
      {loaderCardCount.map((card) => (
        <Placeholder className="file-list-loader__card" key={card}>
          <Placeholder.Image square />
        </Placeholder>
      ))}
    </div>
  );
};

export default FileListLoader;
