import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { find, get } from "lodash";
import {
  GO_TO_NEXT_TABLE,
  GO_TO_PREVIOUS_TABLE
} from "containers/SmartSuggestion/types";
import * as at from "../types";
import { getTablesBySlide } from "api/table";
import {
  triggerSaveTable,
  updateTableList,
  setCurrentTableBeingEdited,
  updateSelectTable,
  replaceTableList
} from "../actions";
import { rsSaveUpdatedTables } from "../sagas/resourceSagas";
import { uploadToS3 } from "containers/ImageListView/sagas";
import CONFIG from "configs/config";
import { createAction as ca } from "utils/action";
import { handleCatchedError } from "utils/sagaUtils";
import {
  getActiveSection,
  getCurrentSlide,
  getIsFilterIdentified
} from "containers/Sanitize/selectors";
import {
  highlightElementOnSlide,
  refreshSanitizationProgress
} from "containers/Sanitize/actions";
import { getSelectedTable, getTableList } from "../selectors";
import { canApplySmartOption, filterSensitiveItem } from "utils/helpers";

/**
 * Save dirty table, Involves saving table (stamp) image to s3 and save the s3 key on BE
 * @param {Object} payload modified table data
 */
export function* saveTable(payload = {}) {
  try {
    const requestPayload = payload.modifiedTable
      ? { ...payload.modifiedTable }
      : yield select(getSelectedTable);
    if (
      requestPayload &&
      requestPayload.savedSmartOption !== requestPayload.smartOption
    ) {
      yield put(ca(at.TABLE_SAVE_UPDATED_TABLE_REQUESTED)({ requestPayload }));
      const isReset =
        requestPayload.smartOption ===
          CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET ||
        !requestPayload.smartOption;

      let objectKey = requestPayload.uploadObjectKey;

      const skipUploadToS3 =
        !isReset &&
        requestPayload.smartOption ===
          CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT;

      if (!skipUploadToS3) {
        const s3ObjectResponse = yield* uploadToS3([requestPayload]);
        objectKey = get(
          find(s3ObjectResponse, { id: requestPayload.id }),
          "key",
          objectKey
        );
      }

      requestPayload.modifiedTableUrl = isReset ? null : objectKey;
      yield call(rsSaveUpdatedTables, [requestPayload]);
    }
  } catch (e) {
    yield call(handleCatchedError, e);
  }
}

function* watchTableSuggestionSaga() {
  /**
   * On Go to next/prev table, or when selecting table, save the previous dirty table
   */
  yield takeEvery(
    [GO_TO_NEXT_TABLE, GO_TO_PREVIOUS_TABLE, at.TABLE_ON_SELECT_TABLE],
    function* onArrowClick({ payload }) {
      if (payload.currentEntity) {
        yield put(triggerSaveTable({ modifiedTable: payload.currentEntity }));
      }

      yield put(
        setCurrentTableBeingEdited({
          ...(payload.entity || payload.nextEntity),
          isArrowClick: true
        })
      );
    }
  );

  // Listen for action which select images and highlight the selected images
  yield takeLatest(
    [at.TABLE_SET_CURRENT_TABLE, at.TABLE_HIGHLIGHT_CURRENT_TABLE],
    function* highlightElement({ payload = {} }) {
      const id = get(
        payload.nextEntity
          ? payload.nextEntity
          : yield select(getSelectedTable),
        "id"
      );
      yield put(
        highlightElementOnSlide({
          sectionType: CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES,
          ids: {
            tableIds: [id]
          }
        })
      );
    }
  );

  /**
   * When selecting smart option for currently selected table, update table with selected option to apply changes on preview
   */
  yield takeLatest(
    at.SELECT_SMART_OPTION_FOR_TABLE,
    function* updateTableData({ payload }) {
      const tableList = yield select(getTableList);
      const selectedTable = yield select(getSelectedTable);
      yield put(
        replaceTableList(
          tableList.map((table) => ({
            ...table,
            smartOption:
              table.id === selectedTable.id ? payload : table.smartOption
          }))
        )
      );
    }
  );

  /**
   * After successfully fetching recommendations data for table,
   * update table list with fetched redacted table image url
   * Also, select smartOption as smart edit
   */
  yield takeLatest(
    at.TABLE_FETCH_TABLE_SUGGESTIONS_SUCCEEDED,
    function* updateSmartOption({ payload }) {
      const selectedTable = yield select(getSelectedTable);
      const tableList = yield select(getTableList);
      if (payload?.data) {
        const newTableList = tableList.map((table) => {
          const redactionData = get(payload, `data[${table.id}]`);
          if (redactionData) {
            const newTable = { ...table, ...redactionData };
            if (
              selectedTable.id === table.id &&
              canApplySmartOption(newTable)
            ) {
              newTable.smartOption =
                CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT;
            }
            return newTable;
          }
          return table;
        });

        yield put(replaceTableList(newTableList));

        // Set the first table as selected when fetching tablelist,
        // Redirecting it through setCurrentTableBeingEdited to highlight the default selected table
        if (!selectedTable?.id && newTableList.length) {
          const identified = yield select(getIsFilterIdentified);
          yield put(
            setCurrentTableBeingEdited(
              get(
                identified ? filterSensitiveItem(newTableList) : newTableList,
                [0],
                {}
              )
            )
          );
        }
      }
    }
  );

  yield takeEvery(
    [at.TABLE_TRIGGER_SAVE_TABLE],
    function* triggerSaveTable({ payload = {} }) {
      yield* saveTable(payload);
    }
  );

  /**
   * On after success of save table, refresh table list to reflect changes
   */
  yield takeLatest(
    at.TABLE_SAVE_UPDATED_TABLE_SUCCEEDED,
    function* refreshTablesList({ payload }) {
      const slideId = payload.data[0]?.slideId;
      const slideData = yield select(getCurrentSlide);
      const currentActiveSection = yield select(getActiveSection);
      yield put(
        refreshSanitizationProgress({
          slideId,
          activeSection: currentActiveSection
        })
      );
      // Refresh tableList only if the current slide and active section is same as before saving
      if (
        slideId === slideData.slideId &&
        currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
      ) {
        const response = yield call(
          getTablesBySlide,
          slideData.pptId,
          slideData.slideId
        );

        const currentTable = yield select(getSelectedTable);

        const updatedTable = find(response, { id: currentTable.id });
        if (updatedTable) {
          yield put(updateSelectTable(updatedTable));
        }

        // When refreshing tableList after any save
        // we shouldnt replace the current dirty value for the currently selected table
        yield put(updateTableList(response));
      }
    }
  );
}

export default function* tableSuggestionSaga() {
  yield call(watchTableSuggestionSaga);
}
