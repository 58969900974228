import { createSelector } from "reselect";

export const getUserDetails = (state) => state.user;

export const getHrId = createSelector(getUserDetails, (user) => user.hrId);

export const getStaffId = createSelector(
  getUserDetails,
  (user) => user.staffId
);

export const getSignedToken = (state) => state.signedToken;

export const getSignedTokenFromStore = createSelector(
  getSignedToken,
  (token) => token.data || {}
);
