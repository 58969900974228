import { combineReducers } from "redux";
import { resourceReducer } from "redux/sagas/networkResource";
import * as at from "../types";
import {
  STORE_HIGHLIGHT_ELEMENT_RESPONES,
  HIGHLIGHT_ELEMENT_ON_SLIDE_REQUESTED
} from "containers/Sanitize/types";
import { IMAGE_SAVE_MULTIPLE_IMAGES_SUCCEEDED } from "containers/ImageListView/types";

function activeSection(state = "", { type, payload }) {
  switch (type) {
    case at.EDITOR_SET_ACTIVE_SECTION:
      return payload.next;
    case at.EDITOR_RESET_STORE:
      return "";
    default:
      return state;
  }
}

function pptDetails(state, action) {
  switch (action.type) {
    case at.UPDATE_PPT_DETAILS_IN_STORE:
      return { data: action.payload };
    case at.EDITOR_RESET_ON_UNMOUNT:
      return { data: {} };
    default:
      return resourceReducer(at.EDITOR_FETCH_PPT_DETAILS, {
        reset: true,
        init: {}
      })(state, action);
  }
}

function slidesList(state, action) {
  switch (action.type) {
    case at.EDITOR_RESET_STORE: {
      return { data: [] };
    }
    case STORE_HIGHLIGHT_ELEMENT_RESPONES: {
      if (action.payload?.slideId) {
        const newState = {};
        newState["data"] = state.data.map((slide) => {
          if (slide.slideId === action.payload.slideId) {
            slide["resetImageUrl"] = slide.resetImageUrl || slide.image;
            slide.image = action.payload.dynamicSlideUrl || slide.image;
          }
          return slide;
        });
        return newState;
      }
      return state;
    }
    case at.USER_MARK_AS_COMPLETE_SUCCEEDED: {
      const newState = [...state.data];
      newState.forEach((obj) => {
        if (obj.slideId === action.payload.slideId) {
          if (action.payload.markComplete) {
            obj.prevSanitizationProgress = obj.sanitizationProgress;
            obj.sanitizationProgress = 3;
          } else {
            obj.sanitizationProgress = obj.prevSanitizationProgress;
          }
        }
      });
      return { data: newState };
    }
    case at.UPDATE_SLIDES_IN_STORE: {
      const newState = [...state.data];
      newState.forEach((obj) => {
        if (obj.slideId === action.payload.slideId) {
          obj.sanitizationProgress = action.payload.sanitizationProgress;
        }
      });
      return { data: newState };
    }
    case at.EDITOR_SET_ACTIVE_SECTION:
    case HIGHLIGHT_ELEMENT_ON_SLIDE_REQUESTED: {
      const newState = {};
      newState["data"] = state.data.map((slide) => {
        slide.image = slide.resetImageUrl || slide.image;
        return slide;
      });
      return newState;
    }
    default:
      return resourceReducer(at.EDITOR_FETCH_SLIDES, { reset: true })(
        state,
        action
      );
  }
}

function currentSlide(state = {}, { payload, type }) {
  switch (type) {
    case at.EDITOR_SET_CURRENT_SLIDE:
      return payload;
    case at.EDITOR_RESET_STORE:
      return {};
    default:
      return state;
  }
}

function isManualEditModalShown(state = false, { type, payload }) {
  switch (type) {
    case at.TOGGLE_MANUAL_EDIT_MODAL:
      return !state;
    case IMAGE_SAVE_MULTIPLE_IMAGES_SUCCEEDED:
      if (payload.isManualEdit) {
        return false;
      }
      return state;
    default:
      return state;
  }
}

function currentTextGroup(state = {}, { type, payload }) {
  switch (type) {
    case at.EDITOR_SET_CURRENT_TEXT_GROUP_ID:
      return { ...state, id: payload, editing: false };
    case at.EDITOR_SET_CURRENT_SLIDE:
    case at.EDITOR_RESET_STORE:
      return {};
    case at.EDITOR_SET_EDITING_CURRENT_TEXT_GROUP:
      return { ...state, editing: payload };
    default:
      return state;
  }
}

function previewSlide(state = {}, { type, payload }) {
  switch (type) {
    case at.GET_PREVIEW_SLIDE_SUCCEEDED:
      return payload;
    default:
      return state;
  }
}

function isFilterIdentified(state = false, { type, payload }) {
  switch (type) {
    case at.TOGGLE_IS_FILTER_IDENTIFIED:
      return payload;
    case at.EDITOR_RESET_ON_UNMOUNT:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  activeSection,
  pptDetails,
  slidesList,
  currentSlide,
  isManualEditModalShown,
  currentTextGroup,
  previewSlide,
  isFilterIdentified
});
