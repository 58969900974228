import { createSelector } from "reselect";
import { get, some } from "lodash";
import { configureLoadingState } from "containers/Common/reducers/loading";

import {
  TABLE_FETCH_TABLELIST,
  TABLE_SAVE_UPDATED_TABLE,
  TABLE_FETCH_TABLE_SUGGESTIONS,
  TABLE_REFRESH_TABLELIST
} from "../types";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import { getIsFilterIdentified } from "containers/Sanitize/selectors";
import CONFIG from "configs/config";

configureLoadingState([
  TABLE_FETCH_TABLELIST,
  TABLE_SAVE_UPDATED_TABLE,
  TABLE_FETCH_TABLE_SUGGESTIONS,
  TABLE_REFRESH_TABLELIST
]);

export const isFetchingTables = createLoadingSelector([
  TABLE_FETCH_TABLELIST,
  TABLE_FETCH_TABLE_SUGGESTIONS,
  TABLE_REFRESH_TABLELIST
]);

export const isSavingUpdatedTable = createLoadingSelector([
  TABLE_SAVE_UPDATED_TABLE
]);

const table = (state) => state.table;

export const getTableList = createSelector(
  table,
  (table) => table.tableList.data || []
);

export const getFilteredTableList = createSelector(
  [getTableList, getIsFilterIdentified],
  (tables, isIdentified) => {
    if (isIdentified) {
      return tables.filter((image) => image.redactedImageUrl);
    }
    return tables;
  }
);

export const getSelectedTable = createSelector(
  table,
  (table) => table.selectedTable
);

export const getIndividualTableSaveStatus = createSelector(
  table,
  (table) => table.individualTableSaveStatus
);

export const getSelectedTableSmartOption = createSelector(
  table,
  (table) => table.selectedSmartOption
);

export const getNextTable = createSelector(
  [getFilteredTableList, getSelectedTable],
  (list, img) => {
    if (img.id) {
      let currentIndex = 0;
      some(list, (i, idx) => {
        currentIndex = idx;
        return i.id === img.id;
      });
      return get(list, [currentIndex + 1], {});
    }
    return {};
  }
);

export const getPrevTable = createSelector(
  [getFilteredTableList, getSelectedTable],
  (list, table) => {
    if (table.id) {
      let currentIndex = 0;
      some(list, (i, idx) => {
        currentIndex = idx;
        return i.id === table.id;
      });
      return get(list, [currentIndex - 1], {});
    }
    return {};
  }
);

export const getSelectedSmartOptionForTable = createSelector(
  [getSelectedTable, getSelectedTableSmartOption],
  (table, smartOption) => smartOption || table.smartOption || ""
);

export const canResetTableToOriginal = createSelector(
  getSelectedSmartOptionForTable,
  (selectedOption) =>
    selectedOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET
      ? false
      : !!selectedOption
);
