import React from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Header, Popup, Icon } from "semantic-ui-react";
import { noop } from "lodash";
import "./HeaderButtons.scss";

import CONFIG from "configs/config";
import { Link } from "react-router-dom";
import { AALinkName, onAAClickEvents } from "analytics/AnalyticsCapture";

const HeaderButtons = ({
  userDetails,
  pptName,
  isFetchingPpt,
  isPreviewDocShown,
  togglePreviewDoc,
  toggleSummaryModal,
  showPreviewSlide,
  togglePreviewSlide,
  currentSlideNo,
  onHomePageRedirect
}) => {
  const onDownloadClick = () => {
    toggleSummaryModal(true);
    onAAClickEvents(pptName, "Download_doc_start");
  };

  const onPreviewButton = () => {
    togglePreviewDoc(true);
    onAAClickEvents(pptName, "Preview_document");
  };

  const handlePreviewClick = () => {
    togglePreviewSlide(true);
    onAAClickEvents(pptName, "Preview");
  };
  const handleHomePageRedirect = () => {
    onHomePageRedirect();
    AALinkName("Home_icon", "Link_click");
  };
  const options = [
    ...(isPreviewDocShown
      ? []
      : [
          {
            key: "preview_document",
            icon: "eye",
            text: CONFIG.LABELS.PREVIEW_DOC,
            value: "preview_document",
            onClick: onPreviewButton
          }
        ]),
    {
      key: "download",
      icon: "download",
      text: CONFIG.LABELS.DOWNLOAD_DOC,
      value: "download",
      onClick: onDownloadClick
    }
  ];

  return (
    <section className="header-buttons" data-testid="header-buttons">
      {showPreviewSlide ? (
        <Header
          as="h2"
          data-testid="all-slides"
          className="preview-slide__header"
        >
          Preview Slide: #{currentSlideNo}
        </Header>
      ) : (
        <Header as="h4" className="slider__ppt-name px-3" title={pptName}>
          {pptName}
        </Header>
      )}
      {!showPreviewSlide && !isFetchingPpt && !!userDetails.hrId && (
        <div className="header-buttons__actions">
          {!isPreviewDocShown ? (
            <>
              <Popup
                content={CONFIG.LABELS.SAVE_HOME}
                position="bottom center"
                trigger={
                  <Link
                    to="/"
                    className="ui button primary hover header-buttons__actions--home mr-0"
                    onClick={() => handleHomePageRedirect()}
                  >
                    <Icon name="home" className="mr-0" />
                  </Link>
                }
              />
              <Button
                className="header-buttons__actions--preview hover"
                primary
                onClick={() => handlePreviewClick(true)}
              >
                {CONFIG.LABELS.PREVIEW_SLIDE}
              </Button>
              <Dropdown
                className="header-buttons__actions--menu primary button hover m-0"
                floating
                icon="ellipsis vertical"
                options={options}
                direction="left"
                trigger={<></>}
              />
            </>
          ) : (
            <Button
              secondary
              className="py-0"
              onClick={() => onDownloadClick()}
            >
              {CONFIG.LABELS.DOWNLOAD_DOC}
            </Button>
          )}
        </div>
      )}
    </section>
  );
};

HeaderButtons.propTypes = {
  userDetails: PropTypes.object,
  pptName: PropTypes.string,
  isFetchingPpt: PropTypes.bool,
  isPreviewDocShown: PropTypes.bool,
  togglePreviewDoc: PropTypes.func,
  toggleSummaryModal: PropTypes.func,
  showPreviewSlide: PropTypes.bool,
  currentSlideNo: PropTypes.number,
  togglePreviewSlide: PropTypes.func,
  onHomePageRedirect: PropTypes.func
};

HeaderButtons.defaultProps = {
  userDetails: {},
  pptName: "",
  currentSlideNo: 0,
  showPreviewSlide: false,
  isPreviewDocShown: false,
  toggleSummaryModal: noop,
  togglePreviewDoc: noop,
  setShowDownload: noop,
  togglePreviewSlide: noop,
  onHomePageRedirect: noop
};

export default HeaderButtons;
