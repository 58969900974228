import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Image } from "semantic-ui-react";
import { noop } from "lodash";
import CONFIG from "configs/config";
import { useResizeHandler } from "utils/customHooks";
import { isSmall } from "utils/mobile";

import CloseIcon from "assets/images/close-green-large.svg";
import { onAAClickEvents } from "analytics/AnalyticsCapture";
import { presentationType } from "utils/customPropTypes";

/**
 * PREWVIEW DOCUMENT POPUP
 * header of preview document
 * @returns JSX
 */
const PreviewHeader = ({
  originalCheckbox,
  setOriginalCheckbox,
  onClosePreviewDoc,
  showPreviewSlide,
  togglePreviewSlide,
  isLoading,
  toggleSummaryModal,
  pptDetails
}) => {
  const isSmallScreen = useResizeHandler(isSmall);
  const handleCompareWithOriginal = (value) => {
    setOriginalCheckbox(value);
    onAAClickEvents("Compare_With_Original");
  };
  const onDownloadClick = () => {
    onAAClickEvents(pptDetails.pptName, "Download_doc_start");
    toggleSummaryModal(true);
  };
  return (
    <div className="preview__floating-header">
      <Checkbox
        disabled={isLoading}
        label={
          isSmallScreen
            ? "Compare"
            : showPreviewSlide
            ? "Compare with Original Slide"
            : "Compare with Original Document"
        }
        checked={originalCheckbox}
        onClick={() =>
          isLoading ? null : handleCompareWithOriginal(!originalCheckbox)
        }
      />
      <div>
        {!showPreviewSlide && (
          <Button secondary className="py-2 mr-5" onClick={onDownloadClick}>
            {CONFIG.LABELS.DOWNLOAD}
          </Button>
        )}
        <Image
          inline
          className="pointer"
          onClick={() => {
            showPreviewSlide
              ? togglePreviewSlide(false)
              : onClosePreviewDoc(false);
          }}
          src={CloseIcon}
          alt=""
        />
      </div>
    </div>
  );
};

PreviewHeader.propTypes = {
  originalCheckbox: PropTypes.bool,
  setOriginalCheckbox: PropTypes.func,
  onClosePreviewDoc: PropTypes.func,
  showPreviewSlide: PropTypes.bool,
  togglePreviewSlide: PropTypes.func,
  isLoading: PropTypes.bool,
  toggleSummaryModal: PropTypes.func,
  pptDetails: presentationType
};

PreviewHeader.defaultProps = {
  setOriginalCheckbox: noop,
  onClosePreviewDoc: noop,
  togglePreviewSlide: noop,
  toggleSummaryModal: noop,
  pptDetails: {}
};

export default PreviewHeader;
