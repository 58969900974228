import APP from "./app";
import OKTA from "./okta";
import SERVER from "./server";
import LOCAL_STORAGE from "./storage";
import APP_ROUTES from "./routes";
import { LABELS, CONSTANTS, DATA } from "./constants";
import { PAGES, API } from "./error";

export default {
  APP: APP,
  OKTA: OKTA,
  SERVER: SERVER,
  STORAGE: LOCAL_STORAGE,
  APP_ROUTES: APP_ROUTES,
  LABELS: LABELS,
  CONSTANTS: CONSTANTS,
  COMPONENT_DATA: DATA,
  ERROR: {
    PAGES: PAGES,
    API: API
  }
};
