import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { find, get, groupBy, noop } from "lodash";
import { Image } from "semantic-ui-react";
import "./TableManualEdit.scss";
import CONFIG from "configs/config";

// Components
import TableManualEditHeader from "containers/TableManualEditHeader/TableManualEditHeader";
import TableTextListView from "containers/TableTextListView/TableTextListView";
import TableTextSmartSuggestion from "containers/TableTextSmartSuggestion/TableTextSmartSuggestion";

import {
  getColumnsList,
  getCurrentColumn,
  getCurrentTableText,
  getCurrentTableWord,
  getDirtyTableTextList,
  getGroupedTableParaDetails,
  showLoaderForTableText
} from "./selectors";
import {
  exitTableManualEditor,
  onPreviousTableColumn,
  onNextTableColumn,
  setCurrentTableWordBeingEdited,
  updateCurrentTableTextPortions,
  saveManuallySanitizedTablePara
} from "./actions";
import {
  setCurrentTextGroupId,
  setEditingCurrentTextGroup
} from "containers/Sanitize/actions";
import { getCurrentTextGroup } from "containers/Sanitize/selectors";

// Images
import loadingIconWhite from "assets/images/uploading-ppt-icon-white.svg";
import TextPreview from "components/TextPreview/TextPreview";
import { getCurrentWordWithBlock, getManualResetPortions } from "utils/helpers";

const TableManualEdit = ({
  allTextList,
  showLoader,
  currentText,
  currentWord,
  currentColumn,
  columnsList,
  savingTableData,
  exitTableManualEditor,
  onPreviousTableColumn,
  onNextTableColumn,
  currentTextGroup,
  setCurrentTextGroupId,
  setCurrentWord,
  setInlineEditingGroup,
  updateCurrentTableTextPortions,
  groupedPara,
  saveManuallySanitizedPara
}) => {
  const modifiedPortions = get(
    groupBy(currentText.portions, "groupId"),
    currentTextGroup.id,
    []
  );

  const onResetManuallEditedText = () => {
    updateCurrentTableTextPortions({
      id: currentText.id,
      portions: getManualResetPortions(
        currentText.portions,
        currentTextGroup.id
      ),
      groupId: currentTextGroup.id,
      skipHighlight: false
    });
    const identifiedWord = find(modifiedPortions, (port) => port.isIdentified);
    if (identifiedWord) {
      setCurrentWord({
        ...getCurrentWordWithBlock(identifiedWord, currentText, true),
        reselectRecommendations: true
      });
    }
  };

  const onExitManualEdit = () => {
    exitTableManualEditor({ currentText, allTextList });
  };

  return (
    <div
      className="editor-section table-me-section"
      data-testid="editor-section"
    >
      {savingTableData && (
        <div className="overlay-loader">
          <Image src={loadingIconWhite} alt="" />
        </div>
      )}
      <section className="editor-section__list">
        <TableManualEditHeader
          showLoader={showLoader}
          currentText={currentText}
          currentColumn={currentColumn}
          onPrevColumn={onPreviousTableColumn}
          onNextColumn={onNextTableColumn}
          columnsList={columnsList}
          onCloseEditor={onExitManualEdit}
        />
        <TableTextListView
          isFetchingList={showLoader}
          setCurrentGroupId={setCurrentTextGroupId}
          currentGroup={currentTextGroup}
        />
      </section>

      <TableTextSmartSuggestion
        disabled={currentTextGroup.editing}
        isManuallyEdited={modifiedPortions[0]?.isGroupManuallyEdited}
        onResetManuallEditedText={onResetManuallEditedText}
      />

      <TextPreview
        isFromTableEdit
        activeSection={CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES}
        isLoading={showLoader}
        currentText={currentText}
        currentGroup={currentTextGroup}
        setCurrentGroupId={setCurrentTextGroupId}
        currentWord={currentWord}
        setInlineEditingGroup={setInlineEditingGroup}
        groupedPara={groupedPara}
        saveManuallySanitizedPara={saveManuallySanitizedPara}
        onExitManualEdit={onExitManualEdit}
      />
    </div>
  );
};

TableManualEdit.propTypes = {
  allTextList: PropTypes.array,
  currentText: PropTypes.object,
  currentWord: PropTypes.object,
  currentColumn: PropTypes.number,
  columnsList: PropTypes.array,
  showLoader: PropTypes.bool,
  savingTableData: PropTypes.bool,
  exitTableManualEditor: PropTypes.func,
  onPreviousTableColumn: PropTypes.func,
  onNextTableColumn: PropTypes.func,
  currentTextGroup: PropTypes.object,
  setCurrentTextGroupId: PropTypes.func,
  setCurrentWord: PropTypes.func,
  updateCurrentTableTextPortions: PropTypes.func,
  setInlineEditingGroup: PropTypes.func,
  groupedPara: PropTypes.object,
  saveManuallySanitizedPara: PropTypes.func
};

TableManualEdit.defaultProps = {
  allTextList: [],
  currentText: {},
  currentWord: {},
  currentColumn: 0,
  columnsList: [],
  showLoader: false,
  exitTableManualEditor: noop,
  onPreviousTableColumn: noop,
  onNextTableColumn: noop,
  currentTextGroup: {},
  setCurrentTextGroupId: noop,
  setCurrentWord: noop,
  updateCurrentTableTextPortions: noop,
  setInlineEditingGroup: noop,
  groupedPara: {},
  saveManuallySanitizedPara: noop
};

const mapStateToProps = (state) => {
  return {
    allTextList: getDirtyTableTextList(state),
    currentText: getCurrentTableText(state),
    currentWord: getCurrentTableWord(state),
    currentColumn: getCurrentColumn(state),
    showLoader: showLoaderForTableText(state),
    columnsList: getColumnsList(state),
    currentTextGroup: getCurrentTextGroup(state),
    groupedPara: getGroupedTableParaDetails(state)
  };
};

export default connect(mapStateToProps, {
  exitTableManualEditor,
  onPreviousTableColumn,
  onNextTableColumn,
  setCurrentTextGroupId,
  updateCurrentTableTextPortions,
  setCurrentWord: setCurrentTableWordBeingEdited,
  setInlineEditingGroup: setEditingCurrentTextGroup,
  saveManuallySanitizedPara: saveManuallySanitizedTablePara
})(TableManualEdit);
