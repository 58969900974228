import * as at from "../types";

const initialState = { hrId: 0, name: "", profileImage: "" };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case at.USER_SET_USER_DETAILS:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
