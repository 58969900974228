import CONFIG from "configs/config";
import { clamp, filter } from "lodash";
import { currentTimeStamp } from "utils/common";
import { getAspectRatio } from ".";

/**
 * Derive Image type based on its aspectRatio
 *
 * @param {String} imageSrc image url
 * @param {Function} setReady called when image is loaded
 * @param {Functoin} setType called when loaded with derived image type
 * @param {*} prevType prev image type to compare with new
 * @returns derived image type
 */
export const getDerivedImageType = (imageSrc, setReady, setType, prevType) => {
  if (imageSrc) {
    const img = new Image();
    img.width = "1px";
    img.height = "1px";
    img.onload = function onLoad() {
      setReady(true);
      const aspectRatio = getAspectRatio(this.width, this.height);
      setType(
        aspectRatio === CONFIG.CONSTANTS.SLIDE_IMAGE_RATIO.STANDARD
          ? CONFIG.CONSTANTS.SLIDE_IMAGE_TYPE.STANDARD
          : CONFIG.CONSTANTS.SLIDE_IMAGE_TYPE.WIDESCREEN
      );
    };
    img.onerror = function onError() {
      // setting to default
      setReady(true);
    };
    img.src = imageSrc;
  }
  return prevType || CONFIG.CONSTANTS.SLIDE_IMAGE_TYPE.WIDESCREEN;
};

/**
 * Get modified image url based on smart option
 * @param {Object} image
 * @param {Boolean} isManualEdit
 */
export function getModifiedImageUrl(img = {}, isManualEdit) {
  return img.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET
    ? null
    : !img.smartOption && !isManualEdit
    ? null
    : img.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH ||
      img.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT
    ? img.redactedImageUrl
    : img.uploadObjectKey || "";
}

/**
 * @param {Object} image
 * @returns {Boolean} if smart option can be applied or not
 */
export const canApplySmartOption = (image) =>
  !image.savedSmartOption && !image.smartOption && !!image.redactedImageUrl;

/**
 * @param {Object} image
 * @returns {Boolean} if smart option can be applied or not
 */
export const applySmartEffectOnImage = (image) =>
  !image.savedSmartOption || image.smartOption !== image.savedSmartOption;

export const getCFDomain = (domain) => `https://${domain + "/"}`;

/**
 * Create/Build signed url (with cloudfront domain) for the given s3 key
 *
 * @param {String} src image s3 key
 * @param {Object} signedToken cloudfront signed token to attach with url
 * @param {String} extraParam extra param to send
 * @param {Booleam} sendTimestampAsQs whether to send timestamp as query string or not (true when we need to skip cloudfront caching)
 * @returns {String} url
 */
export const getCFImageSrc = (
  src,
  signedToken,
  extraParam = "",
  sendTimestampAsQs = false
) => {
  if (!src) {
    return "";
  }
  const { qs, domain } = signedToken;
  if (qs) {
    return (
      getCFDomain(domain) +
      src +
      ("?" + qs) +
      (extraParam ? "&" + extraParam : "") +
      (sendTimestampAsQs ? "&timestamp=" + currentTimeStamp() : "")
    );
  }
  return "";
};

/**
 * Create/Build signed url without caching
 *
 * @param {String} src
 * @param {Object} signedToken
 * @returns {String} url
 */
export const getCFImageSrcWithoutCache = (src, signedToken) => {
  if (!src) {
    return "";
  }
  const { qs, domain } = signedToken;
  if (qs) {
    return (
      getCFDomain(domain) +
      src +
      (qs ? "?" + qs : "?") +
      `&timestamp=${new Date().getTime()}`
    );
  }
  return "";
};

/**
 * Get Blur redaction's relative value to be applied based on image resolution
 *
 * @param {Object} imageData image width and height
 * @returns {Array} threshold and noOftime to apply the blur
 */
export const getBlurRelativeValue = ({ width, height }) => {
  const thresholdSteps = 5;
  const x = (width * height) / (width + height) + 100;
  const rad = clamp(
    Math.ceil(x) % 100,
    CONFIG.CONSTANTS.IMAGE_EDITOR.BLUR_INITIAL_VALUE_SMART,
    100
  );
  const noOfTime = Math.floor(x / 100);
  // Limit the max no of time we apply blur effect and set the density level to max when no of time is greate to a threshold
  return [
    noOfTime >= thresholdSteps ? 100 : rad,
    clamp(noOfTime, 1, thresholdSteps)
  ];
};

/**
 * Filterout sensitive image
 *
 * @param {Array} imageList
 * @returns {Array} filtered image list
 */
export const filterSensitiveItem = (imageList) =>
  filter(imageList, (img) => img.redactedImageUrl);
