import { toast } from "react-toastify";

const defaultSettings = {};

function ToastService() {}

ToastService.prototype.success = function successToast(message, settings = {}) {
  return toast.success(message, { ...defaultSettings, ...settings });
};

ToastService.prototype.warning = function warnToast(message, settings = {}) {
  return toast.warn(message, {
    ...defaultSettings,
    autoClose: false,
    ...settings
  });
};

ToastService.prototype.error = function errorToast(message, settings = {}) {
  return toast.error(message, {
    ...defaultSettings,
    autoClose: false,
    ...settings
  });
};

ToastService.prototype.default = function defaultToast(message, settings = {}) {
  return toast(message, { ...defaultSettings, ...settings });
};

ToastService.prototype.dismiss = function dismissToast(toastId) {
  toast.dismiss(toastId);
};

ToastService.prototype.updateToast = function updateToast(toastId, settings) {
  toast.update(toastId, settings);
};

export default new ToastService();
