import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Placeholder } from "semantic-ui-react";
import { find, get, isNumber, noop } from "lodash";
import "./SlidesView.scss";

// Components
import ProgressBar from "components/ProgressBar/ProgressBar";
import CustomNextArrow from "components/NextArrow/NextArrow";
import CustomPrevArrow from "components/PrevArrow/PrevArrow";
import SlideImageCarousel from "components/SlideImageCarousel/SlideImageCarousel";

import { presentationType } from "utils/customPropTypes";
import { isLG, isXL } from "utils/mobile";
import { getGoToSlideNo } from "utils/common";
import { getCFImageSrc, getDerivedImageType } from "utils/helpers";

const SlidesView = React.forwardRef(
  (
    {
      slidesList,
      pptDetails: { totalSlides, completedSlides, pptId },
      changeSlide,
      showLoader,
      currentSlide,
      setSlideMarkComplete,
      currentSlideIndex,
      signedToken,
      updateCurrentSlide,
      setCurrentSlideIndex
    },
    ref
  ) => {
    const [isReady, setIsReady] = useState(false);
    const [imageType, setImageType] = useState();

    const [goToSlideNo, setGoToSlideNo] = useState(null);

    // Derive image type and basis render slider, until show loader, else we will have jumping effect when opening 4:3 ppt slides
    useEffect(() => {
      if (slidesList && slidesList[0]) {
        const newImageType = getDerivedImageType(
          getCFImageSrc(slidesList[0].image, signedToken),
          setIsReady,
          setImageType,
          imageType
        );
        if (imageType !== newImageType) {
          setImageType(newImageType);
        }
      }
    }, [slidesList]);

    useEffect(() => {
      const slideNo = get(slidesList, `[${currentSlideIndex}].slideNumber`, 0);
      if (goToSlideNo !== slideNo) {
        setGoToSlideNo(slideNo);
      }
    }, [currentSlideIndex]);

    const onChangeSlide = (current, next) => {
      if (Number(current) !== Number(next)) {
        const slide = get(slidesList, `[${next}]`, {});
        const { pptId, slideId, activeSectionToSet } = currentSlide;
        // pass down the activeSectionToSet value
        if (slideId !== slide.slideId || activeSectionToSet) {
          changeSlide({
            slideData: {
              ...slide,
              pptId,
              activeSectionToSet
            }
          });
        }
      }
    };

    const goToSlide = (slideNo) => {
      if (isNumber(slideNo)) {
        const moveToSlideNo = getGoToSlideNo(
          slidesList,
          slideNo,
          currentSlideIndex
        );
        setGoToSlideNo(moveToSlideNo);
        onChangeSlide(
          currentSlideIndex,
          find(slidesList, { slideNumber: moveToSlideNo })?.slideIndex
        );
      }
    };

    const isLoading = showLoader || !imageType || !isReady;

    const slideNotLoaded = currentSlideIndex === -1;

    const singleSlideSettings = {
      className: "slider-container single-slide-mode",
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      lazyLoad: false
    };

    const basicSliderSettings = {
      className: "slider-container loader-container multiple-slide-mode",
      centerMode: false,
      infinite: false,
      centerPadding: "0px",
      slidesToShow: 3,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      responsive: [
        {
          breakpoint: 1024,
          settings: singleSlideSettings
        }
      ]
    };
    const imageSliderSettings = {
      ...basicSliderSettings,
      ...(slidesList.length > 2
        ? {
            className: "slider-container multiple-slide-mode",
            centerMode: true,
            slidesToScroll: 1
          }
        : singleSlideSettings),
      speed: 500,
      lazyLoad: true,
      focusOnSelect: true,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow visibilyHide={slideNotLoaded} />,
      beforeChange: onChangeSlide
    };

    // Center mode is on only when slides length is greater than 2
    const isCenterMode = (isLG() || isXL()) && slidesList.length > 2;

    return (
      <section className="slider">
        {!isLoading && pptId ? (
          <SlideImageCarousel
            ref={ref}
            slidesList={slidesList}
            imageSliderSettings={imageSliderSettings}
            currentSlide={currentSlide}
            setSlideMarkComplete={setSlideMarkComplete}
            pptId={pptId}
            imageType={imageType}
            isCenterMode={isCenterMode}
            visibilyHide={slideNotLoaded}
            updateCurrentSlide={updateCurrentSlide}
            setCurrentSlideIndex={setCurrentSlideIndex}
          />
        ) : (
          <Slider {...basicSliderSettings}>
            <Placeholder className="slider-block" />
            <Placeholder className="slider-block" />
            <Placeholder className="slider-block" />
          </Slider>
        )}

        <ProgressBar
          totalSlides={totalSlides ? totalSlides : slidesList.length}
          completedSlides={completedSlides}
          showGoTo={!isLoading && slidesList.length > 1 && !slideNotLoaded}
          goToSlideNo={goToSlideNo}
          goToSlide={goToSlide}
          setGoToSlideNo={setGoToSlideNo}
        />
      </section>
    );
  }
);

SlidesView.propTypes = {
  slidesList: PropTypes.array.isRequired,
  pptDetails: presentationType,
  changeSlide: PropTypes.func,
  showLoader: PropTypes.bool,
  currentSlide: PropTypes.object,
  setSlideMarkComplete: PropTypes.func,
  currentSlideIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  signedToken: PropTypes.object,
  updateCurrentSlide: PropTypes.func,
  setCurrentSlideIndex: PropTypes.func
};

SlidesView.defaultProps = {
  pptDetails: {},
  changeSlide: noop,
  currentSlide: {},
  setSlideMarkComplete: noop,
  currentSlideIndex: "",
  signedToken: {},
  updateCurrentSlide: noop,
  setCurrentSlideIndex: noop
};

export default SlidesView;
