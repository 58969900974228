import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { List, Placeholder } from "semantic-ui-react";
import { noop } from "lodash";
import "./TableTextListView.scss";

import NoResult from "components/NoResult/NoResult";
import TextAreaWithHighlight from "components/TextAreaWithHighlight/TextAreaWithHighlight";

import {
  setCurrentTableTextBeingEdited,
  setCurrentTableWordBeingEdited
} from "containers/TableManualEdit/actions";
import {
  getCurrentTableText,
  getCurrentTableWord,
  getTableTextListByColumn,
  showLoaderForTableText
} from "containers/TableManualEdit/selectors";
import { getSmartSuggestion } from "containers/SmartSuggestion/selectors";

/**
 * SANITIZE SCREEN => TABLE TEXT MANUAL EDIT
 * List of table text from selected table when manually editing
 * @returns JSX
 */
const TableTextListView = ({
  showLoader,
  textList,
  currentText,
  setCurrentText,
  currentWord,
  setCurrentWord,
  savedData,
  currentGroup,
  setCurrentGroupId
}) => {
  const showNoResult = !showLoader && !textList.length;

  const onTextSelect = (nextText) => {
    if (currentText.id !== nextText.id) {
      setCurrentText(nextText);
    }
  };

  if (showNoResult) {
    return <NoResult />;
  }

  return (
    <div className="main-block list-container text-list">
      {showLoader ? (
        <div className="text-list__all-texts">
          <Placeholder className="mt-0" />
          <Placeholder />
          <Placeholder />
          <Placeholder />
        </div>
      ) : (
        <div className="text-list__all-texts">
          <SimpleBar
            className="text-list__list-wrapper"
            scrollbarMaxSize={50}
            timeout={1500}
          >
            <List verticalAlign="middle">
              {textList.map((text) => (
                <List.Item
                  id={text.id}
                  className={currentText.id === text.id ? "active" : ""}
                  key={text.id}
                  onClick={() => onTextSelect(text)}
                >
                  {text.text}
                </List.Item>
              ))}
            </List>
          </SimpleBar>
        </div>
      )}
      <TextAreaWithHighlight
        currentText={currentText}
        currentWord={currentWord}
        setCurrentWord={setCurrentWord}
        showLoader={showLoader}
        savedData={savedData}
        currentGroup={currentGroup}
        setCurrentGroupId={setCurrentGroupId}
        isTableText
      />
    </div>
  );
};

TableTextListView.propTypes = {
  textList: PropTypes.array,
  showLoader: PropTypes.bool,
  currentText: PropTypes.object,
  setCurrentText: PropTypes.func,
  currentWord: PropTypes.object,
  setCurrentWord: PropTypes.func,
  savedData: PropTypes.array,
  currentGroup: PropTypes.object,
  setCurrentGroupId: PropTypes.func
};

TableTextListView.defaultProps = {
  textList: [],
  currentText: {},
  showLoader: false,
  setCurrentText: noop,
  currentWord: {},
  setCurrentWord: noop,
  savedData: [],
  currentGroup: {},
  setCurrentGroupId: noop
};

function mapStateToProps(state) {
  return {
    textList: getTableTextListByColumn(state),
    currentText: getCurrentTableText(state),
    currentWord: getCurrentTableWord(state),
    showLoader: showLoaderForTableText(state),
    savedData: getSmartSuggestion(state)
  };
}

export default connect(mapStateToProps, {
  setCurrentText: setCurrentTableTextBeingEdited,
  setCurrentWord: setCurrentTableWordBeingEdited
})(TableTextListView);
