import React from "react";
import PropTypes from "prop-types";
import { Placeholder } from "semantic-ui-react";
import { noop } from "lodash";
import { connect } from "react-redux";
import "./ImageSmartSuggestion.scss";

import CONFIG from "configs/config";
import ResetManualChange from "components/ResetManualChange";
import {
  getCanApplySmartOption,
  getSelectedSmartOption
} from "containers/SmartSuggestion/selectors";

import {
  onSelectSmartOption,
  resetManuallyEditedImage
} from "containers/ImageListView/actions";
import { onSelectSmartOptionForTable } from "containers/TableListView/actions";
import { resetManuallyEditedTable } from "containers/TableManualEdit/actions";

// Images
import patchIcon from "assets/images/patch-icon.svg";
import blurIcon from "assets/images/blur.svg";
import stampIcon from "assets/images/stamp.svg";
import selectIcon from "assets/images/tick-circle-green.svg";
import smartEdit from "assets/images/smart-graph.svg";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

/**
 * SANITIZE SCREEN
 * Image Smart Suggestions
 * @returns JSX
 */
const ImageSmartSuggestion = ({
  activeSection,
  currentEntity,
  selectedOption,
  onSelectSmartOption,
  onSelectSmartOptionForTable,
  isLoading,
  canApplySmartOption,
  isManuallyEdited,
  resetManuallyEditedImage,
  resetManuallyEditedTable
}) => {
  const SMART_OPTIONS = CONFIG.CONSTANTS.IMAGE_SMART_OPTION;
  const isGraphView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS;
  const isTableView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;
  const showTwoOption = isTableView || isGraphView;
  const selectedIcon = <img className="select-icon" src={selectIcon} alt="" />;

  const onSmartSelection = isTableView
    ? onSelectSmartOptionForTable
    : onSelectSmartOption;

  const onResetManualChange = isTableView
    ? resetManuallyEditedTable
    : resetManuallyEditedImage;

  if (isLoading) {
    return (
      <div
        className="image-suggestion-container"
        data-testid="image-suggestion-loader"
      >
        {!showTwoOption && (
          <Placeholder className="image-suggestion-container__option-item" />
        )}
        <Placeholder
          className={
            "image-suggestion-container__option-item" +
            (showTwoOption ? " mr-5" : "")
          }
        />
        <Placeholder className="image-suggestion-container__option-item" />
      </div>
    );
  }
  return isManuallyEdited ? (
    <ResetManualChange
      section={isTableView ? "Table" : "Image"}
      onResetManualChange={() => {
        onResetManualChange(currentEntity);
        onAAClickEvents("Reset_Manual_Changes");
      }}
    />
  ) : (
    <ul
      className="image-suggestion-container"
      data-testid="image-suggestion-container"
    >
      {showTwoOption ? (
        <li
          data-testid="image-smartEdit"
          className={`image-suggestion-container__option-item mr-5 ${
            selectedOption === SMART_OPTIONS.SMARTEDIT ? "selected" : ""
          } ${!canApplySmartOption ? "disabled" : ""}`}
          onClick={() => {
            if (canApplySmartOption) {
              onSmartSelection(
                selectedOption === SMART_OPTIONS.SMARTEDIT
                  ? ""
                  : SMART_OPTIONS.SMARTEDIT
              );
              onAAClickEvents("Smart_Edit");
            }
          }}
        >
          <img
            className="image-suggestion-container__icon"
            src={smartEdit}
            alt="Smart Edit"
          />
          <span>{CONFIG.LABELS.SMART_EDIT}</span>
          {selectedIcon}
        </li>
      ) : (
        <li
          data-testid="image-patch"
          className={`image-suggestion-container__option-item ${
            selectedOption === SMART_OPTIONS.PATCH ? "selected" : ""
          } ${!canApplySmartOption ? "disabled" : ""}`}
          onClick={() => {
            if (canApplySmartOption) {
              onSmartSelection(
                selectedOption === SMART_OPTIONS.PATCH
                  ? ""
                  : SMART_OPTIONS.PATCH
              );
              onAAClickEvents("Smart_Image_Patch");
            }
          }}
        >
          <img
            className="image-suggestion-container__icon"
            src={patchIcon}
            alt="Patch"
          />
          <span>{CONFIG.LABELS.PATCH}</span>
          {selectedIcon}
        </li>
      )}
      {!showTwoOption && (
        <li
          data-testid="image-blur"
          className={`image-suggestion-container__option-item ${
            selectedOption === SMART_OPTIONS.BLUR ? "selected" : ""
          }`}
          onClick={() => {
            onSmartSelection(
              selectedOption === SMART_OPTIONS.BLUR ? "" : SMART_OPTIONS.BLUR
            );
            onAAClickEvents("Smart_Image_Blur");
          }}
        >
          <img
            className="image-suggestion-container__icon"
            src={blurIcon}
            alt="Blur"
          />
          <span>{CONFIG.LABELS.BLUR}</span>
          {selectedIcon}
        </li>
      )}
      <li
        data-testid="image-stamp"
        className={`image-suggestion-container__option-item ${
          selectedOption === SMART_OPTIONS.STAMP ? "selected" : ""
        }`}
        onClick={() => {
          onSmartSelection(
            selectedOption === SMART_OPTIONS.STAMP ? "" : SMART_OPTIONS.STAMP
          );
          onAAClickEvents("Smart_Image_Stamp");
        }}
      >
        <img
          className="image-suggestion-container__icon"
          src={stampIcon}
          alt="Stamp"
        />
        <span>{CONFIG.LABELS.STAMP}</span>
        {selectedIcon}
      </li>
    </ul>
  );
};

ImageSmartSuggestion.propTypes = {
  activeSection: PropTypes.string.isRequired,
  currentEntity: PropTypes.object,
  selectedOption: PropTypes.string,
  onSelectSmartOption: PropTypes.func,
  onSelectSmartOptionForTable: PropTypes.func,
  isLoading: PropTypes.bool,
  canApplySmartOption: PropTypes.bool,
  isManuallyEdited: PropTypes.bool,
  resetManuallyEditedImage: PropTypes.func,
  resetManuallyEditedTable: PropTypes.func
};

ImageSmartSuggestion.defaultProps = {
  selectedOption: "",
  currentEntity: {},
  onSelectSmartOption: noop,
  onSelectSmartOptionForTable: noop,
  canApplySmartOption: false,
  isLoading: false,
  isManuallyEdited: false,
  resetManuallyEditedImage: noop,
  resetManuallyEditedTable: noop
};

export default connect(
  (state, props) => ({
    canApplySmartOption: getCanApplySmartOption(state),
    selectedOption: props.selectedOption || getSelectedSmartOption(state)
  }),
  {
    onSelectSmartOption,
    onSelectSmartOptionForTable,
    resetManuallyEditedImage,
    resetManuallyEditedTable
  }
)(ImageSmartSuggestion);
