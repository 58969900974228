/**
 * Downloads PPT with creating anchor tag in document to prevent opening new tab
 * @param {String} filePath URL of PPT to be downloaded
 */
export const downloadFile = (filePath) => {
  const link = document.createElement("a");
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
  link.click();
};
