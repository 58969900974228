import React from "react";
import PropTypes from "prop-types";
import { Image, Modal } from "semantic-ui-react";
import { noop } from "lodash";
import "./FullPagePreview.scss";

import CloseIcon from "assets/images/close-green-large.svg";

const FullPagePreview = ({ show, children, onCloseFn }) => {
  return (
    <Modal
      className="fullpage-preview"
      size="fullscreen"
      open={show}
      onClose={onCloseFn}
    >
      <Image
        className="fullpage-preview__close"
        src={CloseIcon}
        alt=""
        width="16"
        height="16"
        onClick={onCloseFn}
      />
      {children}
    </Modal>
  );
};

FullPagePreview.propTypes = {
  show: PropTypes.bool,
  onCloseFn: PropTypes.func,
  children: PropTypes.node
};

FullPagePreview.defaultProps = {
  show: false,
  children: <span />,
  onCloseFn: noop
};

export default FullPagePreview;
