import React, { useRef, useEffect } from "react";
import { Button, Divider, Header, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import "./DisclaimerModal.scss";
import CONFIG from "configs/config";

/**
 * HOME SCREEN
 * Shows disclaimer to the user after OKTA login
 * @returns JSX
 */
const DisclaimerModal = ({
  showDisclaimerModal,
  disclaimerModalData,
  setShowDisclaimerModal,
  showingDisclaimerFromFooter,
  onCloseDisclaimer
}) => {
  const actionButton = useRef();

  useEffect(() => {
    if (actionButton.current) {
      actionButton.current.focus();
    }
  }, [showDisclaimerModal]);

  const onCloseModal = () => {
    setShowDisclaimerModal(false);
    onCloseDisclaimer();
  };

  const buttonLabel = showingDisclaimerFromFooter
    ? CONFIG.LABELS.CLOSE
    : CONFIG.LABELS.AGREE_DISCLAIMER;

  return (
    <Modal size="tiny" open={showDisclaimerModal} className="disclaimer-modal">
      <Modal.Content>
        <Header as="h3">{disclaimerModalData.header}</Header>
        <Divider />
        <div className="text-align-justify my-2">
          {disclaimerModalData.message.map((disclaimerTextObject) => {
            if (disclaimerTextObject.type === "link") {
              return (
                <a
                  key={disclaimerTextObject.id}
                  target="_blank"
                  rel="noreferrer"
                  href={disclaimerTextObject.href}
                >
                  {disclaimerTextObject.text}
                </a>
              );
            }
            return disclaimerTextObject.text;
          })}
        </div>
        <Divider />
        <div className="disclaimer-modal__footer-container mt-2">
          <Button
            ref={actionButton}
            onClick={onCloseModal}
            className="px-5"
            primary
          >
            {buttonLabel}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

DisclaimerModal.propTypes = {
  showDisclaimerModal: PropTypes.bool,
  setShowDisclaimerModal: PropTypes.func,
  disclaimerModalData: PropTypes.shape({
    header: PropTypes.string,
    message: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
        link: PropTypes.string
      })
    )
  }),
  showingDisclaimerFromFooter: PropTypes.bool,
  onCloseDisclaimer: PropTypes.func
};

DisclaimerModal.defaultProps = {
  showDisclaimerModal: false,
  disclaimerModalData: {},
  showingDisclaimerFromFooter: false,
  onCloseDisclaimer: noop,
  setShowDisclaimerModal: noop
};

export default DisclaimerModal;
