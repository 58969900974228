import * as at from "../types";
import { createAction as ca } from "utils/action";

export const getSlideDetailsForPreview = ca(at.GET_SLIDE_DETAILS_FOR_PREVIEW);

export const getPreviewSlidesAction = ca(at.GET_PREVIEW_DOCUMENT);

export const clearPreviewSlidesAction = ca(at.CLEAR_PREVIEW_SLIDES);

export const setCurrentSlideIndexAction = ca(at.SET_CURRENT_SLIDE_INDEX);

export const togglePreviewDocAction = ca(at.TOGGLE_PREVIEW_DOC);
