import React from "react";
import PropTypes from "prop-types";
import { Button, Image, Modal } from "semantic-ui-react";
import { noop } from "lodash";
import "./ConfirmationModal.scss";

import CloseIcon from "assets/images/close_grey_small.svg";

const ConfirmationModal = ({
  size = "tiny",
  show = false,
  renderComponent,
  isConfirming = false,
  onCloseFn,
  onConfirmFn,
  closeBtnLabel = "No",
  confirmBtnLabel = "Yes",
  headerMessage = "Are you sure?"
}) => {
  return (
    <Modal
      size={size}
      open={show}
      onClose={onCloseFn}
      className="confirmation-modal"
    >
      <Modal.Header>
        <span>{headerMessage}</span>
        <Image
          className={
            "close-btn " + (isConfirming ? "cursor-not-allowed" : "pointer")
          }
          src={CloseIcon}
          alt=""
          onClick={() => {
            if (!isConfirming) {
              onCloseFn(false);
            }
          }}
          role="Close"
          tabIndex={3}
          onKeyPress={(event) => {
            if (!isConfirming) {
              event.keyCode === 13 ? onCloseFn(false) : null;
            }
          }}
        />
      </Modal.Header>
      <Modal.Content>{renderComponent}</Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={onCloseFn}
          tabIndex={1}
          disabled={isConfirming}
        >
          {closeBtnLabel}
        </Button>
        <Button
          primary
          onClick={onConfirmFn}
          tabIndex={2}
          loading={isConfirming}
        >
          {confirmBtnLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool,
  renderComponent: PropTypes.node,
  onCloseFn: PropTypes.func,
  onConfirmFn: PropTypes.func,
  closeBtnLabel: PropTypes.string,
  confirmBtnLabel: PropTypes.string,
  isConfirming: PropTypes.bool,
  headerMessage: PropTypes.string
};

ConfirmationModal.defaultProps = {
  size: "tiny",
  show: false,
  renderComponent: null,
  isConfirming: false,
  closeBtnLabel: "No",
  confirmBtnLabel: "Yes",
  headerMessage: "Are you sure?",
  onCloseFn: noop,
  onConfirmFn: noop
};

export default ConfirmationModal;
