import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./UserGuideFloatingIcon.scss";
import {
  AALinkName,
} from "../../analytics/AnalyticsCapture";

const UserGuideFloatingIcon = ({ triggerFrom }) => {
  return (
    <Link
      onClick={() => AALinkName("User_Guide", "Link_click")}
      className="user-guide-floating-icon"
      to={{
        pathname: "/user-guide",
        state: { from: triggerFrom }
      }}
    >
      Help Guide
    </Link>
  );
};

UserGuideFloatingIcon.propTypes = {
  triggerFrom: PropTypes.string
};

UserGuideFloatingIcon.defaultProps = {
  triggerFrom: "home"
};

export default UserGuideFloatingIcon;
