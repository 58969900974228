import axiosInstance from "configs/axios";
import { compact, forEach, get, map } from "lodash";
import CONFIG from "configs/config";
import { extractApiResponse } from "utils/helpers";

// URIs
const URI_GET_TABLE_TEXTS = "/Tables/getcellsbytable/";
const URI_TABLE_TEXT_RECOMMEDATIONS = "/Table/cellsrecommendation/";
const URI_SAVE_TABLE_TEXT = "/Tables/updatecells/";
const URI_GET_UPDATED_THUMBNAIL = "/Tables/getupdatedtablethumbnails";
const URI_RESET_MANUAL_TABLE_CHANGES = "/Tables/resetcells/";

export function Portion(result, addSpaceSeparator) {
  this.id = result.id;
  this.groupId = result.groupId;
  this.parentId = result.parentId;
  this.separator = addSpaceSeparator ? " " : "";
  this.isGroupManuallyEdited = result.isGroupManuallyEdited;
  this.text = result.text;
  this.isUpdated = result.isUpdated;
  this.isReset = result.isReset;
  this.updatedText = result.updatedText;
  this.isIdentified = result.isIdentified;
  this.isFromSuggestion = result.isFromSuggestion;
  this.identifiedEntityGroup = result.identifiedPortionGroup;
}

export function TableTextList(result, index) {
  this.idx = index + 1;
  this.id = result.cellId;
  this.tableId = result.tableId;
  this.text = result.text;
  this.columnNo = result.columnNumber;
  this.rowNo = result.rowNumber;
  this.isIdentified = result.isIdentified;
  this.portionIds = result.portionIds;
  this.portions = map(
    result.portions,
    (portion, i) =>
      new Portion(
        portion,
        portion.parentId !== (result.portions[i + 1] || {}).parentId
      )
  );
}

export function getTableTextList(tableId, getStanitized = false) {
  return axiosInstance
    .get(`${URI_GET_TABLE_TEXTS}${tableId}/${getStanitized}`)
    .then((response) =>
      map(
        extractApiResponse(response),
        (result, idx) => new TableTextList(result, idx)
      )
    );
}

export function SensitiveEntities(entry) {
  this.entity = entry.entity;
  this.startPos = entry.startPosition;
  this.endPos = entry.endPosition;
  this.entityIds = entry.identitifiedPortions.map((id) => id.portionId);
  this.recommendations = (entry.recommendations || []).slice(0, 2);
}

export function TableTextSuggestions(data) {
  this.id = data.cellId;
  this.entities = compact(
    map(get(data, "identifiedEntities", []), (entry) =>
      entry.identitifiedPortions.length ? new SensitiveEntities(entry) : false
    )
  ); // Remove entities if there is no portionIds
}

// From Table text recommendation service
export function getTableTextSuggestions(slideId, tableId) {
  const mappedData = {};
  return axiosInstance
    .get(
      `${CONFIG.SERVER.BASE_RECOMMENDATION_URL}${URI_TABLE_TEXT_RECOMMEDATIONS}${slideId}/${tableId}`
    )
    .then((response) => {
      forEach(extractApiResponse(response), (result) => {
        mappedData[result.cellId] = new TableTextSuggestions(result);
      });
      return mappedData;
    });
}

function SanitizedPortion(portion) {
  this.id = portion.id;
  this.parentId = portion.parent;
  this.text = portion.text;
  this.groupId = portion.groupId;
  this.isGroupManuallyEdited = portion.isGroupManuallyEdited;
  this.isUpdated = portion.isUpdated;
  this.isReset = portion.isReset;
  this.updatedText = portion.updatedText;
  this.isIdentified = portion.isIdentified;
  this.isFromSuggestion = portion.isFromSuggestion;
  this.identifiedPortionGroup = portion.identifiedEntityGroup;
}

function SanitizedTableCell(input) {
  this.slideId = input.slideId;
  this.tableId = input.tableId;
  this.cellId = input.id;
  this.text = input.text;
  this.columnNumber = input.columnNo;
  this.rowNumber = input.rowNo;
  this.portionIds = input.portionIds;
  this.isIdentified = input.isIdentified;

  this.portions = map(
    input.portions,
    (portion) => new SanitizedPortion(portion)
  );
}

export function saveSanitizedTableText(slideId, updatedCells) {
  const requestPayload = map(
    updatedCells,
    (cell) => new SanitizedTableCell(cell)
  );
  return axiosInstance
    .put(`${URI_SAVE_TABLE_TEXT}${slideId}`, requestPayload)
    .then(() => ({ slideId, ...requestPayload }));
}

export function getUpdatedTableThumbnails(slideId, tableIds) {
  return axiosInstance
    .post(`${URI_GET_UPDATED_THUMBNAIL}`, tableIds)
    .then(() => slideId);
}

export function resetManuallyEditedTable(payload) {
  const { slideId, id } = payload;
  return axiosInstance
    .put(`${URI_RESET_MANUAL_TABLE_CHANGES}${slideId}/${id}`)
    .then(() => ({
      ...payload,
      modifiedTableUrl: null,
      savedSmartOption: null,
      smartOption: null,
      uploadObjectKey: null
    }));
}
