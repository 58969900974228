import { createSelector } from "reselect";
import { configureLoadingState } from "containers/Common/reducers/loading";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import { GET_DOCUMENT_SUMMARY, GET_DOWNLOAD_URL } from "../types";
import { getSelectedPPTId } from "containers/Preview/selectors";
import { getPptDetails } from "containers/Sanitize/selectors";

// Configure loading state reducer to capture resource required
configureLoadingState([GET_DOCUMENT_SUMMARY, GET_DOWNLOAD_URL]);

export const isFetchingDocumentSummary = createLoadingSelector([
  GET_DOCUMENT_SUMMARY
]);
export const isFetchingDownloadUrl = createLoadingSelector([GET_DOWNLOAD_URL]);

const documentSummary = (state) => state.documentSummary;

export const getCurrentPptDetails = createSelector(
  [getPptDetails, getSelectedPPTId],
  (pptDetail, selectedpptId) => pptDetail?.pptId || selectedpptId
);

export const isSummaryModalShown = createSelector(
  documentSummary,
  (ds) => ds.showSummaryModal
);

export const getDocumentSummaryData = createSelector(
  documentSummary,
  (ds) => ds.data.data
);
export const getDownloadUrlSelector = createSelector(
  documentSummary,
  (ds) => ds.downloadUrl
);
