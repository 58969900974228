import React from "react";
import { Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import CONFIG from "configs/config";

import LogoPlaceholder from "assets/images/Placeholders/logo-placeholder.svg";
import PortraitPlaceholder from "assets/images/Placeholders/portrait-placeholder.svg";
import StockPlaceholder from "assets/images/Placeholders/stock-placeholder.svg";

const StampSubMenu = ({ addStamp }) => {
  return (
    <div className="image-manual-editor__stamp">
      <div
        data-testid="logo-stamp"
        className="stamp-item"
        onClick={() => addStamp("logo")}
      >
        <Image src={LogoPlaceholder} size="tiny" draggable={false} />
        <p>{CONFIG.LABELS.IMAGE_MANUAL_EDITOR.LOGO}</p>
      </div>
      <div
        data-testid="portrait-stamp"
        className="stamp-item"
        onClick={() => addStamp("portrait")}
      >
        <Image src={PortraitPlaceholder} size="tiny" draggable={false} />
        <p>{CONFIG.LABELS.IMAGE_MANUAL_EDITOR.PORTRAIT}</p>
      </div>
      <div
        data-testid="stock-stamp"
        className="stamp-item"
        onClick={() => addStamp("stock")}
      >
        <Image src={StockPlaceholder} size="tiny" draggable={false} />
        <p>{CONFIG.LABELS.IMAGE_MANUAL_EDITOR.GENERIC}</p>
      </div>
    </div>
  );
};

StampSubMenu.propTypes = {
  addStamp: PropTypes.func.isRequired
};

export default StampSubMenu;
