export * from "./date";
export * from "./text";
export * from "./image";
export * from "./download";

/**
 * Extract result from api response
 *
 * @param {Object} axiosResponse
 * @returns result from api response
 */
export function extractApiResponse({ data = {} }) {
  return data.result || {};
}

// Greatest common divisor (GCD)
export function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

/**
 * Get image's aspectRatio
 *
 * @param {Number} w image width
 * @param {Number} h image height
 * @returns
 */
export function getAspectRatio(w, h) {
  if (w && h) {
    const r = gcd(w, h);
    return w / r + ":" + h / r;
  }
  return "";
}
