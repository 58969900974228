import { call, put, select, takeLatest } from "redux-saga/effects";
import * as at from "../types";
import CONFIG from "configs/config";
import { getCurrentSlide } from "containers/Sanitize/selectors";
import { autoSaveDirtyChanges } from "containers/Sanitize/sagas";
import { handleCatchedError } from "utils/sagaUtils";
import { getPreviewSlidesCache, getSelectedPPTId } from "../selectors";
import { filter, uniqBy } from "lodash";
import { rsGetPreviewDocument, rsGetSlidesData } from "./resourceSaga";
import { createAction } from "utils/action";

function* watchPreviewSaga() {
  /**
   * Get preview document based on the slot.
   * Preview document fetches only 7 slides, from the starting slide no
   * Also, we reuse already fetched data, when user is going back to the previously visited slot
   */
  yield takeLatest(at.GET_PREVIEW_DOCUMENT, function* getPreviewSlides(data) {
    try {
      // Mock requested call before autoSave,
      // so that we show preview document loader even while saving dirty changes (similar to preview slide)
      yield put(createAction(at.GET_PREVIEW_DOCUMENT_REQUESTED)());
      yield* autoSaveDirtyChanges();

      const pptDetails = yield select(getCurrentSlide);
      const pptId = yield select(getSelectedPPTId);

      const slidesCacheAll = yield select(getPreviewSlidesCache);

      const startIndex = data.payload;
      const endIndex =
        data.payload +
        CONFIG.CONSTANTS.PREVIEW_DOCUMENT_SETTINGS.THUMBNAILS_COUNT -
        1;

      const filtered = filter(slidesCacheAll, (slide) => {
        if (slide.slideIndex >= startIndex && slide.slideIndex <= endIndex) {
          return slide;
        }
      });

      // get unique items from filtered slides
      const uniqueItems = uniqBy(filtered, "slideIndex");

      if (filtered.length) {
        yield put({
          type: at.GET_PREVIEW_DOCUMENT_SUCCEEDED,
          payload: { data: uniqueItems }
        });
      } else {
        yield call(
          rsGetPreviewDocument,
          pptDetails.pptId || pptId,
          data.payload
        );
      }
    } catch (e) {
      yield call(handleCatchedError, e);
    }
  });

  /**
   * Get slide details for preview,
   * triggered when opening preview document after auto sanitize
   */
  yield takeLatest(
    at.GET_SLIDE_DETAILS_FOR_PREVIEW,
    function* getPPTDetailsForPreview(data) {
      const pptId = yield select(getSelectedPPTId);
      if (pptId) {
        yield put({
          type: at.GET_PREVIEW_DOCUMENT,
          payload: data.payload
        });
        yield call(rsGetSlidesData, pptId);
      }
    }
  );
}

export default function* previewSaga() {
  yield call(watchPreviewSaga);
}
