import { createSelector } from "reselect";
import { filter, findIndex, get, isEmpty } from "lodash";
import CONFIG from "configs/config";
import {
  getActiveSection,
  getCurrentSlide,
  getPptDetails,
  getSlidesList,
  isFetchingPpt,
  getCurrentTextGroup
} from "containers/Sanitize/selectors";
import {
  isFetchingTextList,
  isFetchingModifiedText,
  localTextDictionary,
  getCurrentWord,
  getPrevWord,
  getNextWord,
  canResetTextToOriginal,
  getCurrentText,
  isFetchingSuggestions
} from "containers/TextListView/selectors";
import {
  getSelectedImages,
  isFetchingImages,
  getPrevImage,
  getNextImage,
  canResetImageToOriginal,
  getSelectedSmartOptionForImage,
  isResetingEditedImage
} from "containers/ImageListView/selectors";
import {
  getNextTable,
  getPrevTable,
  getSelectedTable,
  isFetchingTables,
  getSelectedSmartOptionForTable,
  canResetTableToOriginal
} from "containers/TableListView/selectors";
import { getSectionWhichHasData } from "utils/common";
import { getSanitizedWord } from "utils/helpers";
import {
  refreshingTableThumbnails,
  resetingTableManualChanges
} from "containers/TableManualEdit/selectors";

export const getSmartSuggestion = (state) => state.smartSuggestions;

export const isSmartSuggestionIsLoading = createSelector(
  [
    isFetchingPpt,
    getActiveSection,
    isFetchingTextList,
    isFetchingSuggestions,
    isFetchingModifiedText,
    isFetchingImages,
    isFetchingTables,
    refreshingTableThumbnails
  ],
  (
    ppt,
    activeSection,
    textlist,
    suggestions,
    modifiedText,
    fetchingImages,
    fetchingTables,
    refreshingTableThumbnails
  ) => {
    let loadingModule = false;
    if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
      loadingModule = textlist || suggestions || modifiedText;
    } else if (
      activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
      activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    ) {
      loadingModule = fetchingImages;
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      loadingModule = fetchingTables || refreshingTableThumbnails;
    }
    return ppt || loadingModule;
  }
);

export const isResettingManualChanges = createSelector(
  [getActiveSection, isResetingEditedImage, resetingTableManualChanges],
  (activeSection, image, table) => {
    let loadingModule = false;
    if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES) {
      loadingModule = image;
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      loadingModule = table;
    }
    return loadingModule;
  }
);

export const getSelectedSmartOption = createSelector(
  [
    getActiveSection,
    getSelectedSmartOptionForImage,
    getSelectedSmartOptionForTable
  ],
  (activeS, img, table) =>
    activeS === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES ? table : img
);

export const getSavedSuggestion = createSelector(
  [getSmartSuggestion, (_, word) => word || {}],
  (savedSuggestion, word) => {
    return getSanitizedWord(savedSuggestion, word);
  }
);

export const getSuggestionFromLS = createSelector(
  [localTextDictionary, getPptDetails],
  (ls, ppt) => ls[ppt.pptId] || {}
);

export const getCurrentEntity = createSelector(
  [getActiveSection, getCurrentWord, getSelectedImages, getSelectedTable],
  (aSection, word, images, table) => {
    if (aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
      return word;
    } else if (
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    ) {
      return images[0];
    } else if (aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      return table;
    }
    return {};
  }
);

export const isCurrentEntityManuallyEdited = createSelector(
  [
    getActiveSection,
    getCurrentTextGroup,
    getCurrentText,
    getSelectedImages,
    getSelectedTable
  ],
  (aSection, currentTextGroup, text, images, table) => {
    if (aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
      const currentGroupPortions = filter(
        text.portions,
        (port) => port.groupId === currentTextGroup.id
      );
      return get(currentGroupPortions, "[0].isGroupManuallyEdited", false);
    } else if (
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    ) {
      return (
        get(images, "[0].smartOption", "") ===
        CONFIG.CONSTANTS.IMAGE_SMART_OPTION.MANUALEDIT
      );
    } else if (aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      return (
        get(table, "smartOption", "") ===
        CONFIG.CONSTANTS.IMAGE_SMART_OPTION.MANUALEDIT
      );
    }
    return false;
  }
);

export const getNextSection = createSelector(
  [getActiveSection, getCurrentSlide, getSlidesList],
  (activeSection, currentSlide, allSlides) => {
    let nextSlide,
      nextSection,
      goToNextSlide = false;
    const { totalImages, totalGraphs, totalTables } = currentSlide;

    if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
      if (totalImages || totalGraphs || totalTables) {
        nextSection = totalImages
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES
          : totalGraphs
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
          : CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;
      } else {
        goToNextSlide = true;
      }
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES) {
      if (totalGraphs || totalTables) {
        nextSection = totalGraphs
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
          : CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;
      } else {
        goToNextSlide = true;
      }
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS) {
      if (totalTables) {
        nextSection = CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;
      } else {
        goToNextSlide = true;
      }
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      goToNextSlide = true;
    }

    if (goToNextSlide) {
      const currentSlideIndex = findIndex(allSlides, {
        slideId: currentSlide.slideId
      });
      if (currentSlideIndex !== -1) {
        const len = allSlides.length;
        for (let i = currentSlideIndex + 1; i < len; i++) {
          if (!nextSlide) {
            const {
              totalTexts,
              totalImages,
              totalGraphs,
              totalTables
            } = allSlides[i];
            if (totalTexts || totalImages || totalGraphs || totalTables) {
              nextSlide = i;
              nextSection = getSectionWhichHasData(allSlides[i]);
            }
          } else {
            break;
          }
        }
      }
    }
    return {
      slide: nextSlide,
      section: nextSection
    };
  }
);

export const getPrevSection = createSelector(
  [getActiveSection, getCurrentSlide, getSlidesList],
  (activeSection, currentSlide, allSlides) => {
    let prevSlide,
      prevSection,
      goToPrevSlide = false;
    const { totalImages, totalTexts, totalGraphs } = currentSlide;
    if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
      goToPrevSlide = true;
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES) {
      if (totalTexts) {
        prevSection = CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
      } else {
        goToPrevSlide = true;
      }
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS) {
      if (totalImages || totalTexts) {
        prevSection = totalImages
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES
          : CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
      } else {
        goToPrevSlide = true;
      }
    } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      if (totalTexts || totalImages || totalGraphs) {
        prevSection = totalGraphs
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
          : totalImages
          ? CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES
          : CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
      } else {
        goToPrevSlide = true;
      }
    }

    if (goToPrevSlide) {
      const currentSlideIndex = findIndex(allSlides, {
        slideId: currentSlide.slideId
      });
      for (let i = currentSlideIndex - 1; i >= 0; i--) {
        if (!prevSlide) {
          const {
            totalTexts,
            totalImages,
            totalGraphs,
            totalTables
          } = allSlides[i];
          if (totalTexts || totalImages || totalGraphs || totalTables) {
            prevSlide = i;
            prevSection = getSectionWhichHasData(allSlides[i]);
          }
        } else {
          break;
        }
      }
    }
    return {
      slide: prevSlide,
      section: prevSection
    };
  }
);

export const getPrevEntity = createSelector(
  [getActiveSection, getPrevWord, getPrevImage, getPrevTable, getPrevSection],
  (activeSection, prevWord, prevImage, prevTable, prevSection) => {
    let prev =
      activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT
        ? prevWord
        : activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
          activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
        ? prevImage
        : activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
        ? prevTable
        : {};

    if (isEmpty(prev)) {
      prev = prevSection.slide || prevSection.section ? prevSection : {};
    }
    return prev;
  }
);

export const getNextEntity = createSelector(
  [getActiveSection, getNextWord, getNextImage, getNextTable, getNextSection],
  (activeSection, nextWord, nextImage, nextTable, nextSection) => {
    let next =
      activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT
        ? nextWord
        : activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
          activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
        ? nextImage
        : activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
        ? nextTable
        : {};
    if (isEmpty(next)) {
      next = nextSection.slide || nextSection.section ? nextSection : {};
    }
    return next;
  }
);

export const canResetToOriginal = createSelector(
  [canResetTextToOriginal, canResetImageToOriginal, canResetTableToOriginal],
  (text, img, table) => text || img || table
);

export const getCanApplySmartOption = createSelector(
  [getActiveSection, getSelectedImages, getSelectedTable],
  (aSection, images, table) => {
    if (
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
      aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    ) {
      return !filter(images, (img) => !img?.redactedImageUrl).length;
    } else if (aSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
      return !!table.redactedImageUrl;
    }
    return false;
  }
);
