import React from "react";
import "./FilesListPlaceholder.scss";
import CONFIG from "configs/config";

import undrawMyFiles from "assets/images/undraw_my_files.png";

/**
 * HOME SCREEN
 * Placeholder shows when no files are uploaded by user
 * @returns JSX
 */
const FilesListPlaceholder = () => (
  <div className="file-list-placeholder">
    <div>
      <img src={undrawMyFiles} alt="undraw_my_files" />
      <p className="mt-1">{CONFIG.LABELS.YOU_HAVENT_UPLOADED_FILE}</p>
    </div>
  </div>
);

export default FilesListPlaceholder;
