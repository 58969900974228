import axios from "axios";
import axiosInstance from "configs/axios";

const URI_GET_PRESINGED_URL = "/Upload/geturlforupload";

export function getPresignedUrlForUpload(payload) {
  return axiosInstance
    .post(`${URI_GET_PRESINGED_URL}`, payload)
    .then(({ data }) => data?.result || {});
}

export function putToS3(url, blob, response) {
  const config = {
    headers: {
      "Content-Type": "binary/octet-stream"
    }
  };
  return axios.put(url, blob, config).then(() => response);
}
