import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

export const ROUTE_CHANGE = "ROUTE_CHANGE";

// An HOC wrapping all the root component of the page (Route)
// extending functionality of triggering route change action
export function withRouteChange(Component) {
  return function Render(props) {
    const dispatch = useDispatch();
    const [location, match] = [useLocation(), useRouteMatch()];
    useEffect(() => {
      dispatch({ type: ROUTE_CHANGE, payload: { location, match } });
    }, [location.pathname]);
    return <Component {...props} params={match?.params} />;
  };
}
