import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Image as SemanticImage,
  Placeholder,
  Popup
} from "semantic-ui-react";
import { connect } from "react-redux";
import { noop } from "lodash";
import "./TableImagePreview.scss";

import FullPagePreview from "components/FullPagePreview/FullPagePreview";
import ProtectedImageRenderer from "components/ProtectedImageRenderer/ProtectedImageRenderer";
import WithCustomTooltip from "components/WithCustomTooltip/WithCustomTooltip";
import { setDataUriForCurrentTable } from "containers/TableListView/actions";
import { openTableManualEditor } from "containers/TableManualEdit/actions";
import { getImageDimensionDict } from "containers/ImageListView/selectors";

import CONFIG from "configs/config";

import tablePlaceholder from "assets/images/table.svg";
import fullScreenIcon from "assets/images/full-screen.svg";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

/**
 * SANITIZE SCREEN
 * Table Image Preview
 * @returns JSX
 */
const TableImagePreview = ({
  selectedTable,
  isLoading,
  dimensionDict,
  setDataUri,
  openTableManualEditor
}) => {
  const [showImage, setShowImage] = useState(true);
  const [showFullPagePreview, toggleFullPagePreview] = useState(false);
  const dimension = selectedTable.id
    ? dimensionDict[selectedTable.id] || {}
    : {};

  useEffect(() => {
    if (
      selectedTable.id &&
      dimension.width &&
      selectedTable.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP &&
      !selectedTable.dataUri &&
      selectedTable.savedSmartOption !==
        CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP
    ) {
      const canvas = document.getElementById(`c-${selectedTable.id}`);
      const img = new Image();
      img.onload = function load() {
        canvas
          .getContext("2d")
          .drawImage(img, 0, 0, dimension.width, dimension.height);

        setShowImage(false);
        setDataUri({
          id: selectedTable.id,
          dataUri: canvas.toDataURL("image/png")
        });
      };
      img.src = tablePlaceholder;
    } else if (
      !showImage &&
      selectedTable.smartOption !== CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP
    ) {
      setShowImage(true);
    }
  }, [selectedTable, dimension]);

  const showOriginalImage =
    selectedTable.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET ||
    !selectedTable.smartOption;

  const getImageSrc = () =>
    showOriginalImage
      ? selectedTable.originalTableUrl
      : selectedTable.smartOption ===
          CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT &&
        selectedTable.redactedImageUrl
      ? selectedTable.redactedImageUrl
      : selectedTable.modifiedTableUrl || selectedTable.originalTableUrl;

  return (
    <>
      <section className="editor-section__editor">
        <div className="header-stripe editor-header">
          <span className="editor-header__title">
            {CONFIG.LABELS.PREVIEW_SANITIZED_TABLE}
          </span>
          {!isLoading && !!selectedTable.id && (
            <span className="editor-header__actions">
              <Popup
                content={"Open full page view of the table"}
                trigger={
                  <span
                    className="pointer"
                    onClick={() => toggleFullPagePreview(true)}
                  >
                    <SemanticImage
                      src={fullScreenIcon}
                      alt=""
                      width="21"
                      height="20"
                    />
                  </span>
                }
              />
              <span className="mx-3">|</span>
              <WithCustomTooltip
                className="text-align-right mr-2"
                id="manual-edit-tooltip"
                message={CONFIG.LABELS.TOOLTIP_MESSAGE_MANUAL_EDIT_TABLE}
              >
                <a
                  data-testid="manual-edit-anchor"
                  className="editor-header__manual-edit"
                  onClick={() => {
                    openTableManualEditor(selectedTable);
                    onAAClickEvents("Manual_Table_Edit");
                  }}
                >
                  <Icon name="edit" />
                  {CONFIG.LABELS.MANUAL_EDIT}
                </a>
              </WithCustomTooltip>
            </span>
          )}
        </div>
        <div className="main-block editor-container image-preview-container table-preview-container">
          {isLoading ? (
            <Placeholder />
          ) : selectedTable?.id ? (
            <>
              <ProtectedImageRenderer
                className={"image-preview" + (!showImage ? " d-none" : "")}
                src={getImageSrc()}
                hardLoad
              />
              <canvas
                className={"image-preview" + (showImage ? " d-none" : "")}
                width={dimension.width}
                height={dimension.height}
                id={`c-${selectedTable.id}`}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </>
          ) : (
            <div className="no-preview-msg">
              {CONFIG.LABELS.PLEASE_SELECT_TABLE_TO_PREVIEW}
            </div>
          )}
        </div>
      </section>
      <FullPagePreview
        show={showFullPagePreview}
        onCloseFn={() => toggleFullPagePreview(false)}
      >
        {/* When data uri is shown*/}
        {!showOriginalImage &&
        !selectedTable.modifiedTableUrl &&
        selectedTable.dataUri ? (
          <img
            src={selectedTable.dataUri}
            alt=""
            className="fullpage-content"
          />
        ) : (
          <ProtectedImageRenderer
            src={getImageSrc()}
            className="fullpage-content"
          />
        )}
      </FullPagePreview>
    </>
  );
};

TableImagePreview.propTypes = {
  selectedTable: PropTypes.object,
  isLoading: PropTypes.bool,
  dimensionDict: PropTypes.object,
  setDataUri: PropTypes.func,
  openTableManualEditor: PropTypes.func
};

TableImagePreview.defaultProps = {
  selectedTable: {},
  isLoading: false,
  dimensionDict: {},
  setDataUri: noop,
  openTableManualEditor: noop
};

export default connect(
  (state) => ({
    dimensionDict: getImageDimensionDict(state)
  }),
  {
    setDataUri: setDataUriForCurrentTable,
    openTableManualEditor
  }
)(TableImagePreview);
