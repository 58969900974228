import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, isFunction, noop } from "lodash";

import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";

import { onCloseConfirmationModal } from "../actions";
import { getAllConfirmationModals } from "../selectors/modal";

const Modal = ({ confirmationModals }) => {
  return (
    <>
      {!isEmpty(confirmationModals) &&
        Object.keys(confirmationModals).map((modalId) => {
          return !isEmpty(confirmationModals[modalId]) ? (
            <ConfirmationModal
              key={modalId}
              onCloseFn={() => {
                if (isFunction(confirmationModals[modalId].onClose)) {
                  confirmationModals[modalId].onClose();
                }
                onCloseConfirmationModal({ modalId });
              }}
              {...(confirmationModals[modalId] || {})}
            />
          ) : null;
        })}
    </>
  );
};

Modal.propTypes = {
  confirmationModals: PropTypes.object,
  onCloseModal: PropTypes.func
};

Modal.defaultProps = {
  confirmationModals: {},
  onCloseModal: noop
};

export default connect(
  (state) => ({ confirmationModals: getAllConfirmationModals(state) }),
  {
    onCloseConfirmationModal
  }
)(Modal);
