import { createSelector } from "reselect";
import { compact, find, get, keyBy, some, sortBy } from "lodash";
import { configureLoadingState } from "containers/Common/reducers/loading";

import {
  IMAGE_FETCH_IMAGELIST,
  IMAGE_FETCH_IMAGE_SUGGESTION,
  IMAGE_SAVE_UPDATED_IMAGES,
  IMAGE_SAVE_MULTIPLE_IMAGES,
  IMAGE_RESET_MANUALLY_EDITED_IMAGE,
  IMAGE_FETCH_AUTO_EDITED_IMAGES
} from "../types";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import { getIsFilterIdentified } from "containers/Sanitize/selectors";
import CONFIG from "configs/config";

configureLoadingState([
  IMAGE_FETCH_IMAGELIST,
  IMAGE_FETCH_IMAGE_SUGGESTION,
  IMAGE_SAVE_UPDATED_IMAGES,
  IMAGE_SAVE_MULTIPLE_IMAGES,
  IMAGE_RESET_MANUALLY_EDITED_IMAGE,
  IMAGE_FETCH_AUTO_EDITED_IMAGES
]);

export const isFetchingImages = createLoadingSelector([
  IMAGE_FETCH_IMAGELIST,
  IMAGE_FETCH_IMAGE_SUGGESTION
]);

export const isSavingUpdatedImages = createLoadingSelector([
  IMAGE_SAVE_UPDATED_IMAGES
]);

export const isSavingMultipleImages = createLoadingSelector([
  IMAGE_SAVE_MULTIPLE_IMAGES
]);

export const isResetingEditedImage = createLoadingSelector([
  IMAGE_RESET_MANUALLY_EDITED_IMAGE
]);

export const isFetchingAutoEditedImages = createLoadingSelector([
  IMAGE_FETCH_AUTO_EDITED_IMAGES
]);

const image = (state) => state.image;

export const getImageList = createSelector(
  image,
  (img) => img.imageList.data || []
);

export const getFilteredImageList = createSelector(
  [getImageList, getIsFilterIdentified],
  (images, isIdentified) => {
    if (isIdentified) {
      return images.filter((image) => image.redactedImageUrl);
    }
    return images;
  }
);

export const getSelectedImagesFromStore = createSelector(
  image,
  (img) => img.selectedImages
);

export const getSelectedImages = createSelector(
  [getImageList, getSelectedImagesFromStore],
  (list, sImgs) => {
    const keyedList = keyBy(list, "id");
    return sortBy(compact(sImgs.map((imgId) => keyedList[imgId])), "index");
  }
);

export const getDirtySelectEditOptions = createSelector(
  image,
  (img) => img.dirtySelectEditOptions
);

export const isMultiSelectMode = createSelector(
  image,
  (img) => img.isMultiSelect
);

export const selectedSmartOption = createSelector(
  image,
  (img) => img.selectedSmartOption
);

export const getSelectedSmartOptionForImage = createSelector(
  [getImageList, getSelectedImages, isMultiSelectMode, selectedSmartOption],
  (imageList, sImgs, selectMode, option) =>
    !selectMode
      ? get(find(imageList, { id: sImgs[0]?.id }), "smartOption", "")
      : option
);

export const getCurrentImage = createSelector(getSelectedImages, (sImg) =>
  get(sImg, [0], {})
);

export const getNextImage = createSelector(
  [getFilteredImageList, getCurrentImage, isMultiSelectMode],
  (list, img, selectMode) => {
    if (img.id && !selectMode) {
      let currentIndex = 0;
      some(list, (i, idx) => {
        currentIndex = idx;
        return i.id === img.id;
      });
      return get(list, [currentIndex + 1], {});
    }
    return {};
  }
);

export const getPrevImage = createSelector(
  [getFilteredImageList, getCurrentImage, isMultiSelectMode],
  (list, img, selectMode) => {
    if (img.id && !selectMode) {
      let currentIndex = 0;
      some(list, (i, idx) => {
        currentIndex = idx;
        return i.id === img.id;
      });
      return get(list, [currentIndex - 1], {});
    }
    return {};
  }
);

export const getImageDimensionDict = createSelector(
  image,
  (img) => img.imageDimensionDict
);

export const getCurrentEditedImageDataUri = createSelector(
  image,
  (img) => img.currentEditedImageDataUri
);

export const canResetImageToOriginal = createSelector(
  [getSelectedImages, isMultiSelectMode, getSelectedSmartOptionForImage],
  (sImgs, selectMode, selectedOption) => {
    if (selectMode) {
      const len = sImgs.filter(
        (img) =>
          !!img.smartOption &&
          img.smartOption !== CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET
      );
      return len.length > 0;
    }
    return !!(
      selectedOption &&
      selectedOption !== CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET
    );
  }
);

export const getStampedImages = createSelector(
  image,
  (img) => img.stampedImages
);

export const getIndividualImageSaveStatus = createSelector(
  image,
  (img) => img.individualImageSaveStatus
);
