import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/text";
import * as at from "../types";

export const rsGetTextListByPptId = resourceSaga(
  at.TEXT_FETCH_LIST,
  api.getTextListByPptId
);

export const rsGetSuggestionsByParaId = resourceSaga(
  at.TEXT_FETCH_SUGGESTIONS,
  api.getSuggestionsByParaId
);

export const rsGetSanitizedPara = resourceSaga(
  at.TEXT_FETCH_SANITIZED_PARA,
  api.getSanitizedPara
);

export const rsSaveSanitizedPara = resourceSaga(
  at.TEXT_SAVE_SANITIZED_PARA,
  api.saveSanitizedPara
);
