import React, { useEffect, useState } from "react";
import { Image, Modal, Placeholder } from "semantic-ui-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import "./PreviewSlide.scss";
import CONFIG from "configs/config";

import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import PreviewHeader from "components/PreviewHeader/PreviewHeader";
import { getCFImageSrc } from "utils/helpers";

import { useResizeHandler } from "utils/customHooks";
import { isMedium, isSmall } from "utils/mobile";
import { currentTimeStamp } from "utils/common";
import { presentationType } from "utils/customPropTypes";

/**
 * PREVIEW SLIDE SCREEN (Single Slide)
 * Comparision view with original slide (highlighted text/image/graph/table) with sanitized slide
 * @returns JSX
 */
const PreviewSlide = ({
  showPreviewSlide,
  currentSlideView,
  identifiedCount,
  allSlidesCount,
  togglePreviewSlide,
  previewSlide,
  isFetchingPreviewSlide,
  getPreviewSlideAction,
  currentSlideNo,
  signedToken,
  pptDetails
}) => {
  const [compareImageLoaded, setCompareImageLoaded] = useState(false);
  const [sanitizeImageLoaded, setSanitizeImageLoaded] = useState(false);
  const [timestamp, setTimeStampWhenMounted] = useState(0);
  const [originalCheckbox, setOriginalCheckbox] = useState(false);

  useEffect(() => {
    if (showPreviewSlide) {
      getPreviewSlideAction();
      setTimeStampWhenMounted(currentTimeStamp());
    }
  }, []);

  const isMediumScreen = useResizeHandler(isMedium);
  const isSmallScreen = useResizeHandler(isSmall);

  const sanitizedSlideSrc = getCFImageSrc(
    previewSlide.sanitizedSlide,
    signedToken,
    `timestamp=${timestamp}` // store the timestamp on component did mount and use the same to load new image, to avoid missing cloudfront caching.
  );

  return (
    <Modal size="fullscreen" open={showPreviewSlide} className="preview-slide">
      <HeaderButtons
        showPreviewSlide={showPreviewSlide}
        currentSlideView={currentSlideView}
        identifiedCount={identifiedCount}
        allSlidesCount={allSlidesCount}
        currentSlideNo={currentSlideNo}
      />
      <PreviewHeader
        originalCheckbox={originalCheckbox}
        setOriginalCheckbox={setOriginalCheckbox}
        showPreviewSlide={showPreviewSlide}
        togglePreviewSlide={togglePreviewSlide}
        isLoading={isFetchingPreviewSlide}
        pptDetails={pptDetails}
      />
      {isFetchingPreviewSlide ? (
        <div className="preview-slide__loader">
          <Placeholder>
            <Placeholder.Image square />
          </Placeholder>
        </div>
      ) : (
        <div
          className={`preview-slide__thumbnails px-3 ${
            (isMediumScreen || isSmallScreen) && originalCheckbox
              ? "double-column"
              : ""
          }`}
        >
          {originalCheckbox ? (
            <div className="preview-slide__double-container">
              <Image
                src={getCFImageSrc(
                  previewSlide?.highlightedSlide,
                  signedToken,
                  `timestamp=${timestamp}`
                )}
                className={compareImageLoaded ? "visible" : "hidden"}
                alt=""
                onLoad={() => {
                  setCompareImageLoaded(true);
                }}
              />
              <span className={compareImageLoaded ? "visible" : "hidden"}>
                {CONFIG.LABELS.ORIGINAL_SLIDE}
              </span>
            </div>
          ) : null}
          <div
            className={
              originalCheckbox && compareImageLoaded
                ? "preview-slide__double-container"
                : "preview-slide__single-container"
            }
          >
            <Image
              src={sanitizedSlideSrc}
              alt=""
              onLoad={() => {
                setSanitizeImageLoaded(true);
              }}
            />
            <span className={sanitizeImageLoaded ? "visible" : "hidden"}>
              {CONFIG.LABELS.SANITIZED_SLIDE}
            </span>
          </div>
        </div>
      )}
    </Modal>
  );
};

PreviewSlide.propTypes = {
  showPreviewSlide: PropTypes.bool,
  currentSlideView: PropTypes.string,
  identifiedCount: PropTypes.number,
  allSlidesCount: PropTypes.number,
  togglePreviewSlide: PropTypes.func,
  previewSlide: PropTypes.object,
  isFetchingPreviewSlide: PropTypes.bool,
  getPreviewSlideAction: PropTypes.func,
  currentSlideNo: PropTypes.number,
  signedToken: PropTypes.object,
  pptDetails: presentationType
};

PreviewSlide.defaultProps = {
  currentSlideView: {},
  identifiedCount: 0,
  allSlidesCount: 0,
  togglePreviewSlide: noop,
  previewSlide: {},
  getPreviewSlideAction: noop,
  currentSlideNo: 0,
  signedToken: {},
  pptDetails: {}
};

export default PreviewSlide;
