import { requestedString, succeededString, failedString } from "utils/common";

export const TABLE_ON_SELECT_TABLE = "TABLE_ON_SELECT_TABLE";
export const TABLE_UPDATE_SELECT_TABLE = "TABLE_UPDATE_SELECT_TABLE";
export const TABLE_REPLACE_TABLELIST = "TABLE_REPLACE_TABLELIST";
export const TABLE_SET_CURRENT_TABLE = "TABLE_SET_CURRENT_TABLE";
export const TABLE_SET_CURRENT_EDITED_TABLE_DATA_URI =
  "TABLE_SET_CURRENT_EDITED_TABLE_DATA_URI";

export const SELECT_SMART_OPTION_FOR_TABLE = "SELECT_SMART_OPTION_FOR_TABLE";

export const TABLE_FETCH_TABLELIST = "TABLE_FETCH_TABLELIST";
export const TABLE_FETCH_TABLELIST_REQUESTED = requestedString(
  "TABLE_FETCH_TABLELIST"
);
export const TABLE_FETCH_TABLELIST_SUCCEEDED = succeededString(
  "TABLE_FETCH_TABLELIST"
);
export const TABLE_REFRESH_TABLELIST = "TABLE_REFRESH_TABLELIST";
export const TABLE_REFRESH_TABLELIST_SUCCEEDED = succeededString(
  "TABLE_REFRESH_TABLELIST"
);

export const TABLE_RESET_TABLE_STATE = "TABLE_RESET_TABLE_STATE";

export const TABLE_FETCH_TABLE_SUGGESTIONS = "TABLE_FETCH_TABLE_SUGGESTIONS";
export const TABLE_FETCH_TABLE_SUGGESTIONS_REQUESTED = requestedString(
  "TABLE_FETCH_TABLE_SUGGESTIONS"
);
export const TABLE_FETCH_TABLE_SUGGESTIONS_SUCCEEDED = succeededString(
  "TABLE_FETCH_TABLE_SUGGESTIONS"
);

export const TABLE_UPDATE_TABLELIST = "TABLE_UPDATE_TABLELIST";

// Save image data
export const TABLE_TRIGGER_SAVE_TABLE = "TABLE_TRIGGER_SAVE_TABLE";
export const TABLE_SAVE_UPDATED_TABLE = "TABLE_SAVE_UPDATED_TABLE";
export const TABLE_SAVE_UPDATED_TABLE_REQUESTED = requestedString(
  "TABLE_SAVE_UPDATED_TABLE"
);
export const TABLE_SAVE_UPDATED_TABLE_SUCCEEDED = succeededString(
  "TABLE_SAVE_UPDATED_TABLE"
);
export const TABLE_SAVE_UPDATED_TABLE_FAILED = failedString(
  "TABLE_SAVE_UPDATED_TABLE"
);

export const TABLE_HIGHLIGHT_CURRENT_TABLE = "TABLE_HIGHLIGHT_CURRENT_TABLE";
