import React from "react";
import PropTypes from "prop-types";
import CONFIG from "configs/config";
import { noop } from "lodash";

const MainMenu = ({ selectedMenu, selectPortion, startStamp, startText }) => {
  return (
    <div className="image-manual-editor__menu">
      <div
        data-testid="fuzzify-menu"
        className={`menu-item ${
          selectedMenu === CONFIG.LABELS.IMAGE_MANUAL_EDITOR.SELECT
            ? "menu-selected"
            : ""
        }`}
        onClick={selectPortion}
      >
        <i className="icon menu-item--icon icon-fuzzify" />
        {CONFIG.LABELS.IMAGE_MANUAL_EDITOR.FUZZIFY}
      </div>
      <div
        data-testid="stamp-menu"
        className={`menu-item ${
          selectedMenu === CONFIG.LABELS.IMAGE_MANUAL_EDITOR.STAMP
            ? "menu-selected"
            : ""
        }`}
        onClick={startStamp}
      >
        <i className="icon menu-item--icon icon-stamp" />
        {CONFIG.LABELS.IMAGE_MANUAL_EDITOR.STAMP}
      </div>
      <div
        data-testid="text-menu"
        className={`menu-item menu-item--text ${
          selectedMenu === CONFIG.LABELS.IMAGE_MANUAL_EDITOR.TEXT
            ? "menu-selected"
            : ""
        }`}
        onClick={startText}
      >
        <i className="icon menu-item--icon icon-text font" />
        {CONFIG.LABELS.IMAGE_MANUAL_EDITOR.TEXT}
      </div>
    </div>
  );
};

MainMenu.propTypes = {
  selectedMenu: PropTypes.string,
  selectPortion: PropTypes.func,
  startStamp: PropTypes.func,
  startText: PropTypes.func
};

MainMenu.defaultProps = {
  selectedMenu: "",
  selectPortion: noop,
  startStamp: noop,
  startText: noop
};

export default MainMenu;
