import * as at from "../types";
import { createAction as ca } from "utils/action";

export const openTableManualEditor = ca(at.TABLE_OPEN_MANUAL_EDIT);
export const exitTableManualEditor = ca(at.TABLE_EXIT_MANUAL_EDIT);

export const fetchTableTextList = ca(at.TABLE_ME_FETCH_LIST);
export const fetchTableTextSuggestions = ca(at.TABLE_ME_FETCH_SUGGESTIONS);

export const setCurrentTableTextBeingEdited = ca(at.TABLE_ME_SET_CURRENT_TEXT);
export const setCurrentTableWordBeingEdited = ca(at.TABLE_ME_SET_CURRENT_WORD);

export const setCurrentColumnBeingEdited = ca(at.TABLE_ME_SET_CURRENT_COLUMN);
export const onPreviousTableColumn = ca(at.TABLE_ME_SET_PREV_COLUMN);
export const onNextTableColumn = ca(at.TABLE_ME_SET_NEXT_COLUMN);

export const replaceTableTextList = ca(at.TABLE_TEXT_REPLACE_LIST);
export const updateTableTextList = ca(at.TABLE_TEXT_UPDATE_LIST);

export const onTableSetCustomText = ca(at.TABLE_SET_REPLACEMENT_TEXT);
export const onTableSelectSuggestions = ca(at.TABLE_SELECT_SMART_SUGGESTION);
export const onTableDeselectSuggestions = ca(
  at.TABLE_DESELECT_SMART_SUGGESTION
);

export const updateCurrentTableTextPortions = ca(
  at.TABLE_UPDATE_CURRENT_TEXT_POTIONS
);

export const saveManuallySanitizedTablePara = ca(
  at.TABLE_SAVE_MANUALLY_SANITIZED
);
export const saveSanitizedTableText = ca(at.TABLE_ME_SAVE_TEXT);
export const triggerSaveSanitizedTableText = ca(at.TABLE_ME_TRIGGER_SAVE_TEXT);

export const refreshTableThumbnail = ca(at.TABLE_REFRESH_TABLE_THUMBNAILS);

export const resetManuallyEditedTable = ca(
  at.TABLE_RESET_MANUALLY_EDITED_TABLE
);
