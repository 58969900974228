import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { clamp, noop } from "lodash";
import "./TextManualEditor.scss";
import CONFIG from "configs/config";

import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import { getEditedParaLength } from "utils/helpers";

/**
 * SANITIZE SCREEN
 * Manual Editor for text
 * @returns JSX
 */
const TextManualEditor = ({
  currentText,
  groupedParaDetails,
  currentGroupId,
  setInlineEditingGroup,
  saveManuallySanitized
}) => {
  const { para: groupedPara, originalPara } = groupedParaDetails;
  const getInitialState = () => {
    const allParaLength = getEditedParaLength(groupedPara);
    const currentPara = groupedPara[currentGroupId] || "";
    const maxCharLimit = (Math.max(currentPara.length, originalPara[currentGroupId].length)) * 2.0;
    return {
      allParaById: groupedPara,
      paraByGroupId: { [currentGroupId]: currentPara },
      currentPara,
      maxLength: maxCharLimit,
      editedParaLength: allParaLength
    };
  };
  const [editorData, setEditorData] = useState(getInitialState());
  useEffect(
    () => () => {
      setInlineEditingGroup(false);
    },
    []
  );

  useEffect(() => {
    setEditorData((prevState) => ({
      ...prevState,
      currentPara: groupedPara[currentGroupId] || ""
    }));
  }, [currentGroupId]);

  const onEditorChange = (newPara) => {
    setEditorData((prevState) => {
      const updatedAllPara = {
        ...prevState.allParaById,
        ...{ [currentGroupId]: newPara }
      };
      return {
        ...prevState,
        allParaById: updatedAllPara,
        paraByGroupId: { [currentGroupId]: newPara },
        currentPara: newPara || "",
        editedParaLength: getEditedParaLength(updatedAllPara)
      };
    });
  };

  const saveManualEditedText = () => {
    const requestBody = {
      ...currentText,
      modifiedParaByGroup: editorData.paraByGroupId
    };

    saveManuallySanitized(requestBody);
  };

  const noOfCharLeft = clamp(
    editorData.maxLength - editorData.currentPara.length,
    0,
    editorData.maxLength
  );

  return (
    <section className="text-inline-manual-editor__editor">
      <textarea
        spellCheck
        className="text-inline-manual-editor__editor--textarea"
        value={editorData.currentPara}
        onChange={(event) => onEditorChange(event.target.value)}
      />

      <div className="text-inline-manual-editor__footer">
        <span className="text-inline-manual-editor__footer--limit d-none">
          {noOfCharLeft} of {editorData.maxLength} Characters Left
        </span>
        <div className="text-inline-manual-editor__footer--actions">
          <Button
            secondary
            size="mini"
            onClick={() => {
              setInlineEditingGroup(false);
            }}
          >
            Cancel
          </Button>
          <CustomActionButton
            label={CONFIG.LABELS.APPLY}
            className="ml-2"
            size="mini"
            onClickfn={() => {
              saveManualEditedText();
            }}
            disabled={
              !editorData.editedParaLength ||
              groupedPara[currentGroupId] === editorData.currentPara
            }
          />
        </div>
      </div>
    </section>
  );
};

TextManualEditor.propTypes = {
  currentText: PropTypes.object,
  currentGroupId: PropTypes.string,
  groupedParaDetails: PropTypes.object,
  setInlineEditingGroup: PropTypes.func,
  saveManuallySanitized: PropTypes.func
};

TextManualEditor.defaultProps = {
  currentText: {},
  currentGroupId: "",
  groupedParaDetails: {},
  setInlineEditingGroup: noop,
  saveManuallySanitized: noop
};
export default TextManualEditor;
