import { createSelector } from "reselect";

import { GET_PREVIEW_DOCUMENT } from "../types";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import { configureLoadingState } from "containers/Common/reducers/loading";
import { EDITOR_FETCH_SLIDES } from "containers/Sanitize/types";

const previewWindow = (state) => state.preview;

const homeWindow = (state) => state.home;

configureLoadingState([GET_PREVIEW_DOCUMENT]);

export const getPreviewSlides = createSelector(
  previewWindow,
  (state) => state,
  (pw) => pw.previewSlides || []
);

export const getPreviewSlidesCache = createSelector(
  previewWindow,
  (pw) => pw.previewSlidesCache
);

export const getStartingIndex = createSelector(
  previewWindow,
  (pw) => pw.startingIndex
);

export const isFetchingPreviewSlides = createLoadingSelector([
  GET_PREVIEW_DOCUMENT,
  EDITOR_FETCH_SLIDES
]);

export const getSelectedPPTId = createSelector(
  homeWindow,
  (hw) => hw.selectedPPTId
);

export const getCurrentSlideIndexSelector = createSelector(
  previewWindow,
  (pw) => pw.currentSlideIndex
);

export const isPreviewDocShownSelector = createSelector(
  previewWindow,
  (pw) => pw.isPreviewDocShown
);
