import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { connect } from "react-redux";
import { find, inRange, isNumber, noop, times } from "lodash";
import {
  Modal,
  Image,
  Placeholder,
  Icon,
  Input,
  Form,
  Button
} from "semantic-ui-react";
import "./Preview.scss";

import PreviewHeader from "components/PreviewHeader/PreviewHeader";
import PrevArrow from "components/PrevArrow/PrevArrow";
import NextArrow from "components/NextArrow/NextArrow";

import CONFIG from "configs/config";
import { PreviewSlides } from "api/preview";
import {
  getSlideDetailsForPreview,
  setCurrentSlideIndexAction,
  togglePreviewDocAction,
  clearPreviewSlidesAction,
  getPreviewSlidesAction
} from "./actions";
import { changeSlide } from "containers/Sanitize/actions";
import {
  getPreviewSlides,
  isFetchingPreviewSlides,
  getStartingIndex,
  getCurrentSlideIndexSelector,
  isPreviewDocShownSelector
} from "./selectors";
import { getCFImageSrc } from "utils/helpers";
import {
  currentTimeStamp,
  getGoToSlideNo,
  getMinimumValue
} from "utils/common";
import { isMoveToHomeFromPreview } from "containers/Home/selectors";
import { getSignedTokenFromStore } from "containers/User/selectors";
import { getCurrentSlide, getPptDetails, getSlidesList } from "containers/Sanitize/selectors";
import { toggleSummaryModalAction } from "containers/SummaryModal/actions";
import { getFilesAction } from "containers/Home/actions";
import { presentationType } from "utils/customPropTypes";

/**
 * PREVIEW DOCUMENT
 * User can compare original and sanitized slides
 * User can scroll to other slides also
 * @returns JSX
 */
const Preview = ({
  isPreviewDocShown,
  getSlideDetailsForPreview,
  previewSlides,
  isFetchingPreviewSlides,
  currentSlide,
  currentSlideIndex,
  setCurrentSlideIndex,
  toggleSummaryModal,
  signedToken,
  startingIndex,
  isMoveToHomeFromPreview,
  clearPreviewSlidesAction,
  togglePreviewDocAction,
  changeSlideAction,
  allSlides,
  getPreviewSlidesAction,
  getFilesAction,
  pptDetails
}) => {
  const [goToSlideNo, setGoToSlideNo] = useState(0);
  let upperSliderRef = useRef(null);
  const [timestamp, setTimeStampWhenMounted] = useState(0);
  const [compareImageLoaded, setCompareImageLoaded] = useState(false);
  const [sanitizeImageLoaded, setSanitizeImageLoaded] = useState(false);
  const [originalCheckbox, setOriginalCheckbox] = useState(false);

  const totalSlides = allSlides.length;
  const lastSlideIndex = totalSlides - 1;
  const showUpperSlideArrow = totalSlides > 1;
  const hasNextSlot = totalSlides > 7;
  const thumbnail_count =
    totalSlides < 7
      ? totalSlides
      : CONFIG.CONSTANTS.PREVIEW_DOCUMENT_SETTINGS.THUMBNAILS_COUNT;

  useEffect(() => {
    const currentSlideIndex = currentSlide?.slideIndex || 0;
    if (!currentSlide.pptId) {
      getSlideDetailsForPreview(0);
    } else {
      getPreviewSlidesAction(getMinimumValue(currentSlideIndex));
    }
    setCurrentSlideIndex(currentSlideIndex);
    setTimeStampWhenMounted(currentTimeStamp());
  }, []);

  useEffect(() => {
    const slideNo = allSlides[currentSlideIndex]?.slideNumber;
    if (goToSlideNo !== slideNo) {
      setGoToSlideNo(slideNo);
    }
  }, [currentSlideIndex, allSlides]);

  const sanitizedImageSrc = (slide) =>
    getCFImageSrc(
      slide.sanitizedSlide,
      signedToken,
      `timestamp=${timestamp}` // store the timestamp on component did mount and use the same to load new image, to avoid missing cloudfront caching.
    );

  const onLoadSanitizeSlide = () => {
    if (!sanitizeImageLoaded) {
      setSanitizeImageLoaded(true);
    }
  };

  const onLoadHighlightSlide = () => {
    if (!compareImageLoaded) {
      setCompareImageLoaded(true);
    }
  };

  const upperSlider = {
    className: "preview__upper-slider",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    initialSlide: currentSlideIndex % 7
  };

  const changeSlide = (startSlideNo, selectSlideNo) => {
    getPreviewSlidesAction(startSlideNo);
    setCurrentSlideIndex(selectSlideNo);
  };

  const goToLastSlot = () => {
    changeSlide(getMinimumValue(lastSlideIndex), lastSlideIndex);
  };

  const goToFirstSlot = () => {
    changeSlide(0, 0);
  };

  const updateUpperSlide = (slideNoToSet) => {
    upperSliderRef.current.slickGoTo(slideNoToSet % thumbnail_count);
    setCurrentSlideIndex(slideNoToSet);
  };

  // GOTO SLIDE
  const goToSlide = () => {
    if (isNumber(goToSlideNo)) {
      const goToNo = getGoToSlideNo(allSlides, goToSlideNo, currentSlideIndex);
      const slide = find(allSlides, { slideNumber: goToNo });
      const goToIndex = slide ? slide?.slideIndex : currentSlideIndex;
      if (inRange(goToIndex, startingIndex, startingIndex + thumbnail_count)) {
        updateUpperSlide(goToIndex);
      } else if (goToIndex <= 0) {
        goToFirstSlot();
      } else if (goToIndex >= lastSlideIndex) {
        goToLastSlot();
      } else {
        upperSliderRef.current.slickGoTo(goToIndex % thumbnail_count);
        changeSlide(getMinimumValue(goToIndex), goToIndex);
      }
    }
  };

  const jumpToPrevSlot = () => {
    const jumpToStartIndex = startingIndex - thumbnail_count;
    if (jumpToStartIndex < 0) {
      goToLastSlot();
    } else {
      upperSliderRef.current.slickGoTo(thumbnail_count - 1);
      changeSlide(jumpToStartIndex, startingIndex - 1);
    }
  };

  const jumpToNextSlot = () => {
    let gotoIndex = startingIndex + thumbnail_count;
    if (gotoIndex > lastSlideIndex) {
      goToFirstSlot();
    } else {
      upperSliderRef.current.slickGoTo(0);
      changeSlide(gotoIndex, gotoIndex);
    }
  };

  const jumpToPrevSlide = () => {
    if (currentSlideIndex === startingIndex) {
      if (hasNextSlot) {
        jumpToPrevSlot();
      } else {
        updateUpperSlide(lastSlideIndex);
      }
    } else {
      const prevSlideNo = currentSlideIndex - 1;
      updateUpperSlide(prevSlideNo);
    }
  };

  const jumpToNextSlide = () => {
    let lastIndex = startingIndex + thumbnail_count - 1; // 0 based index
    if (
      currentSlideIndex ===
      (lastIndex > lastSlideIndex ? lastSlideIndex : lastIndex)
    ) {
      if (hasNextSlot) {
        jumpToNextSlot();
      } else {
        updateUpperSlide(startingIndex);
      }
    } else {
      const nextSlideNo = currentSlideIndex + 1;
      updateUpperSlide(nextSlideNo);
    }
  };

  const showOriginalSlide = originalCheckbox && compareImageLoaded;

  const onClose = () => {
    if (currentSlide.pptId) {
      changeSlideAction({ slideData: allSlides[currentSlideIndex] });
    } else {
      getFilesAction();
    }

    clearPreviewSlidesAction();
    setOriginalCheckbox(false);
    togglePreviewDocAction(false);
  };

  return (
    <Modal open={isPreviewDocShown} size="fullscreen" className="preview">
      <section>
        <PreviewHeader
          originalCheckbox={originalCheckbox}
          setOriginalCheckbox={setOriginalCheckbox}
          onClosePreviewDoc={onClose}
          isLoading={isFetchingPreviewSlides}
          toggleSummaryModal={toggleSummaryModal}
          isMoveToHomeFromPreview={isMoveToHomeFromPreview}
          togglePreviewDocAction={togglePreviewDocAction}
          pptDetails={pptDetails}
        />
        <div className="preview__upper-container">
          {isFetchingPreviewSlides ? (
            <div className="preview__upper-container--loader">
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
            </div>
          ) : (
            <div className="preview__upper-container--thumbnail">
              <span className="preview__upper-arrow preview__upper-arrow--prev">
                {showUpperSlideArrow && <PrevArrow onClick={jumpToPrevSlide} />}
              </span>
              <span className="preview__upper-arrow preview__upper-arrow--next">
                {showUpperSlideArrow && <NextArrow onClick={jumpToNextSlide} />}
              </span>
              <Slider {...upperSlider} ref={upperSliderRef}>
                {previewSlides.map((slide = {}, index) => {
                  return (
                    <div
                      className={`preview__slider-blocks ${
                        showOriginalSlide ? "compare-view" : ""
                      }`}
                      key={index}
                    >
                      {originalCheckbox ? (
                        <div
                          className={`preview__slider-blocks--slide ${
                            !compareImageLoaded ? "render-magically" : ""
                          }`}
                        >
                          <span>{CONFIG.LABELS.ORIGINAL_SLIDE}</span>
                          <Image
                            className="slide-img"
                            src={getCFImageSrc(
                              slide?.highlightedSlide,
                              signedToken,
                              `timestamp=${timestamp}`
                            )}
                            onLoad={onLoadHighlightSlide}
                          />
                        </div>
                      ) : null}
                      <div className={"preview__slider-blocks--slide"}>
                        <span
                          className={
                            !sanitizeImageLoaded
                              ? "visibility-hide"
                              : "visibility-show"
                          }
                        >
                          {CONFIG.LABELS.SANITIZED_SLIDE}
                        </span>
                        <Image
                          className="slide-img"
                          src={sanitizedImageSrc(slide)}
                          onLoad={onLoadSanitizeSlide}
                        />
                      </div>
                    </div>
                  );
                })}
                {!showUpperSlideArrow && (
                  <div className="preview__slider-blocks compare-view" />
                )}
              </Slider>
            </div>
          )}
        </div>
        {!isFetchingPreviewSlides && (
          <Form className="preview__goto-slide-form">
            <div className="goto-slide preview__goto-slide">
              <label>Slide</label>
              <Input
                label={
                  <Button
                    type="submit"
                    primary
                    size="mini"
                    onClick={() => goToSlide()}
                  >
                    Go
                  </Button>
                }
                labelPosition="right"
                type="number"
                size="mini"
                value={goToSlideNo}
                onChange={(event) =>
                  setGoToSlideNo(
                    event.target.value === ""
                      ? event.target.value
                      : Number(event.target.value)
                  )
                }
              />
            </div>
          </Form>
        )}

        <div className="preview__lower-slide-container">
          {isFetchingPreviewSlides ? (
            <div className="preview__lower-slide-container--loader">
              {times(7, (idx) => (
                <Placeholder key={idx}>
                  <Placeholder.Image square />
                </Placeholder>
              ))}
            </div>
          ) : (
            <div className="preview__lower-slide-container--slider preview__lower-slider">
              <span className="preview__lower-slider__arrows">
                {hasNextSlot && (
                  <Icon
                    name="angle double left"
                    size="big"
                    onClick={jumpToPrevSlot}
                  />
                )}
              </span>
              <div className="preview__lower-slider__blocks">
                {previewSlides.map((slide = {}, index) => (
                  <div
                    key={index}
                    className={`preview__lower-slider__block`}
                    onClick={() => {
                      updateUpperSlide(slide.slideIndex);
                    }}
                  >
                    <span className="preview__lower-slider__block--slide-no">
                      {slide.slideNumber}
                    </span>
                    <Image
                      className={`preview__lower-slider__block--thumbnail ${
                        currentSlideIndex === slide.slideIndex
                          ? "preview__lower-slider__block--active"
                          : ""
                      }`}
                      onLoad={onLoadSanitizeSlide}
                      src={sanitizedImageSrc(slide)}
                    />
                    <span className="preview__lower-slider__block--status">
                      {
                        CONFIG.CONSTANTS
                          .SLIDE_PROGRESS_STATUS_PREVIEW_THUMBNAILS[
                          slide.status
                        ]
                      }
                    </span>
                  </div>
                ))}
              </div>
              <span className="preview__lower-slider__arrows">
                {hasNextSlot && (
                  <Icon
                    name="angle double right"
                    size="big"
                    onClick={jumpToNextSlot}
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </section>
    </Modal>
  );
};

Preview.propTypes = {
  allSlides: PropTypes.array,
  isPreviewDocShown: PropTypes.bool,
  getPreviewSlidesAction: PropTypes.func,
  previewSlides: PropTypes.arrayOf(PreviewSlides),
  isFetchingPreviewSlides: PropTypes.bool,
  currentSlide: PropTypes.object,
  currentSlideIndex: PropTypes.number,
  setCurrentSlideIndex: PropTypes.func,
  toggleSummaryModal: PropTypes.func,
  signedToken: PropTypes.object,
  startingIndex: PropTypes.number,
  isMoveToHomeFromPreview: PropTypes.bool,
  clearPreviewSlidesAction: PropTypes.func,
  getSlideDetailsForPreview: PropTypes.func,
  togglePreviewDocAction: PropTypes.func,
  changeSlideAction: PropTypes.func,
  getFilesAction: PropTypes.func,
  pptDetails: presentationType
};

Preview.defaultProps = {
  allSlides: [],
  getPreviewSlidesAction: noop,
  previewSlides: [],
  currentSlide: {},
  currentSlideIndex: 0,
  setCurrentSlideIndex: noop,
  toggleSummaryModal: noop,
  signedToken: {},
  startingIndex: 0,
  isMoveToHomeFromPreview: false,
  clearPreviewSlidesAction: noop,
  getSlideDetailsForPreview: noop,
  togglePreviewDocAction: noop,
  changeSlideAction: noop,
  getFilesAction: noop,
  pptDetails: {}
};

const mapStateToProps = (state) => {
  return {
    previewSlides: getPreviewSlides(state),
    isFetchingPreviewSlides: isFetchingPreviewSlides(state),
    startingIndex: getStartingIndex(state),
    isMoveToHomeFromPreview: isMoveToHomeFromPreview(state),
    isPreviewDocShown: isPreviewDocShownSelector(state),
    currentSlideIndex: getCurrentSlideIndexSelector(state),
    signedToken: getSignedTokenFromStore(state),
    currentSlide: getCurrentSlide(state),
    allSlides: getSlidesList(state),
    pptDetails: getPptDetails(state)
  };
};

export default connect(mapStateToProps, {
  getPreviewSlidesAction,
  getSlideDetailsForPreview,
  setCurrentSlideIndex: setCurrentSlideIndexAction,
  togglePreviewDocAction,
  changeSlideAction: changeSlide,
  clearPreviewSlidesAction,
  toggleSummaryModal: toggleSummaryModalAction,
  getFilesAction: getFilesAction
})(Preview);
