import React, { useRef, useEffect } from "react";
import { Modal, Button, Header, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import "./DeleteConfirmationModal.scss";

import CustomActionButton from "components/CustomActionButton/CustomActionButton";
import CONFIG from "configs/config";

import CloseIcon from "assets/images/close_grey_small.svg";
import CautionIcon from "assets/images/Caution-icon.svg";

/**
 * HOME SCREEN
 * Delete confirmation popup for files
 * @returns JSX
 */
const DeleteConfirmationModal = ({
  showDeleteConfirmationModal,
  deleteConfirmationModalData,
  setShowDeleteConfirmationModal,
  deleteFile,
  isDeletingFile
}) => {
  const cancelButton = useRef();

  useEffect(() => {
    if (cancelButton.current) {
      cancelButton.current.focus();
    }
  }, [showDeleteConfirmationModal]);

  return (
    <Modal
      open={!!showDeleteConfirmationModal}
      className="delete-confirmation"
      data-test="delete-confirmation-modal"
    >
      <Modal.Content>
        <div className="delete-confirmation__header-container mb-2">
          <Header as="h3">
            <Image
              size="mini"
              className="mr-2"
              src={CautionIcon}
              alt="caution-icon"
            />{" "}
            {deleteConfirmationModalData.header}
          </Header>
          <Image
            className={isDeletingFile ? "cursor-not-allowed" : "pointer"}
            src={CloseIcon}
            alt="close-icon"
            onClick={() => {
              if (!isDeletingFile) {
                setShowDeleteConfirmationModal(false);
              }
            }}
            role="presentation"
            tabIndex={3}
            onKeyPress={(event) => {
              if (!isDeletingFile) {
                event.keyCode === 13
                  ? setShowDeleteConfirmationModal(false)
                  : null;
              }
            }}
          />
        </div>

        <div className="delete-confirmation__description">
          {deleteConfirmationModalData.message}
        </div>
        <div className="delete-confirmation__action-container action-btns">
          <Button
            ref={cancelButton}
            disabled={isDeletingFile}
            className="px-5"
            secondary
            onClick={() => setShowDeleteConfirmationModal(false)}
            tabIndex={1}
            data-testid="dismiss-button"
          >
            {CONFIG.LABELS.DISMISS}
          </Button>
          <CustomActionButton
            label={CONFIG.LABELS.DELETE}
            className="px-5 ml-3"
            onClickfn={() => deleteFile(showDeleteConfirmationModal)}
            tabIndex={2}
            data-testid="delete-button"
            isLoading={isDeletingFile}
            noApplyIcon
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  showDeleteConfirmationModal: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  deleteConfirmationModalData: PropTypes.shape({
    header: PropTypes.string,
    message: PropTypes.string
  }),
  setShowDeleteConfirmationModal: PropTypes.func,
  deleteFile: PropTypes.func,
  isDeletingFile: PropTypes.bool
};

DeleteConfirmationModal.defaultProps = {
  showDeleteConfirmationModal: false,
  deleteConfirmationModalData: {},
  setShowDeleteConfirmationModal: noop,
  deleteFile: noop,
  isDeletingFile: false
};

export default DeleteConfirmationModal;
