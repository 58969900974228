import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import { getSignedTokenFromStore } from "containers/User/selectors";
import { getCFImageSrc } from "utils/helpers";

const ProtectedImageRenderer = ({
  src,
  alt,
  className,
  style,
  signedToken,
  onLoad,
  hardLoad
}) => {
  let signedSrc = "";
  if (signedToken?.qs) {
    signedSrc = getCFImageSrc(src, signedToken, "", hardLoad);
  }
  if (!signedSrc) {
    return null;
  }
  return (
    <img
      src={signedSrc}
      alt={alt}
      className={className}
      style={style}
      onLoad={onLoad}
    />
  );
};

ProtectedImageRenderer.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onLoad: PropTypes.func,
  signedToken: PropTypes.object,
  hardLoad: PropTypes.bool
};

ProtectedImageRenderer.defaultProps = {
  src: "",
  alt: "",
  className: "",
  onLoad: noop,
  hardLoad: false
};

export default connect((state) => ({
  signedToken: getSignedTokenFromStore(state)
}))(ProtectedImageRenderer);
