import React from "react";
import PropTypes from "prop-types";

import arrow from "assets/images/prev-arrow.svg";

export default function PrevArrow({
  className,
  customClass,
  style,
  onClick,
  visibilyHide
}) {
  return (
    <div
      className={`${className} ${customClass} ${visibilyHide ? "d-none" : ""}`}
      style={style}
      onClick={onClick}
      data-testid="prev-arrow"
    >
      <img src={arrow} className="w-100" alt="Previous Slide" />
    </div>
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
  style: PropTypes.object,
  visibilyHide: PropTypes.bool
};

PrevArrow.defaultProps = {
  className: "",
  customClass: "custom-arrow custom-arrow--prev",
  onClick: null,
  visibilyHide: false
};
