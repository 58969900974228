import { combineReducers } from "redux";
import { map, sortedUniq } from "lodash";
import { resourceReducer } from "redux/sagas/networkResource";
import * as at from "../types";

import { RESET_TABLE_TEXT_TO_ORIGINAL } from "containers/SmartSuggestion/types";
import {
  getUpdatedPortionForReplacementText,
  getUpdatedPortionForReset
} from "utils/helpers";
import { TABLE_RESET_TABLE_STATE } from "containers/TableListView/types";
import { EDITOR_SET_ACTIVE_SECTION } from "containers/Sanitize/types";

function showTableManualEditor(state = false, { type }) {
  switch (type) {
    case at.TABLE_OPEN_MANUAL_EDIT:
      return true;
    case EDITOR_SET_ACTIVE_SECTION:
    case TABLE_RESET_TABLE_STATE:
    case at.TABLE_EXIT_MANUAL_EDIT:
      return false;
    default:
      return state;
  }
}

function currentTableTextId(state = "", { type, payload }) {
  switch (type) {
    case at.TABLE_EXIT_MANUAL_EDIT:
    case TABLE_RESET_TABLE_STATE:
    case at.TABLE_ME_FETCH_LIST_REQUESTED:
      return "";
    case at.TABLE_ME_SET_CURRENT_TEXT:
      return payload?.id || "";

    default:
      return state;
  }
}

function currentTableWord(state = {}, { type, payload }) {
  switch (type) {
    case at.TABLE_EXIT_MANUAL_EDIT:
    case TABLE_RESET_TABLE_STATE:
    case at.TABLE_ME_SET_CURRENT_TEXT:
    case at.TABLE_ME_FETCH_LIST_REQUESTED:
      return {};
    case at.TABLE_ME_SET_CURRENT_WORD:
      if (payload?.entityMappingId) {
        return payload;
      }
      return {};
    default:
      return state;
  }
}

function currentColumn(state = 0, { type, payload }) {
  switch (type) {
    case at.TABLE_EXIT_MANUAL_EDIT:
    case TABLE_RESET_TABLE_STATE:
    case at.TABLE_ME_FETCH_LIST_REQUESTED:
      return 0;
    case at.TABLE_ME_SET_CURRENT_COLUMN:
      return payload;
    default:
      return state;
  }
}

function columnsList(state = [], { type, payload }) {
  switch (type) {
    case at.TABLE_EXIT_MANUAL_EDIT:
    case at.TABLE_OPEN_MANUAL_EDIT:
    case at.TABLE_ME_FETCH_LIST_REQUESTED:
      return [];

    case at.TABLE_ME_FETCH_LIST_SUCCEEDED: {
      const { data } = payload || {};
      if (data?.length) {
        return sortedUniq(map(data, (text) => text.columnNo));
      }
      return state;
    }
    default:
      return state;
  }
}

function tableTextList(state = [], { type, payload }) {
  switch (type) {
    case TABLE_RESET_TABLE_STATE:
    case at.TABLE_EXIT_MANUAL_EDIT:
    case at.TABLE_ME_FETCH_LIST_REQUESTED:
      return [];

    case at.TABLE_TEXT_REPLACE_LIST:
      return payload;

    case at.TABLE_TEXT_UPDATE_LIST: {
      return map(state, (text) => {
        if (payload?.id === text.id) {
          return {
            ...text,
            ...payload
          };
        }
        return text;
      });
    }

    case at.TABLE_UPDATE_CURRENT_TEXT_POTIONS: {
      return state.map((text) => {
        if (text.id === payload.id) {
          const identifiedBlocks = map(text.identifiedBlocks, (block) => {
            if (block.groupId === payload.groupId) {
              return {
                ...block,
                skipHighlight: payload.skipHighlight
              };
            }
            return block;
          });
          return {
            ...text,
            portions: payload.portions,
            identifiedBlocks,
            dirty: true
          };
        }
        return text;
      });
    }

    case at.TABLE_SELECT_SMART_SUGGESTION:
    case at.TABLE_SET_REPLACEMENT_TEXT: {
      if (!payload.skipLS) {
        return state.map((text) => {
          if (text.id === payload.textId) {
            const isFromSuggestion = type === at.TABLE_SELECT_SMART_SUGGESTION;
            const updatedPortions = getUpdatedPortionForReplacementText(
              text.portions,
              payload,
              isFromSuggestion
            );
            return { ...text, portions: updatedPortions, dirty: true };
          }
          return text;
        });
      }
      return state;
    }

    case at.TABLE_DESELECT_SMART_SUGGESTION:
    case RESET_TABLE_TEXT_TO_ORIGINAL: {
      if (!payload.skipLS) {
        return state.map((text) => {
          if (text.id === payload.textId) {
            const isReset = type === RESET_TABLE_TEXT_TO_ORIGINAL;
            const updatedPortions = getUpdatedPortionForReset(
              text.portions,
              payload,
              isReset
            );
            return { ...text, portions: updatedPortions, dirty: true };
          }
          return text;
        });
      }
      return state;
    }

    default:
      return state;
  }
}

export default combineReducers({
  showTableManualEditor,
  tableTextList,
  columnsList,
  currentTableTextId,
  currentTableWord,
  currentColumn,
  suggestionEntities: resourceReducer(at.TABLE_ME_FETCH_SUGGESTIONS, {
    init: {}
  })
});
