import React from "react";
import PropTypes from "prop-types";
import { noop, startCase } from "lodash";
import { Button, Image } from "semantic-ui-react";
import "./CustomActionButton.scss";

import applyIcon from "assets/images/tick-white-small.svg";
import loadingIcon from "assets/images/uploading-ppt-icon.svg";

const CustomActionButton = ({
  className,
  label,
  actionIcon,
  isLoading,
  onClickfn,
  disabled,
  noApplyIcon,
  ...otherProps
}) => {
  return (
    <Button
      disabled={isLoading || disabled}
      primary
      className={`custom-action-button ${className}`}
      alt={label}
      onClick={onClickfn}
      {...otherProps}
    >
      <div className="custom-action-button__btn-with-icon">
        <Image
          data-testid="custom-action-button-loader"
          className={
            "loader-with-animation custom-action-button__loader" +
            (isLoading ? " visibility-show" : " visibility-hide") +
            (noApplyIcon ? " no-icon" : "")
          }
          src={loadingIcon}
          alt=""
        />

        {!noApplyIcon && (
          <img
            className={!isLoading ? "visibility-show" : "visibility-hide"}
            data-testid="custom-action-button-container"
            src={actionIcon}
            alt=""
          />
        )}

        <span className="ml-2">{startCase(label)}</span>
      </div>
    </Button>
  );
};

CustomActionButton.propTypes = {
  className: PropTypes.string,
  actionIcon: PropTypes.string,
  onClickfn: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  noApplyIcon: PropTypes.bool
};

CustomActionButton.defaultProps = {
  className: "",
  actionIcon: applyIcon,
  onClickfn: noop,
  isLoading: false,
  label: "Save",
  disabled: false,
  noApplyIcon: false
};

export default CustomActionButton;
