/* eslint-disable no-console */
import "./config-AA.js";
import Hashes from "jshashes";

const adobe = {
  page: {
    category: {
      primaryCategory: "TAMS"
    },
    pageInfo: {
      pageName: "",
      staffSystemID: ""
    },
    toggle: {
      value: ""
    }
  },
  source: {
    application: {
      applicationName: "TAMS"
    }
  },
  document:{
    title:""
  },
  link: {
    name: ""
  }
};
function AAPageLoad(pageName, staffSystemID) {
  try {
    const HashedStaffEmailID = new Hashes.SHA256().hex(
      staffSystemID.toLowerCase()
    );
    adobe.page.pageInfo.pageName = pageName;
    adobe.page.pageInfo.staffSystemID = HashedStaffEmailID;
    window.digitalData = Object.assign(window.digitalData, adobe);
    window._satellite.track("Global_Page_Load");
    return true;
  } catch (error) {
    console.log("Error: AAPageLoad > ", error);
  }
}

const onAAClickEvents = (docTitle, trackWord) => {
  try {
    adobe.document.title = docTitle;
    window.digitalData = Object.assign(window.digitalData, adobe);
    window._satellite.track(trackWord);
  } catch (error) {
    console.log("Error: onAAClickEvents > ", error);
  }
};

function AALinkName(linkName, trackWord) {

  try {
    adobe.link.name = linkName;
    window.digitalData = Object.assign(window.digitalData, adobe);
    if (window?._satellite) window._satellite.track(trackWord);
  } catch (error) {
    console.log("Error: AALinkName > ", error);
  }
}

export {
  AAPageLoad,
  AALinkName,
  onAAClickEvents
};
