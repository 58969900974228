import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Placeholder, Image } from "semantic-ui-react";
import SimpleBar from "simplebar-react";
import { noop } from "lodash";
import "./TableListView.scss";
import CONFIG from "configs/config";

import NoResult from "components/NoResult/NoResult";
import ProtectedImageRenderer from "components/ProtectedImageRenderer/ProtectedImageRenderer";

import { tableListType } from "utils/customPropTypes";
import { isFetchingPpt } from "containers/Sanitize/selectors";
import {
  getSelectedTable,
  isFetchingTables,
  getIndividualTableSaveStatus,
  isSavingUpdatedTable,
  getFilteredTableList
} from "./selectors";
import { storeImageDimension } from "containers/ImageListView/actions";
import { onSelectTable } from "containers/TableListView/actions";
import { refreshingTableThumbnails } from "containers/TableManualEdit/selectors";

import loadingIcon from "assets/images/uploading-ppt-icon-white.svg";

/**
 * SANITIZE SCREEN
 * List of table identified by the system from PPT
 * @returns JSX
 */
const TableListView = ({
  tableList,
  isFetchingtableList,
  isFetchingPpt,
  selectedTable,
  isIndividualTableSaving,
  storeImageDimension,
  onSelectTable,
  savingUpdatedTable,
  hasTables,
  refreshingTableThumbnails,
  isFilterIdentified
}) => {
  const [lastActiveImage, setActiveImage] = useState({});
  const showLoader =
    isFetchingtableList || isFetchingPpt || refreshingTableThumbnails;
  useEffect(() => {
    if (selectedTable.id && lastActiveImage.id !== selectedTable.id) {
      setActiveImage(selectedTable);
      if (selectedTable.id) {
        document.getElementById(selectedTable.id)?.scrollIntoView(false);
      }
    }
  }, [selectedTable]);

  const noResult = !tableList.length && !showLoader;

  const noFilteredResult =
    isFilterIdentified && tableList.length === 0 && !showLoader;

  if (noFilteredResult) {
    return (
      <NoResult
        label={`Sensitive ${CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES}`}
      />
    );
  }

  if (noResult) {
    return (
      <NoResult
        label={hasTables ? "" : CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES}
      />
    );
  }

  if (showLoader) {
    return (
      <div className="main-block table-container placeholder">
        <Placeholder>
          <Placeholder.Image />
        </Placeholder>
      </div>
    );
  }
  return (
    <div className="main-block table-container">
      <SimpleBar className={"table-container__list"} timeout={1500}>
        <ul className={"image-list table-list"}>
          {tableList.map((table) => {
            const isSaving =
              isIndividualTableSaving.includes(table.id) && savingUpdatedTable;
            return (
              <li
                id={table.id}
                key={table.id}
                className={`image-list__item ${
                  selectedTable.id === table.id
                    ? " image-list__item--current"
                    : ""
                } ${isSaving ? " cursor-not-allowed" : ""}`}
                onClick={() => {
                  if (!isSaving) {
                    onSelectTable({
                      currentEntity: selectedTable,
                      nextEntity: table
                    });
                  }
                }}
              >
                {isSaving && (
                  <div className="overlay-loader">
                    <Image src={loadingIcon} alt="" />
                  </div>
                )}
                <ProtectedImageRenderer
                  src={table.originalTableUrl}
                  onLoad={(e) => {
                    if (e.target.naturalWidth) {
                      storeImageDimension({
                        [table.id]: {
                          width: e.target.naturalWidth,
                          height: e.target.naturalHeight
                        }
                      });
                    }
                  }}
                />
                {!!table.savedSmartOption && (
                  <span className="image-list__badge">
                    {CONFIG.LABELS.SANITIZED_ENTITY}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </SimpleBar>
    </div>
  );
};

TableListView.propTypes = {
  tableList: PropTypes.arrayOf(tableListType),
  isFetchingtableList: PropTypes.bool,
  isFetchingPpt: PropTypes.bool,
  selectedTable: PropTypes.object,
  isIndividualTableSaving: PropTypes.array,
  storeImageDimension: PropTypes.func,
  onSelectTable: PropTypes.func,
  savingUpdatedTable: PropTypes.bool,
  hasTables: PropTypes.bool,
  refreshingTableThumbnails: PropTypes.bool,
  isFilterIdentified: PropTypes.bool
};

TableListView.defaultProps = {
  tableList: [],
  isFetchingtableList: false,
  isFetchingPpt: false,
  isIndividualTableSaving: [],
  selectedTable: {},
  storeImageDimension: noop,
  onSelectTable: noop,
  savingUpdatedTable: false,
  hasTables: false,
  refreshingTableThumbnails: false,
  isFilterIdentified: false
};

const mapStateToProps = (state) => ({
  isFetchingtableList: isFetchingTables(state),
  isFetchingPpt: isFetchingPpt(state),
  tableList: getFilteredTableList(state),
  selectedTable: getSelectedTable(state),
  isIndividualTableSaving: getIndividualTableSaveStatus(state),
  savingUpdatedTable: isSavingUpdatedTable(state),
  refreshingTableThumbnails: refreshingTableThumbnails(state)
});

export default connect(mapStateToProps, {
  storeImageDimension,
  onSelectTable
})(TableListView);
