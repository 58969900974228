import { createAction as ca } from "utils/action";
import * as at from "../types";

export const fetchImageList = ca(at.IMAGE_FETCH_IMAGELIST);
export const updateImageList = ca(at.IMAGE_UPDATE_IMAGELIST);
export const replaceImageList = ca(at.IMAGE_REPLACE_IMAGELIST);
export const resetImageState = ca(at.IMAGE_RESET_IMAGE_STATE);

export const onSetCurrentImage = ca(at.IMAGE_ON_SET_CURRENT_IMAGE);
export const setCurrentImageBeingEdited = ca(at.IMAGE_SET_CURRENT_IMAGE);
export const onSelectSmartOption = ca(at.SELECT_SMART_OPTION_FOR_IMAGE);

// Multiselect images
export const toggleMultiselect = ca(at.TOGGLE_MULTISELECT_IMAGE);
export const toggleMultiselectOff = ca(at.TOGGLE_MULTISELECT_IMAGE_OFF);
export const resetDirtyChanges = ca(at.RESET_MULTISELECT_DIRTY_CHANGES);
export const exitMultiselect = ca(at.EXIT_MULTISELECT_IMAGE);
export const selectImage = ca(at.IMAGE_SELECT_IMAGE);
export const deselectImage = ca(at.IMAGE_DESELECT_IMAGE);
export const resetSelectedImages = ca(at.IMAGE_RESET_SELECTED_IMAGES);
export const selectAllImages = ca(at.IMAGE_SELECT_ALL_IMAGES);

export const storeImageDimension = ca(at.IMAGE_STORE_IMAGE_DIMENSION);

export const applyMultipleImageChanges = ca(
  at.IMAGE_APPLY_MULTIPLE_IMAGE_CHANGES
);

export const setCurrentEditedImageDataUri = ca(
  at.IMAGE_SET_CURRENT_EDITED_IMAGE_DATA_URI
);

export const triggerSaveImage = ca(at.IMAGE_TRIGGER_SAVE_IMAGE);

export const resetManuallyEditedImage = ca(
  at.IMAGE_RESET_MANUALLY_EDITED_IMAGE
);
