import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { getHighlightClassName } from "utils/helpers";
import { getElementId } from "utils/common";
import CONFIG from "configs/config";

const RenderIdentifiedPortion = ({
  portion,
  currentWord,
  savedData,
  onWordSelect,
  onWordHover,
  currentHoverId
}) => {
  const wordState = getHighlightClassName(currentWord, portion, savedData);
  return (
    <span
      id={getElementId(
        portion.id,
        CONFIG.CONSTANTS.AUTO_SCROLL_PREFIX.WORD_HIGHLIGHT_LEFT
      )}
      key={portion.id}
      className={`text-area-selector--identified text-area-selector--${wordState} ${
        portion.entityMappingId === currentHoverId ? "current-hover" : ""
      } `}
      data-testid={`highlight-as-${wordState}`}
      onClick={(event) => {
        onWordSelect(portion);
        event.stopPropagation();
      }}
      onMouseOver={() => onWordHover(portion)}
      onMouseLeave={() => onWordHover({ id: "" })} // reset
    >
      {portion.text}
    </span>
  );
};

RenderIdentifiedPortion.propTypes = {
  portion: PropTypes.object,
  currentWord: PropTypes.object,
  savedData: PropTypes.array,
  onWordSelect: PropTypes.func,
  onWordHover: PropTypes.func,
  currentHoverId: PropTypes.string
};

RenderIdentifiedPortion.defaultProps = {
  portion: {},
  currentWord: {},
  savedData: [],
  onWordSelect: noop,
  onWordHover: noop,
  currentHoverId: "",
  multiple: false
};

export default RenderIdentifiedPortion;
