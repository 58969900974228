import React from "react";
import PropTypes from "prop-types";
import { Checkbox, List, Popup } from "semantic-ui-react";
import { noop } from "lodash";
import "./EditorListHeader.scss";

import CONFIG from "configs/config";
import { slideType } from "utils/customPropTypes";

const EditorListHeader = ({
  activeSection,
  changeSection,
  currentSlide,
  isFetchingPpt,
  isFetchingData,
  toggleIsFilterIdentified,
  isFilterIdentified,
  isMultiple,
  isManualEditorOpen
}) => {
  const isText = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
  const isImage = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES;
  const isGraph = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS;
  const isTable = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;

  const disableFilterCheckbox =
    isMultiple || isFetchingData || isFetchingPpt || isManualEditorOpen;

  return (
    <div
      className={
        "header-stripe list-header" +
        (isFetchingPpt ? " list-header__disabled" : "")
      }
    >
      <List horizontal className="list-header__tabs" data-testid="tabs-list">
        <List.Item
          data-testid={CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT}
          className={isText ? "active" : ""}
          onClick={() => {
            if (!isText) {
              changeSection({
                current: activeSection,
                next: CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT
              });
            }
          }}
        >
          {CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT}
          {currentSlide.totalTexts > 0 && (
            <span
              className={`pile-count ${isText ? "pile-count--active" : ""}`}
            >
              {isFilterIdentified
                ? currentSlide.identifiedCount?.text
                : currentSlide.totalTexts}
            </span>
          )}
        </List.Item>
        <List.Item
          data-testid={CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES}
          className={isImage ? "active" : ""}
          onClick={() => {
            if (!isImage) {
              changeSection({
                current: activeSection,
                next: CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES
              });
            }
          }}
        >
          {CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES}
          {currentSlide.totalImages > 0 && (
            <span
              className={`pile-count ${isImage ? "pile-count--active" : ""}`}
            >
              {isFilterIdentified
                ? currentSlide.identifiedCount?.images
                : currentSlide.totalImages}
            </span>
          )}
        </List.Item>
        <List.Item
          data-testid={CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS}
          className={isGraph ? "active" : ""}
          onClick={() => {
            if (!isGraph) {
              changeSection({
                current: activeSection,
                next: CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
              });
            }
          }}
        >
          {CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS}
          {currentSlide.totalGraphs > 0 && (
            <span
              className={`pile-count ${isGraph ? "pile-count--active" : ""}`}
            >
              {isFilterIdentified
                ? currentSlide.identifiedCount?.graphs
                : currentSlide.totalGraphs}
            </span>
          )}
        </List.Item>
        <List.Item
          data-testid={CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES}
          className={isTable ? "active" : ""}
          onClick={() => {
            if (!isTable) {
              changeSection({
                current: activeSection,
                next: CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
              });
            }
          }}
        >
          {CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES}
          {currentSlide.totalTables > 0 && (
            <span
              className={`pile-count ${isTable ? "pile-count--active" : ""}`}
            >
              {isFilterIdentified
                ? currentSlide.identifiedCount?.tables
                : currentSlide.totalTables}
            </span>
          )}
        </List.Item>
      </List>
      <div className="list-header__filter-icon">
        <Popup
          content={CONFIG.LABELS.TOOPTIP_FILTER}
          position="top left"
          trigger={
            <Checkbox
              toggle
              disabled={disableFilterCheckbox}
              size="small"
              name="filter"
              onClick={() => {
                if (!disableFilterCheckbox) {
                  toggleIsFilterIdentified(!isFilterIdentified);
                }
              }}
            />
          }
        />
      </div>
    </div>
  );
};

EditorListHeader.propTypes = {
  activeSection: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  currentSlide: slideType,
  isFetchingPpt: PropTypes.bool,
  isFetchingData: PropTypes.bool,
  toggleIsFilterIdentified: PropTypes.func,
  isFilterIdentified: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isManualEditorOpen: PropTypes.bool
};

EditorListHeader.defaultProps = {
  activeSection: "",
  changeSection: noop,
  currentSlide: {},
  isFetchingPpt: false,
  isFetchingData: false,
  toggleIsFilterIdentified: noop,
  isFilterIdentified: false,
  isMultiple: false,
  isManualEditorOpen: false
};

export default EditorListHeader;
