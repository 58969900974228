import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

const ResetManualChange = ({ section, onResetManualChange }) => {
  return (
    <div className="reset-manual-edit px-2">
      <p>{`Smart Suggestions are not allowed for Manually Edited ${section}`}</p>
      <p>
        {`Click `}
        <span
          onClick={() => {
            onResetManualChange();
          }}
        >
          <u>here</u>
        </span>
        {` to Reset Manual Changes to apply Smart Options`}
      </p>
    </div>
  );
};

ResetManualChange.propTypes = {
  section: PropTypes.string,
  onResetManualChange: PropTypes.func
};

ResetManualChange.defaultProps = {
  section: "",
  onResetManualChange: noop
};

export default ResetManualChange;
