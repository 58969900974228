import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { last } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "semantic-ui-react";
import "./UploadFiles.scss";
import CONFIG from "configs/config";

import PptImage from "assets/images/ppt.svg";

/**
 * HOME SCREEN
 * Upload section where user can select file or drag and drop PPTX files
 * @returns JSX
 */
const UploadFiles = ({ uploadPowerPoint }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = (files) => {
    setErrorMessage("");

    // VALIDATIONS
    const pptFiles = files.filter(
      (fileObj) =>
        last(fileObj.name.split(".")).toLowerCase() === CONFIG.CONSTANTS.PPTX
    );

    const pptFilesLength = pptFiles.length;
    const filesLength = files.length;

    if (pptFiles.length === 0) {
      setErrorMessage(CONFIG.LABELS.FILE_TYPE_NOT_SUPPORTED_WARNING);
      return;
    }

    if (pptFiles.length > 10 && pptFilesLength === filesLength) {
      files = pptFiles.slice(0, 10);
      setErrorMessage(CONFIG.LABELS.MAX_TEN_CONSIDERED_RESET_DISCARDED_WARNING);
    }

    if (pptFilesLength !== filesLength) {
      if (files.length > 10) {
        setErrorMessage(CONFIG.LABELS.MAX_PPT_FILES_SUPPORTED_WARNING);
        files = pptFiles.slice(0, 10);
      } else {
        setErrorMessage(CONFIG.LABELS.ONLY_PPT_FILES_SUPPORTED_WARNING);
        files = pptFiles;
      }
    }

    files.forEach((fileObj) => {
      const formData = new FormData();
      formData.append("files", fileObj);
      const newFiles = {
        pptId: `PPT_${uuidv4()}`,
        pptTitle: fileObj.name,
        pptStatus: CONFIG.CONSTANTS.UPLOAD_FILE_STATUSES.UPLOADING,
        createdOn: new Date(),
        formData
      };
      uploadPowerPoint(newFiles);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="upload-files__upload-container">
        <div
          {...getRootProps({})}
          style={{ height: "100%" }}
        >
          <input {...getInputProps()} accept=".pptx" />
          <div
            className={`upload-files__upload-section ${
              isDragActive ? "upload-files__drag-active" : ""
            }`}
          >
            {!isDragActive ? (
              <>
                <img
                  src={PptImage}
                  alt="ppticon"
                  className="upload-files__ppt-icon"
                />
                <strong className="mt-3 upload-files__label">
                  <Icon name="upload" />
                  {CONFIG.LABELS.DRAG_AND_DROP_OR_BROWSE}
                </strong>

                <div className="upload-files__error-msg ml-2">
                  <small className="text-red">{errorMessage}</small>
                </div>
              </>
            ) : (
              <strong className="">{CONFIG.LABELS.DROP_FILES_HERE}</strong>
            )}
          </div>
        </div>
      </div>
      <div className="upload-files__additional-messages">
        <p className="text-green mt-1 mb-0">
          <Icon name="info circle" />
          {CONFIG.LABELS.MULTIPLE_FILES_ARE_ALLOWED}
        </p>
        <p className="text-green">
          <Icon name="info circle" />
          {CONFIG.LABELS.CURRENLTY_ONLY_PPT_FILES_ALLOWED}
        </p>
      </div>
    </>
  );
};

UploadFiles.propTypes = {
  uploadPowerPoint: PropTypes.func.isRequired
};

export default UploadFiles;
