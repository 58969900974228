import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import CONFIG from "configs/config";

import UploadingPptIcon from "assets/images/uploading-ppt-icon.svg";
import BlackStarNewUpload from "assets/images/black-star-new-upload.svg";
import FileInProgress from "assets/images/file-in-progress.svg";

/**
 * HOME SCREEN
 * Files status
 * @returns JSX
 */
const FileUploadStatus = ({ status, totalSlide, completedSlides }) => {
  const uploaded = CONFIG.CONSTANTS.SUCCESS_STATUSES.includes(status);
  const failed = CONFIG.CONSTANTS.FAILED_STATUSES.includes(status);
  const isSanitized =
    totalSlide && completedSlides && totalSlide === completedSlides;
  const statusIcon =
    totalSlide > 0
      ? FileInProgress
      : uploaded
      ? BlackStarNewUpload
      : UploadingPptIcon;

  return (
    <div
      className={
        "file-list__status " +
        (isSanitized
          ? "file-list__status--completed"
          : failed
          ? "file-list__status--failed"
          : "")
      }
    >
      {isSanitized ? (
        <Icon name="check" />
      ) : failed ? (
        <Icon name="warning circle" />
      ) : (
        <img
          className={uploaded || totalSlide > 0 ? "" : "loader-with-animation"}
          src={statusIcon}
          alt="uploading-ppt-icon"
        />
      )}{" "}
      <span data-testid="status-text">
        {isSanitized
          ? CONFIG.CONSTANTS.UPLOAD_FILE_STATUSES.SANITIZED
          : totalSlide > 0
          ? `${CONFIG.CONSTANTS.UPLOAD_FILE_STATUSES.IN_PROGRESS} (${completedSlides}/${totalSlide})`
          : CONFIG.CONSTANTS.DISPLAY_FILE_STATUS[status]}
      </span>
    </div>
  );
};

FileUploadStatus.propTypes = {
  status: PropTypes.string.isRequired,
  totalSlide: PropTypes.number,
  completedSlides: PropTypes.number
};

FileUploadStatus.defaultProps = {
  totalSlide: 0,
  completedSlides: 0
};

export default FileUploadStatus;
