import React from "react";
import PropTypes from "prop-types";
import { noop, groupBy } from "lodash";
import { Icon } from "semantic-ui-react";
import "./TextInlineEditPreview.scss";

import CONFIG from "configs/config";
import TextManualEditor from "containers/TextManualEditor";
import { getDisplayTextFromPortion } from "utils/helpers";

const TextInlineEditPreview = ({
  portions,
  showModifiedText,
  currentText,
  currentWordIds,
  classNameOnRow,
  currentGroup,
  showHighlights,
  setInlineEditingGroup,
  groupedPara,
  saveManuallySanitizedPara
}) => {
  const hasReplacementText = getDisplayTextFromPortion(portions, true);
  const wordIds = currentWordIds.split(CONFIG.CONSTANTS.WORD_ID_SEPARATOR);
  const groupedPortions = groupBy(portions, "groupId");

  if (!portions.length) {
    return null;
  }
  return (
    <div
      className="text-inline-edit-preview text-inline-manual-editor"
      onClick={() => {
        if (!currentGroup.editing) {
          setInlineEditingGroup(true);
        }
      }}
    >
      {currentGroup.editing ? (
        <TextManualEditor
          setInlineEditingGroup={setInlineEditingGroup}
          currentGroupId={currentGroup.id}
          currentText={currentText}
          groupedParaDetails={groupedPara}
          saveManuallySanitized={saveManuallySanitizedPara}
        />
      ) : !hasReplacementText ? (
        <p className="text-inline-edit-preview__deleted-message">
          <Icon
            name="edit"
            className="text-inline-edit-preview__hover-edit-icon"
            onClick={() => setInlineEditingGroup(currentGroup.id)}
          />
          {CONFIG.LABELS.TEXT_DELETED}
        </p>
      ) : (
        Object.keys(groupedPortions).map((gId) => {
          const portionsByGroup = groupedPortions[gId];
          return (
            <p
              key={gId}
              className={`grouped-paragraph ${classNameOnRow} ${
                currentGroup.id === gId ? "active-group" : ""
              }`}
            >
              <Icon
                name="edit"
                className="text-inline-edit-preview__hover-edit-icon"
                onClick={() => setInlineEditingGroup(currentGroup.id)}
              />
              {portionsByGroup.map((portion, idx) => {
                const className = wordIds.length
                  ? wordIds.includes(portion.id)
                    ? "current-text"
                    : portion.isUpdated
                    ? "updated-text"
                    : "not-updated-text"
                  : "";
                const text =
                  showModifiedText && portion.isUpdated
                    ? portion.updatedText
                    : portion.text;

                return (
                  <span key={idx}>
                    <span
                      key={portion.id}
                      className={showHighlights ? className : ""}
                    >
                      {text}
                    </span>
                    {portion.separator}
                  </span>
                );
              })}
            </p>
          );
        })
      )}
    </div>
  );
};

TextInlineEditPreview.propTypes = {
  portions: PropTypes.array,
  showModifiedText: PropTypes.bool,
  currentText: PropTypes.object,
  currentWordIds: PropTypes.string,
  classNameOnRow: PropTypes.string,
  currentGroup: PropTypes.object,
  showHighlights: PropTypes.bool,
  setInlineEditingGroup: PropTypes.func,
  groupedPara: PropTypes.object,
  saveManuallySanitizedPara: PropTypes.func
};

TextInlineEditPreview.defaultProps = {
  portions: [],
  currentWordIds: "",
  classNameOnRow: "",
  currentText: {},
  currentGroup: {},
  setInlineEditingGroup: noop,
  groupedPara: {},
  saveManuallySanitizedPara: noop
};

export default TextInlineEditPreview;
