import CONFIG from "configs/config";
import { find, first, forEach, get, indexOf, last, reverse } from "lodash";
import moment from "moment";

export const requestedString = (resourceName) =>
  `${resourceName}_${CONFIG.CONSTANTS.REQUESTED}`;
export const succeededString = (resourceName) =>
  `${resourceName}_${CONFIG.CONSTANTS.SUCCEEDED}`;
export const failedString = (resourceName) =>
  `${resourceName}_${CONFIG.CONSTANTS.FAILED}`;

/**
 * Get Params from prosp
 * @param {Object} props
 * @returns {Object}
 */
export const getUriParamsFromProps = (props) => get(props, "match.params", {});

/**
 * Generate Element Id
 * @param {String} id
 * @param {String} prefix
 * @returns {String} generated Element ID
 */
export const getElementId = (id, prefix) =>
  `${prefix}_${id.replace(/\./g, "_")}`;

/**
 * Get section which has data
 * @param {Object} slideData
 * @returns {Object}
 */
export const getSectionWhichHasData = (slideData) =>
  slideData.totalTexts
    ? CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT
    : slideData.totalImages
    ? CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES
    : slideData.totalGraphs
    ? CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    : slideData.totalTables
    ? CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
    : CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;

/**
 * Get Slide where needed to move
 * @param {Number} allSlidesLength Total Slides Length
 * @param {Number} newSlideNo
 * @returns {Number} Slide Number where need to move
 */
export const getGoToSlideNo = (allSlides, newSlideNo, currentSlideIndex) => {
  const hasSlide = find(allSlides, { slideNumber: newSlideNo });
  if (hasSlide) {
    return newSlideNo;
  }
  const lastSlideNumber = get(last(allSlides), "slideNumber", 0);
  const firstSlideNumber = get(first(allSlides), "slideNumber", 0);
  if (newSlideNo >= lastSlideNumber) {
    return lastSlideNumber;
  } else if (newSlideNo <= firstSlideNumber) {
    return firstSlideNumber;
  }
  return get(allSlides`[${currentSlideIndex}].slideNumber`, 0);
};

/**
 * For Error Page (IE/404)
 * @param {String} Key
 * @returns {Object} Error Object
 */
export const getErrorObject = (key) => {
  return CONFIG.ERROR.PAGES[key];
};

/**
 * Used in preview document, needed where slots are changed.
 * @param {Number} number
 * @returns {Number} minimum value of current value in slot of multiple of 7
 * Eg: for 4 it returns 0, for 11 it returns 7
 */
export const getMinimumValue = (number) => {
  let numberValue = number;
  let breakWhile = true;

  while (breakWhile) {
    if (numberValue === 0) {
      breakWhile = false;
    } else if (numberValue % 7 === 0) {
      breakWhile = false;
    } else {
      numberValue = numberValue - 1;
    }
  }
  return numberValue;
};

/**
 * Get Current time
 * @returns {Number} Current Time stamp
 */
export const currentTimeStamp = () => new Date().getTime();

/**
 * Get milliseconds from expiry timestamp
 * @returns {Number} milliseconds
 */
export const getMillisecondsFromExpiry = (expiry) => {
  if (!expiry) {
    return null;
  }
  return moment(expiry).diff(moment());
};

/**
 * Whether a file is allowed select or not based on status
 * @param {Object} file
 * @returns {Boolean}
 */
export const canSelectFile = (file) =>
  CONFIG.CONSTANTS.SUCCESS_STATUSES.includes(file.pptStatus);

/**
 * Whether to show progress bar or not
 * @param {Object} file
 * @returns {Boolean}
 */
export const canShowProgressBar = (file) =>
  !CONFIG.CONSTANTS.FAILED_STATUSES.includes(file.pptStatus) &&
  file.pptStatus !==
    CONFIG.CONSTANTS.UPLOAD_FILE_STATUSES.RECOMMENDATIONS_APPLIED;

/**
 * Get file progress (completed step)
 * @returns {Number} completed step
 */
export const getFileProgress = (file) => {
  const list = [];
  forEach(CONFIG.CONSTANTS.DISPLAY_FILE_STATUS, (_, key) => {
    list.push(key);
  });
  const currentState = indexOf(list, file.pptStatus) + 1;
  return currentState > 0 ? currentState : 0;
};

/**
 * Return nearest sibiling file card after delete
 * @param {Array} filesList
 * @param {Number} deletedIndex
 * @return {Object} file to select
 */
export const getSelectedFileAfterDelete = (filesList, deletedIndex) => {
  const nextItem = find(filesList.slice(deletedIndex + 1), (file) =>
    canSelectFile(file)
  );
  if (nextItem) {
    return nextItem;
  }
  const filteredList = filesList.slice(0, deletedIndex);
  if (filteredList.length) {
    find(reverse(filteredList), (file) => canSelectFile(file)) || {};
  }
  return {};
};

/**
 * Return nearest sibiling item after delete
 * @param {Array} itemList
 * @param {Number} currentItem
 * @return {Object} file to select
 */
export const getNearestItem = (itemList, currentItemIndex) => {
  const nextItem = itemList.slice(currentItemIndex + 1);
  if (nextItem.length) {
    return nextItem[0];
  }
  const filteredList = itemList.slice(0, currentItemIndex);
  if (filteredList.length) {
    return reverse(filteredList)[0];
  }
  return {};
};

/**
 * Get the index of an object from array
 * @param {Array} list
 * @param {Object} obj
 * @param {String} matchProperty
 * @return {Number} index
 */
export const indexOfObject = (list, obj, matchProperty) => {
  let currentIdx = -1;
  find(list, (text, idx) => {
    if (text[matchProperty] === obj[matchProperty]) {
      currentIdx = idx;
      return true;
    }
    return false;
  });
  return currentIdx;
};

/**
 * returns formated HrIds to be used for graphQL
 * @param {*} ids list of ids to get the data fo
 * @returns formated HrIds to be used for graphQL
 */
export const getPeopleIdsFormat = ids => {
  return Array.isArray(ids)
    ? `[${ids.reduce(
        (string, id, index) =>
          (string += '"' + id + `"${index === ids.length - 1 ? "" : ","}`),
        ""
      )}]`
    : '"' + ids + '"';
};