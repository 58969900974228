import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { noop } from "lodash";
import "./SlideImageCarousel.scss";

import CONFIG from "configs/config";
import ProtectedImageRenderer from "components/ProtectedImageRenderer/ProtectedImageRenderer";
import RenderSlideStatus from "components/RenderSlideStatus/RenderSlideStatus";

/**
 * SANTIZE SCREEN
 * Sanitize screen slider
 */
const SlideImageCarousel = React.forwardRef(
  (
    {
      slidesList,
      imageSliderSettings,
      currentSlide,
      pptId,
      setSlideMarkComplete,
      imageType,
      isCenterMode,
      visibilyHide,
      updateCurrentSlide,
      setCurrentSlideIndex
    },
    ref
  ) => {
    const [hasSlideImageLoaded, setSlideImageLoaded] = useState(false);

    useEffect(() => {
      if (ref && ref.current) {
        updateCurrentSlide();
      }
    }, [ref]);

    const markIncomplete = (mark) => {
      const requestBody = {
        presentationId: pptId,
        slideId: currentSlide.slideId,
        markComplete: mark
      };

      setSlideMarkComplete(requestBody);
    };

    const firstSlide = slidesList[0];

    const getClassBasedOnSlideNo = (slideNumber) =>
      slideNumber > 99 ? "triple-digit" : slideNumber > 9 ? "double-digit" : "";

    return slidesList.length > 1 ? (
      <Slider ref={ref} {...imageSliderSettings} key={imageType}>
        {slidesList.map((slide) => {
          return (
            <div
              key={slide.slideId}
              className={
                "slider-block " +
                imageType +
                (visibilyHide ? " visibility-hide" : "")
              }
            >
              <div className="slide-progress">
                <span
                  className={
                    "slide-progress__slide-no " +
                    getClassBasedOnSlideNo(slide.slideNumber)
                  }
                >
                  {slide.slideNumber}
                </span>
                {hasSlideImageLoaded && (
                  <>
                    <div className="slide-progress__slide-status">
                      <span className="slide-progress__slide-status--title">
                        Status:
                      </span>
                      <span>
                        {
                          CONFIG.CONSTANTS.SLIDE_PROGRESS_STATUS[
                            slide.sanitizationProgress
                          ]
                        }
                      </span>
                    </div>
                    <span className="slide-progress__action-btn">
                      <RenderSlideStatus
                        slide={slide}
                        markIncomplete={markIncomplete}
                      />
                    </span>
                  </>
                )}
              </div>
              <ProtectedImageRenderer
                src={slide.image}
                onLoad={() => {
                  if (slide.slideId === currentSlide.slideId) {
                    setSlideImageLoaded(true);
                  }
                }}
              />
            </div>
          );
        })}
        {isCenterMode && <div className="slider-block extra-slide" />}
        {isCenterMode && <div className="slider-block extra-slide" />}
      </Slider>
    ) : firstSlide ? (
      <div className="slider-container">
        <div key={firstSlide.slideId} className="single-slide-view">
          <div className="slide-progress">
            <span
              className={
                "slide-progress__slide-no " +
                getClassBasedOnSlideNo(firstSlide.slideNumber)
              }
            >
              {firstSlide.slideNumber}
            </span>
            {hasSlideImageLoaded && (
              <>
                <div className="slide-progress__slide-status">
                  <span className="slide-progress__slide-status--title">
                    Status:
                  </span>
                  <span>
                    {
                      CONFIG.CONSTANTS.SLIDE_PROGRESS_STATUS[
                        firstSlide.sanitizationProgress
                      ]
                    }
                  </span>
                </div>
                <span className="slide-progress__action-btn">
                  <RenderSlideStatus
                    slide={firstSlide}
                    markIncomplete={markIncomplete}
                  />
                </span>
              </>
            )}
          </div>
          <ProtectedImageRenderer
            src={firstSlide.image}
            onLoad={() => {
              setCurrentSlideIndex(firstSlide.slideIndex);
              setSlideImageLoaded(true);
            }}
          />
        </div>
      </div>
    ) : null;
  }
);

SlideImageCarousel.propTypes = {
  slidesList: PropTypes.array,
  imageSliderSettings: PropTypes.object,
  currentSlide: PropTypes.object,
  setSlideMarkComplete: PropTypes.func,
  pptId: PropTypes.string,
  imageType: PropTypes.string,
  isCenterMode: PropTypes.bool,
  visibilyHide: PropTypes.bool,
  updateCurrentSlide: PropTypes.func,
  setCurrentSlideIndex: PropTypes.func
};

SlideImageCarousel.defaultProps = {
  slidesList: [],
  imageSliderSettings: {},
  currentSlide: {},
  setSlideMarkComplete: noop,
  pptId: "",
  imageType: "",
  updateCurrentSlide: noop,
  setCurrentSlideIndex: noop
};

export default SlideImageCarousel;
