import { pick } from "lodash";
import * as at from "../types";

import {
  TEXT_SELECT_SMART_SUGGESTION,
  TEXT_DESELECT_SMART_SUGGESTION,
  TEXT_SET_REPLACEMENT_TEXT
} from "containers/TextListView/types";
import {
  RESET_TABLE_TEXT_TO_ORIGINAL,
  RESET_TEXT_TO_ORIGINAL
} from "containers/SmartSuggestion/types";
import {
  TABLE_DESELECT_SMART_SUGGESTION,
  TABLE_SELECT_SMART_SUGGESTION,
  TABLE_SET_REPLACEMENT_TEXT
} from "containers/TableManualEdit/types";

export default function dictionary(state = {}, { type, payload }) {
  switch (type) {
    case TEXT_SELECT_SMART_SUGGESTION:
    case TEXT_SET_REPLACEMENT_TEXT:
    case TABLE_SET_REPLACEMENT_TEXT:
    case TABLE_SELECT_SMART_SUGGESTION:
    case RESET_TABLE_TEXT_TO_ORIGINAL:
    case RESET_TEXT_TO_ORIGINAL:
    case at.SET_SUGGESTION_BY_WORD:
      if (payload && !payload.skipLS) {
        return {
          ...state,
          [payload.pptId]: {
            ...(state[payload.pptId] || {}),
            [payload.entity]: {
              ...pick(payload, ["updatedText"]),
              hasReset: type === RESET_TEXT_TO_ORIGINAL,
              isFromLS: true
            }
          }
        };
      }
      return state;

    case TABLE_DESELECT_SMART_SUGGESTION:
    case TEXT_DESELECT_SMART_SUGGESTION:
    case at.REMOVE_SUGGESTION_BY_WORD: {
      const ppt = state[payload.pptId];
      if (ppt && ppt[payload.entity]) {
        delete ppt[payload.entity];
        return {
          ...state,
          [payload.pptId]: ppt
        };
      }
      return state;
    }

    case at.CLEAR_ALL_LOCAL_SUGGESTIONS:
      return {};

    default:
      return state;
  }
}
