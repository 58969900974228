import { requestedString, succeededString } from "utils/common";

export const EDITOR_FETCH_SLIDES = "EDITOR_FETCH_SLIDES";
export const EDITOR_FETCH_PPT_DETAILS = "EDITOR_FETCH_PPT_DETAILS";
export const EDITOR_FETCH_PPT_DETAILS_REQUESTED = requestedString(
  EDITOR_FETCH_PPT_DETAILS
);

export const EDITOR_RESET_ON_UNMOUNT = "EDITOR_RESET_ON_UNMOUNT";
export const EDITOR_RESET_STORE = "EDITOR_RESET_STORE";

export const EDITOR_SET_ACTIVE_SECTION = "EDITOR_SET_ACTIVE_SECTION";

export const EDITOR_CHANGE_SLIDE = "EDITOR_CHANGE_SLIDE";
export const EDITOR_SET_INITIAL_SLIDE = "EDITOR_SET_INITIAL_SLIDE";
export const EDITOR_SET_CURRENT_SLIDE = "EDITOR_SET_CURRENT_SLIDE";

export const EDITOR_SET_CURRENT_TEXT_GROUP_ID =
  "EDITOR_SET_CURRENT_TEXT_GROUP_ID";
export const EDITOR_SET_EDITING_CURRENT_TEXT_GROUP =
  "EDITOR_SET_EDITING_CURRENT_TEXT_GROUP";

export const USER_MARK_AS_COMPLETE = "USER_MARK_AS_COMPLETE";
export const USER_MARK_AS_COMPLETE_SUCCEEDED = succeededString(
  USER_MARK_AS_COMPLETE
);

export const EDITOR_REFRESH_SANITIZATION_PROGRESS =
  "EDITOR_REFRESH_SANITIZATION_PROGRESS";

export const UPDATE_PPT_DETAILS_IN_STORE = "UPDATE_PPT_DETAILS_IN_STORE";
export const UPDATE_SLIDES_IN_STORE = "UPDATE_SLIDES_IN_STORE";

export const TOGGLE_MANUAL_EDIT_MODAL = "TOGGLE_MANUAL_EDIT_MODAL";

export const GET_PREVIEW_SLIDE = "GET_PREVIEW_SLIDE";
export const GET_PREVIEW_SLIDE_REQUESTED = requestedString(GET_PREVIEW_SLIDE);
export const GET_PREVIEW_SLIDE_SUCCEEDED = succeededString(GET_PREVIEW_SLIDE);

export const HIGHLIGHT_ELEMENT_ON_SLIDE = "HIGHLIGHT_ELEMENT_ON_SLIDE";
export const HIGHLIGHT_ELEMENT_ON_SLIDE_REQUESTED = requestedString(
  HIGHLIGHT_ELEMENT_ON_SLIDE
);
export const STORE_HIGHLIGHT_ELEMENT_RESPONES =
  "STORE_HIGHLIGHT_ELEMENT_RESPONES";

export const TOGGLE_IS_FILTER_IDENTIFIED = "TOGGLE_IS_FILTER_IDENTIFIED";
