export const RESET_TEXT_TO_ORIGINAL = "RESET_TEXT_TO_ORIGINAL";
export const GO_TO_PREVIOUS_WORD = "GO_TO_PREVIOUS_WORD";
export const GO_TO_NEXT_WORD = "GO_TO_NEXT_WORD";

// Images
export const RESET_IMAGE_TO_ORIGINAL = "RESET_IMAGE_TO_ORIGINAL";
export const GO_TO_PREVIOUS_IMAGE = "GO_TO_PREVIOUS_IMAGE";
export const GO_TO_NEXT_IMAGE = "GO_TO_NEXT_IMAGE";

// Tables
export const RESET_TABLE_TO_ORIGINAL = "RESET_TABLE_TO_ORIGINAL";
export const GO_TO_PREVIOUS_TABLE = "GO_TO_PREVIOUS_TABLE";
export const GO_TO_NEXT_TABLE = "GO_TO_NEXT_TABLE";

// TableText
export const RESET_TABLE_TEXT_TO_ORIGINAL = "RESET_TABLE_TEXT_TO_ORIGINAL";
export const GO_TO_PREVIOUS_TABLE_WORD = "GO_TO_PREVIOUS_TABLE_WORD";
export const GO_TO_NEXT_TABLE_WORD = "GO_TO_NEXT_TABLE_WORD";

export const GO_TO_PREV_SECTION = "GO_TO_PREV_SECTION";
export const GO_TO_NEXT_SECTION = "GO_TO_NEXT_SECTION";

export const SET_UPDATED_SUGGESTION_VALUES = "SET_UPDATED_SUGGESTION_VALUES";
