import { USER_GUIDE_TRIGGERS } from "./constants";

import HomeScreen from "assets/images/UserGuide/homev3.png";
import SanitizeSlidesView from "assets/images/UserGuide/sanitize_slideviewv2.png";
import SanitizeText from "assets/images/UserGuide/sanitize_textv2.png";
import SanitizeImages from "assets/images/UserGuide/sanitize_imagesv2.png";
import SanitizeTables from "assets/images/UserGuide/sanitize_tablesv3.png";
import SanitzieTableEdit from "assets/images/UserGuide/sanitize_table_editv3.png";
import SanitizeGraphs from "assets/images/UserGuide/sanitize_graphsv2.png";
import PreviewDocument from "assets/images/UserGuide/preview_documentv2.png";
import PreviewDocumentDownload from "assets/images/UserGuide/preview_document_downloadv3.png";

export const disclaimerModalData = {
  header: "Welcome to the Document Sanitization Tool !",
  message: [
    {
      id: 1,
      text:
        "Preserving the security of confidential or sensitive client information is of utmost importance. While this tool is intended to help in the sanitization process, you are still responsible for personally verifying that the materials meet the ",
      type: "string"
    },
    {
      id: 2,
      text: "Sanitization Guidelines (and checklist) ",
      type: "link",
      href:
        "https://pages.navigator.bcg.com/kp/ade111cf-0fab-4dcd-bbb9-71bd0142259a"
    },
    {
      id: 3,
      text:
        "prior to uploading or sharing such materials. This process includes getting approval of the MDP responsible for the client relationship.",
      type: "string"
    }
  ]
};

export const disclaimerFooterData = {
  label:
    "Disclaimer: This tool runs on an AI, ML-based engine. The tool is in the Beta stage currently, the more you will use the tool the smarter it will become.",
  moreInfo: "For more info please",
  copyright: "© 2021 Boston Consulting Group"
};

export const deleteFileConfirmationModal = {
  header: "DELETE FILE?",
  message:
    "Are you sure you want to delete the file? By proceeding you agree that any progress made so far will be lost and you will need to start fresh with the sanitization process."
};

export const USER_GUIDE = [
  {
    HEADER: "Home Page",
    CONTENTS: [
      "Upload PPT document for sanitization",
      [
        {
          item: "All Documents",
          subItems: [
            "All your uploaded documents will be shown here",
            "Click on card representing document to start sanitization"
          ]
        }
      ],
      "Click to move to Sanitization window",
      "Click to auto sanitize document using system generated suggestions"
    ],
    IMAGE: HomeScreen,
    TAG: USER_GUIDE_TRIGGERS.HOME
  },
  {
    HEADER: "Slides View",
    CONTENTS: [
      "Preview of selected slide(original). By default it will be first slide for first time sanitization or last sanitized slide if opened again",
      "Shows progress of sanitized slide out of total slides. Slide is considered sanitized if you have sanitized all identified element or manually marked slide as sanitized",
      "Clicking on link will mark slide as sanitized and update the progress. This will ignore system identified sensitive information",
      "Enter slide number(integer) to navigate directly to any slide of document",
      "Open comparitive view of original and sanitized version with clear difference of changes performed"
    ],
    IMAGE: SanitizeSlidesView,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Text Sanitization",
    CONTENTS: [
      "Shows textual information in list format present in selected slide. First List item selected by default",
      [
        {
          item: "Shows whole textual information of selected list item",
          subItems: [
            "Sensitive identified information by system is shown in yellow by default",
            "In case you had previously sanitized any text, that will be shown in green"
          ]
        }
      ],
      [
        {
          item: "Shows options to sanitize selected sensitive information:",
          subItems: [
            "You can select system suggested recommendations",
            "In case system suggested recommendations are not suitable, custom text can be entered",
            "Same text can be selected by using 'Reset to Original' which will inform system your choice"
          ]
        }
      ],
      "Shows the live Preview of the updated content basis text sanitization"
    ],
    IMAGE: SanitizeText,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Image Sanitization",
    CONTENTS: [
      "Shows all the images in the selected slide",
      [
        {
          item:
            "On selecting the image, system will show maximum 3 sanitized options one of which can be selected:",
          subItems: [
            "Patch - System suggested recommendation",
            "Blur - This will blur the image",
            "Stamp - Depending on type of image, respective stamped(User, Logo & Generic)  image will be applied"
          ]
        }
      ],

      "Shows preview of sanitized image basis sanitization you have performed in previous section"
    ],
    IMAGE: SanitizeImages,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Graph Sanitization",
    CONTENTS: [
      "Shows all the graphs in the selected slide",
      [
        {
          item:
            "On selecting the hraph, system will show maximum 2 sanitized options one of which can be selected:",
          subItems: [
            "Smart Edit - System suggested recommendation",
            "Stamp - Stamped table version will be applied"
          ]
        }
      ],
      "Shows preview of sanitized graph basis sanitization you have performed in previous section"
    ],
    IMAGE: SanitizeGraphs,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Table Sanitization",
    CONTENTS: [
      "Shows all the tables in the selected slide",
      [
        {
          item:
            "On selecting the table, system will show maximum 2 sanitized options one of which can be selected:",
          subItems: [
            "Smart Edit - System suggested recommendation",
            "Stamp - Stamped table version will be applied"
          ]
        }
      ],

      "Shows preview of sanitized table basis sanitization you have performed in previous section"
    ],
    IMAGE: SanitizeTables,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Table Sanitization - Manual Edit",
    CONTENTS: [
      "Shows all the table cell text from the selected table",
      "Navigate between table columns by arrows",
      [
        {
          item: "Shows options to sanitize selected sensitive information:",
          subItems: [
            "You can select system suggested recommendations",
            "In case system suggested recommendations are not suitable, custom text can be entered",
            "Same text can be selected by using 'Reset to Original' which will inform system your choice"
          ]
        }
      ],
      "Shows preview of the sanitized text basis the sanitization you have performed in the previous section"
    ],
    IMAGE: SanitzieTableEdit,
    TAG: USER_GUIDE_TRIGGERS.SANITIZE
  },
  {
    HEADER: "Preview Document",
    CONTENTS: [
      "On Selecting checkbox system will show original and sanitized version of slide, if not selcted then only sanitzed version will be shown.",
      "Original slide version will be shown",
      "Sanitized slide version will be shown",
      [
        {
          item: "Thumbnails of slides will be shown:",
          subItems: [
            "User can jump to any slide by click on thumbnail",
            "Current selected slide will be highlighted with green border"
          ]
        }
      ],
      "On clicking Slider will jump to next/previous 7 slide and so on."
    ],
    IMAGE: PreviewDocument,
    TAG: USER_GUIDE_TRIGGERS.PREVIEW
  },
  {
    HEADER: "Preview Document",
    CONTENTS: [
      "Shows PPT sanitization status",
      "On clicking PPT can be downloaded"
    ],
    IMAGE: PreviewDocumentDownload,
    TAG: USER_GUIDE_TRIGGERS.PREVIEW
  }
];
