/**
 * Returns method with type, payload and action
 * @param {String} type
 * @returns {Function} Action
 */
export function createAction(type) {
  return (payload, args) => ({
    type,
    payload,
    args
  });
}
