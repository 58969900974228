import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/sanitize";
import * as at from "../types";

export const rsGetPptDetails = resourceSaga(
  at.EDITOR_FETCH_PPT_DETAILS,
  api.getPresentationDetailsByPptId
);

export const rsGetSlides = resourceSaga(
  at.EDITOR_FETCH_SLIDES,
  api.getSlidesByPptId
);

export const rsHighlightElementOnSlide = resourceSaga(
  at.HIGHLIGHT_ELEMENT_ON_SLIDE,
  api.highlightElementOnSlide
);
