import axios from "axios";
import oktaAuth from "./okta/oktaAuth";

import CONFIG from "./config";

const axiosInstance = axios.create({
  baseURL: CONFIG.SERVER.BASE_API_URL
});

const requestInterceptor = (config) => {
  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) {
    window.location.reload();
    throw { message: CONFIG.ERROR.API.TOKEN_EXPIRED };
  }
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  config.headers["x-api-key"] = CONFIG.SERVER.BASE_API_X_API_KEY;
  return config;
};

const requestErrorInterceptor = (error) => {
  return Promise.reject(error);
};

const responseErrorInterceptor = (e = {}) => {
  const fallbackValue = {
    ...e,
    message: e.message || CONFIG.ERROR.API.UNHANDLED_EXCEPTION
  };

  return Promise.reject(fallbackValue);
};

axiosInstance.interceptors.request.use(
  requestInterceptor,
  requestErrorInterceptor
);

axiosInstance.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default axiosInstance;
