import { requestedString, succeededString } from "utils/common";

export const TABLE_OPEN_MANUAL_EDIT = "TABLE_OPEN_MANUAL_EDIT";
export const TABLE_EXIT_MANUAL_EDIT = "TABLE_EXIT_MANUAL_EDIT";

export const TABLE_TEXT_REPLACE_LIST = "TABLE_TEXT_REPLACE_LIST";
export const TABLE_TEXT_UPDATE_LIST = "TABLE_TEXT_UPDATE_LIST";

export const TABLE_ME_FETCH_LIST = "TABLE_ME_FETCH_LIST";
export const TABLE_ME_FETCH_LIST_REQUESTED = requestedString(
  TABLE_ME_FETCH_LIST
);
export const TABLE_ME_FETCH_LIST_SUCCEEDED = succeededString(
  TABLE_ME_FETCH_LIST
);

export const TABLE_ME_FETCH_SUGGESTIONS = "TABLE_ME_FETCH_SUGGESTIONS";
export const TABLE_ME_FETCH_SUGGESTIONS_REQUESTED = requestedString(
  TABLE_ME_FETCH_SUGGESTIONS
);
export const TABLE_ME_FETCH_SUGGESTIONS_SUCCEEDED = succeededString(
  TABLE_ME_FETCH_SUGGESTIONS
);

export const TABLE_ME_SET_CURRENT_COLUMN = "TABLE_ME_SET_CURRENT_COLUMN";
export const TABLE_ME_SET_NEXT_COLUMN = "TABLE_ME_SET_NEXT_COLUMN";
export const TABLE_ME_SET_PREV_COLUMN = "TABLE_ME_SET_PREV_COLUMN";

export const TABLE_ME_SET_CURRENT_TEXT = "TABLE_ME_SET_CURRENT_TEXT";
export const TABLE_ME_RESET_TEXT = "TABLE_ME_RESET_TEXT";
export const TABLE_ME_SET_CURRENT_WORD = "TABLE_ME_SET_CURRENT_WORD";

export const TABLE_SET_REPLACEMENT_TEXT = "TABLE_SET_REPLACEMENT_TEXT";
export const TABLE_SELECT_SMART_SUGGESTION = "TABLE_SELECT_SMART_SUGGESTION";
export const TABLE_DESELECT_SMART_SUGGESTION =
  "TABLE_DESELECT_SMART_SUGGESTION";

export const TABLE_UPDATE_CURRENT_TEXT_POTIONS =
  "TABLE_UPDATE_CURRENT_TEXT_POTIONS";

export const TABLE_SAVE_MANUALLY_SANITIZED = "TABLE_SAVE_MANUALLY_SANITIZED";
export const TABLE_ME_SAVE_TEXT = "TABLE_ME_SAVE_TEXT";
export const TABLE_ME_TRIGGER_SAVE_TEXT = "TABLE_ME_TRIGGER_SAVE_TEXT";

export const TABLE_REFRESH_TABLE_THUMBNAILS = "TABLE_REFRESH_TABLE_THUMBNAILS";
export const TABLE_REFRESH_TABLE_THUMBNAILS_SUCCEEDED = succeededString(
  "TABLE_REFRESH_TABLE_THUMBNAILS"
);

export const TABLE_RESET_MANUALLY_EDITED_TABLE =
  "TABLE_RESET_MANUALLY_EDITED_TABLE";
export const TABLE_RESET_MANUALLY_EDITED_TABLE_SUCCEEDED = succeededString(
  "TABLE_RESET_MANUALLY_EDITED_TABLE"
);
