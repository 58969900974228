import { createAction as ca } from "utils/action";
import * as at from "../types";

export const setActiveSection = ca(at.EDITOR_SET_ACTIVE_SECTION);

export const resetOnUnmountEditor = ca(at.EDITOR_RESET_ON_UNMOUNT);
export const resetEditorStoreData = ca(at.EDITOR_RESET_STORE);

export const changeSlide = ca(at.EDITOR_CHANGE_SLIDE);
export const setInitialSlide = ca(at.EDITOR_SET_INITIAL_SLIDE);
export const setCurrentSlide = ca(at.EDITOR_SET_CURRENT_SLIDE);

export const setCurrentTextGroupId = ca(at.EDITOR_SET_CURRENT_TEXT_GROUP_ID);
export const setEditingCurrentTextGroup = ca(
  at.EDITOR_SET_EDITING_CURRENT_TEXT_GROUP
);

export const setSlideMarkComplete = ca(at.USER_MARK_AS_COMPLETE);

export const refreshSanitizationProgress = ca(
  at.EDITOR_REFRESH_SANITIZATION_PROGRESS
);

export const toggleImageManualEditModal = ca(at.TOGGLE_MANUAL_EDIT_MODAL);

export const getPreviewSlideAction = ca(at.GET_PREVIEW_SLIDE_REQUESTED);

export const highlightElementOnSlide = ca(at.HIGHLIGHT_ELEMENT_ON_SLIDE);
export const storeHighlightElementRespones = ca(
  at.STORE_HIGHLIGHT_ELEMENT_RESPONES
);

export const toggleIsFilterIdentified = ca(at.TOGGLE_IS_FILTER_IDENTIFIED);
