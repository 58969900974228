import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { findIndex } from "lodash";
import { Button, Icon, Image } from "semantic-ui-react";
import "./UserGuide.scss";

import UserGuideHeader from "components/UserGuideHeader";

import CONFIG from "configs/config";
import Pages from "./Pages";

/**
 * USER GUIDE SCREEN
 * User will see guide for each screens available in application
 * @returns JSX
 */
const UserGuide = ({ history, location }) => {
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState();
  const [ready, setReady] = useState(false);
  const sliderArr = CONFIG.COMPONENT_DATA.USER_GUIDE;

  let userGuideSlider = useRef(null);

  useEffect(() => {
    if (ready) {
      changeSliderAccToMenu(selectedMenu);
    }
  }, [selectedMenu]);

  useEffect(() => {
    const { from } = location.state || {};
    if (from) {
      setSelectedMenu(from);
      setReady(true);
    }
  }, []);

  const getSectionIndex = (menu) => {
    return findIndex(sliderArr, { TAG: menu });
  };

  const changeSliderAccToMenu = (menu) => {
    const menuIndex = getSectionIndex(menu);
    userGuideSlider.current.slickGoTo(menuIndex >= 0 ? menuIndex : 0);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: getSectionIndex(location.state?.from || "home"),
    beforeChange: (before, next) => {
      setCurrentSlideNumber(next);
    },
    arrows: false
  };

  return (
    <div className="user-guide">
      <UserGuideHeader
        history={history}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
      <div className="user-guide__left-section">
        <Pages
          currentPageData={CONFIG.COMPONENT_DATA.USER_GUIDE[currentSlideNumber]}
        />
        <div className="user-guide__actions">
          <Button
            className="user-guide__prev-btn"
            primary
            disabled={!currentSlideNumber}
            onClick={() => userGuideSlider.current.slickPrev()}
            icon
          >
            <Icon name="angle left" /> {CONFIG.LABELS.BACK}&nbsp;
          </Button>
          <span className="mx-3">
            {currentSlideNumber + 1} of{" "}
            {CONFIG.COMPONENT_DATA.USER_GUIDE.length}
          </span>
          <Button
            className="user-guide__next-btn"
            primary
            disabled={currentSlideNumber >= sliderArr.length - 1}
            onClick={() => userGuideSlider.current.slickNext()}
            icon
          >
            &nbsp;
            {CONFIG.LABELS.NEXT} <Icon name="angle right" />
          </Button>
        </div>
      </div>
      <div className="user-guide__right-section">
        <Slider
          ref={userGuideSlider}
          {...sliderSettings}
          className="user-guide__slider"
        >
          {CONFIG.COMPONENT_DATA.USER_GUIDE.map((guide, index) => (
            <div key={index}>
              <Image src={guide.IMAGE} className="user-guide__slider--image" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

UserGuide.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object
};

UserGuide.defaultProps = {
  location: {}
};

export default UserGuide;
