import {
  call,
  takeLatest,
  put,
  select,
  takeEvery,
  all,
  debounce
} from "redux-saga/effects";
import { find, get, isEmpty, isUndefined, keyBy, last, map } from "lodash";
import * as at from "../types";
import {
  getCurrentEditedImageDataUri,
  getCurrentImage,
  getImageList,
  getSelectedImages,
  getSelectedImagesFromStore,
  getSelectedSmartOptionForImage,
  getStampedImages,
  isMultiSelectMode
} from "../selectors";
import {
  exitMultiselect,
  setCurrentImageBeingEdited,
  triggerSaveImage,
  updateImageList,
  replaceImageList,
  onSetCurrentImage
} from "../actions";
import { handleCatchedError } from "utils/sagaUtils";
import {
  rsSaveUpdatedImages,
  rsResetUpdatedImage,
  rsFetchAutoEditedImages
} from "./resourceSagas";
import CONFIG from "configs/config";
import {
  GO_TO_NEXT_IMAGE,
  GO_TO_PREVIOUS_IMAGE,
  RESET_IMAGE_TO_ORIGINAL
} from "containers/SmartSuggestion/types";
import { createAction as ca } from "utils/action";
import {
  getActiveSection,
  getCurrentSlide,
  getIsFilterIdentified,
  isGraphSection
} from "containers/Sanitize/selectors";
import { getImagesBySlide } from "api/image";
import {
  refreshSanitizationProgress,
  highlightElementOnSlide
} from "containers/Sanitize/actions";
import { getPresignedUrlForUpload, putToS3 } from "api/s3";
import {
  canApplySmartOption,
  filterSensitiveItem,
  getModifiedImageUrl
} from "utils/helpers/image";

/**
 * Upload handler to get presigned put url and use them to upload to s3
 * @param {Object} requestPayload
 * @returns api response for all the upload call
 */
export function* uploadToS3(requestPayload) {
  // Get presigned url for single/multiple keys
  const { pptId, slideId } = requestPayload[0];
  const actionArgs = [requestPayload];

  try {
    yield put(ca(at.IMAGE_SAVE_UPLOAD_TO_S3_REQUESTED)({}, actionArgs));

    const urls = yield call(getPresignedUrlForUpload, {
      fileType: CONFIG.CONSTANTS.S3_UPLOAD_FILE_TYPE.IMAGES,
      pptId,
      slideId,
      keys: map(requestPayload, "id")
    });
    const keyByPayload = keyBy(requestPayload, "id");

    const blobDatas = [];
    // Put the blob data into s3 using presigned url
    for (const key in urls) {
      const idFromKey = last(key.split("/"));
      const dataUri = keyByPayload[idFromKey]?.dataUri;
      if (dataUri) {
        let binary = atob(dataUri.split(",")[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        blobDatas.push({
          blobData: new Blob([new Uint8Array(array)], {
            type: "image/png"
          }),
          url: urls[key],
          response: { key, id: idFromKey }
        });
      }
    }
    const result = yield all(
      map(blobDatas, (entry) =>
        call(putToS3, entry.url, entry.blobData, entry.response)
      )
    );

    yield put(ca(at.IMAGE_SAVE_UPLOAD_TO_S3_SUCCEEDED)({}, actionArgs));
    return result;
  } catch (e) {
    yield put(ca(at.IMAGE_SAVE_UPLOAD_TO_S3_FAILED)({}, actionArgs));
    yield call(handleCatchedError, e, {
      message: CONFIG.ERROR.API.S3_UPLOAD_FAILED
    });
  }
}

/**
 * Generator saga to trigger the api call to fetch the highlighted slide image
 */
function* highlightElement() {
  const ids = map(yield select(getSelectedImages), "id");
  const isGraph = yield select(isGraphSection);
  if (isGraph) {
    yield put(
      highlightElementOnSlide({
        sectionType: CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS,
        ids: {
          graphIds: ids
        }
      })
    );
  } else {
    yield put(
      highlightElementOnSlide({
        sectionType: CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES,
        ids: {
          imageIds: ids
        }
      })
    );
  }
}

/**
 * After every success call of save image, following code block is executed to refresh image list to see the updated changes
 * @param {Object} input
 */
function* refreshImageList({
  imageToSelect,
  isManualEdit,
  isMultiple,
  isGraph
}) {
  try {
    const currentSlide = yield select(getCurrentSlide);
    const { slideId } = imageToSelect;
    const currentActiveSection = yield select(getActiveSection);
    yield put(
      refreshSanitizationProgress({
        slideId,
        activeSection: currentActiveSection
      })
    );

    // Refresh image list only if the current slide is same as before saving (happens when apply effect and move to next slide)
    if (currentSlide.slideId === slideId) {
      // Refresh imagelist only if the current active section is same as before saving
      if (
        (isGraph &&
          currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS) ||
        (!isGraph &&
          currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES)
      ) {
        const response = yield call(
          getImagesBySlide,
          currentSlide.pptId,
          currentSlide.slideId,
          isGraph
        );
        if (yield select(isMultiSelectMode)) {
          yield put(exitMultiselect());
        }
        if (isMultiple) {
          yield put(updateImageList(response));
          // When auto save is completed, select first image as selected
          yield put(
            setCurrentImageBeingEdited({
              ...(find(response, { id: imageToSelect.id }) || response[0]),
              isGraph
            })
          );
        } else {
          // When refreshing imagelist after any save
          // we shouldnt replace the current dirty value for the currently selected image
          const currentImage = yield select(getCurrentImage);
          yield put(
            updateImageList(
              response.map((image) => {
                if (currentImage.id === image.id && !isManualEdit) {
                  return {
                    ...currentImage,
                    savedSmartOption: image.savedSmartOption,
                    modifiedImageUrl: image.modifiedImageUrl
                  };
                }
                return image;
              })
            )
          );
        }

        if (isManualEdit && !isMultiple) {
          yield put(
            setCurrentImageBeingEdited({
              ...(find(response, { id: imageToSelect.id }) || response[0]),
              isGraph
            })
          );
        }
      }
    }
    yield put(
      ca(at.IMAGE_SAVE_MULTIPLE_IMAGES_SUCCEEDED)({
        isManualEdit
      })
    );
  } catch (e) {
    yield call(handleCatchedError, e, {
      message: CONFIG.ERROR.API.REFRESH_IMAGE_LIST_FAILED
    });
    yield put(ca(at.IMAGE_SAVE_MULTIPLE_IMAGES_FAILED)());
  }
}

/**
 * Saga Handler, to conditionally trigger s3 upload
 */
function* handleUploadImage(requestPayload) {
  const requestWithImage = requestPayload.filter(
    (request) =>
      request.modifiedImageUrl !== null &&
      ![
        CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT,
        CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH
      ].includes(request.smartOption)
  );
  if (requestWithImage.length) {
    const s3ObjectResponse = yield* uploadToS3(requestWithImage);
    requestPayload.forEach((payload) => {
      payload.modifiedImageUrl = get(
        find(s3ObjectResponse, { id: payload.id }),
        "key",
        payload.modifiedImageUrl
      );
    });
  }
  return requestPayload;
}

/**
 * Generator saga to save single image
 */
export function* saveImage(payload = {}) {
  try {
    const { images, dataUri, isManualEdit, imageEditorObj } = payload;
    const isGraph = !isUndefined(payload.isGraph)
      ? payload.isGraph
      : yield select(isGraphSection);
    const isMultiSelect = yield select(isMultiSelectMode);
    const selectedImages = images ? images : yield select(getSelectedImages);

    if (!isMultiSelect && selectedImages.length) {
      const firstImage = selectedImages[0];
      if (
        !isEmpty(firstImage) &&
        (firstImage.smartOption !== firstImage.savedSmartOption || isManualEdit)
      ) {
        let requestPayload = [
          {
            ...firstImage,
            imageMetadata: {
              ...firstImage.imageMetadata,
              manualHeight: imageEditorObj?.meta?.manualHeight,
              manualWidth: imageEditorObj?.meta?.manualWidth,
              objects: imageEditorObj?.objects
            },
            dataUri: dataUri
              ? dataUri
              : yield select(getCurrentEditedImageDataUri),
            smartOption: isManualEdit
              ? CONFIG.CONSTANTS.IMAGE_SMART_OPTION.MANUALEDIT
              : firstImage.smartOption,
            modifiedImageUrl: getModifiedImageUrl(firstImage, isManualEdit)
          }
        ];
        if (!isGraph) {
          requestPayload = yield* handleUploadImage(requestPayload);
        }
        if (requestPayload.length) {
          yield call(rsSaveUpdatedImages, requestPayload, isGraph);

          yield* refreshImageList({
            imageToSelect: firstImage,
            isMultipe: false,
            isGraph,
            isManualEdit
          });
        }
      }
    }
  } catch (e) {
    yield call(handleCatchedError, e);
  }
}

function* watchImageSuggestionSaga() {
  /**
   * Whenever selecting smart option, update image list with respective smart option to reflect the update image on preview
   */
  yield takeLatest(
    [
      at.SELECT_SMART_OPTION_FOR_IMAGE,
      at.IMAGE_SELECT_IMAGE,
      at.IMAGE_DESELECT_IMAGE,
      at.IMAGE_RESET_SELECTED_IMAGES,
      at.IMAGE_SELECT_ALL_IMAGES,
      at.RESET_MULTISELECT_DIRTY_CHANGES,
      RESET_IMAGE_TO_ORIGINAL
    ],
    function* selectSmartOption({ type, payload }) {
      try {
        const selectedOption = yield select(getSelectedSmartOptionForImage);
        const selectedImages = yield select(getSelectedImagesFromStore);

        const imageList = yield select(getImageList);
        if (imageList.length) {
          let newImageList;
          if (selectedImages.length) {
            newImageList = [...imageList].map((img) => {
              if (selectedImages.includes(img.id)) {
                if (type === at.RESET_MULTISELECT_DIRTY_CHANGES) {
                  img.smartOption = img.savedSmartOption;
                } else if (type === at.SELECT_SMART_OPTION_FOR_IMAGE) {
                  img.smartOption = payload;
                  if (
                    img.savedSmartOption &&
                    img.savedSmartOption === payload
                  ) {
                    img.modifiedImageUrl = img.uploadObjectKey;
                  }
                } else if (type === RESET_IMAGE_TO_ORIGINAL) {
                  img.smartOption = CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET;
                } else {
                  img.smartOption = selectedOption || img.smartOption;
                }

                if (type === RESET_IMAGE_TO_ORIGINAL || !img.smartOption) {
                  img.modifiedImageUrl = null;
                }
              }
              return img;
            });
          } else {
            // When toggling off multiselect without applying the changes, revert all the dirty changes from store to avoid saving automatically
            newImageList = [...imageList].map((img) => {
              if (type === at.RESET_MULTISELECT_DIRTY_CHANGES) {
                img.smartOption = img.savedSmartOption;
              }
              return img;
            });
          }
          yield put(replaceImageList(newImageList));
          if (type === at.RESET_MULTISELECT_DIRTY_CHANGES) {
            // when in this case, select the first image as selected
            const selectedFirstImage = selectedImages[0]
              ? find(newImageList, { id: selectedImages[0] })
              : newImageList[0];

            yield put(
              onSetCurrentImage({
                entity: selectedFirstImage,
                autoSave: false
              })
            );
          }
        }
      } catch (e) {
        yield call(handleCatchedError, e);
      }
    }
  );

  /**
   * After successfully fetching recommendations data for images/graph,
   * update image/graph list with fetched redacted image, smartly edited graph image url
   * with imageType
   * Also, select smartOption as patch/smart edit
   */
  yield takeLatest(
    at.IMAGE_FETCH_IMAGE_SUGGESTION_SUCCEEDED,
    function* updateSmartOption({ payload, args }) {
      const selectedImages = yield select(getSelectedImagesFromStore);
      const imageList = yield select(getImageList);
      if (payload?.data) {
        const newImageList = imageList.map((image) => {
          const redactionData = get(payload, `data[${image.id}]`);
          if (redactionData) {
            const newImg = { ...image, ...redactionData };
            if (
              selectedImages.includes(image.id) &&
              canApplySmartOption(newImg)
            ) {
              // check whether isGraph is true from args
              if (!get(args, "[2]", false)) {
                newImg.smartOption = CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH;
              } else {
                newImg.smartOption =
                  CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT;
              }
            }
            return newImg;
          }
          return image;
        });

        yield put(replaceImageList(newImageList));

        // Set the first image as selected when fetching imagelist,
        // Redirecting it through setCurrentImageBeingEdited to highlight the default selected images
        if (!selectedImages.length && newImageList.length) {
          const identified = yield select(getIsFilterIdentified);
          yield put(
            onSetCurrentImage({
              entity: get(
                identified ? filterSensitiveItem(newImageList) : newImageList,
                [0],
                {}
              ),
              autoSave: true
            })
          );
        }
      }
    }
  );

  /**
   * On Select image, set the selected image as selected and trigger save call of previous image if any
   */
  yield takeEvery(
    at.IMAGE_ON_SET_CURRENT_IMAGE,
    function* onSetCurrentImage({ payload }) {
      const { entity, autoSave = false } = payload;
      const currentEntity = get(yield select(getSelectedImages), [0], {});
      if (!isEmpty(currentEntity) && autoSave) {
        yield put(
          triggerSaveImage({
            images: [currentEntity],
            dataUri: yield select(getCurrentEditedImageDataUri)
          })
        );
      }
      yield put(
        setCurrentImageBeingEdited({
          ...entity,
          isGraph: yield select(isGraphSection)
        })
      );
    }
  );

  /**
   * In case moving between image using arrow, we need capture previous image which was being sanitized,
   * And smart option applied on it
   */
  yield takeEvery(
    [GO_TO_NEXT_IMAGE, GO_TO_PREVIOUS_IMAGE],
    function* onArrowClick({ payload }) {
      // Need to capture dataUri of the previous image when moving using arrow
      const image = payload.currentEntity
        ? payload.currentEntity
        : get(yield select(getSelectedImages), [0], {});
      if (!isEmpty(image)) {
        yield put(
          triggerSaveImage({
            images: [{ ...image, isArrowClick: true }],
            dataUri: yield select(getCurrentEditedImageDataUri)
          })
        );
      }

      yield put(
        setCurrentImageBeingEdited({
          ...(payload.entity || payload),
          isArrowClick: true,
          isGraph: yield select(isGraphSection)
        })
      );
    }
  );

  // When selecting multiple images/graphs debouce the highligh call for 2ms.
  yield debounce(
    2000,
    [
      at.IMAGE_SELECT_IMAGE,
      at.IMAGE_DESELECT_IMAGE,
      at.IMAGE_RESET_SELECTED_IMAGES,
      at.IMAGE_SELECT_ALL_IMAGES
    ],
    function* highlightOnMultiselect({ type, payload }) {
      // Avoid calling highlight when reseting selected image from outside component, for eg, before saving the images
      if (type === at.IMAGE_RESET_SELECTED_IMAGES) {
        if (payload) {
          yield call(highlightElement);
        }
      } else {
        yield call(highlightElement);
      }
    }
  );

  // Listen for action which select images and highlight the selected images
  yield takeLatest([at.IMAGE_SET_CURRENT_IMAGE], highlightElement);

  /**
   * A saga to listen every action triggered to save individual image sanitization
   */
  yield takeEvery(
    [at.IMAGE_TRIGGER_SAVE_IMAGE],
    function* triggerSaveImage({ payload = {} }) {
      yield* saveImage(payload);
    }
  );

  /**
   * A saga listener to save multiple images
   */
  yield takeEvery(
    at.IMAGE_APPLY_MULTIPLE_IMAGE_CHANGES,
    function* applyChanges({
      payload: { dataUris = {}, hasReset = false, isPatchImage = false }
    }) {
      try {
        const isGraphSelected = yield select(isGraphSection);
        if (!isEmpty(dataUris) || hasReset || isGraphSelected || isPatchImage) {
          const selectedImages = yield select(getSelectedImages);

          yield put(ca(at.IMAGE_SAVE_MULTIPLE_IMAGES_REQUESTED)());

          const modifiedImages = selectedImages.filter(
            (img) => img.smartOption !== img.savedSmartOption
          );
          let requestPayload = modifiedImages.map((sImg) => {
            return {
              ...sImg,
              dataUri: dataUris[sImg.id],
              modifiedImageUrl: getModifiedImageUrl(sImg)
            };
          });
          if (requestPayload.length) {
            if (!isGraphSelected) {
              requestPayload = yield* handleUploadImage(requestPayload);
            }

            yield call(rsSaveUpdatedImages, requestPayload, isGraphSelected);
          }
          yield* refreshImageList({
            imageToSelect: selectedImages[0],
            isManualEdit: false,
            isMultiple: selectedImages.length > 1,
            isGraph: isGraphSelected
          });
        }
      } catch (e) {
        yield call(handleCatchedError, e);
        yield put(ca(at.IMAGE_SAVE_MULTIPLE_IMAGES_FAILED)());
      }
    }
  );

  yield takeEvery(
    at.IMAGE_RESET_MANUALLY_EDITED_IMAGE,
    function* resetImage({ payload }) {
      yield call(rsResetUpdatedImage, [
        {
          ...payload,
          smartOption: "",
          modifiedImageUrl: "",
          savedSmartOption: ""
        }
      ]);
    }
  );

  yield takeEvery(
    at.SELECT_SMART_OPTION_FOR_IMAGE,
    function* fetchAutoEditedImage({ payload: smartOption }) {
      const activeSection = yield select(getActiveSection);
      const currentSlide = yield select(getCurrentSlide);
      const selectedImages = yield select(getSelectedImages);
      if (
        activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS &&
        smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP
      ) {
        let newStampedImages = {};
        const stampedImageCache = yield select(getStampedImages);
        const idsToFetch = selectedImages
          .filter((img) => img.id && !stampedImageCache[img.id])
          .map((img) => img.id);
        if (idsToFetch.length) {
          newStampedImages = yield call(
            rsFetchAutoEditedImages,
            currentSlide.pptId,
            currentSlide.slideId,
            idsToFetch
          );
        }

        const stampedImages = {
          ...stampedImageCache,
          ...newStampedImages
        };
        const imageList = yield select(getImageList);
        yield put(
          replaceImageList(
            [...imageList].map((img) => {
              img.modifiedImageUrl = stampedImages[img.id]
                ? stampedImages[img.id].url
                : img.modifiedImageUrl;
              img.uploadObjectKey = stampedImages[img.id]
                ? stampedImages[img.id].key
                : img.uploadObjectKey;
              return img;
            })
          )
        );
      }
    }
  );
}

export default function* imageSuggestionSaga() {
  yield call(watchImageSuggestionSaga);
}
