// From newnavigator

export const BREAKPOINTS = {
  XS: {
    MIN: 0,
    MAX: 575
  },
  SM: {
    MIN: 576,
    MAX: 666
  },
  MD: {
    MIN: 667,
    MAX: 1023
  },
  LG: {
    MIN: 1024,
    MAX: 1199
  },
  XL: { MIN: 1200 }
};

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isSmall() {
  return window.matchMedia("(max-width: 767px)").matches;
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isMedium() {
  return (
    window.matchMedia("(min-width: 768px)").matches &&
    window.matchMedia("(max-width: 992px)").matches
  );
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isSmallOrMedium() {
  return isSmall() || isMedium();
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isXS() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.XS.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.XS.MAX}px)`).matches
  );
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isSM() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.SM.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.SM.MAX}px)`).matches
  );
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isMD() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.MD.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.MD.MAX}px)`).matches
  );
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isLG() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.LG.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.LG.MAX}px)`).matches
  );
}

/**
 * Check screen size
 * @returns {Boolean}
 */
export function isXL() {
  return window.matchMedia(`(min-width: ${BREAKPOINTS.XL.MIN}px)`).matches;
}

export function globalScreenTypeClassName() {
  return (
    "screen-" +
    (isXL() ? "xl" : isLG() ? "lg" : isMD() ? "md" : isSM() ? "sm" : "xs") +
    (isMedium() ? " screen-medium" : isSmall() ? " screen-small" : "")
  );
}

/**
 * Check if browser is IE or Edge
 * @returns {Boolean} safari or not
 */
export function isIEOrEdge() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf("MSIE ");
  // IE 11
  const trident = ua.indexOf("Trident/");
  // edge
  // the mac version of edge uses the abbreviation 'edg' instead of 'edge' but we are not including as of now
  const edge = ua.indexOf("Edge");
  return msie > 0 || trident > 0 || edge > 0;
}

/**
 * Check if browser is IE
 * @returns {Boolean} IE or not
 */
export function isIE() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf("MSIE ");
  // IE 11
  const trident = ua.indexOf("Trident/");
  return msie > 0 || trident > 0;
}

/**
 * Check if browser is Safari
 * @returns {Boolean} safari or not
 */
export function isSafari() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(ua);
  return isSafariBrowser;
}

/**
 * Check if browser is IOS
 * @returns {Boolean} IOS or not
 */
export function isIos() {
  // Check the platform property of the window.navigator object
  const pf = window.navigator.platform;
  return ["iPad", "iPhone", "iPod"].indexOf(pf) >= 0;
}

/**
 * Check if browser is Mobile Safari
 * @returns {Boolean} Mobile safari or not
 */
export function isMobileSafari() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  return ua.match(/(iPod|iPhone|iPad)/) && ua.match(/AppleWebKit/);
}
