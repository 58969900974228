import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import ToastService from "utils/toast";

import CONFIG from "configs/config";
import { push } from "connected-react-router";

export function resourceReducer(resourceName, options) {
  const { init, key = "data", reset = false } = options || {};
  const name = resourceName.toUpperCase();
  const initialState = defaultState(key, init);

  return (state = initialState, action) => {
    if (!action.type.includes(name)) {
      // This is just avoid unnecessary data manipulation before even reducer match
      return state;
    }

    const fetchedData = get(action, "payload.data", null);
    const base = reset ? initialState : state;

    switch (action.type) {
      case `${name}_${CONFIG.CONSTANTS.REQUESTED}`:
        return base;

      case `${name}_${CONFIG.CONSTANTS.SUCCEEDED}`:
        return {
          ...state,
          [key]: fetchedData
        };

      case `${name}_${CONFIG.CONSTANTS.FAILED}`:
        return state;

      default:
        return state;
    }
  };
}

export function defaultState(key = "data", init = []) {
  return { [key]: init };
}

export function resourceSaga(resourceName, apiResource) {
  const name = resourceName.toUpperCase();
  return function* rs(...args) {
    yield put({ type: `${name}_${CONFIG.CONSTANTS.REQUESTED}`, args });
    try {
      const data = yield call(apiResource, ...args);
      yield put({
        type: `${name}_${CONFIG.CONSTANTS.SUCCEEDED}`,
        payload: { data },
        args
      });
      return data;
    } catch (e) {
      const responseMessage = get(e, "response.data.customErrorMessage", "");
      const responseStatus = get(e, "response.status", "");

      // eslint-disable-next-line no-console
      console.error(e);

      if (responseStatus === 401) {
        yield put(push("/"));
      }

      if (responseStatus === 404) {
        yield put(push("/"));
        ToastService.error(CONFIG.LABELS.DOCUMENT_NOT_FOUND);
      }

      yield put({
        type: `${name}_${CONFIG.CONSTANTS.FAILED}`,
        payload: responseMessage,
        args
      });
    }
  };
}
