import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { clamp, filter, find, get, indexOf, isUndefined } from "lodash";

import CONFIG from "configs/config";
import * as at from "../types";
import {
  rsGetTableTextList,
  rsGetTableTextSuggestions,
  rsSaveSanitizedTableText,
  rsGetUpdatedTableThumbnails,
  rsResetManuallyEditedTable
} from "./resourceSagas";
import { rsRefreshTableTextList } from "containers/TableListView/sagas/resourceSagas";
import {
  exitTableManualEditor,
  replaceTableTextList,
  setCurrentColumnBeingEdited,
  setCurrentTableTextBeingEdited,
  setCurrentTableWordBeingEdited,
  triggerSaveSanitizedTableText,
  updateCurrentTableTextPortions,
  onTableSelectSuggestions,
  onTableSetCustomText
} from "../actions";
import {
  highlightElementOnSlide,
  refreshSanitizationProgress,
  setCurrentTextGroupId,
  setEditingCurrentTextGroup
} from "containers/Sanitize/actions";
import { resetTableTextToOriginal } from "containers/SmartSuggestion/actions";
import {
  attachIdentifiedBlocks,
  getActiveWord,
  getFirstNonEditedPortionGroup,
  getUpdatedPortionFromManualEdit
} from "utils/helpers";
import {
  GO_TO_NEXT_TABLE_WORD,
  GO_TO_PREVIOUS_TABLE_WORD
} from "containers/SmartSuggestion/types";
import {
  getTableTextList,
  getColumnsList,
  getDirtyTableTextList
} from "../selectors";
import {
  onSelectWord,
  updateSanitizedWord
} from "containers/TextListView/sagas";
import { getCurrentSlide, getPptDetails } from "containers/Sanitize/selectors";
import {
  highlightCurrentTable,
  updateSelectTable,
  updateTableList
} from "containers/TableListView/actions";
import { getSelectedTable } from "containers/TableListView/selectors";

export function* saveTableText(exit = true) {
  const dirtyTextList = yield select(getDirtyTableTextList);
  const currentSlide = yield select(getCurrentSlide);
  if (dirtyTextList.length) {
    yield call(rsSaveSanitizedTableText, currentSlide.slideId, dirtyTextList);
  }
  if (exit) {
    const currentTable = yield select(getSelectedTable);
    yield call(rsGetUpdatedTableThumbnails, currentSlide.slideId, [
      currentTable.id
    ]);
    yield put(exitTableManualEditor());
  }
}

function* watchTableTextSaga() {
  yield takeLatest(
    [GO_TO_PREVIOUS_TABLE_WORD, GO_TO_NEXT_TABLE_WORD],
    function* goTo({ type, payload: { currentText, payload } }) {
      if (payload) {
        // if payload is word, go to word, else move to next para and select word based on the type of the action
        if (payload.entityMappingId) {
          yield put(
            setCurrentTableWordBeingEdited({
              ...payload,
              isArrowClick: true
            })
          );
        } else {
          // first word of the next text or last word of the prev text
          const selectWordAtIndex = type === GO_TO_NEXT_TABLE_WORD ? 0 : -1;
          yield put(triggerSaveSanitizedTableText(currentText));
          yield put(
            setCurrentTableTextBeingEdited({
              ...payload,
              selectWordAtIndex,
              isArrowClick: true
            })
          );
        }
      }
    }
  );

  /**
   * Listen open manual edit action, and fetch textlist and suggestions for the current table
   * @param {Object} action.payload action payload
   * @param {String} action.payload.slideId current slideId
   * @param {String} action.payload.tableId current tabelId
   */
  yield takeLatest(
    at.TABLE_OPEN_MANUAL_EDIT,
    function* fetchTableTextData({ payload: { slideId, id, smartOption } }) {
      const [list, suggestions] = yield all([
        call(
          rsGetTableTextList,
          id,
          smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT
        ),
        call(rsGetTableTextSuggestions, slideId, id)
      ]);

      const updatedTextList = attachIdentifiedBlocks(list, suggestions);
      yield put(replaceTableTextList(updatedTextList));

      const firstText = get(updatedTextList, "[0]", {});
      yield put(setCurrentTableTextBeingEdited(firstText));

      yield* updateSanitizedWord(updatedTextList);
    }
  );

  /**
   * On exit from table manual edit, save the dirty text and trigger the fetch call to get updated thumbnail after saving the text
   */
  yield takeLatest(
    at.TABLE_EXIT_MANUAL_EDIT,
    function* exitManualEdit({ payload = {} }) {
      const { currentText, allTextList } = payload;
      // highlight the entire table when exiting from table manual edit
      yield put(highlightCurrentTable());
      if (currentText) {
        const currentSlide = yield select(getCurrentSlide);
        if (currentText?.dirty) {
          const dirtyTextList = filter(allTextList, (text) => text.dirty);
          if (dirtyTextList.length) {
            yield call(
              rsSaveSanitizedTableText,
              currentSlide.slideId,
              dirtyTextList
            );
          }
        }
        yield call(rsGetUpdatedTableThumbnails, currentSlide.slideId, [
          currentText.tableId
        ]);
      }
    }
  );

  /**
   * Listen for action to set current text, and set current word as side effect
   * @param {String} payload (textIdToSet) id for the text being edited
   */
  yield takeLatest(
    [at.TABLE_ME_SET_CURRENT_TEXT],
    function* onSetCurrentText({ payload: text }) {
      if (text.isIdentified) {
        const word = getActiveWord(text);
        if (word.entityMappingId) {
          yield put(
            setCurrentTableWordBeingEdited({ ...word, textId: text.id })
          );
          if (word.groupId) {
            yield put(setCurrentTextGroupId(word.groupId));
          }
        }
      } else {
        yield put(
          setCurrentTextGroupId(getFirstNonEditedPortionGroup(text.portions))
        );
      }
      if (!isUndefined(text.columnNo)) {
        yield put(setCurrentColumnBeingEdited(text.columnNo));
      }
    }
  );

  /**
   * On select word (sensitive word), trigger select word saga to update selected smart option if available or select first one as default
   */
  yield takeLatest(
    at.TABLE_ME_SET_CURRENT_WORD,
    function* onSetWord({ payload }) {
      yield* onSelectWord(
        payload,
        resetTableTextToOriginal,
        onTableSelectSuggestions,
        onTableSetCustomText
      );
    }
  );

  /**
   * Listen for on change column, and set the current column
   * @param {String} action.payload currentColumn
   */
  yield takeLatest(
    [at.TABLE_ME_SET_PREV_COLUMN, at.TABLE_ME_SET_NEXT_COLUMN],
    function* changeColumn({ type, payload }) {
      const { currentColumn, currentText } = payload;
      yield put(triggerSaveSanitizedTableText(currentText));
      const textList = yield select(getTableTextList);
      const columnsList = yield select(getColumnsList);
      const currentIdx = indexOf(columnsList, currentColumn);
      let newIdx;
      if (type === at.TABLE_ME_SET_PREV_COLUMN) {
        newIdx = clamp(currentIdx - 1, 0, columnsList.length);
      } else {
        newIdx = clamp(currentIdx + 1, 0, columnsList.length);
      }
      const text = find(textList, { columnNo: columnsList[newIdx] });
      if (text) {
        yield put(setCurrentTableTextBeingEdited(text)); // isFilterIdentified ??
      }
    }
  );

  /**
   * Listen for action which select text and highlight the selected text
   * @param {String} payload id for the text being edited
   */
  yield takeLatest(
    [at.TABLE_ME_SET_CURRENT_TEXT],
    function* highlightElement({ payload: text }) {
      yield put(
        highlightElementOnSlide({
          sectionType: CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES,
          ids: {
            tableIds: [text.tableId],
            tableCellIds: [text.id]
          }
        })
      );
    }
  );

  yield takeLatest(
    at.TABLE_ME_TRIGGER_SAVE_TEXT,
    function* triggerSave({ payload: text }) {
      if (text.dirty) {
        // Check if any upsaved texts are preset and save them
        yield* saveTableText(false);
      }
    }
  );

  /**
   * After fetching updated thumbnail, refrest table list to update the preview
   */
  yield takeLatest(
    at.TABLE_REFRESH_TABLE_THUMBNAILS_SUCCEEDED,
    function* onTableSaveSuccess({ payload }) {
      // When resetting manually edited text, refresh the text to get latest portion (include resetting current word)
      if (payload.data) {
        yield put(
          refreshSanitizationProgress({
            slideId: payload.data
          })
        );
      }
      const currentPpt = yield select(getPptDetails);
      const response = yield call(
        rsRefreshTableTextList,
        currentPpt.pptId,
        payload.data
      );
      yield put(updateTableList(response));
    }
  );

  /**
   * On saving manually edited text, update the portion on text list
   */
  yield takeLatest(
    at.TABLE_SAVE_MANUALLY_SANITIZED,
    function* onManualTextSave({ payload }) {
      const { id, portions, modifiedParaByGroup } = payload;
      let newPortions = getUpdatedPortionFromManualEdit(
        portions,
        modifiedParaByGroup
      );

      yield put(
        updateCurrentTableTextPortions({
          id,
          portions: newPortions,
          groupId: Object.keys(modifiedParaByGroup)[0],
          skipHighlight: true
        })
      );

      yield put(setEditingCurrentTextGroup(false));
      yield put(setCurrentTableWordBeingEdited({}));
    }
  );

  /**
   * Listen for reset manual changes on table list and update the selected table
   */
  yield takeLatest(
    at.TABLE_RESET_MANUALLY_EDITED_TABLE,
    function* resetManualChanges({ payload }) {
      const response = yield call(rsResetManuallyEditedTable, payload);
      yield put(updateSelectTable(response));
    }
  );
}

export default function* tableTextSaga() {
  yield call(watchTableTextSaga);
}
