/* eslint-disable no-unused-vars */
import { combineReducers } from "redux";
import { map, pick, pull } from "lodash";
import { resourceReducer } from "redux/sagas/networkResource";
import * as at from "../types";
import CONFIG from "configs/config";
import {
  EDITOR_RESET_STORE,
  EDITOR_SET_ACTIVE_SECTION
} from "containers/Sanitize/types";
import { RESET_TEXT_TO_ORIGINAL } from "containers/SmartSuggestion/types";
import {
  getUpdatedPortionForReplacementText,
  getUpdatedPortionForReset
} from "utils/helpers";

function currentTextBeingEdited(state = {}, { type, payload }) {
  switch (type) {
    case at.TEXT_RESET_TEXT:
    case at.TEXT_FETCH_LIST_REQUESTED:
      return {};
    // saving the selected para id, separately, to give instant feedback to user action (set active)
    case at.TEXT_SET_CURRENT_TEXT:
      return pick(payload, ["id", "isArrowClick"]);
    case at.TEXT_STORE_CURRENT_TEXT:
      return payload;
    case at.TEXT_FETCH_SANITIZED_PARA_SUCCEEDED: {
      if (state.id === payload.data.id) {
        return {
          ...state,
          ...payload.data
        };
      }
      return state;
    }

    case at.TEXT_SELECT_SMART_SUGGESTION:
    case at.TEXT_SET_REPLACEMENT_TEXT: {
      if (!payload.skipLS) {
        const isFromSuggestion = type === at.TEXT_SELECT_SMART_SUGGESTION;
        const updatedPortions = getUpdatedPortionForReplacementText(
          state.portions,
          payload,
          isFromSuggestion
        );
        return { ...state, portions: updatedPortions, dirty: true };
      }
      return state;
    }

    case at.TEXT_DESELECT_SMART_SUGGESTION:
    case RESET_TEXT_TO_ORIGINAL: {
      if (!payload.skipLS) {
        const isReset = type === RESET_TEXT_TO_ORIGINAL;
        const updatedPortions = getUpdatedPortionForReset(
          state.portions,
          payload,
          isReset
        );
        return { ...state, portions: updatedPortions, dirty: true };
      }
      return state;
    }

    case at.TEXT_UPDATE_CURRENT_TEXT_POTIONS: {
      const identifiedBlocks = map(state.identifiedBlocks, (block) => {
        if (block.groupId === payload.groupId) {
          return {
            ...block,
            skipHighlight: payload.skipHighlight
          };
        }
        return block;
      });
      return {
        ...state,
        portions: payload.portions,
        identifiedBlocks,
        dirty: true
      };
    }

    case at.TEXT_SAVE_SANITIZED_PARA_SUCCEEDED: {
      if (state.id === payload.data.id) {
        return {
          ...state,
          ...pick(payload.data, ["portions"])
        };
      }
      return state;
    }

    default:
      return state;
  }
}

function currentWordBeingEdited(state = {}, { type, payload }) {
  switch (type) {
    case EDITOR_SET_ACTIVE_SECTION:
    case at.TEXT_SET_CURRENT_TEXT:
    case at.TEXT_FETCH_LIST_REQUESTED:
      return {};
    case at.TEXT_SET_CURRENT_WORD:
      return payload;
    default:
      return state;
  }
}

function textList(state, action) {
  switch (action.type) {
    case EDITOR_RESET_STORE:
    case at.TEXT_RESET_TEXT:
      return { data: [] };
    default:
      return resourceReducer(at.TEXT_FETCH_LIST, { reset: true })(
        state,
        action
      );
  }
}

export default combineReducers({
  textList,
  currentTextBeingEdited,
  currentWordBeingEdited,
  suggestionEntities: resourceReducer(at.TEXT_FETCH_SUGGESTIONS, { init: {} })
});
