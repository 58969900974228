import * as at from "../types";
import { createAction as ca } from "utils/action";

export const setDataUriForCurrentTable = ca(
  at.TABLE_SET_CURRENT_EDITED_TABLE_DATA_URI
);

export const onSelectSmartOptionForTable = ca(at.SELECT_SMART_OPTION_FOR_TABLE);
export const onSelectTable = ca(at.TABLE_ON_SELECT_TABLE);
export const updateSelectTable = ca(at.TABLE_UPDATE_SELECT_TABLE);
export const setCurrentTableBeingEdited = ca(at.TABLE_SET_CURRENT_TABLE);

export const updateTableList = ca(at.TABLE_UPDATE_TABLELIST);
export const replaceTableList = ca(at.TABLE_REPLACE_TABLELIST);
export const refreshTableList = ca(at.TABLE_REFRESH_TABLELIST);

export const triggerSaveTable = ca(at.TABLE_TRIGGER_SAVE_TABLE);
export const resetTableState = ca(at.TABLE_RESET_TABLE_STATE);

export const highlightCurrentTable = ca(at.TABLE_HIGHLIGHT_CURRENT_TABLE);
