import { call, put, takeLatest, select, delay } from "redux-saga/effects";
import { flatMap, find, filter } from "lodash";
import * as at from "../types";
import CONFIG from "configs/config";
import {
  fetchTextListBySlideId,
  resetTextState,
  setCurrentTextBeingEdited,
  triggerSaveSanitizedPara
} from "containers/TextListView/actions";
import {
  getCurrentText,
  getFilteredTextList
} from "containers/TextListView/selectors";
import {
  setActiveSection,
  setCurrentSlide,
  storeHighlightElementRespones,
  resetEditorStoreData,
  refreshSanitizationProgress
} from "../actions";
import {
  getPresentationDetailsByPptId,
  getSlideByPptId,
  markSlideAsSanitized
} from "api/sanitize";

import {
  getActiveSection,
  getCurrentSlide,
  getSlidesList,
  isGraphSection
} from "../selectors";
import { ON_HOME_PAGE_REDIRECT } from "containers/Home/types";
import { getSectionWhichHasData } from "utils/common";
import { getPreviewSlideAPI } from "api/preview";
import {
  rsGetTableListBySlide,
  rsGetTableSuggestions
} from "containers/TableListView/sagas/resourceSagas";
import { rsHighlightElementOnSlide } from "./resourceSagas";
import { IMAGE_FETCH_IMAGELIST_SUCCEEDED } from "containers/ImageListView/types";
import {
  triggerSaveImage,
  resetImageState,
  toggleMultiselectOff,
  onSetCurrentImage
} from "containers/ImageListView/actions";
import {
  rsGetImagesBySlide,
  rsGetImageSuggestion
} from "containers/ImageListView/sagas/resourceSagas";
import { saveImage } from "containers/ImageListView/sagas";
import {
  resetTableState,
  setCurrentTableBeingEdited,
  triggerSaveTable
} from "containers/TableListView/actions";
import {
  getCurrentImage,
  getFilteredImageList,
  getSelectedImages,
  isMultiSelectMode
} from "containers/ImageListView/selectors";
import {
  getFilteredTableList,
  getSelectedTable
} from "containers/TableListView/selectors";
import { rsSaveSanitizedPara } from "containers/TextListView/sagas/resourceSagas";
import { saveTable } from "containers/TableListView/sagas";
import { createAction } from "utils/action";
import { isTableManualEditShown } from "containers/TableManualEdit/selectors";
import { saveTableText } from "containers/TableManualEdit/sagas";
import { filterSensitiveItem } from "utils/helpers";

export const ACTIONS_TO_TRIGGER_AUTO_SAVE = [
  at.EDITOR_SET_ACTIVE_SECTION,
  ON_HOME_PAGE_REDIRECT,
  at.EDITOR_RESET_ON_UNMOUNT
];

/**
 * Fetch Image/Graph data (including recommenation) when opening image/graph section
 *
 * @param {Object} slideData
 * @param {String} activeSection
 */
function* fetchImageData(slideData, activeSection) {
  const isGraphView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS;
  const imageListResponse = yield call(
    rsGetImagesBySlide,
    slideData.pptId,
    slideData.slideId,
    isGraphView
  );
  // call recommenation api
  const imageIds = imageListResponse.map((img) => img.id);
  if (imageIds.length) {
    yield call(rsGetImageSuggestion, slideData.slideId, imageIds, isGraphView);
  }
}

/**
 * Fetch Table data (including recommenation) when opening table section
 *
 * @param {Object} slideData
 */
function* fetchTableData(slideData) {
  const tableDataResponse = yield call(
    rsGetTableListBySlide,
    slideData.pptId,
    slideData.slideId
  );

  // call recommenation api
  const tableIds = tableDataResponse.map((table) => table.id);
  if (tableIds.length) {
    yield call(rsGetTableSuggestions, slideData.slideId, tableIds);
  }
}

/**
 * Generator function to check if any dirty changes of text, images, graphs and tables.
 * if yes, auto save those changes.
 * Used when opening preview, preview document, download with dirty changes
 */
export function* autoSaveDirtyChanges(payload = {}) {
  const activeSection = yield select(getActiveSection);
  const currentActiveSection = payload.current || activeSection;
  if (currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
    const currentText = yield select(getCurrentText);
    if (currentText.dirty) {
      yield call(rsSaveSanitizedPara, currentText);
    }
  } else if (
    [
      CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES,
      CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
    ].includes(currentActiveSection)
  ) {
    yield* saveImage();
  } else if (currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
    const isManualEdit = yield select(isTableManualEditShown);
    if (isManualEdit) {
      yield* saveTableText();
    } else {
      yield* saveTable();
    }
  }
}

function* watchSanitizerSaga() {
  /**
   * trigger auto save when changing active section or moving away from sanitize window page
   */
  yield takeLatest(
    ACTIONS_TO_TRIGGER_AUTO_SAVE,
    function* triggerTextSave({ type, payload = {} }) {
      const activeSection = yield select(getActiveSection);
      const currentActiveSection =
        type === at.EDITOR_SET_ACTIVE_SECTION ? payload.current : activeSection;
      if (currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
        const currentText = yield select(getCurrentText);

        // When changing slide, save the existing text changes
        if (currentText?.text && currentText.dirty) {
          yield put(triggerSaveSanitizedPara(currentText));
        }
        // Reset text list when changing active section
        yield put(resetTextState());
      } else if (
        [
          CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES,
          CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
        ].includes(currentActiveSection)
      ) {
        const isMultiSelect = yield select(isMultiSelectMode);
        if (!isMultiSelect) {
          const isGraph = currentActiveSection
            ? currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
            : yield select(isGraphSection);

          yield put(
            triggerSaveImage({
              isGraph,
              images: yield select(getSelectedImages)
            })
          );
        } else {
          yield put(toggleMultiselectOff());
        }
        // Reset images and selected images when changing active section
        yield put(resetImageState());
      } else if (
        currentActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES
      ) {
        const isManualEdit = yield select(isTableManualEditShown);
        if (isManualEdit) {
          yield* saveTableText();
        } else {
          yield put(
            triggerSaveTable({
              modifiedTable: yield select(getSelectedTable)
            })
          );
        }

        // Reset tables, manual edit table data and selected table when changing active section
        yield put(resetTableState());
      }

      if (type === at.EDITOR_RESET_ON_UNMOUNT) {
        yield put(resetEditorStoreData());
      }
    }
  );

  /**
   * Trigger active section when setting initial slide.
   * triggered when loading the sanitize window for first time, this block is to not delay the api call
   */
  yield takeLatest(
    at.EDITOR_SET_INITIAL_SLIDE,
    function* onChangeSlide({ payload }) {
      yield put(setCurrentSlide(payload.slide));
      yield put(
        setActiveSection({
          next:
            payload.lastActiveElementType ||
            CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT
        })
      );
    }
  );

  /**
   * ON change slide, set the current slide in the store the active section based on the payload
   */
  yield takeLatest(
    at.EDITOR_CHANGE_SLIDE,
    function* onChangeSlide({ payload }) {
      const { slideData } = payload;
      const currentSlide = yield select(getCurrentSlide);
      const { activeSectionToSet, ...originalSlideData } = slideData;
      if (currentSlide.slideId !== slideData.slideId || activeSectionToSet) {
        yield put(setCurrentSlide(originalSlideData));

        const currentActiveSection = yield select(getActiveSection);
        // Based on the active section, make respective fetch call
        if (activeSectionToSet) {
          yield put(
            setActiveSection({
              current: currentActiveSection,
              next: activeSectionToSet,
              delayedCall: true
            })
          );
        } else {
          const actionSectionWithData = getSectionWhichHasData(slideData);
          yield put(
            setActiveSection({
              current: currentActiveSection,
              next: currentActiveSection || actionSectionWithData,
              delayedCall: true
            })
          );
        }
      }
    }
  );

  /**
   * ON change/set active section, fetch data using respective api.
   * Note, here we are making delayed call to avoid calling api when changing slide multiple time
   * @param {object} action
   * @param {object} action.payload
   * @param {string} action.payload.current prev active section
   * @param {string} action.payload.next next active section
   * @param {boolean} action.payload.delayedCall whether to delay the api call or not
   */
  yield takeLatest(
    at.EDITOR_SET_ACTIVE_SECTION,
    function* activeSectionChange({ payload }) {
      if (payload.next) {
        const nextActiveSection = payload.next;
        const currentSlide = yield select(getCurrentSlide);

        if (payload.delayedCall) {
          yield delay(2000);
        }

        if (currentSlide.pptId) {
          if (
            nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT &&
            currentSlide.totalTexts
          ) {
            yield put(fetchTextListBySlideId(currentSlide));
          } else if (
            nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
            nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
          ) {
            if (
              (nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES &&
                currentSlide.totalImages) ||
              (nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS &&
                currentSlide.totalGraphs)
            ) {
              yield* fetchImageData(currentSlide, nextActiveSection);
            } else {
              // Mock success call to reset previous state when skipping the api based on totalImages/totalGraphs count
              yield put(
                createAction(IMAGE_FETCH_IMAGELIST_SUCCEEDED)({ data: [] })
              );
            }
          } else if (
            nextActiveSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES &&
            currentSlide.totalTables
          ) {
            yield* fetchTableData(currentSlide);
          }
        }
      }
    }
  );

  /**
   * ON refresh sanitization action, Call slide level data to get the updated status,
   * and call ppt level status if the slide level status is different than the previous state
   * @param {string} action.payload current active section
   */
  yield takeLatest(
    at.EDITOR_REFRESH_SANITIZATION_PROGRESS,
    function* refreshProgress({ payload = {} }) {
      let currentSlideLocal = yield select(getCurrentSlide);
      const { slideId = currentSlideLocal.slideId } = payload;

      if (currentSlideLocal.pptId && currentSlideLocal.slideId !== slideId) {
        const allSlides = yield select(getSlidesList);
        currentSlideLocal = find(allSlides, { slideId });
      }
      if (currentSlideLocal.slideId) {
        const slides = yield call(
          getSlideByPptId,
          currentSlideLocal.pptId,
          currentSlideLocal.slideIndex
        );

        if (slides.length) {
          const newCurrentSlide = slides[0];
          const hasStatusChanged =
            currentSlideLocal.sanitizationProgress !==
            newCurrentSlide.sanitizationProgress;

          // Only refresh slide and ppt data if progress has changed
          if (
            (hasStatusChanged && newCurrentSlide) ||
            payload.hardRefreshProgress
          ) {
            yield put({
              type: at.UPDATE_SLIDES_IN_STORE,
              payload: newCurrentSlide
            });

            const response = yield call(
              getPresentationDetailsByPptId,
              currentSlideLocal.pptId
            );

            yield put({
              type: at.UPDATE_PPT_DETAILS_IN_STORE,
              payload: response
            });
          }
        }
      }
    }
  );

  /**
   * ON selecting any active element, trigger an api call to fetch the highlighted slide image
   * Update the same into slide data, to see the updated image on the slider
   * @param {object} action
   * @param {object} action.payload
   * @param {object} action.payload.ids element ids (textIds if sectionType is text, imageIds if images, graphIds if graphs, tableIds if tables)
   * @param {string} action.payload.sectionType current section type
   */
  yield takeLatest(
    [at.HIGHLIGHT_ELEMENT_ON_SLIDE],
    function* highlightElement({ payload }) {
      const { ids, sectionType } = payload;
      const slide = yield select(getCurrentSlide);
      if (slide.pptId) {
        let response;
        if (flatMap(ids)?.length) {
          response = yield call(rsHighlightElementOnSlide, {
            pptId: slide.pptId,
            slideId: slide.slideId,
            ...ids
          });
        } else {
          // When deselecting all the images/graphs, we get to this case.
          // reset the slide image to do default image which has highlights in it
          response = slide.image;
        }
        // Highlight text only if the current active section is same text as it was when the request made and,
        // also if the slide is not changed
        const activeSection = yield select(getActiveSection);
        const currentSlide = yield select(getCurrentSlide);
        if (
          response &&
          activeSection === sectionType &&
          slide.slideId === currentSlide.slideId
        ) {
          yield put(
            storeHighlightElementRespones({
              slideId: slide.slideId,
              dynamicSlideUrl: response
            })
          );
        }
      }
    }
  );

  /**
   * ON click of mark slide as sanitized, mark the slide as user sanitized
   * @param {object} action
   * @param {object} action.payload
   * @param {string} action.payload.presentationId current pptId
   * @param {string} action.payload.slideId current slide Id
   * @param {boolean} action.payload.markComplete state for markComplete property whether to mark or unmark
   */
  yield takeLatest(
    at.USER_MARK_AS_COMPLETE,
    function* markComplete({ payload }) {
      yield put({
        type: at.USER_MARK_AS_COMPLETE_SUCCEEDED,
        payload: payload
      });

      yield call(markSlideAsSanitized, payload);

      // As we update local sanitizationProgress immediately, progress comparision will fail on refresh
      // So force to refresh ppt progress in this case
      yield put(refreshSanitizationProgress({ hardRefreshProgress: true }));
    }
  );

  /**
   * Get preview slide data
   */
  yield takeLatest(at.GET_PREVIEW_SLIDE_REQUESTED, function* previewSlide() {
    yield* autoSaveDirtyChanges();
    const pptDetails = yield select(getCurrentSlide);
    const response = yield call(
      getPreviewSlideAPI,
      pptDetails.pptId,
      pptDetails.slideId
    );
    yield put({ type: at.GET_PREVIEW_SLIDE_SUCCEEDED, payload: response });
  });

  /**
   * On activating/deactivating filter for TEXT/IMAGE/GRAPH/TABLE
   * filter out currenly visibile list with only sensitive or all (respective to payload) and select first item
   */
  yield takeLatest(
    at.TOGGLE_IS_FILTER_IDENTIFIED,
    function* toggleFilter({ payload }) {
      const activeSection = yield select(getActiveSection);
      if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT) {
        const currentText = yield select(getCurrentText);
        const textList = yield select(getFilteredTextList);
        const filteredTextList = payload
          ? filter(textList, { isIdentified: true })
          : textList;
        const available = find(filteredTextList, ["id", currentText.id]);
        if (!available) {
          yield put(
            setCurrentTextBeingEdited(
              filteredTextList.length ? filteredTextList[0] : {}
            )
          );
        }
      } else if (
        activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES ||
        activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
      ) {
        const currentImage = yield select(getCurrentImage);
        const imageList = yield select(getFilteredImageList);
        const filteredImageList = payload
          ? filterSensitiveItem(imageList)
          : imageList;
        const available = find(filteredImageList, ["id", currentImage.id]);
        if (!available) {
          yield put(
            onSetCurrentImage({
              entity: filteredImageList.length ? filteredImageList[0] : {},
              autoSave: true
            })
          );
        }
      } else if (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES) {
        const currentTable = yield select(getSelectedTable);
        const tableList = yield select(getFilteredTableList);
        const filteredTableList = payload
          ? filterSensitiveItem(tableList)
          : tableList;
        const available = find(filteredTableList, ["id", currentTable.id]);
        if (!available) {
          yield put(
            setCurrentTableBeingEdited(
              filteredTableList.length ? filteredTableList[0] : {}
            )
          );
        }
      }
    }
  );
}

export default function* sanitizerSaga() {
  yield call(watchSanitizerSaga);
}
