import { call, takeLatest, put, select, delay } from "redux-saga/effects";

import { getOktaUserInfo, getUserProfilePic } from "api/profile";

import * as at from "../types";
import {
  fetchSignedTokenFromAPI,
  getSignedToken,
  setUserDetails,
  getUserProfilePicture
} from "../actions";
import { handleCatchedError } from "utils/sagaUtils";
import { rsGetSignedToken } from "./resourceSagas";
import { getSignedTokenFromStore } from "../selectors";
import { isDatePast } from "utils/helpers";
import { getHrIdFromLocalStorage } from "utils/localStorage/localStorage";
import { getMillisecondsFromExpiry } from "utils/common";

/**
 * Generator saga function, to fetch userinfo from okta,
 * fetch cloudfront signed token for assets
 * and fetch profile picture for the current user
 *
 * Save the same on store
 */
export function* getUserDetailsSaga() {
  let userDetails = yield call(getOktaUserInfo);
  if (!userDetails?.hrId) {
    userDetails.hrId = getHrIdFromLocalStorage();
  }
  yield put(setUserDetails(userDetails));
  try {
    if (userDetails?.hrId) {
      yield put(getSignedToken());
      yield put(getUserProfilePicture(userDetails.hrId));
    }
    return userDetails;
  } catch (e) {
    yield call(handleCatchedError, e);
    yield put(
      setUserDetails({
        ...userDetails,
        profileImage: ""
      })
    );
  }
}

function* watchUserSaga() {
  yield takeLatest(at.USER_GET_USER_DETAILS, getUserDetailsSaga);

  // yield takeLatest(at.USER_SET_USER_DETAILS, function setUser({ payload }) {
  //   if (payload.email) {
  //     window.usabilla_live("data", {
  //       email: payload.email
  //     });
  //   }
  // });
  /**
   * Listener saga, to fetch profile picture
   */
  yield takeLatest(
    at.USER_GET_USER_PROFILE_PIC,
    function* getProfilePic({ payload: hrId }) {
      const profileImage = yield call(getUserProfilePic, hrId);
      yield put(setUserDetails({ profileImage }));
    }
  );

  /**
   * Listener saga, to fetch cloudfront signed token
   */
  yield takeLatest(at.USER_GET_SIGNED_TOKEN, function* getSignedToken() {
    const existingToken = yield select(getSignedTokenFromStore);
    if (!existingToken.expiry || isDatePast(existingToken.expiry)) {
      yield put(fetchSignedTokenFromAPI());
    }
  });

  /**
   * Listener saga, to setup refreshing logic for signed token based on the expiry
   */
  yield takeLatest(at.USER_FETCH_SIGNED_TOKEN_FROM_API, function* fetchToken() {
    const response = yield call(rsGetSignedToken);
    const twoDays = 2 * 24 * 60 * 60 * 1000;
    yield delay(getMillisecondsFromExpiry(response.expiry) || twoDays); // refresh the token after two days similar to BE
    yield put(getSignedToken());
  });
}

export default function* userSaga() {
  yield call(watchUserSaga);
}
