import React from "react";
import PropTypes from "prop-types";
import "./ErrorPage.scss";

import CONFIG from "configs/config";
import { getErrorObject } from "utils/common";

/**
 * ERROR SCREEN
 * Shows Errors of application such as IE Error/404 Error
 * @returns JSX
 */
const ErrorPage = ({ page }) => {
  document.title = CONFIG.APP.TITLE;
  const { title, errorTitle, errorDescription } = getErrorObject(page);

  return (
    <div className="error error__content-wrapper">
      <div className="error__content">
        <p className="error__code">{title}</p>
        <p className="error__title">{errorTitle}</p>
        <p className="error__message ie">{errorDescription}</p>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  page: PropTypes.string
};

ErrorPage.defaultProps = {
  page: ""
};

export default ErrorPage;
