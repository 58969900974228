/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { List, Placeholder } from "semantic-ui-react";
import { isUndefined, noop } from "lodash";
import "./TextListView.scss";
import CONFIG from "configs/config";

import NoResult from "components/NoResult/NoResult";
import TextAreaWithHighlight from "components/TextAreaWithHighlight/TextAreaWithHighlight";

import {
  setCurrentTextBeingEdited,
  setCurrentWordBeingEdited,
  triggerSaveSanitizedPara
} from "./actions";
import {
  getCurrentText,
  isFetchingTextList,
  isFetchingSuggestions,
  getCurrentWord,
  isFetchingModifiedText,
  getFilteredTextList
} from "./selectors";
import { getSmartSuggestion } from "containers/SmartSuggestion/selectors";
import { textListType } from "utils/customPropTypes";

/**
 * SANITIZE SCREEN
 * List of text identified by the system from PPT
 * @returns JSX
 */
const TextListView = ({
  isFetchingPpt,
  textList,
  currentText,
  isFetchingText,
  setCurrentText,
  isFetchingSuggestions,
  isFetchingModifiedText,
  currentWord,
  setCurrentWord,
  savedData,
  triggerSaveSanitizedPara,
  currentGroup,
  setCurrentGroupId,
  hasText,
  isFilterIdentified
}) => {
  const [lastActiveTextId, setActiveTextId] = useState("");
  const showLoader = isFetchingPpt || isFetchingText;
  const showNoResult = !showLoader && !textList.length && !hasText;

  const noFilteredResult =
    isFilterIdentified && textList.length === 0 && !showLoader;

  const onTextSelect = (nextText) => {
    if (currentText.id !== nextText.id) {
      triggerSaveSanitizedPara(currentText);
      setCurrentText(nextText);
    }
  };

  useEffect(() => {
    if (
      currentText.id &&
      !isUndefined(currentText.isArrowClick) &&
      lastActiveTextId !== currentText.id
    ) {
      setActiveTextId(currentText.id);
      if (currentText.isArrowClick) {
        document.getElementById(currentText.id)?.scrollIntoView(false);
      }
    }
  }, [currentText]);

  if (noFilteredResult) {
    return <NoResult label={"Sensitive Text"} />;
  }

  if (showNoResult) {
    return <NoResult label={CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT} />;
  }
  return (
    <div className="main-block list-container text-list">
      {showLoader ? (
        <div className="text-list__all-texts">
          <Placeholder className="mt-0" />
          <Placeholder />
          <Placeholder />
          <Placeholder />
        </div>
      ) : (
        <div className="text-list__all-texts">
          <SimpleBar
            className="text-list__list-wrapper"
            scrollbarMaxSize={50}
            timeout={1500}
          >
            <List verticalAlign="middle">
              {textList.map((text) => (
                <List.Item
                  id={text.id}
                  className={currentText.id === text.id ? "active" : ""}
                  key={text.id}
                  onClick={() => onTextSelect(text)}
                >
                  {text.text}
                </List.Item>
              ))}
            </List>
          </SimpleBar>
        </div>
      )}
      <TextAreaWithHighlight
        currentText={currentText}
        currentWord={currentWord}
        setCurrentWord={setCurrentWord}
        showLoader={
          showLoader || isFetchingSuggestions || isFetchingModifiedText
        }
        savedData={savedData}
        currentGroup={currentGroup}
        setCurrentGroupId={setCurrentGroupId}
      />
    </div>
  );
};

TextListView.propTypes = {
  isFetchingPpt: PropTypes.bool,
  textList: PropTypes.arrayOf(textListType),
  currentText: PropTypes.object,
  isFetchingText: PropTypes.bool,
  setCurrentText: PropTypes.func,
  isFetchingSuggestions: PropTypes.bool,
  isFetchingModifiedText: PropTypes.bool,
  currentWord: PropTypes.object,
  setCurrentWord: PropTypes.func,
  triggerSaveSanitizedPara: PropTypes.func,
  savedData: PropTypes.array,
  currentGroup: PropTypes.object,
  setCurrentGroupId: PropTypes.func,
  hasText: PropTypes.bool,
  isFilterIdentified: PropTypes.bool
};

TextListView.defaultProps = {
  textList: [],
  currentText: {},
  setCurrentText: noop,
  currentWord: {},
  setCurrentWord: noop,
  triggerSaveSanitizedPara: noop,
  savedData: [],
  currentGroup: {},
  setCurrentGroupId: noop,
  hasText: false,
  isFilterIdentified: false
};

function mapStateToProps(state) {
  return {
    textList: getFilteredTextList(state),
    currentText: getCurrentText(state),
    isFetchingText: isFetchingTextList(state),
    currentWord: getCurrentWord(state),
    isFetchingSuggestions: isFetchingSuggestions(state),
    isFetchingModifiedText: isFetchingModifiedText(state),
    savedData: getSmartSuggestion(state)
  };
}

export default connect(mapStateToProps, {
  setCurrentText: setCurrentTextBeingEdited,
  setCurrentWord: setCurrentWordBeingEdited,
  triggerSaveSanitizedPara
})(TextListView);
