import React from "react";
import PropTypes from "prop-types";
import "./DisclaimerFooter.scss";
import { noop } from "lodash";
import CONFIG from "configs/config";

/**
 * HOME SCREEN
 * Footer disclaimer always visible to the user
 * @returns JSX
 */
const DisclaimerFooter = ({ label, onShowDisclaimer, moreInfo, copyright }) => (
  <p className="disclaimer-footer">
    <span className="pr-2">
      {label} {moreInfo}
      <span
        data-testid="click-here"
        className="pointer text-green"
        onClick={onShowDisclaimer}
      >
        {" "}
        <u>{CONFIG.LABELS.CLICK_HERE}</u>{" "}
      </span>
    </span>
    <span data-testid="copy-right">{copyright}</span>
  </p>
);

DisclaimerFooter.propTypes = {
  label: PropTypes.string,
  onShowDisclaimer: PropTypes.func,
  moreInfo: PropTypes.string,
  copyright: PropTypes.string
};

DisclaimerFooter.defaultProps = {
  label: "",
  moreInfo: "",
  copyright: "",
  onShowDisclaimer: noop
};

export default DisclaimerFooter;
