import config from "configs/config";
import * as at from "../types";

const initialState = {
  previewSlides: [],
  previewSlidesCache: [],
  startingIndex: 0,
  endingIndex: config.CONSTANTS.PREVIEW_DOCUMENT_SETTINGS.THUMBNAILS_COUNT - 1,
  currentSlideIndex: 0,
  isPreviewDocShown: false,
  selectedPPTId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case at.GET_PREVIEW_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        previewSlides: action.payload.data,
        previewSlidesCache: [
          ...state.previewSlidesCache,
          ...action.payload.data
        ]
      };
    case at.GET_PREVIEW_DOCUMENT:
      return {
        ...state,
        startingIndex: action.payload,
        endingIndex:
          action.payload +
          (config.CONSTANTS.PREVIEW_DOCUMENT_SETTINGS.THUMBNAILS_COUNT - 1)
      };
    case at.SET_CURRENT_SLIDE_INDEX:
      return {
        ...state,
        currentSlideIndex: action.payload
      };
    case at.TOGGLE_PREVIEW_DOC:
      return { ...state, isPreviewDocShown: action.payload };
    case at.CLEAR_PREVIEW_SLIDES:
      return {
        ...state,
        previewSlides: [],
        previewSlidesCache: []
      };
    default:
      return state;
  }
};
