export default {
  IE11: {
    title: "IE11",
    errorTitle:
      "We are sorry, Sanitization Tool doesn't support Internet Explorer 11",
    errorDescription:
      "Please use Chrome or Firefox or Microsoft Edge. Both can be installed from your computer's Software Center."
  },
  404: {
    title: "404",
    errorTitle: "We are sorry, page not found!",
    errorDescription:
      "The page you are looking for might have been removed, had its name chaged or its temporarily unavailable."
  }
};
