import { createSelector } from "reselect";

const loadingSelector = (state) => state.loadingResources;

/**
 * A common loading selector to get loading state of all the given resource as bool
 * @param {array} actions List of actions (resources)
 * @return {bool} loading state of given actions (resources)
 * Note when multiple resources are provided as input, (as an array)
 * it will return true if any one of the resource is loading,
 * return false, if all the resources are loaded
 */
export const createLoadingSelector = (actions) => {
  if (!Array.isArray(actions)) {
    return;
  }
  return createSelector(loadingSelector, (loadingState) =>
    actions.some((action) => loadingState[action])
  );
};
