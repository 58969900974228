import { map, sortBy } from "lodash";
import axiosInstance from "configs/axios";
import { extractApiResponse } from "utils/helpers";

const UPLOAD_POWER_POINT_API_END_POINT = "/Presentation/upload/";
const GET_FILES_API_END_POINT = "/Presentation/getbyuser";
const DELETE_FILE_API_END_POINT = "/presentation/delete/";
const GET_PPT_STATUS_BY_ID = "/Presentation/getPresentationStatusById/";
const AUTO_SANITIZE_DOCUMENT_END_POINT =
  "/Presentation/getautosanitizeddocument/";
const RETRY_FAILED_DOCUMENT_URI = "/Presentation/retryextraction/";

export function uploadPowerPointApi(payload) {
  return axiosInstance
    .post(
      `${UPLOAD_POWER_POINT_API_END_POINT}${payload.pptId}`,
      payload.formData
    )
    .then(extractApiResponse);
}

export function FileList(result) {
  this.pptId = result.pptId;
  this.pptTitle = result.pptTitle;
  this.pptStatus = result.pptStatus;
  this.createdOn = result.createdOn;
  this.modifiedOn = result.modifiedOn;
  this.totalSlide = result.totalSlides;
  this.completedSlides =
    result.completedSlides < 0 ? 0 : result.completedSlides;
  this.downloadDate = result.downloadDate;
  this.deleteDate = result.deleteDate;
  this.extractionRetried = result.extractionRetried;
}

export function getFilesApi() {
  return axiosInstance.get(`${GET_FILES_API_END_POINT}`).then((response) =>
    sortBy(
      map(extractApiResponse(response), (result) => new FileList(result)),
      (file) => new Date(file.modifiedOn)
    ).reverse()
  );
}

export function deleteFileApi(pptId) {
  return axiosInstance
    .delete(`${DELETE_FILE_API_END_POINT}${pptId}`)
    .then(() => pptId);
}

export function fetchStatusByPptId(pptIds) {
  return axiosInstance
    .post(`${GET_PPT_STATUS_BY_ID}`, pptIds)
    .then(extractApiResponse);
}

export function autoSanitizeDocument(pptId) {
  return axiosInstance
    .get(`${AUTO_SANITIZE_DOCUMENT_END_POINT}${pptId}`)
    .then((response) => response.data);
}

export function retryFailedDocument(pptId) {
  return axiosInstance
    .put(`${RETRY_FAILED_DOCUMENT_URI}${pptId}`)
    .then(extractApiResponse);
}
