import moment from "moment";
import CONFIG from "configs/config";

/**
 *
 * @param {Date} date
 * @returns {String} Formatted date
 */
export const formatDate = (date) =>
  moment
    .utc(date, CONFIG.CONSTANTS.DATE_TIME_FORMATS.SERVER_DATE_TIME)
    .local()
    .format(CONFIG.CONSTANTS.DATE_TIME_FORMATS.LOCAL_DATE);

/**
 *
 * @param {Date} date
 * @returns {String} Formatted date and time
 */
export const formatDateAndTime = (date) =>
  moment
    .utc(date, CONFIG.CONSTANTS.DATE_TIME_FORMATS.SERVER_DATE_TIME)
    .local()
    .format(CONFIG.CONSTANTS.DATE_TIME_FORMATS.LOCAL_DATE_TIME);

/**
 *
 * @param {Date} date
 * @returns {Boolean} Returns whether date is past current date
 */
export const isDatePast = (date) => {
  const utcNow = moment.utc();
  const inputDate = moment.utc(date);
  return inputDate < utcNow;
};
