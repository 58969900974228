import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import homeReducer from "containers/Home/reducers";
import userReducer from "containers/User/reducers";
import sanitizeWindowReducer from "containers/Sanitize/reducers";
import loadingResourcesReducers from "containers/Common/reducers/loading";
import textReducer from "containers/TextListView/reducers";
import smartSuggestionsReducer from "containers/SmartSuggestion/reducers";
import imageReducer from "containers/ImageListView/reducers";
import tableReducer from "containers/TableListView/reducers";
import tableTextReducer from "containers/TableManualEdit/reducers";
import localTextDictionaryReducer from "containers/TextListView/reducers/localTextDictionary";
import previewReducer from "containers/Preview/reducers";
import documentSummaryReducer from "containers/SummaryModal/reducers";
import modalReducer from "containers/Common/reducers/modal";
import signedTokenReducer from "containers/User/reducers/signedToken";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    loadingResources: loadingResourcesReducers,
    user: userReducer,
    signedToken: signedTokenReducer,
    home: homeReducer,
    sanitizeWindow: sanitizeWindowReducer,
    text: textReducer,
    smartSuggestions: smartSuggestionsReducer,
    image: imageReducer,
    table: tableReducer,
    tableText: tableTextReducer,
    localTextDictionary: localTextDictionaryReducer,
    preview: previewReducer,
    documentSummary: documentSummaryReducer,
    modal: modalReducer
  });
