import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/image";
import * as at from "../types";

export const rsGetImagesBySlide = resourceSaga(
  at.IMAGE_FETCH_IMAGELIST,
  api.getImagesBySlide
);

export const rsGetImageSuggestion = resourceSaga(
  at.IMAGE_FETCH_IMAGE_SUGGESTION,
  api.getImageSuggestion
);

export const rsSaveUpdatedImages = resourceSaga(
  at.IMAGE_SAVE_UPDATED_IMAGES,
  api.saveUpdatedImages
);

export const rsResetUpdatedImage = resourceSaga(
  at.IMAGE_RESET_MANUALLY_EDITED_IMAGE,
  api.saveUpdatedImages
);

export const rsFetchAutoEditedImages = resourceSaga(
  at.IMAGE_FETCH_AUTO_EDITED_IMAGES,
  api.fetchAutoEditedImages
);
