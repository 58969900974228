import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import SmartSuggestion from "containers/SmartSuggestion/SmartSuggestion";

import {
  getPrevEntity,
  getNextEntity,
  showLoaderForTableText,
  canResetTableTextToOriginal,
  getCurrentTableText,
  getCurrentTableWord
} from "containers/TableManualEdit/selectors";
import {
  onTableSelectSuggestions,
  onTableSetCustomText,
  onTableDeselectSuggestions
} from "containers/TableManualEdit/actions";
import {
  resetTableTextToOriginal,
  onPrevTableWord,
  onNextTableWord
} from "containers/SmartSuggestion/actions";
import { getActiveSection } from "containers/Sanitize/selectors";

const TableTextSmartSuggestion = ({
  activeSection,
  showLoader,
  currentEntity,
  prevEntity,
  nextEntity,
  currentText,
  canResetToOriginal,
  onReset,
  onPrevTableWord,
  onNextTableWord,
  onSetCustomText,
  onSelectSuggestion,
  onDeSelectSuggestion,
  disabled,
  isManuallyEdited,
  onResetManuallEditedText
}) => {
  const onPrev = () => {
    onPrevTableWord({ currentEntity, payload: prevEntity, currentText });
  };
  const onNext = () => {
    onNextTableWord({ currentEntity, payload: nextEntity, currentText });
  };

  return (
    <SmartSuggestion
      activeSection={activeSection}
      currentEntity={currentEntity}
      prevEntity={prevEntity}
      nextEntity={nextEntity}
      showLoader={showLoader}
      onPrevFn={onPrev}
      onNextFn={onNext}
      onResetFn={onReset}
      canResetToOriginal={canResetToOriginal}
      onSelectSuggestion={onSelectSuggestion}
      onDeselectSuggestion={onDeSelectSuggestion}
      onSetCustomText={onSetCustomText}
      isTableTextView
      currentTextId={currentText.id}
      disabled={disabled}
      isManuallyEdited={isManuallyEdited}
      onResetManuallEditedText={onResetManuallEditedText}
    />
  );
};

TableTextSmartSuggestion.propTypes = {
  activeSection: PropTypes.string.isRequired,
  showLoader: PropTypes.bool,
  currentEntity: PropTypes.object,
  prevEntity: PropTypes.object,
  nextEntity: PropTypes.object,
  currentText: PropTypes.object,
  canResetToOriginal: PropTypes.bool,
  onReset: PropTypes.func,
  onPrevTableWord: PropTypes.func,
  onNextTableWord: PropTypes.func,
  onSetCustomText: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  onDeSelectSuggestion: PropTypes.func,
  disabled: PropTypes.bool,
  isManuallyEdited: PropTypes.bool,
  onResetManuallEditedText: PropTypes.func
};

TableTextSmartSuggestion.defaultProps = {
  showLoader: false,
  currentEntity: {},
  prevEntity: {},
  nextEntity: {},
  currentText: {},
  canResetToOriginal: false,
  onReset: noop,
  onPrevTableWord: noop,
  onNextTableWord: noop,
  onSetCustomText: noop,
  onSelectSuggestion: noop,
  onDeSelectSuggestion: noop,
  disabled: false,
  isManuallyEdited: false,
  onResetManuallEditedText: noop
};

const mapStateToProps = (state) => ({
  currentEntity: getCurrentTableWord(state),
  activeSection: getActiveSection(state),
  showLoader: showLoaderForTableText(state),
  prevEntity: getPrevEntity(state),
  nextEntity: getNextEntity(state),
  currentText: getCurrentTableText(state),
  canResetToOriginal: canResetTableTextToOriginal(state)
});

export default connect(mapStateToProps, {
  onReset: resetTableTextToOriginal,
  onPrevTableWord,
  onNextTableWord,
  onSetCustomText: onTableSetCustomText,
  onSelectSuggestion: onTableSelectSuggestions,
  onDeSelectSuggestion: onTableDeselectSuggestions
})(TableTextSmartSuggestion);
