const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

const ORIGIN_SERVER = window.location.origin;
const REDIRECT_URI = `${ORIGIN_SERVER}${process.env.REACT_APP_OKTA_REDIRECT_URI}`;
const SCOPES = process.env.REACT_APP_OKTA_SCOPES || "";

export default {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  pkce: false,
  tokenManager: {
    // Auto renew accessToken before expiry,
    // We have also setup inside axios interceptor to use OktaAuth.getAccessToken which will auto renew before expiry
    autoRenew: true,
    expireEarlySeconds: 5 * 60 // 5 mins before expiry
  }
};
