import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import SummaryModal from "./SummaryModal";

import { getDownloadUrlAction, toggleSummaryModalAction } from "./actions";
import {
  getDocumentSummaryData,
  isFetchingDocumentSummary,
  isFetchingDownloadUrl,
  isSummaryModalShown
} from "./selectors";
import { documentSummaryType, presentationType } from "utils/customPropTypes";
import { isPreviewDocShownSelector } from "containers/Preview/selectors";
import { getPptDetails } from "containers/Sanitize/selectors";

const SummaryModalWrapper = ({
  isModalShown,
  isPreviewDocShown,
  toggleSummaryModal,
  documentSummary,
  isFetchingDocumentSummary,
  isFetchingDownloadUrl,
  getDownloadUrlAction,
  pptDetails
}) => {
  return isModalShown ? (
    <SummaryModal
      isPreviewDocShown={isPreviewDocShown}
      toggleSummaryModal={toggleSummaryModal}
      documentSummary={documentSummary}
      pptDetails={pptDetails}
      isFetchingDocumentSummary={isFetchingDocumentSummary}
      getDownloadUrlAction={getDownloadUrlAction}
      isFetchingDownloadUrl={isFetchingDownloadUrl}
    />
  ) : null;
};

SummaryModalWrapper.propTypes = {
  isModalShown: PropTypes.bool,
  isPreviewDocShown: PropTypes.bool,
  toggleSummaryModal: PropTypes.func,
  documentSummary: PropTypes.objectOf(documentSummaryType),
  isFetchingDocumentSummary: PropTypes.bool,
  isFetchingDownloadUrl: PropTypes.bool,
  getDownloadUrlAction: PropTypes.func,
  pptDetails: presentationType
};

SummaryModalWrapper.defaultProps = {
  isModalShown: false,
  isPreviewDocShown: false,
  toggleSummaryModal: noop,
  documentSummary: {},
  pptDetails: {},
  isFetchingDocumentSummary: false,
  isFetchingDownloadUrl: false,
  getDownloadUrlAction: noop
};

export default connect(
  (state) => ({
    isModalShown: isSummaryModalShown(state),
    documentSummary: getDocumentSummaryData(state),
    pptDetails: getPptDetails(state),
    isPreviewDocShown: isPreviewDocShownSelector(state),
    isFetchingDocumentSummary: isFetchingDocumentSummary(state),
    isFetchingDownloadUrl: isFetchingDownloadUrl(state)
  }),
  { toggleSummaryModal: toggleSummaryModalAction, getDownloadUrlAction }
)(SummaryModalWrapper);
