import React from "react";
import { Divider, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { GithubPicker } from "react-color";
import CONFIG from "configs/config";
import { noop } from "lodash";

const TextSubMenu = ({
  boldText,
  italicText,
  underLineText,
  applyTextColor,
  boldCheckbox,
  italicCheckbox,
  underlineCheckbox,
  addText,
  addRectOnTextSection,
  addCircleOnTextSection
}) => {
  return (
    <div className="image-manual-editor__text">
      <p>
        <b>{CONFIG.LABELS.IMAGE_MANUAL_EDITOR.DRAW_SHAPE}</b>
      </p>
      <div onClick={addRectOnTextSection} className="patch-with-icon">
        <div className="shape-switch">
          <div className="shape-switch--rect">
            <span className="rect-icon" />
          </div>
          <div
            className="shape-switch--circle"
            onClick={addCircleOnTextSection}
          >
            <span className="circle-icon" />
          </div>
        </div>
      </div>
      <Divider />

      <div className="add-text" onClick={addText}>
        <Icon name="font" />
        <span>{CONFIG.LABELS.IMAGE_MANUAL_EDITOR.ADD_TEXT}</span>
      </div>

      <div className="text-item">
        <span
          className={`pointer text-item-span text-item-bold ${
            boldCheckbox ? "text-item-span--selected" : ""
          }`}
          onClick={boldText}
        >
          <Icon name="bold" />
        </span>
        <span
          className={`pointer text-item-span text-item-italic ${
            italicCheckbox ? "text-item-span--selected" : ""
          }`}
          onClick={italicText}
        >
          <Icon name="italic" />
        </span>
        <span
          className={`pointer text-item-span text-item-underline ${
            underlineCheckbox ? "text-item-span--selected" : ""
          }`}
          onClick={underLineText}
        >
          <Icon name="underline" />
        </span>
      </div>
      <div>
        <GithubPicker
          width={"150px"}
          colors={CONFIG.CONSTANTS.IMAGE_EDITOR.COLOR_CODES}
          triangle="hide"
          className="color-picker"
          onChange={(color) => applyTextColor(color.hex)}
        />
      </div>
    </div>
  );
};

TextSubMenu.propTypes = {
  boldText: PropTypes.func.isRequired,
  italicText: PropTypes.func.isRequired,
  underLineText: PropTypes.func.isRequired,
  applyTextColor: PropTypes.func.isRequired,
  boldCheckbox: PropTypes.bool.isRequired,
  italicCheckbox: PropTypes.bool.isRequired,
  underlineCheckbox: PropTypes.bool.isRequired,
  addText: PropTypes.func.isRequired,
  addRectOnTextSection: PropTypes.func,
  addCircleOnTextSection: PropTypes.func
};

TextSubMenu.defaultProps = {
  addRectOnTextSection: noop,
  addCircleOnTextSection: noop
};

export default TextSubMenu;
