import React from "react";
import PropTypes from "prop-types";
import { Button, Image } from "semantic-ui-react";
import { isEmpty, noop } from "lodash";
import "./SmartSuggestion.scss";

// import SuggestionHeader from "components/SuggestionHeader/SuggestionHeader";
import SuggestionFooter from "components/SuggestionFooter/SuggestionFooter";
import TextSuggestionContainer from "containers/TextSuggestionContainer/TextSuggestionContainer";
import NoResult from "components/NoResult/NoResult";
import ImageSmartSuggestion from "containers/ImageSmartSuggestion/ImageSmartSuggestion";

import CONFIG from "configs/config";
import { textListType } from "utils/customPropTypes";

// Images
import bulbGray from "assets/images/lightbulb-solid.svg";
import bulbGreen from "assets/images/lightbulb-solid-green.svg";
import nextArrow from "assets/images/next-arrow-white.svg";
import prevArrow from "assets/images/prev-arrow-white.svg";
import loadingIconWhite from "assets/images/uploading-ppt-icon-white.svg";

/**
 * SANITIZE SCREEN
 * Smart suggestions for text/image/graph/table
 * @returns JSX
 */
const SmartSuggestion = ({
  activeSection,
  currentEntity,
  prevEntity,
  nextEntity,
  showLoader,
  canResetToOriginal,
  onPrevFn,
  onNextFn,
  onResetFn,
  isTableTextView,
  onSetCustomText,
  onSelectSuggestion,
  onDeselectSuggestion,
  currentTextId,
  disabled,
  isManuallyEdited,
  onResetManuallEditedText,
  isResetting,
  isFilterIdentified
}) => {
  const isTextView =
    activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT || isTableTextView;

  const noResult = isEmpty(currentEntity) && !showLoader && !isManuallyEdited;

  const noFilteredResult =
    isFilterIdentified &&
    isEmpty(currentEntity) &&
    !showLoader &&
    !isManuallyEdited;
  const showGrayBulb = isEmpty(currentEntity) || showLoader || isManuallyEdited;

  const canPrev = !isEmpty(prevEntity) && !showLoader;
  const canNext = !isEmpty(nextEntity) && !showLoader;

  return (
    <section className="editor-section__suggestion">
      <div className="header-stripe suggestion-header">
        <Button
          primary
          disabled={!canPrev}
          className="suggestion-header__arrow"
          onClick={() => {
            if (canPrev) {
              onPrevFn();
            }
          }}
        >
          <Image src={prevArrow} alt="Previous word" verticalAlign="middle" />
        </Button>
        <div>
          <Image
            className="mr-2"
            src={showGrayBulb ? bulbGray : bulbGreen}
            alt="Smart Suggestions"
            verticalAlign="top"
            width="12px"
            height="16px"
          />
          <span>Smart Suggestions</span>
        </div>
        <Button
          primary
          disabled={!canNext}
          className="suggestion-header__arrow"
          onClick={() => {
            if (canNext) {
              onNextFn();
            }
          }}
        >
          <Image src={nextArrow} alt="Next word" verticalAlign="middle" />
        </Button>
      </div>
      {noResult || noFilteredResult ? (
        isTextView ? (
          <NoResult label="confidential information" />
        ) : (
          <div className="main-block smart-suggestion__container" />
        )
      ) : (
        <div className="main-block smart-suggestion__container">
          {disabled && <div className="full-width-overlay-white" />}
          {isResetting && (
            <div className="overlay-loader">
              <Image src={loadingIconWhite} alt="" />
            </div>
          )}
          {!activeSection || isTextView ? (
            <TextSuggestionContainer
              activeWord={currentEntity}
              showLoader={showLoader}
              currentTextId={currentTextId}
              onSelectSuggestion={onSelectSuggestion}
              onDeselectSuggestion={onDeselectSuggestion}
              onSetCustomText={onSetCustomText}
              isManuallyEdited={isManuallyEdited}
              onResetManuallEditedText={onResetManuallEditedText}
              isResetting={isResetting}
            />
          ) : (
            <ImageSmartSuggestion
              activeSection={activeSection}
              currentEntity={currentEntity}
              isLoading={showLoader}
              isManuallyEdited={isManuallyEdited}
              isResetting={isResetting}
            />
          )}
          {!(isManuallyEdited || isResetting) && (
            <SuggestionFooter
              currentEntity={currentEntity}
              resetToOriginal={onResetFn}
              showLoader={showLoader}
              canReset={canResetToOriginal}
              textId={currentTextId}
            />
          )}
        </div>
      )}
    </section>
  );
};

SmartSuggestion.propTypes = {
  activeSection: PropTypes.string.isRequired,
  currentEntity: PropTypes.oneOfType([PropTypes.object, textListType]),
  showLoader: PropTypes.bool,
  canResetToOriginal: PropTypes.bool,
  prevEntity: PropTypes.object,
  nextEntity: PropTypes.object,
  onPrevFn: PropTypes.func,
  onNextFn: PropTypes.func,
  onResetFn: PropTypes.func,
  isTableTextView: PropTypes.bool,
  onSetCustomText: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  onDeselectSuggestion: PropTypes.func,
  currentTextId: PropTypes.string,
  disabled: PropTypes.bool,
  isManuallyEdited: PropTypes.bool,
  onResetManuallEditedText: PropTypes.func,
  isResetting: PropTypes.bool,
  isFilterIdentified: PropTypes.bool
};

SmartSuggestion.defaultProps = {
  currentEntity: {},
  showLoader: false,
  canResetToOriginal: false,
  prevEntity: {},
  nextEntity: {},
  onPrevFn: noop,
  onNextFn: noop,
  onResetFn: noop,
  isTableTextView: false,
  onSetCustomText: noop,
  onSelectSuggestion: noop,
  onDeselectSuggestion: noop,
  currentTextId: "",
  disabled: false,
  isManuallyEdited: false,
  onResetManuallEditedText: noop,
  isRestting: false,
  isFilterIdentified: false
};

export default SmartSuggestion;
