import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Image, Popup } from "semantic-ui-react";
import "./User.scss";

import { getUserDetails } from "./selectors";

import defaultAvatar from "assets/images/default-avatar.svg";
import { AALinkName } from "analytics/AnalyticsCapture";

/**
 * COMMON USER COMPONENT SHOWN IN HEADER
 * @returns JSX
 */
const UserProfileImage = ({ userDetails: { hrId, name, profileImage } }) => {
  const url = `${process.env.REACT_APP_LINK_PROFILE_URL}/overview?hrid=${hrId}&source=TAMS`;

  const goToLinkProfile = () => {
    AALinkName("Profile_redirect", "Link_click");
    window.open(url);
  };

  return (
    <Popup
      key={hrId}
      content={name}
      disabled={!hrId}
      data-testid="user-image-popup"
      trigger={
        <Image
          className="user-profile-image"
          circular
          src={profileImage || defaultAvatar}
          alt=""
          verticalAlign="middle"
          data-testid="user-image"
          onClick={goToLinkProfile}
        />
      }
    />
  );
};

UserProfileImage.propTypes = {
  userDetails: PropTypes.shape({
    hrId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    profileImage: PropTypes.string,
    name: PropTypes.string,
    staffId: PropTypes.number,
    email: PropTypes.string
  })
};

UserProfileImage.defaultProps = {
  userDetails: PropTypes.shape({
    hrId: 0,
    profileImage: "",
    name: "",
    staffId: 0,
    email: ""
  })
};

export default connect((state) => ({
  userDetails: getUserDetails(state)
}))(UserProfileImage);
