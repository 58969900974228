import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Image as ImageSemantic } from "semantic-ui-react";
import { noop, times, toLower } from "lodash";
import CONFIG from "configs/config";
import { imageListType } from "utils/customPropTypes";

import {
  applySmartEffectOnImage,
  getBlurRelativeValue,
  getCFImageSrc,
  getCFImageSrcWithoutCache
} from "utils/helpers";
import * as StackBlur from "stackblur-canvas";

import logoPlaceholder from "assets/images/Placeholders/logo-placeholder.svg";
import portraitPlaceholder from "assets/images/Placeholders/portrait-placeholder.svg";
import stockPlaceholder from "assets/images/Placeholders/stock-placeholder.svg";
import loadingIconWhite from "assets/images/uploading-ppt-icon-white.svg";

// Render svg placeholder images based on the image type
export const getSvgPlaceholder = (type) => {
  switch (type) {
    case CONFIG.CONSTANTS.DERIVED_IMAGE_TYPE.PORTRAIT:
      return portraitPlaceholder;

    case CONFIG.CONSTANTS.DERIVED_IMAGE_TYPE.LOGO:
      return logoPlaceholder;

    default:
      return stockPlaceholder;
  }
};

// Render Preview Image/Canvas if any smart option (blur or stamp) is applied
const RenderImage = ({
  image,
  dimension,
  isFromSlider,
  setStampDataUris,
  setDataUri,
  signedToken,
  updateLoadedImageList
}) => {
  // Whenever blur or stamp effect is applied show overlay loader
  const [applyingEffect, setApplyingEffect] = useState(false);
  const canApplyEffect = applySmartEffectOnImage(image);
  const isStamp =
    image.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP;
  const isBlur = image.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.BLUR;
  const isPatch =
    image.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH;
  const isManualEdit =
    image.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.MANUALEDIT;
  const originalImageUrl = getCFImageSrc(
    image.backUpOriginalImageUrl,
    signedToken
  );
  const modifiedImageUrl = getCFImageSrc(
    image.modifiedImageUrl,
    signedToken,
    image.savedSmartOption
      ? `operationtype=${toLower(image.savedSmartOption)}`
      : "",
    isManualEdit
  );
  // To solve the inconsistency blur issue, we apply multiple time if the resolution of the image is higher
  const [radius, noOfTimeToApply] = getBlurRelativeValue(dimension);
  const hasEffectApplied = canApplyEffect && (isStamp || isBlur);
  const [showCanvas, setShowCanvas] = useState(hasEffectApplied);

  useEffect(() => {
    if (hasEffectApplied) {
      setShowCanvas(true);
      updateLoadedImageList(image.id, true);
      // Get the canvas to drawImage and blur/stamp
      const canvas = document.getElementById(`edited-${image.id}`);
      const img = new Image();
      img.crossOrigin = "anonymous";
      // On image load start drawing the original image into canvas
      img.onload = function load() {
        canvas
          .getContext("2d")
          .drawImage(img, 0, 0, dimension.width, dimension.height);
        if (isBlur) {
          // Apply blur using StackBlur * noOfTimeToApply
          times(noOfTimeToApply).forEach(() => {
            StackBlur.canvasRGBA(
              canvas,
              0,
              0,
              dimension.width,
              dimension.height,
              radius
            );
          });
        }
        // Extract the dataURI from canvas and save it in the store
        const imageUri = canvas.toDataURL("image/png");
        setStampDataUris((prevState) => ({
          ...prevState,
          [image.id]: imageUri
        }));
        if (!isFromSlider) {
          setDataUri(imageUri);
        }
        setApplyingEffect(false);
        updateLoadedImageList(image.id, false);
      };
      if (isBlur) {
        img.src = getCFImageSrcWithoutCache(
          image.originalImageUrl,
          signedToken
        );
        if (noOfTimeToApply >= 3) {
          setApplyingEffect(true);
        }
      } else {
        // If stamp get the default placeholder,
        img.src = getSvgPlaceholder(image.imageType);
      }
    } else {
      setShowCanvas(false);
    }
  }, [image.smartOption, image.savedSmartOption]);

  const previewUrl =
    isPatch && image.redactedImageUrl
      ? getCFImageSrc(image.redactedImageUrl, signedToken)
      : modifiedImageUrl
      ? modifiedImageUrl
      : originalImageUrl;

  const showCanvasElement = showCanvas && !applyingEffect;

  if (!dimension.width || !dimension.height) {
    return null;
  }

  return (
    <>
      <canvas
        width={dimension.width}
        height={dimension.height}
        id={`edited-${image.id}`}
        className={`${
          isFromSlider ? "slider-image-preview" : "image-preview"
        } ${!showCanvasElement ? "d-none" : ""}`}
      />
      {!showCanvasElement && (
        <img
          key={image.savedSmartOption + image.id}
          className={`${
            isFromSlider ? "slider-image-preview" : "image-preview"
          }`}
          src={previewUrl}
          alt=""
        />
      )}
      {applyingEffect && (
        <div className="overlay-loader">
          <ImageSemantic src={loadingIconWhite} alt="" />
        </div>
      )}
    </>
  );
};

RenderImage.propTypes = {
  image: imageListType.isRequired,
  dimension: PropTypes.object,
  isFromSlider: PropTypes.bool,
  setStampDataUris: PropTypes.func,
  setDataUri: PropTypes.func,
  signedToken: PropTypes.object,
  updateLoadedImageList: PropTypes.func
};

RenderImage.defaultProps = {
  dimension: {},
  setStampDataUris: noop,
  setDataUri: noop,
  signedToken: {},
  updateLoadedImageList: noop
};

export default RenderImage;
