import { createAction as ca } from "utils/action";
import * as at from "../types";

export const resetTextToOriginal = ca(at.RESET_TEXT_TO_ORIGINAL);
export const onPrevWord = ca(at.GO_TO_PREVIOUS_WORD);
export const onNextWord = ca(at.GO_TO_NEXT_WORD);

export const resetImageToOriginal = ca(at.RESET_IMAGE_TO_ORIGINAL);
export const onPrevImage = ca(at.GO_TO_PREVIOUS_IMAGE);
export const onNextImage = ca(at.GO_TO_NEXT_IMAGE);

export const resetTableToOriginal = ca(at.RESET_TABLE_TO_ORIGINAL);
export const onPrevTable = ca(at.GO_TO_PREVIOUS_TABLE);
export const onNextTable = ca(at.GO_TO_NEXT_TABLE);

export const resetTableTextToOriginal = ca(at.RESET_TABLE_TEXT_TO_ORIGINAL);
export const onPrevTableWord = ca(at.GO_TO_PREVIOUS_TABLE_WORD);
export const onNextTableWord = ca(at.GO_TO_NEXT_TABLE_WORD);

export const goToPrevSection = ca(at.GO_TO_PREV_SECTION);
export const goToNextSection = ca(at.GO_TO_NEXT_SECTION);

export const setUpdatedSmartSuggestionValues = ca(
  at.SET_UPDATED_SUGGESTION_VALUES
);
