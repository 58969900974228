import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/table";
import * as at from "../types";

export const rsGetTableListBySlide = resourceSaga(
  at.TABLE_FETCH_TABLELIST,
  api.getTablesBySlide
);

export const rsRefreshTableTextList = resourceSaga(
  at.TABLE_REFRESH_TABLELIST,
  api.getTablesBySlide
);

export const rsGetTableSuggestions = resourceSaga(
  at.TABLE_FETCH_TABLE_SUGGESTIONS,
  api.getTableSuggestions
);

export const rsSaveUpdatedTables = resourceSaga(
  at.TABLE_SAVE_UPDATED_TABLE,
  api.saveUpdatedTables
);
