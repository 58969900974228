import { createSelector } from "reselect";
import { filter, find } from "lodash";
import { configureLoadingState } from "containers/Common/reducers/loading";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import {
  TABLE_ME_FETCH_LIST,
  TABLE_ME_FETCH_SUGGESTIONS,
  TABLE_ME_SAVE_TEXT,
  TABLE_REFRESH_TABLE_THUMBNAILS,
  TABLE_RESET_MANUALLY_EDITED_TABLE
} from "../types";
import {
  getGroupedOriginalParaByGroupId,
  getGroupedParaByGroupId,
  getNextWordOrText,
  getPrevWordOrText,
  getSanitizedWord
} from "utils/helpers";

configureLoadingState([
  TABLE_ME_FETCH_LIST,
  TABLE_ME_FETCH_SUGGESTIONS,
  TABLE_ME_SAVE_TEXT,
  TABLE_REFRESH_TABLE_THUMBNAILS,
  TABLE_RESET_MANUALLY_EDITED_TABLE
]);

export const showLoaderForTableText = createLoadingSelector([
  TABLE_ME_FETCH_LIST,
  TABLE_ME_FETCH_SUGGESTIONS
]);

export const refreshingTableThumbnails = createLoadingSelector([
  TABLE_ME_SAVE_TEXT,
  TABLE_REFRESH_TABLE_THUMBNAILS
]);

export const resetingTableManualChanges = createLoadingSelector([
  TABLE_RESET_MANUALLY_EDITED_TABLE
]);

export const tableTextState = (state) => state.tableText;

export const isTableManualEditShown = createSelector(
  tableTextState,
  (tableData) => tableData.showTableManualEditor
);

export const getTableTextList = createSelector(
  tableTextState,
  (ts) => ts.tableTextList
);

export const getDirtyTableTextList = createSelector(tableTextState, (ts) =>
  filter(ts.tableTextList, (text) => text.dirty)
);

export const getColumnsList = createSelector(
  tableTextState,
  (ts) => ts.columnsList
);

export const getCurrentColumn = createSelector(
  tableTextState,
  (ts) => ts.currentColumn
);

export const getTableTextListByColumn = createSelector(
  [getTableTextList, getCurrentColumn],
  (allList, columnNo) => {
    return allList.filter((text) => text.columnNo === columnNo);
  }
);

export const getTableTextSuggestions = createSelector(
  tableTextState,
  (ts) => ts.suggestionEntities.data
);

export const getCurrentTableTextId = createSelector(
  tableTextState,
  (ts) => ts.currentTableTextId
);

export const getCurrentTableText = createSelector(
  [getTableTextList, getCurrentTableTextId],
  (textList, currentTextId) => find(textList, { id: currentTextId }) || {}
);

export const getCurrentTableWord = createSelector(
  tableTextState,
  (ts) => ts.currentTableWord || {}
);

export const getPrevEntity = createSelector(
  [getTableTextList, getCurrentTableText, getCurrentTableWord],
  (textList, currentText, currentWord) =>
    getPrevWordOrText(textList, currentText, currentWord)
);

export const getNextEntity = createSelector(
  [getTableTextList, getCurrentTableText, getCurrentTableWord],
  (textList, currentText, currentWord) =>
    getNextWordOrText(textList, currentText, currentWord)
);

export const canResetTableTextToOriginal = createSelector(
  [(state) => state.smartSuggestions, getCurrentTableWord],
  (suggestion, currentWord) => {
    const hasValueSelected = getSanitizedWord(suggestion, currentWord);
    return hasValueSelected && !hasValueSelected.hasReset;
  }
);

export const getGroupedTableParaDetails = createSelector(
  getCurrentTableText,
  (ct) => ({
    para: getGroupedParaByGroupId(ct),
    originalPara: getGroupedOriginalParaByGroupId(ct)
  })
);
