import { call, takeLatest, put, select } from "redux-saga/effects";
import {
  setCurrentTextBeingEdited,
  setCurrentWordBeingEdited,
  triggerSaveSanitizedPara
} from "containers/TextListView/actions";
import { handleCatchedError } from "utils/sagaUtils";
import * as at from "../types";
import { setActiveSection, setCurrentSlide } from "containers/Sanitize/actions";
import { getSlidesList } from "containers/Sanitize/selectors";
import { isUndefined } from "lodash";

function* watchSuggestionSaga() {
  /**
   * Listener saga of GO TO (for text), and based on the payload (if next/prev is word or text), select next word/text
   */
  yield takeLatest(
    [at.GO_TO_PREVIOUS_WORD, at.GO_TO_NEXT_WORD],
    function* goTo({ type, payload: { payload, currentText } }) {
      try {
        if (payload) {
          // if payload is word, go to word, else move to next para and select word based on the type of the action
          if (payload.entityMappingId) {
            yield put(
              setCurrentWordBeingEdited({ ...payload, isArrowClick: true })
            );
          } else {
            // first word of the next text or last word of the prev text
            const selectWordAtIndex = type === at.GO_TO_NEXT_WORD ? 0 : -1;
            yield put(triggerSaveSanitizedPara(currentText));
            yield put(
              setCurrentTextBeingEdited({
                ...payload,
                selectWordAtIndex,
                isArrowClick: true
              })
            );
          }
        }
      } catch (e) {
        yield call(handleCatchedError, e);
      }
    }
  );

  /**
   * Listener saga for GO TO section.
   * for eg: When user at the last element of text
   * on click of next arrow move to images section (if available, else graph if available, else table if available, else next slide)
   */
  yield takeLatest(
    [at.GO_TO_NEXT_SECTION, at.GO_TO_PREV_SECTION],
    function* goToSection({ payload: { entity, activeSection } }) {
      if (!isUndefined(entity.slide)) {
        const allSlides = yield select(getSlidesList);
        // as we are relaying on the beforeChange call for triggering changeSlide action,
        // passing down the section which needs to be highlighted down with slide data
        yield put(
          setCurrentSlide({
            ...allSlides[entity.slide],
            activeSectionToSet: entity.section // setting the active setion to open when changing slide, same is used on slidesView.js
          })
        );
      } else if (entity.section) {
        yield put(
          setActiveSection({
            current: activeSection,
            next: entity.section
          })
        );
      }
    }
  );
}

export default function* suggestionSaga() {
  yield call(watchSuggestionSaga);
}
