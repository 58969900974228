import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Image } from "semantic-ui-react";
// import CONFIG from "configs/config";
import "./UserGuideHeader.scss";

import CloseIcon from "assets/images/close-green-large.svg";
import brandLogo from "assets/images/bcg-logo-white.svg";

/**
 * USER GUIDE SCREEN
 * Header of user guide screen
 * @returns JSX
 */
const UserGuideHeader = ({ history }) => {
  // const TRIGGERS = CONFIG.CONSTANTS.USER_GUIDE_TRIGGERS;
  // const DISPLAY = CONFIG.CONSTANTS.USER_GUIDE_DISPLAY_TEXT;
  // const options = [
  //   {
  //     key: TRIGGERS.HOME,
  //     text: DISPLAY[TRIGGERS.HOME],
  //     value: TRIGGERS.HOME
  //   },
  //   {
  //     key: TRIGGERS.SANITIZE,
  //     text: DISPLAY[TRIGGERS.SANITIZE],
  //     value: TRIGGERS.SANITIZE
  //   }
  // ];
  return (
    <div className="user-guide-header">
      <div className="user-guide-header__logo-container">
        <div className="user-guide-header__logo">
          <Image src={brandLogo} alt="BCG" verticalAlign="middle" />
        </div>
        <div className="user-guide-header__brand-text">TAMS User Guide</div>
      </div>
      <div className="user-guide-header__close-icon">
        <div>
          {/* <span className="mr-2">Show me guide by</span>
          <Dropdown
            inline
            options={options}
            direction="left"
            onChange={(event, item) => setSelectedMenu(item.value)}
            value={selectedMenu}
          /> */}
        </div>
        <Image src={CloseIcon} alt="close" onClick={() => history.goBack()} />
      </div>
    </div>
  );
};

UserGuideHeader.propTypes = {
  history: PropTypes.object.isRequired
};

UserGuideHeader.defaultProps = {
  selectedMenu: "home",
  setSelectedMenu: noop
};

export default UserGuideHeader;
