import React from "react";
import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";
import { isUndefined, noop } from "lodash";

import CONFIG from "configs/config";

const RenderSlideStatus = ({ slide, markIncomplete }) => {
  if (isUndefined(slide.sanitizationProgress)) {
    return null;
  }
  switch (slide.sanitizationProgress) {
    case 3:
      return (
        <Popup
          content="Click to reset the status"
          trigger={
            <span onClick={() => markIncomplete(false)}>
              <Icon name="times circle outline" />
              {CONFIG.CONSTANTS.RESET_SLIDE_STATUS}
            </span>
          }
        />
      );
    case 2:
      return null;
    default:
      return (
        <Popup
          content={"Click to manually mark this slide as sanitized"}
          trigger={
            <span onClick={() => markIncomplete(true)}>
              <Icon name="check circle outline" />
              {CONFIG.CONSTANTS.MARK_SLIDE_AS_SANITIZED}
            </span>
          }
        />
      );
  }
};

RenderSlideStatus.propTypes = {
  slide: PropTypes.object,
  markIncomplete: PropTypes.func
};

RenderSlideStatus.defaultProps = {
  slide: {},
  markIncomplete: noop
};

export default RenderSlideStatus;
