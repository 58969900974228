import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import SimpleBar from "simplebar-react";
import { get, groupBy, isEmpty, noop } from "lodash";
import "./TextPreview.scss";
import CONFIG from "configs/config";

import WithCustomTooltip from "components/WithCustomTooltip/WithCustomTooltip";
import ParagraphPlaceholder from "components/ParagraphPlaceholder";
import TextInlineEditPreview from "components/TextInlineEditPreview/TextInlineEditPreview";

import { getDisplayText } from "utils/helpers";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

/**
 * SANITIZE SCREEN
 * Show original text / Sanitized Text in preview section
 * @returns JSX
 */
const TextPreview = ({
  isFromTableEdit,
  currentText,
  currentWord,
  activeSection,
  isLoading,
  currentGroup,
  setInlineEditingGroup,
  groupedPara,
  saveManuallySanitizedPara,
  onExitManualEdit
}) => {
  const { portions, modifiedPara } = currentText;

  const modifiedPortions = get(
    groupBy(portions, "groupId"),
    currentGroup.id,
    []
  );

  return (
    <section className="editor-section__editor">
      <div className="header-stripe editor-header">
        <span className="editor-header__title">
          {`Preview Sanitized ${
            !isFromTableEdit ? activeSection : "Table Cell"
          }`}
        </span>
        {!isEmpty(currentText) &&
          !isLoading &&
          (!isFromTableEdit ? (
            <WithCustomTooltip
              className="text-align-right"
              id="manual-edit-tooltip"
              message={CONFIG.LABELS.TOOLTIP_MESSAGE_MANUAL_EDIT(activeSection)}
            >
              <a
                data-testid="manual-edit-anchor"
                className="editor-header__manual-edit"
                onClick={() => {
                  setInlineEditingGroup(currentGroup.id);
                  onAAClickEvents("Manual_Text_Edit");
                }}
              >
                <Icon name="edit" />
                {CONFIG.LABELS.MANUAL_EDIT}
              </a>
            </WithCustomTooltip>
          ) : (
            <Button
              primary
              onClick={onExitManualEdit}
              className="ml-2 py-2"
              disabled={isLoading}
              size="mini"
            >
              {CONFIG.LABELS.EXIT_TABLE_MANUAL_EDIT}
            </Button>
          ))}
      </div>{" "}
      <div className="main-block editor-container text-preview-container">
        {isLoading ? (
          <div className="text-preview-container__loader">
            <ParagraphPlaceholder numberOfLines={5} />
          </div>
        ) : (
          <SimpleBar
            className="text-preview-container__preview"
            scrollbarMaxSize={50}
            timeout={1500}
          >
            {modifiedPortions?.length ? (
              <TextInlineEditPreview
                portions={modifiedPortions}
                showModifiedText
                currentText={currentText}
                currentWordIds={currentWord.entityMappingId}
                showHighlights
                currentGroup={currentGroup}
                setInlineEditingGroup={setInlineEditingGroup}
                groupedPara={groupedPara}
                saveManuallySanitizedPara={saveManuallySanitizedPara}
              />
            ) : (
              getDisplayText(modifiedPara)
            )}
          </SimpleBar>
        )}
      </div>
    </section>
  );
};
TextPreview.propTypes = {
  isFromTableEdit: PropTypes.bool,
  currentText: PropTypes.object,
  currentWord: PropTypes.object,
  isLoading: PropTypes.bool,
  activeSection: PropTypes.string,
  currentGroup: PropTypes.object,
  setInlineEditingGroup: PropTypes.func,
  groupedPara: PropTypes.object,
  saveManuallySanitizedPara: PropTypes.func,
  onExitManualEdit: PropTypes.func
};

TextPreview.defaultProps = {
  isFromTableEdit: false,
  currentText: {},
  currentWord: {},
  activeSection: "",
  isLoading: false,
  currentGroup: {},
  setInlineEditingGroup: noop,
  groupedPara: {},
  saveManuallySanitizedPara: noop,
  onExitManualEdit: noop
};

export default TextPreview;
