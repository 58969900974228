import React from "react";
import PropTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";
import { indexOf, noop } from "lodash";
import CONFIG from "configs/config";
import "./TableManualEditHeader.scss";

// TODO: Move labels to config, once the design is finalized
const TableManualEditHeader = ({
  showLoader,
  currentText,
  currentColumn,
  columnsList,
  onPrevColumn,
  onNextColumn,
  onCloseEditor
}) => {
  const idx = indexOf(columnsList, currentColumn);

  const canPrev = idx > 0;
  const canNext = idx < columnsList.length - 1;

  if (showLoader) {
    return <div className="header-stripe table-me-header" />;
  }
  return (
    <div className="header-stripe table-me-header">
      <Popup
        content={CONFIG.LABELS.TABLE_ME.GO_BACK}
        trigger={
          <div
            className="table-me-header__go-back mx-3"
            onClick={onCloseEditor}
          >
            Table
          </div>
        }
      />
      <span>/</span>
      <div className="table-me-header__column-header pl-3">
        <div className="table-me-header__column-title">
          <span>{`Column #${idx + 1}`}</span>
        </div>
        <div className="table-me-header__column-arrows">
          <Popup
            content={CONFIG.LABELS.TABLE_ME.PREV_COL}
            position="top right"
            trigger={
              <div
                className="table-me-header__prev-col"
                onClick={() => {
                  if (canPrev) {
                    onPrevColumn({ currentText, currentColumn });
                  }
                }}
              >
                <Icon name="chevron left" disabled={!canPrev} />
              </div>
            }
          />
          <Popup
            content={CONFIG.LABELS.TABLE_ME.NEXT_COL}
            position="top left"
            trigger={
              <div
                className="table-me-header__next-col"
                onClick={() => {
                  if (canNext) {
                    onNextColumn({ currentText, currentColumn });
                  }
                }}
              >
                <Icon name="chevron right" disabled={!canNext} />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

TableManualEditHeader.propTypes = {
  showLoader: PropTypes.bool,
  currentText: PropTypes.object,
  currentColumn: PropTypes.number,
  columnsList: PropTypes.array,
  onPrevColumn: PropTypes.func,
  onNextColumn: PropTypes.func,
  onCloseEditor: PropTypes.func
};

TableManualEditHeader.defaultProps = {
  showLoader: false,
  currentText: {},
  currentColumn: 0,
  columnsList: [],
  onPrevColumn: noop,
  onNextColumn: noop,
  onCloseEditor: noop
};

export default TableManualEditHeader;
