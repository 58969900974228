import { combineReducers } from "redux";
import { resourceReducer } from "redux/sagas/networkResource";
import * as at from "../types";

function showSummaryModal(state = false, { type }) {
  switch (type) {
    case at.TOGGLE_SUMMARY_MODAL:
      return !state;
    default:
      return state;
  }
}

function downloadUrl(state = {}, { type, payload }) {
  switch (type) {
    case at.GET_DOWNLOAD_URL_SUCCEEDED:
      return payload.data;
    case at.GET_DOWNLOAD_URL_REQUESTED:
    case at.CLEAN_DOWNLOAD_URL:
    case at.TOGGLE_SUMMARY_MODAL:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  showSummaryModal,
  data: resourceReducer(at.GET_DOCUMENT_SUMMARY, { init: {}, reset: true }),
  downloadUrl
});
