import React from "react";
import PropTypes from "prop-types";

const NoResult = ({ label }) => {
  return (
    <div className="main-block no-result">{`${
      label ? `No ${label} found` : ""
    }`}</div>
  );
};

NoResult.propTypes = {
  label: PropTypes.string
};

NoResult.defaultProps = {
  label: "result"
};

export default NoResult;
