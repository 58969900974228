import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import "./SmartSuggestion.scss";

import SmartSuggestion from "./SmartSuggestion";

// Actions and selectors
import {
  resetTextToOriginal,
  onPrevWord,
  onNextWord,
  resetImageToOriginal,
  onPrevImage,
  onNextImage,
  resetTableToOriginal,
  onPrevTable,
  onNextTable,
  goToPrevSection,
  goToNextSection
} from "./actions";
import {
  setCustomReplacementText,
  selectSmartSuggestion,
  deselectSmartSuggestion
} from "containers/TextListView/actions";
import { getActiveSection } from "containers/Sanitize/selectors";
import { getCurrentText } from "containers/TextListView/selectors";
import {
  isSmartSuggestionIsLoading,
  getCurrentEntity,
  getPrevEntity,
  getNextEntity,
  canResetToOriginal,
  getSelectedSmartOption,
  isCurrentEntityManuallyEdited,
  isResettingManualChanges
} from "./selectors";
import CONFIG from "configs/config";
import { textListType } from "utils/customPropTypes";

/**
 * SANITIZE SCREEN
 * Smart suggestions for text/image/graph/table
 * @returns JSX
 */
const SmartSuggestionWrapper = ({
  activeSection,
  currentEntity,
  prevEntity,
  nextEntity,
  showLoader,
  resetTextToOriginal,
  resetImageToOriginal,
  resetTableToOriginal,
  onNextWord,
  onPrevWord,
  onPrevImage,
  onNextImage,
  onPrevTable,
  onNextTable,
  currentText,
  selectedSmartOption,
  canResetToOriginal,
  goToPrevSection,
  goToNextSection,
  onSetCustomText,
  onSelectSuggestion,
  onDeSelectSuggestion,
  disabled,
  isManuallyEdited,
  onResetManuallEditedText,
  isResetting,
  isFilterIdentified
}) => {
  const isTextView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
  const isImageView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES;
  const isGraphView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS;
  const isTableView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;

  const onPrev = () => {
    if (prevEntity?.slide || prevEntity?.section) {
      goToPrevSection({ entity: prevEntity, activeSection });
    } else {
      if (isTextView) {
        onPrevWord({ payload: prevEntity, currentText });
      } else if (isImageView || isGraphView) {
        onPrevImage({ entity: prevEntity, currentEntity, selectedSmartOption });
      } else if (isTableView) {
        onPrevTable({ entity: prevEntity, currentEntity, selectedSmartOption });
      }
    }
  };
  const onNext = () => {
    if (nextEntity?.slide || nextEntity?.section) {
      goToNextSection({ entity: nextEntity, activeSection });
    } else {
      if (isTextView) {
        onNextWord({ payload: nextEntity, currentText });
      } else if (isImageView || isGraphView) {
        onNextImage({ entity: nextEntity, currentEntity, selectedSmartOption });
      } else if (isTableView) {
        onNextTable({ entity: nextEntity, currentEntity, selectedSmartOption });
      }
    }
  };

  const onResetToOriginal = isTextView
    ? resetTextToOriginal
    : isTableView
    ? resetTableToOriginal
    : resetImageToOriginal;

  return (
    <SmartSuggestion
      activeSection={activeSection}
      currentEntity={currentEntity}
      prevEntity={prevEntity}
      nextEntity={nextEntity}
      showLoader={showLoader}
      onPrevFn={onPrev}
      onNextFn={onNext}
      onResetFn={onResetToOriginal}
      canResetToOriginal={canResetToOriginal}
      onSelectSuggestion={onSelectSuggestion}
      onDeselectSuggestion={onDeSelectSuggestion}
      onSetCustomText={onSetCustomText}
      currentTextId={currentText.id}
      disabled={disabled}
      isManuallyEdited={isManuallyEdited}
      onResetManuallEditedText={onResetManuallEditedText}
      isResetting={isResetting}
      isFilterIdentified={isFilterIdentified}
    />
  );
};

SmartSuggestionWrapper.propTypes = {
  activeSection: PropTypes.string.isRequired,
  currentEntity: PropTypes.oneOfType([PropTypes.object, textListType]),
  showLoader: PropTypes.bool,
  currentText: PropTypes.object,
  selectedSmartOption: PropTypes.string,
  canResetToOriginal: PropTypes.bool,

  resetTextToOriginal: PropTypes.func,
  resetImageToOriginal: PropTypes.func,
  resetTableToOriginal: PropTypes.func,
  prevEntity: PropTypes.object,
  nextEntity: PropTypes.object,
  onNextWord: PropTypes.func.isRequired,
  onPrevWord: PropTypes.func.isRequired,
  onPrevImage: PropTypes.func,
  onNextImage: PropTypes.func,
  onPrevTable: PropTypes.func,
  onNextTable: PropTypes.func,
  goToPrevSection: PropTypes.func,
  goToNextSection: PropTypes.func,
  onSetCustomText: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  onDeSelectSuggestion: PropTypes.func,
  disabled: PropTypes.bool,
  isManuallyEdited: PropTypes.bool,
  onResetManuallEditedText: PropTypes.func,
  isResetting: PropTypes.bool,
  isFilterIdentified: PropTypes.bool
};

SmartSuggestionWrapper.defaultProps = {
  currentEntity: {},
  currentText: {},
  selectedSmartOption: "",
  canResetToOriginal: false,
  resetTextToOriginal: noop,
  resetImageToOriginal: noop,
  resetTableToOriginal: noop,
  prevEntity: {},
  nextEntity: {},
  onPrevImage: noop,
  onNextImage: noop,
  onPrevTable: noop,
  onNextTable: noop,
  goToPrevSection: noop,
  goToNextSection: noop,
  onSetCustomText: noop,
  onSelectSuggestion: noop,
  onDeSelectSuggestion: noop,
  disabled: false,
  isManuallyEdited: false,
  onResetManuallEditedText: noop,
  isResetting: false,
  isFilterIdentified: false
};

const mapStateToProps = (state) => {
  return {
    activeSection: getActiveSection(state),
    currentEntity: getCurrentEntity(state),
    showLoader: isSmartSuggestionIsLoading(state),
    prevEntity: getPrevEntity(state),
    nextEntity: getNextEntity(state),
    currentText: getCurrentText(state),
    selectedSmartOption: getSelectedSmartOption(state),
    canResetToOriginal: canResetToOriginal(state),
    isManuallyEdited: isCurrentEntityManuallyEdited(state),
    isResetting: isResettingManualChanges(state)
  };
};

export default connect(mapStateToProps, {
  resetTextToOriginal,
  resetImageToOriginal,
  resetTableToOriginal,
  onNextWord,
  onPrevWord,
  onPrevImage,
  onNextImage,
  onPrevTable,
  onNextTable,
  goToPrevSection,
  goToNextSection,
  onSetCustomText: setCustomReplacementText,
  onSelectSuggestion: selectSmartSuggestion,
  onDeSelectSuggestion: deselectSmartSuggestion
})(SmartSuggestionWrapper);
