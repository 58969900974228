import { createSelector } from "reselect";
import { find, pick } from "lodash";
import { configureLoadingState } from "containers/Common/reducers/loading";
import { createLoadingSelector } from "containers/Common/selectors/loading";
import {
  TEXT_FETCH_LIST,
  TEXT_FETCH_SUGGESTIONS,
  TEXT_FETCH_SANITIZED_PARA,
  TEXT_SAVE_SANITIZED_PARA
} from "../types";
import {
  getGroupedOriginalParaByGroupId,
  getGroupedParaByGroupId,
  getNextWordOrText,
  getPrevWordOrText,
  getSanitizedWord
} from "utils/helpers";
import { getIsFilterIdentified } from "containers/Sanitize/selectors";

const getSmartSuggestion = (state) => state.smartSuggestions;

configureLoadingState([
  TEXT_FETCH_LIST,
  TEXT_FETCH_SUGGESTIONS,
  TEXT_FETCH_SANITIZED_PARA,
  TEXT_SAVE_SANITIZED_PARA
]);

export const isFetchingTextList = createLoadingSelector([TEXT_FETCH_LIST]);
export const isFetchingSuggestions = createLoadingSelector([
  TEXT_FETCH_SUGGESTIONS
]);
export const isFetchingModifiedText = createLoadingSelector([
  TEXT_FETCH_SANITIZED_PARA
]);

export const isSavingSanitizedPara = createLoadingSelector([
  TEXT_SAVE_SANITIZED_PARA
]);

export const textState = (state) => state.text;
export const localTextDictionary = (state) => state.localTextDictionary;

export const getTextList = createSelector(textState, (ts) => ts.textList.data);

export const getFilteredTextList = createSelector(
  [getTextList, getIsFilterIdentified],
  (texts, isIdentified) => {
    if (isIdentified) {
      return texts.filter((text) => text.isIdentified);
    }
    return texts;
  }
);

export const getCurrentText = createSelector(
  textState,
  (ts) => ts.currentTextBeingEdited
);

export const getIdentifiedBlocksForWord = createSelector(
  [getCurrentText, (_, word) => word],
  (text, word) =>
    find(text.identifiedBlocks, (block) =>
      block.entityMappingId.includes(word.id)
    ) || {}
);

export const getOriginalPortions = createSelector(
  [getTextList, getCurrentText],
  (textList, currentText) => {
    const text = find(textList, { id: currentText.id });
    if (!text) {
      return [];
    }

    return text.portions;
  }
);

export const getCurrentWord = createSelector(textState, (ts) => {
  const word = ts.currentWordBeingEdited || {};
  const text = ts.currentTextBeingEdited || {};
  const block =
    find(text.identifiedBlocks, (block) =>
      block.entityMappingId.includes(word.id)
    ) || {};
  return {
    ...word,
    ...pick(block, ["entity", "recommendations"])
  };
});

export const getSuggestionEntities = createSelector(
  textState,
  (ts) => ts.suggestionEntities.data
);

export const getPrevWord = createSelector(
  [getFilteredTextList, getCurrentText, getCurrentWord],
  (textList, currentText, currentWord) =>
    getPrevWordOrText(textList, currentText, currentWord)
);

export const getNextWord = createSelector(
  [getFilteredTextList, getCurrentText, getCurrentWord],
  (textList, currentText, currentWord) =>
    getNextWordOrText(textList, currentText, currentWord)
);

export const canResetTextToOriginal = createSelector(
  [getSmartSuggestion, getCurrentWord],
  (suggestion, currentWord) => {
    const hasValueSelected = getSanitizedWord(suggestion, currentWord);
    return hasValueSelected && !hasValueSelected.hasReset;
  }
);

export const getGroupedParaDetails = createSelector(getCurrentText, (ct) => ({
  para: getGroupedParaByGroupId(ct),
  originalPara: getGroupedOriginalParaByGroupId(ct)
}));
