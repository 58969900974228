import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import CONFIG from "configs/config";
import PropTypes from "prop-types";
import { noop } from "lodash";
import "./AutoSanitizationConfirmModal.scss";

import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import CloseIcon from "assets/images/close_grey_small.svg";

/**
 * HOME SCREEN
 * Confirmation Popup for autosanitization document
 * @returns JSX
 */
const AutoSanitizationConfirmModal = ({
  open,
  setShowAutoSanitizationModal,
  autoSanitize,
  selectedFile,
  isAutoSanitizing
}) => {
  return (
    <Modal
      open={open || isAutoSanitizing}
      size="tiny"
      className="auto-sanitization-confirm"
    >
      <Modal.Content>
        <div className="auto-sanitization-confirm__header-container">
          <Header as="h3">Auto Sanitize Document</Header>
          <Image
            className={`pointer ${isAutoSanitizing ? "not-allowed" : ""}`}
            src={CloseIcon}
            alt="close-icon"
            onClick={() => {
              if (!isAutoSanitizing) {
                setShowAutoSanitizationModal(false);
              }
            }}
            role="presentation"
          />
        </div>
        {/* TODO: Move to constants */}
        <div>
          Clicking Proceed will lead you to Preview screen with system
          recommended sanitization along with user manual sanitization. You can
          download the document after review. Are you sure you want to continue?
        </div>
        <div className="auto-sanitization-confirm__action-btns">
          <Button
            className="px-5"
            secondary
            disabled={isAutoSanitizing}
            onClick={() => setShowAutoSanitizationModal(false)}
          >
            {CONFIG.LABELS.CANCEL}
          </Button>
          <CustomActionButton
            disabled={isAutoSanitizing}
            isLoading={isAutoSanitizing}
            label={CONFIG.LABELS.PROCEED}
            className="px-5 ml-3"
            onClickfn={() => {
              setShowAutoSanitizationModal(false);
              autoSanitize(selectedFile.pptId);
            }}
            noApplyIcon
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

AutoSanitizationConfirmModal.propTypes = {
  open: PropTypes.bool,
  setShowAutoSanitizationModal: PropTypes.func,
  autoSanitize: PropTypes.func,
  selectedFile: PropTypes.object,
  isAutoSanitizing: PropTypes.bool
};

AutoSanitizationConfirmModal.defaultProps = {
  open: false,
  setShowAutoSanitizationModal: noop,
  autoSanitize: noop,
  selectedFile: {},
  isAutoSanitizing: false
};

export default AutoSanitizationConfirmModal;
