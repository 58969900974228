// import ToastService from "utils/toast";

/**
 * helper method to handle error from catch block of saga
 * @param {object} error error object from catch block
 * @param {object} options to set custom error message and other
 */
export function handleCatchedError(error, options = {}) {
  const errorMessage =
    options.message ||
    error.message ||
    "Exception occured, while processing request";
  /* eslint-disable no-console */
  console.error(error);
  console.log(errorMessage);
  // ToastService.error(errorMessage);
}
