import { call, put, select, takeLatest } from "redux-saga/effects";
import { forEach, filter, size, trim } from "lodash";

import {
  rsGetSanitizedPara,
  rsGetSuggestionsByParaId,
  rsGetTextListByPptId,
  rsSaveSanitizedPara
} from "./resourceSagas";

import * as at from "../types";

import CONFIG from "configs/config";
import { handleCatchedError } from "utils/sagaUtils";
import {
  getActiveText,
  getActiveWord,
  getCurrentTextWithSuggestions,
  getFirstNonEditedPortionGroup,
  getIdentifiedPortionIds,
  getUpdatedPortionFromManualEdit
} from "utils/helpers";
import {
  fetchSuggestionsByParaId,
  saveCurrentTextOnStore,
  setCurrentTextBeingEdited,
  setCurrentWordBeingEdited,
  saveSanitizedPara
} from "../actions";
import {
  selectSmartSuggestion,
  setCustomReplacementText,
  updateCurrentTextPortions
} from "containers/TextListView/actions";
import {
  resetTextToOriginal,
  setUpdatedSmartSuggestionValues
} from "containers/SmartSuggestion/actions";
import {
  getSavedSuggestion,
  getSuggestionFromLS
} from "containers/SmartSuggestion/selectors";
import {
  getIsFilterIdentified,
  getPptDetails
} from "containers/Sanitize/selectors";
import {
  refreshSanitizationProgress,
  highlightElementOnSlide,
  setEditingCurrentTextGroup,
  setCurrentTextGroupId
} from "containers/Sanitize/actions";
import { getSuggestionEntities } from "../selectors";

/**
 * Generator saga function, which will parse the updated portion and extract the updated text using identified block
 * same will be saved in the store to populate saved smart suggstion when user revisit the same sensitive word
 * @param {Array} textList
 */
export function* updateSanitizedWord(textList) {
  const updatedValues = [];
  forEach(textList, ({ portions, identifiedBlocks = [] }) => {
    const identifiedPortions = filter(portions, { isIdentified: true });
    const filteredIdentifiedBlocks = filter(
      identifiedBlocks,
      (block) => !block.skipHighlight
    );
    if (filteredIdentifiedBlocks.length) {
      filteredIdentifiedBlocks.map(({ entityMappingId, entity }) => {
        const updatedPortions = filter(
          identifiedPortions,
          ({ id, isUpdated }) => entityMappingId.includes(id) && isUpdated
        );
        if (updatedPortions.length) {
          let updatedText = "";
          updatedPortions.forEach((port, i) => {
            if (port.isUpdated && port.updatedText) {
              updatedText += CONFIG.CONSTANTS.WORD_SEPARATOR + port.updatedText;
            }

            if (i === size(updatedPortions) - 1) {
              updatedValues.push({
                entity,
                updatedText: trim(updatedText),
                entityMappingId: entityMappingId.join(","),
                isFromSuggestion: port.isFromSuggestion,
                hasReset: port.isReset,
                defaultSelection: false
              });
            }
          });
        }
      });
    }
  });
  yield put(setUpdatedSmartSuggestionValues(updatedValues));
}

/**
 * On selecting a sensitive word, check local state whether there is already a value saved before for the current word,
 * else, look for localStorage (for the current word, for preferred replacement)
 * else select first smart suggestion as default replacement
 *
 * @param {Object} selectedWord
 * @param {Function} onReset
 * @param {Function} onSelect
 * @param {Function} onCustomText
 */
export function* onSelectWord(selectedWord, onReset, onSelect, onCustomText) {
  const {
    textId, // required for table manual edit
    id: portionId,
    entity,
    entityMappingId,
    isFromSuggestion,
    recommendations,
    updatedText,
    reselectRecommendations = false
  } = selectedWord;
  if (entity) {
    const prevValue = yield select((state) =>
      getSavedSuggestion(state, selectedWord)
    );
    if (!prevValue || reselectRecommendations) {
      const pptDetails = yield select(getPptDetails);
      // For first time, select first recommendation as default or preferred option from localStorage
      const hasSuggestionFromLS = yield select(getSuggestionFromLS);
      const lsWord = hasSuggestionFromLS[entity]?.updatedText;
      if (hasSuggestionFromLS[entity]?.hasReset) {
        yield put(
          onReset({
            portionId,
            entity,
            entityMappingId,
            updatedText,
            isUpdated: true,
            isFromLS: true,
            pptId: pptDetails.pptId,
            defaultSelection: true,
            textId
          })
        );
      } else if (lsWord && !isFromSuggestion) {
        yield put(
          onCustomText({
            portionId,
            entity,
            entityMappingId,
            updatedText: lsWord,
            isUpdated: true,
            isFromLS: true,
            pptId: pptDetails.pptId,
            defaultSelection: true,
            textId
          })
        );
      } else {
        yield put(
          onSelect({
            portionId,
            entity,
            entityMappingId,
            updatedText: lsWord || recommendations[0],
            defaultSelection: true,
            isUpdated: true,
            pptId: pptDetails.pptId,
            isFromLS: true,
            textId
          })
        );
      }
    }
  }
}

function* watchTextSaga() {
  /**
   * On set current text, fetch suggestion for the selected word
   */
  yield takeLatest(
    at.TEXT_SET_CURRENT_TEXT,
    function* setCurrentText({ payload }) {
      yield put(setCurrentTextGroupId(""));
      yield put(fetchSuggestionsByParaId(payload));
    }
  );

  /**
   * On set current word, apply saved option or select first smart suggestion as selected
   */
  yield takeLatest(
    at.TEXT_SET_CURRENT_WORD,
    function* setCurrentWord({ payload: selectedWord }) {
      yield* onSelectWord(
        selectedWord,
        resetTextToOriginal,
        selectSmartSuggestion,
        setCustomReplacementText
      );
    }
  );

  /**
   * On fetch text list, or on Reset Text (which will be used to break previously forked execution)
   */
  yield takeLatest(
    [at.TEXT_FETCH_LIST, at.TEXT_RESET_TEXT],
    function* getTextList({ type, payload }) {
      if (type !== at.TEXT_RESET_TEXT) {
        try {
          const { pptId, slideId } = payload;
          if (pptId && slideId) {
            const isFilterIdentified = yield select(getIsFilterIdentified);
            const textList = yield call(rsGetTextListByPptId, pptId, slideId);
            let currentText;
            if (isFilterIdentified) {
              const filteredTextList = textList.filter(
                (textObj) => textObj.isIdentified
              );
              currentText = getActiveText(filteredTextList);
            } else {
              currentText = getActiveText(textList);
            }

            yield put(setCurrentTextBeingEdited(currentText));
          }
        } catch (e) {
          yield call(handleCatchedError, e);
        }
      }
    }
  );

  /**
   * On fetch suggestions
   * Fetch suggestions and current text data and merge these to create identified blocks for FE processing
   */
  yield takeLatest(
    [at.TEXT_FETCH_SUGGESTIONS, at.TEXT_RESET_TEXT],
    function* getSuggestions({ type, payload: text }) {
      if (type !== at.TEXT_RESET_TEXT) {
        try {
          const { pptId, slideId, id, portions } = text;
          if (id) {
            let suggestions = yield select(getSuggestionEntities);
            suggestions = yield call(rsGetSuggestionsByParaId, slideId, id);
            const portionIdsToHighlights = getIdentifiedPortionIds({
              ...suggestions,
              portions
            });
            yield put(
              highlightElementOnSlide({
                sectionType: CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT,
                ids: {
                  portionIds: portionIdsToHighlights
                }
              })
            );

            // need to call fetch text, to get updated portions when moving between text
            const para = yield call(rsGetSanitizedPara, slideId, id, pptId);
            const textWithSuggestion = getCurrentTextWithSuggestions(
              { ...text, ...para },
              suggestions
            );
            yield put(saveCurrentTextOnStore(textWithSuggestion));

            yield* updateSanitizedWord([textWithSuggestion]);

            // select first identified word as selected
            const currentWord = getActiveWord(
              textWithSuggestion,
              text.selectWordAtIndex
            );
            yield put(setCurrentWordBeingEdited(currentWord));

            // Set the current group id
            const currentGroupId = currentWord?.groupId
              ? currentWord.groupId
              : getFirstNonEditedPortionGroup(textWithSuggestion.portions);
            yield put(setCurrentTextGroupId(currentGroupId));
          }
        } catch (e) {
          yield call(handleCatchedError, e);
        }
      }
    }
  );

  /**
   * On trigger save sanitized text/para, call the api
   */
  yield takeLatest(
    at.TEXT_TRIGGER_SAVE_SANITIZED_PARA,
    function* triggerSave({ payload: text }) {
      if (text.dirty) {
        yield put(saveSanitizedPara(text));
      }
    }
  );

  /**
   * On success of save text, refresh sanitization progress
   */
  yield takeLatest(
    at.TEXT_SAVE_SANITIZED_PARA_SUCCEEDED,
    function* onParaSaveSuccess({ payload }) {
      // When resetting manually edited text, refresh the text to get latest portion (include resetting current word)
      if (payload.data) {
        yield put(
          refreshSanitizationProgress({
            slideId: payload.data.slideId
          })
        );
      }
    }
  );

  /**
   * On saving manually edited para/text,
   * update the portion with updateText
   */
  yield takeLatest(
    at.TEXT_SAVE_MANUALLY_SANITIZED_PARA,
    function* onManualTextSave({ payload }) {
      const { portions, modifiedParaByGroup } = payload;
      let newPortions = getUpdatedPortionFromManualEdit(
        portions,
        modifiedParaByGroup
      );

      yield put(
        updateCurrentTextPortions({
          portions: newPortions,
          groupId: Object.keys(modifiedParaByGroup)[0],
          skipHighlight: true
        })
      );
      yield put(setEditingCurrentTextGroup(false));
      yield put(setCurrentWordBeingEdited({}));
    }
  );

  /**
   * On save action, call save text api
   */
  yield takeLatest([at.TEXT_SAVE_SANITIZED_PARA], function* onSave(data) {
    try {
      yield call(rsSaveSanitizedPara, data.payload);
    } catch (e) {
      yield call(handleCatchedError, e);
    }
  });
}

export default function* textSaga() {
  yield call(watchTextSaga);
}
