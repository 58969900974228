import { all, call } from "redux-saga/effects";
import CONFIG from "configs/config";

import routerSaga from "./router";
import homeSaga from "containers/Home/sagas";
import userSaga from "containers/User/sagas";
import sanitizerSaga from "containers/Sanitize/sagas";
import textSaga from "containers/TextListView/sagas";
import imageSuggestionSaga from "containers/ImageListView/sagas";
import tableSuggestionSaga from "containers/TableListView/sagas";
import tableTextSaga from "containers/TableManualEdit/sagas";
import suggestionsSaga from "containers/SmartSuggestion/sagas";
import previewSaga from "containers/Preview/sagas";
import documentSummarySaga from "containers/SummaryModal/sagas";
import { handleCatchedError } from "utils/sagaUtils";

export default function* rootSaga() {
  let uncaughtException = false;
  while (true) {
    try {
      const sagas = [
        call(routerSaga),
        call(homeSaga),
        call(userSaga),
        call(sanitizerSaga),
        call(textSaga),
        call(suggestionsSaga),
        call(imageSuggestionSaga),
        call(tableSuggestionSaga),
        call(tableTextSaga),
        call(previewSaga),
        call(documentSummarySaga)
      ];
      if (uncaughtException) {
        uncaughtException = false;
        sagas.push(
          call(handleCatchedError, {
            message: CONFIG.ERROR.API.UNHANDLED_EXCEPTION
          })
        );
      }

      yield all(sagas);
    } catch (e) {
      uncaughtException = true;
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
