import * as at from "../types";
import { createAction as ca } from "utils/action";

export const fetchTextListBySlideId = ca(at.TEXT_FETCH_LIST);
export const fetchSuggestionsByParaId = ca(at.TEXT_FETCH_SUGGESTIONS);

export const setCurrentTextBeingEdited = ca(at.TEXT_SET_CURRENT_TEXT);
export const saveCurrentTextOnStore = ca(at.TEXT_STORE_CURRENT_TEXT);
export const resetTextState = ca(at.TEXT_RESET_TEXT);
export const setCurrentWordBeingEdited = ca(at.TEXT_SET_CURRENT_WORD);
export const updateCurrentTextPortions = ca(
  at.TEXT_UPDATE_CURRENT_TEXT_POTIONS
);

export const saveManuallySanitizedParaByGroup = ca(
  at.TEXT_SAVE_MANUALLY_SANITIZED_PARA
);

export const setCustomReplacementText = ca(at.TEXT_SET_REPLACEMENT_TEXT);
export const selectSmartSuggestion = ca(at.TEXT_SELECT_SMART_SUGGESTION);
export const deselectSmartSuggestion = ca(at.TEXT_DESELECT_SMART_SUGGESTION);

export const saveSanitizedPara = ca(at.TEXT_SAVE_SANITIZED_PARA);
export const triggerSaveSanitizedPara = ca(at.TEXT_TRIGGER_SAVE_SANITIZED_PARA);
