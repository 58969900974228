import { createAction as ca } from "utils/action";
import * as at from "../types";

export const getUserDetails = ca(at.USER_GET_USER_DETAILS);
export const setUserDetails = ca(at.USER_SET_USER_DETAILS);

export const getUserProfilePicture = ca(at.USER_GET_USER_PROFILE_PIC);

export const getSignedToken = ca(at.USER_GET_SIGNED_TOKEN);
export const fetchSignedTokenFromAPI = ca(at.USER_FETCH_SIGNED_TOKEN_FROM_API);
