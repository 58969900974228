import React from "react";
import "./MinorDesclaimer.scss";
import warningIcon from "assets/images/warning.png";


const MinorDesclaimer = () => {
  return (
    <div className="graph-desclaimers">
      <img src={warningIcon} className="graph-desclaimers-icon" />
      <span className="graph-desclaimers-txt">
        Please note: This tool only recognizes ppt/excel generated graphs, Think-cell charts are currently not supported.
      </span>
    </div>
  );
};

export default MinorDesclaimer;
