import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/sanitize";
import * as previewApi from "api/preview";
import * as at from "../types";
import { EDITOR_FETCH_SLIDES } from "containers/Sanitize/types";

export const rsGetSlidesData = resourceSaga(
  EDITOR_FETCH_SLIDES,
  api.getSlidesByPptId
);

export const rsGetPreviewDocument = resourceSaga(
  at.GET_PREVIEW_DOCUMENT,
  previewApi.getPreviewSlidesAPI
);
