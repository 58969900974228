/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Placeholder, Image } from "semantic-ui-react";
import { find, findIndex, noop, some } from "lodash";
import SimpleBar from "simplebar-react";
import "./ImageListView.scss";
import CONFIG from "configs/config";

import NoResult from "components/NoResult/NoResult";
import ProtectedImageRenderer from "components/ProtectedImageRenderer/ProtectedImageRenderer";

import { imageListType } from "utils/customPropTypes";
import {
  selectImage,
  deselectImage,
  resetSelectedImages,
  selectAllImages,
  onSetCurrentImage,
  toggleMultiselect,
  storeImageDimension,
  resetDirtyChanges
} from "./actions";
import {
  isFetchingImages,
  getSelectedImages,
  isMultiSelectMode,
  getIndividualImageSaveStatus,
  getFilteredImageList
} from "./selectors";
import {
  isFetchingPpt,
  showManualEditModal
} from "containers/Sanitize/selectors";

import loadingIcon from "assets/images/uploading-ppt-icon-white.svg";
import { getNearestItem } from "utils/common";
import MinorDesclaimer from "components/MinorDesclaimer";

/**
 * SANITIZE SCREEN
 * List of images, identified by system
 * @returns JSX
 */
const ImageListView = ({
  imageList,
  isFetchingImageList,
  isFetchingPpt,
  selectedImages,
  selectImage,
  deselectImage,
  resetSelectedImages,
  selectAllImages,
  onClickImage,
  isSelectMode,
  toggleSelectMode,
  storeImageDimension,
  activeSection,
  isIndividualImageSaving,
  resetDirtyChanges,
  imageEditorShown,
  hasImages,
  isFilterIdentified,
  currentImagePreviewing,
  setPreviewingImage
}) => {
  const [lastActiveImage, setActiveImage] = useState({});
  const disableSelectMode = imageList.length <= 1;
  const showLoader = isFetchingImageList || isFetchingPpt;
  const allChecked = selectedImages.length === imageList.length;

  // Auto scroll to image which is being edited when moving between images using smart suggestion arrow
  useEffect(() => {
    if (
      selectedImages?.length &&
      lastActiveImage.id !== selectedImages[0]?.id
    ) {
      if (
        !isSelectMode &&
        selectedImages[0].id &&
        selectedImages[0].isArrowClick
      ) {
        setActiveImage(selectedImages[0]);
        document.getElementById(selectedImages[0].id)?.scrollIntoView(false);
      }
    }
  }, [selectedImages]);

  // Auto scroll to the image which is being previewed when moving between preview images slider
  useEffect(() => {
    if (
      selectedImages?.length &&
      currentImagePreviewing.id &&
      lastActiveImage.id !== currentImagePreviewing?.id &&
      isSelectMode
    ) {
      setActiveImage(currentImagePreviewing);
      if (!currentImagePreviewing.skipAutoScroll) {
        document
          .getElementById(currentImagePreviewing.id)
          ?.scrollIntoView({ block: "center" });
      }
    }
  }, [currentImagePreviewing]);

  const onSelectModeChange = () => {
    if (!disableSelectMode) {
      toggleSelectMode(!isSelectMode);
    }
  };

  const onAllCheckboxChange = () => {
    if (allChecked) {
      resetSelectedImages(true);
    } else {
      selectAllImages(imageList);
    }
  };

  const onSelectImage = (image, checked) => {
    if (!checked) {
      selectImage(image.id);
      setPreviewingImage({ ...image, skipAutoScroll: true });
    } else {
      setPreviewingImage(
        getNearestItem(
          selectedImages,
          findIndex(selectedImages, { id: image.id })
        )
      );
      deselectImage(image.id);
    }
  };

  const isSelectedImage = (img) => {
    return find(selectedImages, { id: img.id });
  };

  const onSelectCheckboxChange = () => {
    if (isSelectMode) {
      resetDirtyChanges();
      toggleSelectMode(false);
    } else {
      onSelectModeChange();
    }
  };

  const noResult = !imageList.length && !showLoader;

  const noFilteredResult =
    isFilterIdentified && imageList.length === 0 && !showLoader;

  if (noFilteredResult) {
    return (
      <div className="main-block image-container no-result-container">
        <SimpleBar
          className={`image-container__list ${
            disableSelectMode ? "image-container__list--no-header" : ""
          }`}
        >
          <NoResult label={`Sensitive ${activeSection}`} />
        </SimpleBar>
        {activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS && (
          <MinorDesclaimer />
        )}
      </div>
    );
  }

  if (noResult) {
    return (
      <div className="main-block image-container no-result-container">
        <SimpleBar
          className={`image-container__list ${
            disableSelectMode ? "image-container__list--no-header" : ""
          }`}
        >
          <NoResult label={hasImages ? "" : activeSection} />
        </SimpleBar>
        {activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS && (
          <MinorDesclaimer />
        )}
      </div>
    );
  }

  if (showLoader) {
    return (
      <div className="main-block image-container placeholder">
        <Placeholder>
          <Placeholder.Image />
        </Placeholder>
      </div>
    );
  }
  return (
    <div className="main-block image-container">
      <div
        className={`image-container__header ${
          disableSelectMode ? "d-none" : ""
        }`}
      >
        <div className="image-container__header--checkboxes">
          <Checkbox
            toggle
            className="image-container--select"
            checked={isSelectMode}
            onChange={onSelectCheckboxChange}
            label={
              "Multiselect" +
              (isSelectMode ? ` (${selectedImages.length})` : "")
            }
            size="small"
          />
          {isSelectMode && (
            <Checkbox
              className="image-container--check-all"
              label="All"
              checked={allChecked}
              onClick={onAllCheckboxChange}
              indeterminate={!allChecked && !!selectedImages.length}
            />
          )}
        </div>
      </div>
      <SimpleBar
        className={`image-container__list ${
          disableSelectMode ? "image-container__list--no-header" : ""
        }`}
        timeout={1500}
      >
        <ul
          className={
            "image-list " +
            (activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
              ? "graph"
              : "image")
          }
        >
          {imageList.map((image) => {
            const checked = some(
              selectedImages,
              (sImg) => sImg.id === image.id
            );
            const currentItemState =
              "image-list__item--" +
              (isSelectedImage(image)
                ? isSelectMode
                  ? currentImagePreviewing.id === image.id
                    ? "current-preview"
                    : ""
                  : "current"
                : image.smartOption
                ? "visited"
                : "not-visited");
            const isSaving =
              isIndividualImageSaving.includes(image.id) &&
              !isSelectMode &&
              !imageEditorShown;
            return (
              <li
                id={image.id}
                key={image.id}
                className={`image-list__item ${currentItemState} ${
                  isSaving ? "cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (!isSaving) {
                    if (!isSelectMode) {
                      if (!checked) {
                        onClickImage({ entity: image, autoSave: true });
                      }
                    } else {
                      onSelectImage(image, checked);
                    }
                  }
                }}
              >
                {isSaving && (
                  <div className="overlay-loader">
                    <Image src={loadingIcon} alt="" />
                  </div>
                )}
                <span
                  className={`image-list__checkbox ${
                    isSelectMode ? "image-list__checkbox--show" : "d-none"
                  }`}
                >
                  <Checkbox
                    className="with-shadow"
                    checked={checked}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onSelectImage(image, checked);
                    }}
                  />
                </span>
                <ProtectedImageRenderer
                  src={image.backUpOriginalImageUrl}
                  onLoad={(e) => {
                    if (
                      e.target.naturalWidth &&
                      activeSection !== CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS
                    ) {
                      storeImageDimension({
                        [image.id]: {
                          width: e.target.naturalWidth,
                          height: e.target.naturalHeight
                        }
                      });
                    }
                  }}
                />
                {!!image.savedSmartOption && (
                  <span className="image-list__badge">
                    {CONFIG.LABELS.SANITIZED_ENTITY}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </SimpleBar>
      {activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS && (
        <MinorDesclaimer />
      )}
    </div>
  );
};

ImageListView.propTypes = {
  imageList: PropTypes.arrayOf(imageListType),
  isFetchingImageList: PropTypes.bool,
  isFetchingPpt: PropTypes.bool,
  selectedImages: PropTypes.arrayOf(imageListType),
  selectImage: PropTypes.func,
  deselectImage: PropTypes.func,
  resetSelectedImages: PropTypes.func,
  selectAllImages: PropTypes.func,
  onClickImage: PropTypes.func,
  isSelectMode: PropTypes.bool,
  toggleSelectMode: PropTypes.func,
  storeImageDimension: PropTypes.func,
  activeSection: PropTypes.string.isRequired,
  isIndividualImageSaving: PropTypes.array,
  resetDirtyChanges: PropTypes.func,
  imageEditorShown: PropTypes.bool,
  hasImages: PropTypes.bool,
  isFilterIdentified: PropTypes.bool,
  currentImagePreviewing: imageListType,
  setPreviewingImage: PropTypes.func
};

ImageListView.defaultProps = {
  imageList: [],
  isFetchingImageList: false,
  isFetchingPpt: false,
  selectedImages: [],
  selectImage: noop,
  deselectImage: noop,
  resetSelectedImages: noop,
  selectAllImages: noop,
  onClickImage: noop,
  isSelectMode: false,
  toggleSelectMode: noop,
  storeImageDimension: noop,
  activeSection: "",
  isIndividualImageSaving: [],
  resetDirtyChanges: noop,
  imageEditorShown: false,
  hasImages: false,
  isFilterIdentified: false,
  currentImagePreviewing: {},
  setPreviewingImage: noop
};

const mapStateToProps = (state) => ({
  isFetchingImageList: isFetchingImages(state),
  isFetchingPpt: isFetchingPpt(state),
  imageList: getFilteredImageList(state),
  selectedImages: getSelectedImages(state),
  isSelectMode: isMultiSelectMode(state),
  isIndividualImageSaving: getIndividualImageSaveStatus(state),
  imageEditorShown: showManualEditModal(state)
});

export default connect(mapStateToProps, {
  selectImage,
  deselectImage,
  resetSelectedImages,
  selectAllImages,
  onClickImage: onSetCurrentImage,
  toggleSelectMode: toggleMultiselect,
  storeImageDimension,
  resetDirtyChanges
})(ImageListView);
