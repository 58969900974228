import { failedString, requestedString, succeededString } from "utils/common";

export const GET_PREVIEW_DOCUMENT = "GET_PREVIEW_DOCUMENT";
export const GET_PREVIEW_DOCUMENT_REQUESTED = requestedString(
  GET_PREVIEW_DOCUMENT
);
export const GET_PREVIEW_DOCUMENT_SUCCEEDED = succeededString(
  GET_PREVIEW_DOCUMENT
);
export const GET_PREVIEW_DOCUMENT_FAILED = failedString(GET_PREVIEW_DOCUMENT);

export const CLEAR_PREVIEW_SLIDES = "CLEAR_PREVIEW_SLIDES";

export const GET_SLIDE_DETAILS_FOR_PREVIEW = "GET_SLIDE_DETAILS_FOR_PREVIEW";

export const SET_CURRENT_SLIDE_INDEX = "SET_CURRENT_SLIDE_INDEX";

export const TOGGLE_PREVIEW_DOC = "TOGGLE_PREVIEW_DOC";
