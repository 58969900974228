import React, { useState, useEffect } from "react";
import "simplebar/dist/simplebar.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

import "./styles/global.scss";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer, Slide } from "react-toastify";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import oktaAuth from "configs/okta/oktaAuth";
import history from "redux/history";

// COMPONENTS
import Home from "containers/Home/Home";
import Sanitize from "containers/Sanitize/Sanitize";
import UserGuide from "containers/UserGuide";
import ErrorPage from "components/ErrorPage/ErrorPage";
import Modal from "containers/Common/Model/Modal";
import PreviewWrapper from "containers/Preview";
import SummaryModalWrapper from "containers/SummaryModal";
import { withRouteChange } from "redux/withRouteChange";

// Routes
import CONFIG from "configs/config";
import { isIE as isInternetExplorer } from "utils/mobile";

//Images
import closeIcon from "assets/images/close-icon.svg";

function App() {
  const loadAdobeScript = () => {
    const analyticsUrl = process.env.REACT_APP_AA_TAMS_URL;
    if (analyticsUrl) {
      const script = document.createElement("script");
      script.src = analyticsUrl;
      script.async = true;
      document.head.appendChild(script);
    }
  };
  const [isReady, setReady] = useState(false);
  const [isIE, setIE] = useState(false);
  useEffect(() => {
    loadAdobeScript();
    setIE(isInternetExplorer());
    setReady(true);
  }, []);

  if (isIE) {
    return <ErrorPage page={"IE11"} />;
  }

  if (!isReady) {
    return null;
  }
  return (
    <>
      <ConnectedRouter history={history}>
        <Security oktaAuth={oktaAuth}>
          <Switch>
            {process.env.REACT_APP_OKTA_REDIRECT_URI && (
              <Route
                path={process.env.REACT_APP_OKTA_REDIRECT_URI}
                component={withRouteChange(LoginCallback)}
              />
            )}
            <SecureRoute
              exact
              component={withRouteChange(Home)}
              path={CONFIG.APP_ROUTES.PRIVATE.HOME}
            />
            <SecureRoute
              exact
              component={withRouteChange(UserGuide)}
              path={CONFIG.APP_ROUTES.PRIVATE.USER_GUIDE}
            />
            <SecureRoute
              exact
              component={withRouteChange(Sanitize)}
              path={CONFIG.APP_ROUTES.PRIVATE.SANITIZE}
            />
            <Route component={() => <ErrorPage page={"404"} />} />
          </Switch>
        </Security>
      </ConnectedRouter>
      <ToastContainer
        className="custom-toaster"
        autoClose={4000}
        hideProgressBar
        rtl={false}
        newestOnTop
        limit={3}
        transition={Slide}
        closeIcon={closeIcon}
      />
      <Modal />
      <PreviewWrapper />
      <SummaryModalWrapper />
    </>
  );
}

export default App;
