import { requestedString, succeededString } from "utils/common";

export const TOGGLE_SUMMARY_MODAL = "TOGGLE_SUMMARY_MODAL";

export const GET_DOCUMENT_SUMMARY = "GET_DOCUMENT_SUMMARY";

export const GET_DOWNLOAD_URL = "GET_DOWNLOAD_URL";
export const GET_DOWNLOAD_URL_REQUESTED = requestedString(GET_DOWNLOAD_URL);
export const GET_DOWNLOAD_URL_SUCCEEDED = succeededString(GET_DOWNLOAD_URL);
export const CLEAN_DOWNLOAD_URL = "CLEAN_DOWNLOAD_URL";
