const data = {
  page: {
    category: {
      primaryCategory: ""
    },
    pageInfo: {
      pageName: "",
      staffSystemID: ""
    },
    toggle: {
      value: ""
    }
  },
  source: {
    application: {
      applicationName: "TAMS"
    }
  },
  document:{
    title:""
  },
  link: {
    name: ""
  }
};
window.digitalData = data;
export default window.digitalData;
