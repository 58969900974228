import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { find, noop } from "lodash";
import "./Sanitize.scss";

// Components
import PageHeader from "components/PageHeader/PageHeader";
import HeaderButtons from "../../components/HeaderButtons/HeaderButtons";
import SlidesView from "../../components/SlidesView/SlidesView";
import EditorSection from "../EditorSection/EditorSection";
import PreviewSlide from "components/PreviewSlide";
import UserGuideFloatingIcon from "components/UserGuideFloatingIcon/UserGuideFloatingIcon";

import CONFIG from "configs/config";
// Actions and selectors
import {
  changeSlide,
  setSlideMarkComplete,
  resetOnUnmountEditor,
  getPreviewSlideAction
} from "./actions";
import { toggleSummaryModalAction } from "containers/SummaryModal/actions";
import { resetHomeStore, onHomePageRedirect } from "containers/Home/actions";
import { togglePreviewDocAction } from "containers/Preview/actions";
import {
  getSlidesList,
  getIdentifiedSlides,
  getPptDetails,
  isFetchingPpt,
  getCurrentSlide,
  isFetchingPreviewSlide,
  getPreviewSlideSelector,
  getIsFilterIdentified
} from "./selectors";
import {
  getSignedTokenFromStore,
  getUserDetails
} from "containers/User/selectors";

// constants and others
import { presentationType, slideType } from "utils/customPropTypes";
import TableManualEdit from "containers/TableManualEdit";
import { isTableManualEditShown } from "containers/TableManualEdit/selectors";
import { AAPageLoad } from "analytics/AnalyticsCapture";

/**
 * SANITIZE SCREEN
 * Parent container for sanitize screen
 * @returns JSX
 */
const Sanitize = ({
  userDetails,
  allSlides,
  identifiedSlides,
  pptDetails,
  changeSlide,
  isFetchingPpt,
  currentSlide,
  setSlideMarkComplete,
  resetOnUnmountEditor,
  previewSlide,
  toggleSummaryModal,
  signedToken,
  isPreviewDocShown,
  togglePreviewDocAction,
  getPreviewSlideAction,
  isFetchingPreviewSlide,
  showTableManualEditor,
  isFilterIdentified,
  onHomePageRedirect
}) => {
  useEffect(() => {
    if (Number(userDetails.hrId) > 0) {
      AAPageLoad("TAMS:Sanitize", userDetails.email);
    }
  }, [userDetails]);
  const sliderRef = useRef(null);
  const slideView = CONFIG.LABELS.SLIDE_VIEWS.ALL;
  const [showPreviewSlide, togglePreviewSlide] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(-1);
  const showIdentified = slideView === CONFIG.LABELS.SLIDE_VIEWS.IDENTIFIED;

  const updateCurrentSlide = () => {
    if (sliderRef.current) {
      const newIndex = find(allSlides, {
        slideId: currentSlide.slideId
      })?.slideIndex;
      if (newIndex !== -1 && currentSlideIndex !== newIndex) {
        sliderRef.current?.slickGoTo(newIndex, true);
        setCurrentSlideIndex(newIndex);
      }
    }
  };

  useEffect(
    () => () => {
      resetOnUnmountEditor();
      // Triggered to reset previous ppt state, and save dirty changes
    },
    []
  );

  // Whenever currentslide is changed, update the slider with latest
  useEffect(() => {
    updateCurrentSlide();
  }, [currentSlide.slideId]);

  return (
    <main>
      <PageHeader />
      <HeaderButtons
        userDetails={userDetails}
        pptName={pptDetails.pptName}
        isPreviewDocShown={isPreviewDocShown}
        togglePreviewDoc={togglePreviewDocAction}
        toggleSummaryModal={toggleSummaryModal}
        isFetchingPpt={isFetchingPpt}
        togglePreviewSlide={togglePreviewSlide}
        showPreviewSlide={showPreviewSlide}
        onHomePageRedirect={onHomePageRedirect}
      />
      <SlidesView
        ref={sliderRef}
        pptDetails={pptDetails}
        slidesList={showIdentified ? identifiedSlides : allSlides}
        changeSlide={changeSlide}
        showLoader={isFetchingPpt}
        currentSlide={currentSlide}
        setSlideMarkComplete={setSlideMarkComplete}
        currentSlideIndex={currentSlideIndex}
        signedToken={signedToken}
        updateCurrentSlide={updateCurrentSlide}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
      {!showTableManualEditor ? (
        <EditorSection isFilterIdentified={isFilterIdentified} />
      ) : (
        <TableManualEdit />
      )}
      {showPreviewSlide && (
        <PreviewSlide
          showPreviewSlide={showPreviewSlide}
          currentSlideView={slideView}
          togglePreviewSlide={togglePreviewSlide}
          previewSlide={previewSlide}
          isFetchingPreviewSlide={isFetchingPreviewSlide}
          getPreviewSlideAction={getPreviewSlideAction}
          currentSlideNo={currentSlide?.slideNumber}
          signedToken={signedToken}
          pptDetails={pptDetails}
        />
      )}
      <UserGuideFloatingIcon
        triggerFrom={CONFIG.CONSTANTS.USER_GUIDE_TRIGGERS.SANITIZE}
      />
    </main>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  allSlides: getSlidesList(state),
  identifiedSlides: getIdentifiedSlides(state),
  pptDetails: getPptDetails(state),
  isFetchingPpt: isFetchingPpt(state),
  currentSlide: getCurrentSlide(state),
  previewSlide: getPreviewSlideSelector(state),
  isFetchingPreviewSlide: isFetchingPreviewSlide(state),
  signedToken: getSignedTokenFromStore(state),
  showTableManualEditor: isTableManualEditShown(state),
  isFilterIdentified: getIsFilterIdentified(state)
});

Sanitize.propTypes = {
  userDetails: PropTypes.object,
  allSlides: PropTypes.arrayOf(slideType),
  identifiedSlides: PropTypes.arrayOf(slideType),
  pptDetails: presentationType,
  isFetchingPpt: PropTypes.bool,
  changeSlide: PropTypes.func,
  currentSlide: PropTypes.object,
  setSlideMarkComplete: PropTypes.func,
  resetOnUnmountEditor: PropTypes.func,
  toggleSummaryModal: PropTypes.func,
  previewSlide: PropTypes.object,
  signedToken: PropTypes.object,
  isPreviewDocShown: PropTypes.bool,
  togglePreviewDocAction: PropTypes.func,
  getPreviewSlideAction: PropTypes.func,
  isFetchingPreviewSlide: PropTypes.bool,
  showTableManualEditor: PropTypes.bool,
  isFilterIdentified: PropTypes.bool,
  onHomePageRedirect: PropTypes.func
};

Sanitize.defaultProps = {
  allSlides: [],
  identifiedSlides: [],
  pptDetails: {},
  changeSlide: noop,
  currentSlide: {},
  setSlideMarkComplete: noop,
  toggleSummaryModal: noop,
  resetOnUnmountEditor: noop,
  previewSlide: {},
  getPreviewSlideAction: noop,
  signedToken: {},
  resetHomeStore: noop,
  isPreviewDocShown: false,
  togglePreviewDocAction: noop,
  isFetchingPreviewSlide: false,
  isFilterIdentified: false,
  onHomePageRedirect: noop
};

export default connect(mapStateToProps, {
  changeSlide,
  setSlideMarkComplete,
  resetOnUnmountEditor,
  getPreviewSlideAction,
  toggleSummaryModal: toggleSummaryModalAction,
  resetHomeStore,
  togglePreviewDocAction,
  onHomePageRedirect
})(Sanitize);
