import { find, isEmpty, keyBy, map, omit, pick } from "lodash";
import { combineReducers } from "redux";
import { resourceReducer } from "redux/sagas/networkResource";
import { RESET_TABLE_TO_ORIGINAL } from "containers/SmartSuggestion/types";
import * as at from "../types";
import CONFIG from "configs/config";
import {
  IMAGE_SAVE_UPDATED_IMAGES_FAILED,
  IMAGE_SAVE_UPLOAD_TO_S3_REQUESTED,
  IMAGE_SAVE_UPLOAD_TO_S3_SUCCEEDED
} from "containers/ImageListView/types";
import { canApplySmartOption } from "utils/helpers";

function tableList(state, action) {
  switch (action.type) {
    case at.TABLE_REPLACE_TABLELIST:
      return { data: action.payload };

    case at.TABLE_UPDATE_TABLELIST: {
      if (!isEmpty(action.payload)) {
        const responseByKey = keyBy(action.payload, "id");
        const updatedStateData = state.data.map((img) => {
          if (responseByKey[img.id]) {
            return {
              ...responseByKey[img.id],
              ...pick(img, ["redactedImageUrl"])
            };
          }
          return img;
        });
        return { ...state, data: updatedStateData };
      }
      return state;
    }

    case at.TABLE_UPDATE_SELECT_TABLE: {
      if (action.payload.id) {
        const newData = state.data.map((table) => {
          if (table.id === action.payload.id) {
            return { ...table, ...action.payload };
          }
          return table;
        });
        return { ...state, data: newData };
      }
      return state;
    }

    case at.TABLE_RESET_TABLE_STATE:
      return { data: [] };

    default:
      return resourceReducer(at.TABLE_FETCH_TABLELIST, {
        reset: true
      })(state, action);
  }
}

function selectedSmartOption(state = "", { type, payload }) {
  switch (type) {
    case at.SELECT_SMART_OPTION_FOR_TABLE:
      return payload;
    case RESET_TABLE_TO_ORIGINAL:
      return CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET;
    case at.TABLE_REFRESH_TABLELIST_SUCCEEDED:
    case at.TABLE_FETCH_TABLELIST_REQUESTED:
      return "";
    case at.TABLE_SET_CURRENT_TABLE:
      return "";
    default:
      return state;
  }
}

function selectedTable(state = {}, { type, payload }) {
  switch (type) {
    case at.TABLE_SET_CURRENT_EDITED_TABLE_DATA_URI:
      if (payload.dataUri) {
        return { ...state, dataUri: payload.dataUri };
      }
      return state;

    case RESET_TABLE_TO_ORIGINAL:
      return omit(
        { ...state, smartOption: CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET },
        "dataUri"
      );

    case at.SELECT_SMART_OPTION_FOR_TABLE: {
      let newState = { ...state, smartOption: payload };
      if (payload !== CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP) {
        return omit(newState, "dataUri");
      }
      return newState;
    }

    case at.TABLE_REPLACE_TABLELIST:
    case at.TABLE_UPDATE_TABLELIST: {
      const newData = find(payload, { id: state.id }) || {};
      return { ...state, ...newData };
    }

    case at.TABLE_UPDATE_SELECT_TABLE: {
      return { ...state, ...payload };
    }

    case at.TABLE_SET_CURRENT_TABLE:
      if (canApplySmartOption(payload)) {
        return {
          ...payload,
          smartOption: CONFIG.CONSTANTS.IMAGE_SMART_OPTION.SMARTEDIT
        };
      }
      return { ...payload };

    case at.TABLE_RESET_TABLE_STATE:
      return {};

    default:
      return state;
  }
}

function individualTableSaveStatus(state = [], { type, args = [] }) {
  switch (type) {
    case IMAGE_SAVE_UPLOAD_TO_S3_REQUESTED:
    case at.TABLE_SAVE_UPDATED_TABLE_REQUESTED:
      if (args[0] && args[0].length) {
        return [...state, ...map(args[0], "id")];
      }
      return state;

    case IMAGE_SAVE_UPLOAD_TO_S3_SUCCEEDED:
    case at.TABLE_SAVE_UPDATED_TABLE_SUCCEEDED:
    case IMAGE_SAVE_UPDATED_IMAGES_FAILED:
    case at.TABLE_SAVE_UPDATED_TABLE_FAILED: {
      if (args[0] && args[0].length) {
        const ids = map(args[0], "id");
        return state.filter((id) => !ids.includes(id));
      }
      return state;
    }
    default:
      return state;
  }
}

export default combineReducers({
  tableList,
  selectedSmartOption,
  selectedTable,
  individualTableSaveStatus
});
