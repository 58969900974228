import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

const WithCustomTooltip = ({ id, className = "", message, children }) => {
  return (
    <span className={className}>
      {children}
      <Popup
        key={id}
        content={message}
        trigger={<span className="custom-tooltip">?</span>}
      />
    </span>
  );
};

WithCustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  children: PropTypes.node
};

WithCustomTooltip.defaultProps = {
  className: "",
  children: null
};

export default WithCustomTooltip;
