import axiosInstance from "configs/axios";
import { keyBy, map } from "lodash";
import CONFIG from "configs/config";
import { extractApiResponse } from "utils/helpers";

// URIs
const URI_GET_TABLES = "/Tables/gettablebyslide/";
const URI_PUT_TABLES = "/Tables/updatetables";

const URI_TABLE_RECOMMEDATION = "/Table/recommendation/";

export function TablesBySlide(result, pptId) {
  const smartOption = result.operationType
    ? CONFIG.CONSTANTS.IMAGE_SMART_OPTION_MAPPER[result.operationType]
    : "";
  this.pptId = pptId;

  this.slideIndex = result.slideNo;
  this.slideId = result.slideId;

  this.savedSmartOption = smartOption;
  this.smartOption = smartOption;

  this.id = result.tableId;
  this.modifiedTableUrl = result.uploadObjectKey || result.modifiedTableUrl;
  this.originalTableUrl = result.originalTableUrl;
  this.uploadObjectKey = result.uploadObjectKey;
}

export function getTablesBySlide(pptId, slideId) {
  return axiosInstance
    .get(`${URI_GET_TABLES}${slideId}`)
    .then((response) =>
      map(
        extractApiResponse(response),
        (result) => new TablesBySlide(result, pptId)
      )
    );
}

function TableSuggestions(data) {
  this.redactedImageUrl = data.smartEditedTableUrl;
}

// From table recommendation service
export function getTableSuggestions(slideId, ids) {
  return axiosInstance
    .post(
      `${CONFIG.SERVER.BASE_RECOMMENDATION_URL}${URI_TABLE_RECOMMEDATION}${slideId}`,
      ids
    )
    .then((response) => {
      const smartEditedUrls = {};
      const keyedResults = keyBy(extractApiResponse(response), "tableId");
      ids.forEach((id) => {
        if (keyedResults[id]) {
          smartEditedUrls[id] = new TableSuggestions(keyedResults[id]);
        }
      });
      return smartEditedUrls;
    });
}

function SaveTableData(request) {
  this.pptId = request.pptId;
  this.slideId = request.slideId;
  this.slideNo = request.slideIndex;
  this.operationType =
    CONFIG.CONSTANTS.IMAGE_SMART_OPTION_REMAPPER[request.smartOption] || 0;

  this.tableId = request.id;
  this.originalTableUrl = request.originalTableUrl;
  this.modifiedTableUrl = request.modifiedTableUrl;
}

export function saveUpdatedTables(table) {
  const payload = table.map((t) => new SaveTableData(t));
  return axiosInstance.put(`${URI_PUT_TABLES}`, payload).then(() => payload);
}
