import { requestedString, succeededString, failedString } from "utils/common";

export const IMAGE_FETCH_IMAGELIST = "IMAGE_FETCH_IMAGELIST";
export const IMAGE_FETCH_IMAGELIST_REQUESTED = requestedString(
  "IMAGE_FETCH_IMAGELIST"
);
export const IMAGE_FETCH_IMAGELIST_SUCCEEDED = succeededString(
  "IMAGE_FETCH_IMAGELIST"
);
export const IMAGE_UPDATE_IMAGELIST = "IMAGE_UPDATE_IMAGELIST";
export const IMAGE_REPLACE_IMAGELIST = "IMAGE_REPLACE_IMAGELIST";
export const IMAGE_RESET_IMAGE_STATE = "IMAGE_RESET_IMAGE_STATE";

export const IMAGE_FETCH_IMAGE_SUGGESTION = "IMAGE_FETCH_IMAGE_SUGGESTION";
export const IMAGE_FETCH_IMAGE_SUGGESTION_REQUESTED = requestedString(
  "IMAGE_FETCH_IMAGE_SUGGESTION"
);
export const IMAGE_FETCH_IMAGE_SUGGESTION_SUCCEEDED = succeededString(
  "IMAGE_FETCH_IMAGE_SUGGESTION"
);

// Save image data
export const IMAGE_TRIGGER_SAVE_IMAGE = "IMAGE_TRIGGER_SAVE_IMAGE";
export const IMAGE_SAVE_UPDATED_IMAGES = "IMAGE_SAVE_UPDATED_IMAGES";
export const IMAGE_SAVE_UPDATED_IMAGES_REQUESTED = requestedString(
  "IMAGE_SAVE_UPDATED_IMAGES"
);
export const IMAGE_SAVE_UPDATED_IMAGES_SUCCEEDED = succeededString(
  "IMAGE_SAVE_UPDATED_IMAGES"
);
export const IMAGE_SAVE_UPDATED_IMAGES_FAILED = failedString(
  "IMAGE_SAVE_UPDATED_IMAGES"
);
export const IMAGE_RESET_MANUALLY_EDITED_IMAGE =
  "IMAGE_RESET_MANUALLY_EDITED_IMAGE";
export const IMAGE_RESET_MANUALLY_EDITED_IMAGE_SUCCEEDED = succeededString(
  "IMAGE_RESET_MANUALLY_EDITED_IMAGE"
);

const IMAGE_SAVE_UPLOAD_TO_S3 = "IMAGE_SAVE_UPLOAD_TO_S3";
export const IMAGE_SAVE_UPLOAD_TO_S3_REQUESTED = requestedString(
  IMAGE_SAVE_UPLOAD_TO_S3
);
export const IMAGE_SAVE_UPLOAD_TO_S3_SUCCEEDED = succeededString(
  IMAGE_SAVE_UPLOAD_TO_S3
);
export const IMAGE_SAVE_UPLOAD_TO_S3_FAILED = failedString(
  IMAGE_SAVE_UPLOAD_TO_S3
);

export const IMAGE_APPLY_MULTIPLE_IMAGE_CHANGES =
  "IMAGE_APPLY_MULTIPLE_IMAGE_CHANGES";
export const IMAGE_SAVE_MULTIPLE_IMAGES = "IMAGE_SAVE_MULTIPLE_IMAGES";
export const IMAGE_SAVE_MULTIPLE_IMAGES_REQUESTED = requestedString(
  IMAGE_SAVE_MULTIPLE_IMAGES
);
export const IMAGE_SAVE_MULTIPLE_IMAGES_SUCCEEDED = succeededString(
  IMAGE_SAVE_MULTIPLE_IMAGES
);
export const IMAGE_SAVE_MULTIPLE_IMAGES_FAILED = failedString(
  IMAGE_SAVE_MULTIPLE_IMAGES
);

export const IMAGE_FETCH_AUTO_EDITED_IMAGES = "IMAGE_FETCH_AUTO_EDITED_IMAGES";
export const IMAGE_FETCH_AUTO_EDITED_IMAGES_SUCCEEDED = succeededString(
  IMAGE_FETCH_AUTO_EDITED_IMAGES
);

export const IMAGE_ON_SET_CURRENT_IMAGE = "IMAGE_ON_SET_CURRENT_IMAGE";
export const IMAGE_SET_CURRENT_IMAGE = "IMAGE_SET_CURRENT_IMAGE";

export const SELECT_SMART_OPTION_FOR_IMAGE = "SELECT_SMART_OPTION_FOR_IMAGE";
export const UPDATE_SELECTED_SMART_OPTION = "UPDATE_SELECTED_SMART_OPTION";

export const TOGGLE_MULTISELECT_IMAGE = "TOGGLE_MULTISELECT_IMAGE";
export const TOGGLE_MULTISELECT_IMAGE_OFF = "TOGGLE_MULTISELECT_IMAGE_OFF";
export const EXIT_MULTISELECT_IMAGE = "EXIT_MULTISELECT_IMAGE";
export const RESET_MULTISELECT_DIRTY_CHANGES =
  "RESET_MULTISELECT_DIRTY_CHANGES";

export const IMAGE_SELECT_IMAGE = "IMAGE_SELECT_IMAGE";
export const IMAGE_DESELECT_IMAGE = "IMAGE_DESELECT_IMAGE";
export const IMAGE_RESET_SELECTED_IMAGES = "IMAGE_RESET_SELECTED_IMAGES";
export const IMAGE_SELECT_ALL_IMAGES = "IMAGE_ALL_IMAGES";

export const IMAGE_STORE_IMAGE_DIMENSION = "IMAGE_STORE_IMAGE_DIMENSION";

export const IMAGE_SET_CURRENT_EDITED_IMAGE_DATA_URI =
  "IMAGE_SET_CURRENT_EDITED_IMAGE_DATA_URI";
