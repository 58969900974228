import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/tableManualEdit";
import * as at from "../types";

export const rsGetTableTextList = resourceSaga(
  at.TABLE_ME_FETCH_LIST,
  api.getTableTextList
);

export const rsGetTableTextSuggestions = resourceSaga(
  at.TABLE_ME_FETCH_SUGGESTIONS,
  api.getTableTextSuggestions
);

export const rsSaveSanitizedTableText = resourceSaga(
  at.TABLE_ME_SAVE_TEXT,
  api.saveSanitizedTableText
);

export const rsGetUpdatedTableThumbnails = resourceSaga(
  at.TABLE_REFRESH_TABLE_THUMBNAILS,
  api.getUpdatedTableThumbnails
);

export const rsResetManuallyEditedTable = resourceSaga(
  at.TABLE_RESET_MANUALLY_EDITED_TABLE,
  api.resetManuallyEditedTable
);
