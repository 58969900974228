import React from "react";
import { Divider, Radio } from "semantic-ui-react";
import { Slider } from "react-semantic-ui-range";
import PropTypes from "prop-types";
import { noop } from "lodash";

import CONFIG from "configs/config";

import UserSelection from "assets/images/user-selection.svg";

const SelectSubMenu = ({
  selectionBlur,
  selectionPixelate,
  selectionGrayScale,
  userSelection,
  setUserSelection,
  applyBlur,
  applyGreyScale,
  applyPixelate,
  blurCheckBox,
  pixelateCheckbox,
  grayScaleCheckbox,
  blurRange,
  setBlurRange,
  pixelateRange,
  setPixelateRange
}) => {
  return (
    <div className="image-manual-editor__select">
      <div
        data-testid="select-area"
        className={`select-area-container pointer ${
          userSelection ? "select-area-container--selected" : ""
        }`}
        onClick={() => setUserSelection(!userSelection)}
      >
        <img src={UserSelection} />{" "}
        {CONFIG.LABELS.IMAGE_MANUAL_EDITOR.SELECT_AREA}
      </div>
      <Divider />
      <Radio
        data-testid="blur-radio"
        label="Blur"
        name="user-selection"
        checked={blurCheckBox}
        onClick={() => {
          userSelection
            ? selectionBlur(
                CONFIG.CONSTANTS.IMAGE_EDITOR.BLUR_INITIAL_VALUE_MANUAL
              )
            : applyBlur(
                CONFIG.CONSTANTS.IMAGE_EDITOR.BLUR_INITIAL_VALUE_MANUAL
              );
          setBlurRange(CONFIG.CONSTANTS.IMAGE_EDITOR.BLUR_INITIAL_VALUE_MANUAL);
        }}
      />
      <br />
      <br />
      <Slider
        disabled={!blurCheckBox}
        value={blurRange}
        settings={{
          start: CONFIG.CONSTANTS.IMAGE_EDITOR.BLUR_INITIAL_VALUE_MANUAL,
          min: 0,
          max: 2,
          step: 0.1,
          onChange: (value) => {
            setBlurRange(value);
            userSelection ? selectionBlur(value, true) : applyBlur(value, true);
          }
        }}
      />
      <br />
      <br />
      <Radio
        data-testid="pixelate-radio"
        label="Pixelate"
        name="user-selection"
        checked={pixelateCheckbox}
        onClick={() => {
          setPixelateRange(2);
          userSelection ? selectionPixelate(2) : applyPixelate(2);
        }}
      />
      <br />
      <br />
      <Slider
        value={pixelateRange}
        disabled={!pixelateCheckbox}
        settings={{
          start: 2,
          min: 1,
          max: 20,
          step: 2,
          onChange: (value) => {
            setPixelateRange(value);
            userSelection
              ? selectionPixelate(value, true)
              : applyPixelate(value, true);
          }
        }}
      />
      <br />
      <br />
      <Radio
        data-testid="grayScale-radio"
        label="Gray Scale"
        name="user-selection"
        checked={grayScaleCheckbox}
        onClick={() => {
          userSelection ? selectionGrayScale() : applyGreyScale();
        }}
      />
    </div>
  );
};

SelectSubMenu.propTypes = {
  selectionBlur: PropTypes.func.isRequired,
  selectionPixelate: PropTypes.func.isRequired,
  selectionGrayScale: PropTypes.func.isRequired,
  applyBlur: PropTypes.func.isRequired,
  applyGreyScale: PropTypes.func.isRequired,
  userSelection: PropTypes.bool.isRequired,
  setUserSelection: PropTypes.func.isRequired,
  applyPixelate: PropTypes.func.isRequired,
  blurCheckBox: PropTypes.bool.isRequired,
  pixelateCheckbox: PropTypes.bool.isRequired,
  grayScaleCheckbox: PropTypes.bool.isRequired,
  blurRange: PropTypes.number,
  setBlurRange: PropTypes.func,
  pixelateRange: PropTypes.number,
  setPixelateRange: PropTypes.func
};

SelectSubMenu.defaultProps = {
  blurRange: 0,
  setBlurRange: noop,
  pixelateRange: 0,
  setPixelateRange: noop
};

export default SelectSubMenu;
