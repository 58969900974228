import React from "react";
import { connect } from "react-redux";
import Preview from "./Preview";
import PropTypes from "prop-types";
import { isPreviewDocShownSelector } from "./selectors";

const PreviewWrapper = ({ isPreviewDocShown }) => {
  return isPreviewDocShown ? <Preview /> : null;
};

PreviewWrapper.propTypes = {
  isPreviewDocShown: PropTypes.bool
};

PreviewWrapper.defaultProps = {
  isPreviewDocShown: false
};

const mapStateToProps = (state) => ({
  isPreviewDocShown: isPreviewDocShownSelector(state)
});

export default connect(mapStateToProps)(PreviewWrapper);
