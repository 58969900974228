import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { forEach, isFunction, isObject, noop } from "lodash";
import { Grid, Modal, Button, Divider, Header, Icon } from "semantic-ui-react";
import ImageEditor from "tui-image-editor";
import PropTypes from "prop-types";
import "tui-image-editor/dist/tui-image-editor.css";
import "./ImageManualEditor.scss";

import CONFIG from "configs/config";
import SelectSubMenu from "./components/SelectSubMenu";
import StampSubMenu from "./components/StampSubMenu";
import TextSubMenu from "./components/TextSubMenu";
import MainMenu from "./components/MainMenu";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import {
  getIndividualImageSaveStatus,
  getSelectedImages,
  isSavingMultipleImages
} from "containers/ImageListView/selectors";
import { imageListType } from "utils/customPropTypes";
import { triggerSaveImage } from "containers/ImageListView/actions";
import { toggleImageManualEditModal } from "containers/Sanitize/actions";
import { showManualEditModal } from "containers/Sanitize/selectors";
import { getSignedTokenFromStore } from "containers/User/selectors";
import { getCFImageSrcWithoutCache } from "utils/helpers";

import CloseIcon from "assets/images/close_grey_small.svg";
import LogoPlaceholder from "assets/images/Placeholders/logo-placeholder.svg";
import StockPlaceholder from "assets/images/Placeholders/stock-placeholder.svg";
import PortraitPlaceholder from "assets/images/Placeholders/portrait-placeholder.svg";

/**
 * IMAGE MANUAL EDITOR
 * Image editor for images identified from PPT
 * User can edit image manually
 * @returns JSX
 */
const ImageManualEditor = ({
  showImageEditor,
  setIsManualImageEdit,
  selectedImages,
  triggerSaveImage,
  isIndividualImageSaving,
  signedToken,
  refreshingUpdatedImage
}) => {
  const {
    BLUR,
    FILTER,
    LOGO,
    PIXELATE,
    PORTRAIT,
    SELECT,
    STAMP,
    TEXT,
    TRANSPARENT,
    STOCK,
    BOLD,
    ITALIC,
    UNDERLINE,
    RECTANGLE,
    CIRCLE,
    FONT_FAMILY,
    TYPE_HERE,
    SHAPE,
    ICON,
    IMAGE_EDITOR_HEADER,
    GRAYSCALE
  } = CONFIG.LABELS.IMAGE_MANUAL_EDITOR;
  const [editorInstance, setEditorInstance] = useState(null);
  const [userSelection, setUserSelection] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(SELECT);
  const [blurRange, setBlurRange] = useState(0);
  const [pixelateRange, setPixelateRange] = useState(0);

  // USER SELECTION
  const [blurSelected, setBlurSelected] = useState(false);
  const [pixelateSelected, setPixelateSelected] = useState(false);

  // Checkboxes
  const [blurCheckBox, setBlurCheckbox] = useState(false);
  const [pixelateCheckbox, setPixelateCheckbox] = useState(false);
  const [grayScaleCheckbox, setGrayScaleCheckbox] = useState(false);
  const [italicCheckbox, setItalicCheckbox] = useState(false);
  const [boldCheckbox, setBoldCheckbox] = useState(false);
  const [underlineCheckbox, setUnderlineCheckbox] = useState(false);

  const [mouseDown, setMouseDown] = useState(0);

  const myTheme = {
    "common.backgroundColor": "white",
    "header.display": "none"
  };

  const isSaving =
    isIndividualImageSaving.includes(selectedImages[0].id) ||
    refreshingUpdatedImage;

  useEffect(() => {
    const menu = document.querySelector(".tui-image-editor-menu");
    const submenu = document.querySelector(".tui-image-editor-submenu");
    const menuControls = document.querySelector(".tui-image-editor-controls");

    if (menu && submenu && menuControls) {
      submenu.classList.add("d-none");
      menu.classList.add("d-none");
      menuControls.classList.add("d-none");
    }
  });

  const onCloseModal = () => {
    setIsManualImageEdit(false);
    if (editorInstance.destroy && isFunction(editorInstance.destroy)) {
      editorInstance.destroy();
    }
  };

  const getImageName = (imageObj) => {
    const splittedName = imageObj.modifiedImageUrl
      ? imageObj.modifiedImageUrl.split("/")
      : imageObj.originalImageUrl.split("/");
    return splittedName[splittedName.length - 1];
  };

  useEffect(() => {
    setEditorInstance(
      new ImageEditor(document.querySelector("#image-manual-editor"), {
        includeUI: {
          loadImage: {
            path: selectedImages.length
              ? getCFImageSrcWithoutCache(
                  selectedImages[0].originalImageUrl,
                  signedToken
                )
              : "",
            name: getImageName(selectedImages[0])
          },
          theme: myTheme,
          menu: [SHAPE, ICON, TEXT, FILTER],
          initMenu: SHAPE,
          uiSize: {
            height: "500px"
          },
          menuBarPosition: "bottom"
        },
        cssMaxHeight: 320,
        cssMaxWidth: 480,
        selectionStyle: {
          cornerSize: 14,
          rotatingPointOffset: 70,
          cornerColor: "#3ead92",
          borderColor: "#3ead92",
          lockScalingFlip: true
        },
        header: false,
        usageStatistics: false
      })
    );
  }, []);

  useEffect(() => {
    if (userSelection && !editorInstance.activeObjectId) {
      const instance = editorInstance;
      instance.ui.changeMenu(SHAPE);
      instance.setDrawingShape(RECTANGLE, {
        fill: TRANSPARENT,
        strokeWidth: 0
      });
      instance.startDrawingMode(SHAPE.toUpperCase());

      setBlurCheckbox(false);
      setPixelateCheckbox(false);
    }
  }, [userSelection]);

  useEffect(() => {
    if (showImageEditor) {
      setSelectedMenu(SELECT);
      setUserSelection(false);
      setBlurCheckbox(false);
      setPixelateCheckbox(false);
      setItalicCheckbox(false);
      setBoldCheckbox(false);
      setUnderlineCheckbox(false);
      setGrayScaleCheckbox(false);
    }
  }, [showImageEditor]);

  useEffect(() => {
    const instance = editorInstance;
    if (instance) {
      if (selectedMenu === SELECT && !instance.activeObjectId) {
        setBlurCheckbox(instance.hasFilter(BLUR));
        setPixelateCheckbox(instance.hasFilter(PIXELATE));
        setGrayScaleCheckbox(instance.hasFilter(GRAYSCALE));
        setUserSelection(false);
        instance.setDrawingShape(RECTANGLE, {
          fill: TRANSPARENT,
          strokeWidth: 0
        });
        instance.startDrawingMode(SHAPE.toUpperCase());
      }
    }
  }, [mouseDown]);

  useEffect(() => {
    if (editorInstance && editorInstance.on) {
      editorInstance.on({
        mousedown: () => {
          setMouseDown(Math.random());
        },
        objectActivated: function (obj) {
          if (obj.type === "image") {
            setSelectedMenu(STAMP);
          }

          if (obj.type === "i-text") {
            setSelectedMenu(TEXT);
            setBoldCheckbox(false);
            setItalicCheckbox(false);
            setUnderlineCheckbox(false);
            editorInstance.stopDrawingMode();
            if (obj.fontWeight === BOLD) {
              setBoldCheckbox(true);
            }
            if (obj.fontStyle === ITALIC) {
              setItalicCheckbox(true);
            }
            if (obj.textDecoration === UNDERLINE) {
              setUnderlineCheckbox(true);
            }
          }

          if (
            obj.type === RECTANGLE &&
            obj.fill.type === "color" &&
            obj.fill.color !== TRANSPARENT
          ) {
            setSelectedMenu(TEXT);
          } else {
            if (obj.type === RECTANGLE) {
              setSelectedMenu(SELECT);
              setUserSelection(true);
              setBlurCheckbox(false);
              setPixelateCheckbox(false);
              setGrayScaleCheckbox(false);
              if (obj.fill.type === FILTER) {
                if (obj.fill.filter[0].blur) {
                  setBlurCheckbox(true);
                }

                if (obj.fill.filter[0].pixelate) {
                  setPixelateCheckbox(true);
                }

                if (obj.fill.filter[0].grayscale) {
                  setGrayScaleCheckbox(true);
                }
              }
            }
          }
        }
      });
    }
  }, [editorInstance]);

  const selectPortion = () => {
    setSelectedMenu(SELECT);
  };

  const startFilter = () => {
    const instance = editorInstance;
    instance.ui.changeMenu(FILTER);
    setSelectedMenu(FILTER);
  };

  const getFontSize = (instance) => {
    return (instance.getCanvasSize().height * 10) / 100;
  };

  const startText = () => {
    const instance = editorInstance;
    instance.ui.changeMenu(TEXT);
    instance.stopDrawingMode();
    setSelectedMenu(TEXT);
  };

  const addText = () => {
    const instance = editorInstance;
    instance.addText(TYPE_HERE, {
      styles: {
        fontFamily: FONT_FAMILY,
        fontSize: getFontSize(instance) * 1.5
      },
      position: {
        x: 10,
        y: 10
      },
      autofocus: false
    });
  };

  const applyBlur = async (range, slider) => {
    const instance = editorInstance;
    setPixelateCheckbox(false);
    setGrayScaleCheckbox(false);
    if (instance.hasFilter(PIXELATE)) {
      await instance.removeFilter(PIXELATE);
    }
    if (instance.hasFilter(GRAYSCALE)) {
      await instance.removeFilter(GRAYSCALE);
    }

    if (instance.hasFilter(BLUR) && !slider) {
      instance.removeFilter(BLUR);
      setBlurCheckbox(false);
    } else {
      instance.applyFilter(BLUR, { blur: range });
      setBlurCheckbox(true);
    }
  };

  const applyGreyScale = async () => {
    const instance = editorInstance;
    setPixelateCheckbox(false);
    setBlurCheckbox(false);

    if (instance.hasFilter(PIXELATE)) {
      await instance.removeFilter(PIXELATE);
    }
    if (instance.hasFilter(BLUR)) {
      await instance.removeFilter(BLUR);
      setBlurCheckbox(false);
    }

    if (instance.hasFilter(GRAYSCALE)) {
      instance.removeFilter(GRAYSCALE);
      setGrayScaleCheckbox(false);
    } else {
      instance.applyFilter(GRAYSCALE);
      setGrayScaleCheckbox(true);
    }
  };

  const applyPixelate = async (range, slider) => {
    const instance = editorInstance;
    setBlurCheckbox(false);
    setGrayScaleCheckbox(false);
    if (instance.hasFilter(BLUR)) {
      await instance.removeFilter(BLUR);
      setBlurCheckbox(false);
    }
    if (instance.hasFilter(GRAYSCALE)) {
      await instance.removeFilter(GRAYSCALE);
    }

    if (instance.hasFilter(PIXELATE) && !slider) {
      instance.removeFilter(PIXELATE);
      setPixelateCheckbox(false);
    } else {
      instance.applyFilter(PIXELATE, {
        blocksize: range * 10
      });
      setPixelateCheckbox(true);
    }
  };

  const selectionBlur = (range, slider) => {
    setPixelateCheckbox(false);
    const instance = editorInstance;
    const shapeOption = {
      type: FILTER,
      filter: [{ blur: range }]
    };

    if (blurCheckBox && !slider) {
      instance.changeShape(instance.activeObjectId, {
        fill: TRANSPARENT,
        strokeWidth: 0
      });
      setBlurCheckbox(false);
    } else {
      instance.changeShape(instance.activeObjectId, {
        fill: shapeOption
      });
      setBlurCheckbox(true);
    }
  };
  const selectionPixelate = (range = 50, slider) => {
    setBlurCheckbox(false);
    const instance = editorInstance;
    const shapeOption = {
      type: FILTER,
      filter: [{ pixelate: range * 10 }]
    };

    if (pixelateCheckbox && !slider) {
      instance.changeShape(instance.activeObjectId, {
        fill: TRANSPARENT,
        strokeWidth: 0
      });
      setPixelateCheckbox(false);
    } else {
      instance.changeShape(instance.activeObjectId, {
        fill: shapeOption
      });
      setPixelateCheckbox(true);
    }
  };
  const selectionGrayScale = () => {
    setBlurCheckbox(false);
    const instance = editorInstance;
    const shapeOption = {
      type: FILTER,
      filter: [{ [GRAYSCALE]: 100 }]
    };

    if (grayScaleCheckbox ) {
      instance.changeShape(instance.activeObjectId, {
        fill: TRANSPARENT,
        strokeWidth: 0
      });
      setGrayScaleCheckbox(false);
    } else {
      instance.changeShape(instance.activeObjectId, {
        fill: shapeOption
      });
      setGrayScaleCheckbox(true);
    }
  };

  const addStamp = (type) => {
    let placeholder;

    switch (type) {
      case LOGO.toLowerCase():
        placeholder = LogoPlaceholder;
        break;
      case PORTRAIT.toLowerCase():
        placeholder = PortraitPlaceholder;
        break;
      case STOCK.toLowerCase():
        placeholder = StockPlaceholder;
        break;
      default:
        break;
    }
    const instance = editorInstance;
    instance.addImageObject(placeholder);
  };

  const addRectOnTextSection = () => {
    const instance = editorInstance;
    instance.addShape(RECTANGLE, {
      fill: CONFIG.CONSTANTS.IMAGE_EDITOR.COLOR_CODES[0],
      strokeWidth: 0,
      height: getFontSize(instance) * 2,
      width: getFontSize(instance) * 4
    });
  };

  const addCircleOnTextSection = () => {
    const instance = editorInstance;
    instance.addShape(CIRCLE, {
      fill: CONFIG.CONSTANTS.IMAGE_EDITOR.COLOR_CODES[0],
      strokeWidth: 0,
      height: getFontSize(instance) * 2,
      width: getFontSize(instance) * 4,
      rx: 50,
      ry: 50
    });
  };

  const boldText = () => {
    const instance = editorInstance;
    instance.changeTextStyle(instance.activeObjectId, {
      fontWeight: BOLD
    });
    setBoldCheckbox(!boldCheckbox);
  };

  const italicText = () => {
    const instance = editorInstance;
    instance.changeTextStyle(instance.activeObjectId, {
      fontStyle: ITALIC
    });
    setItalicCheckbox(!italicCheckbox);
  };

  const underLineText = () => {
    const instance = editorInstance;
    instance.changeTextStyle(instance.activeObjectId, {
      textDecoration: UNDERLINE
    });
    setUnderlineCheckbox(!underlineCheckbox);
  };

  const applyTextColor = (color) => {
    const instance = editorInstance;

    instance.changeTextStyle(instance.activeObjectId, {
      fill: color
    });
  };

  if (!selectedImages[0]) {
    return null;
  }

  const renderSubmenu = () => {
    switch (selectedMenu) {
      case SELECT:
        return (
          <SelectSubMenu
          applyGreyScale={applyGreyScale}
            applyBlur={applyBlur}
            blurCheckBox={blurCheckBox}
            selectionBlur={selectionBlur}
            applyPixelate={applyPixelate}
            pixelateCheckbox={pixelateCheckbox}
            grayScaleCheckbox={grayScaleCheckbox}
            selectionPixelate={selectionPixelate}
            selectionGrayScale={selectionGrayScale}
            blurSelected={blurSelected}
            setBlurSelected={setBlurSelected}
            pixelateSelecte={pixelateSelected}
            setPixelateSelected={setPixelateSelected}
            userSelection={userSelection}
            setUserSelection={setUserSelection}
            blurRange={blurRange}
            setBlurRange={setBlurRange}
            pixelateRange={pixelateRange}
            setPixelateRange={setPixelateRange}
          />
        );
      case STAMP:
        return <StampSubMenu addStamp={addStamp} />;
      case TEXT:
        return (
          <TextSubMenu
            addText={addText}
            boldText={boldText}
            boldCheckbox={boldCheckbox}
            italicText={italicText}
            italicCheckbox={italicCheckbox}
            underLineText={underLineText}
            underlineCheckbox={underlineCheckbox}
            applyTextColor={applyTextColor}
            addRectOnTextSection={addRectOnTextSection}
            addCircleOnTextSection={addCircleOnTextSection}
          />
        );
      default:
        return <span>Nothing</span>;
    }
  };

  const startStamp = () => {
    setSelectedMenu(STAMP);
  };

  const undo = () => {
    setBlurCheckbox(false);
    setPixelateCheckbox(false);
    setGrayScaleCheckbox(false);
    const instance = editorInstance;
    instance.undo();
  };

  const redo = () => {
    const instance = editorInstance;
    instance.redo();
  };

  const resetAll = async () => {
    setBlurSelected(false);
    setPixelateSelected(false);
    setBlurCheckbox(false);
    setPixelateCheckbox(false);
    setItalicCheckbox(false);
    setBoldCheckbox(false);
    setUnderlineCheckbox(false);
    setGrayScaleCheckbox(false);

    const instance = editorInstance;
    if (instance.hasFilter(BLUR)) {
      await instance.removeFilter(BLUR);
    }
    if (instance.hasFilter(PIXELATE)) {
      await instance.removeFilter(PIXELATE);
    }
    if (instance.hasFilter(GRAYSCALE)) {
      await instance.removeFilter(GRAYSCALE);
    }
    await instance.clearObjects();
  };

  const saveImage = () => {
    const instance = editorInstance;
    const canvasSize = instance.getCanvasSize();
    const objects = [];

    forEach(Object.values(instance._graphics._objects), (item) => {
      // Stamp [Logo]
      if (item._element?.currentSrc.includes("logo")) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["stamp"],
          lineCordinates: item.lineCoords,
          stampType: "logo"
        });
        return;
      }
      // Stamp [Portrait]
      if (item._element?.currentSrc.includes("portrait")) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["stamp"],
          lineCordinates: item.lineCoords,
          stampType: "portrait"
        });
        return;
      }
      // Stamp [Logo]
      if (item._element?.currentSrc.includes("stock")) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["stamp"],
          lineCordinates: item.lineCoords,
          stampType: "stock"
        });
        return;
      }

      // Text (Label)
      if (item.text) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["text"],
          lineCordinates: item.lineCoords,
          text: item.text
        });
        return;
      }

      // Text Background
      if (item.fill && !isObject(item.fill)) {
        objects.push({
          type:
            CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["text-background"],
          lineCordinates: item.lineCoords
        });
        return;
      }

      // Selected Blur/Pixelate
      if (item.fill && isObject(item.fill)) {
        objects.push({
          type:
            CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER[
              "selected-blur-pixelate"
            ],
          lineCordinates: item.lineCoords
        });
        return;
      }

      // Full Screen Blur
      if (instance.hasFilter("Blur")) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["blur"],
          lineCordinates: {
            tl: {
              x: 0,
              y: 0
            },
            tr: {
              x: canvasSize.width,
              y: 0
            },
            bl: {
              x: 0,
              y: canvasSize.height
            },
            br: {
              x: canvasSize.width,
              y: canvasSize.height
            }
          }
        });
        return;
      }

      // Full Screen Pixelate
      if (instance.hasFilter("pixelate")) {
        objects.push({
          type: CONFIG.CONSTANTS.IMAGE_EDITOR_AIML_DATA_MAPPER["pixelate"],
          lineCordinates: {
            tl: {
              x: 0,
              y: 0
            },
            tr: {
              x: canvasSize.width,
              y: 0
            },
            bl: {
              x: 0,
              y: canvasSize.height
            },
            br: {
              x: canvasSize.width,
              y: canvasSize.height
            }
          }
        });
        return;
      }
    });

    const dataUri = instance.toDataURL({
      format: "png"
    });

    triggerSaveImage({
      isGraph: false,
      dataUri,
      isManualEdit: true,
      imageEditorObj: {
        meta: {
          manualHeight: canvasSize.height,
          manualWidth: canvasSize.width
        },
        objects
      }
    });
  };

  return (
    <Modal open={showImageEditor} className="image-manual-editor">
      <Grid>
        <Grid.Column
          computer={2}
          className="image-manual-editor__menu-container"
        >
          <MainMenu
            selectedMenu={selectedMenu}
            selectPortion={selectPortion}
            startStamp={startStamp}
            startFilter={startFilter}
            startText={startText}
            undo={undo}
            redo={redo}
            resetAll={resetAll}
          />
        </Grid.Column>
        <Grid.Column computer={3} className="image-manual-editor__submenu">
          {renderSubmenu()}
        </Grid.Column>
        <Grid.Column computer={11}>
          <div id="image-manual-editor" />
        </Grid.Column>
      </Grid>

      <div className="image-manual-editor__header">
        <Header as="h2">{IMAGE_EDITOR_HEADER}</Header>
        <Divider />
        <span
          className={
            "close-icon " + (isSaving ? "cursor-not-allowed" : "pointer")
          }
          onClick={() => {
            if (!isSaving) {
              setIsManualImageEdit(false);
            }
          }}
        >
          <img src={CloseIcon} alt="close-icon" />
        </span>
      </div>

      <div className="image-manual-editor__secondary-actions">
        <span onClick={undo} className="pointer">
          <Icon name="undo" /> {CONFIG.LABELS.UNDO}
        </span>
        <span onClick={redo} className="pointer">
          <Icon name="redo" /> {CONFIG.LABELS.REDO}
        </span>
        <span onClick={resetAll} className="pointer">
          <Icon name="sync" /> {CONFIG.LABELS.RESET}
        </span>
      </div>

      <div className="image-manual-editor__action-container">
        <Button secondary onClick={onCloseModal} disabled={isSaving}>
          {CONFIG.LABELS.CANCEL}
        </Button>
        <CustomActionButton
          label={CONFIG.LABELS.SAVE}
          onClickfn={saveImage}
          isLoading={isSaving}
        />
      </div>
    </Modal>
  );
};

ImageManualEditor.propTypes = {
  showImageEditor: PropTypes.bool.isRequired,
  setIsManualImageEdit: PropTypes.func,
  selectedImages: PropTypes.arrayOf(imageListType),
  triggerSaveImage: PropTypes.func,
  signedToken: PropTypes.object,
  isIndividualImageSaving: PropTypes.array,
  refreshingUpdatedImage: PropTypes.bool
};

ImageManualEditor.defaultProps = {
  setIsManualImageEdit: noop,
  selectedImages: [],
  triggerSaveImage: noop,
  isSavingImage: false,
  signedToken: {},
  isIndividualImageSaving: [],
  refreshingUpdatedImage: false
};

const mapStateToProps = (state) => ({
  selectedImages: getSelectedImages(state),
  isIndividualImageSaving: getIndividualImageSaveStatus(state),
  showImageEditor: showManualEditModal(state),
  signedToken: getSignedTokenFromStore(state),
  refreshingUpdatedImage: isSavingMultipleImages(state)
});

export default connect(mapStateToProps, {
  triggerSaveImage,
  setIsManualImageEdit: toggleImageManualEditModal
})(ImageManualEditor);
