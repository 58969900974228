export const UNHANDLED_EXCEPTION = "Something went wrong! Please try again";
export const TOKEN_EXPIRED =
  "Authorization token is expired. Please refresh the page.";
export const S3_UPLOAD_FAILED = "Asset/Media Upload failed";
export const REFRESH_IMAGE_LIST_FAILED = "Refresh Image List failed";
export const UPLOAD_DOCUMENT = {
  GENERAL: "Upload failed. please try again",
  UPLOAD_FAILED_BY_FILE_NAME: (fileName) =>
    `Uploading of ${fileName} is failed. Please upload again`,
  UPLOAD_SUCCEEDED_BY_FILE_NAME: (fileName) =>
    `${fileName} uploaded successfully`
};
export const DOWNLOAD_DOCUMENT_FAILED = "Download document failed";
