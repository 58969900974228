import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, Placeholder, Image } from "semantic-ui-react";
import Slider from "react-slick";
import { findIndex, noop, without } from "lodash";
import "./ImagePreview.scss";

import WithCustomTooltip from "components/WithCustomTooltip/WithCustomTooltip";
import CustomNextArrow from "components/NextArrow/NextArrow";
import CustomPrevArrow from "components/PrevArrow/PrevArrow";
import RenderImage from "./RenderImage";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";

import {
  applyMultipleImageChanges,
  setCurrentEditedImageDataUri
} from "containers/ImageListView/actions";
import { toggleImageManualEditModal } from "containers/Sanitize/actions";
import {
  getImageDimensionDict,
  getSelectedImages,
  getSelectedSmartOptionForImage,
  isMultiSelectMode,
  isSavingMultipleImages
} from "containers/ImageListView/selectors";
import { getSignedTokenFromStore } from "containers/User/selectors";
import { imageListType } from "utils/customPropTypes";
import CONFIG from "configs/config";

// Images
import loadingIconWhite from "assets/images/uploading-ppt-icon-white.svg";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

/**
 * SANITIZE SCREEN
 * Image preview of original/sanitized image
 * @returns JSX
 */
const ImagePreview = ({
  selectedImages,
  activeSection,
  setIsManualImageEdit,
  isLoading,
  selectedSmartOption,
  isSelectMode,
  dimensionDict,
  applyMultipleImageChanges,
  setDataUri,
  isSavingMultipleImages,
  signedToken,
  currentImagePreviewing,
  setPreviewingImage
}) => {
  const slideRef = useRef(null);
  const onSliderChange = (current, next) => {
    if (next !== current) {
      setPreviewingImage(selectedImages[next] || selectedImages[current]);
    }
  };

  const imageSliderSettings = {
    className: "preview-slider-container",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 1,
    focusOnSelect: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    beforeChange: onSliderChange
  };
  let timer;

  const [showApplingLoader, toggleApplingLoader] = useState(false);
  const [loadedImageList, setLoadedImageList] = useState([]);
  const [stampJPGDataUris, setStampJPGDataUris] = useState({});

  useEffect(() => {
    if (slideRef.current) {
      const idx = findIndex(selectedImages, { id: currentImagePreviewing.id });
      if (idx !== -1) {
        slideRef.current?.slickGoTo(idx, true);
      }
    }
  }, [currentImagePreviewing]);

  // When selecting multiple images (one by one) loader will be shown for fraction of seconds
  // It creates flickering effect, to avoid this, defer the toggleApplingLoader call for 500ms
  const updateLoadedImageList = (id, add = false) => {
    let newState = loadedImageList;
    setLoadedImageList((prevState) => {
      if (add) {
        newState = [...prevState, id];
      } else {
        newState = without(prevState, id);
      }
      return newState;
    });
    if (newState.length > 1) {
      toggleApplingLoader(!!newState.length);
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        toggleApplingLoader(!!newState.length);
      }, 500);
    }
  };

  const firstImage = selectedImages[0] || {};

  const onApplyChanges = () => {
    if (
      selectedSmartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.BLUR ||
      selectedSmartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.STAMP
    ) {
      applyMultipleImageChanges({ dataUris: stampJPGDataUris });
    } else if (
      selectedSmartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.RESET
    ) {
      applyMultipleImageChanges({ dataUris: {}, hasReset: true });
    } else if (
      selectedSmartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.PATCH
    ) {
      applyMultipleImageChanges({ isPatchImage: true });
    }
  };

  const isManuallEdited =
    firstImage?.smartOption === CONFIG.CONSTANTS.IMAGE_SMART_OPTION.MANUALEDIT;

  return (
    <section className="editor-section__editor">
      <div className="header-stripe editor-header">
        <span className="editor-header__title">
          Preview Sanitized {activeSection}
        </span>
        {!isLoading && firstImage.id && (
          <>
            {!isSelectMode ? (
              !isManuallEdited ? (
                <WithCustomTooltip
                  id="manual-edit-tooltip"
                  message={CONFIG.LABELS.TOOLTIP_MESSAGE_MANUAL_EDIT(
                    activeSection
                  )}
                >
                  <a
                    className="editor-header__manual-edit"
                    onClick={() => {
                      setIsManualImageEdit(true);
                      onAAClickEvents("Manual_Image_Edit");
                    }}
                  >
                    <Icon name="edit" />
                    {CONFIG.LABELS.MANUAL_EDIT}
                  </a>
                </WithCustomTooltip>
              ) : null
            ) : (
              <CustomActionButton
                label={CONFIG.LABELS.APPLY}
                className="ml-2 apply-btn p-2"
                onClickfn={onApplyChanges}
                isLoading={isSavingMultipleImages}
                disabled={!selectedSmartOption || !!loadedImageList.length}
                size="small"
                data-testid="apply-button"
              />
            )}
          </>
        )}
      </div>
      <div className="main-block editor-container image-preview-container">
        {isLoading ? (
          <Placeholder />
        ) : selectedImages.length > 1 ? (
          <>
            {showApplingLoader && (
              <div className="overlay-loader">
                <Image src={loadingIconWhite} alt="" />
              </div>
            )}
            <Slider ref={slideRef} {...imageSliderSettings}>
              {selectedImages.map((image) => {
                return (
                  <div key={image.id} className="slider-block">
                    <RenderImage
                      isFromSlider
                      image={image}
                      dimension={dimensionDict[image.id]}
                      setStampDataUris={setStampJPGDataUris}
                      setDataUri={setDataUri}
                      signedToken={signedToken}
                      updateLoadedImageList={updateLoadedImageList}
                    />
                  </div>
                );
              })}
            </Slider>
          </>
        ) : selectedImages.length ? (
          <RenderImage
            image={firstImage}
            dimension={dimensionDict[firstImage.id]}
            setStampDataUris={setStampJPGDataUris}
            setDataUri={setDataUri}
            signedToken={signedToken}
            updateLoadedImageList={updateLoadedImageList}
          />
        ) : (
          <div className="no-preview-msg">
            {CONFIG.LABELS.PLEASE_SELECT_IMAGE_TO_PREVIEW_MESSAGE}
          </div>
        )}
      </div>
    </section>
  );
};

ImagePreview.propTypes = {
  selectedImages: PropTypes.arrayOf(imageListType),
  activeSection: PropTypes.string,
  setIsManualImageEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedSmartOption: PropTypes.string,
  isSelectMode: PropTypes.bool,
  dimensionDict: PropTypes.object,
  applyMultipleImageChanges: PropTypes.func,
  setDataUri: PropTypes.func,
  isSavingMultipleImages: PropTypes.bool,
  isReseting: PropTypes.bool,
  resetEditedImage: PropTypes.func,
  signedToken: PropTypes.object,
  currentImagePreviewing: imageListType,
  setPreviewingImage: PropTypes.func
};

ImagePreview.defaultProps = {
  selectedImages: [],
  activeSection: "",
  setIsManualImageEdit: noop,
  selectedSmartOption: "",
  dimensionDict: {},
  applyMultipleImageChanges: noop,
  setDataUri: noop,
  resetEditedImage: noop,
  signedToken: {},
  currentImagePreviewing: {},
  setPreviewingImage: noop
};

function mapStateToProps(state) {
  return {
    selectedImages: getSelectedImages(state),
    selectedSmartOption: getSelectedSmartOptionForImage(state),
    isSelectMode: isMultiSelectMode(state),
    dimensionDict: getImageDimensionDict(state),
    isSavingMultipleImages: isSavingMultipleImages(state),
    signedToken: getSignedTokenFromStore(state)
  };
}

export default connect(mapStateToProps, {
  applyMultipleImageChanges,
  setDataUri: setCurrentEditedImageDataUri,
  setIsManualImageEdit: toggleImageManualEditModal
})(ImagePreview);
