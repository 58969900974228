import axiosInstance from "configs/axios";
import { filter, forEach, keyBy, map } from "lodash";
import { extractApiResponse } from "utils/helpers";
import CONFIG from "configs/config";

// URIs
const URI_GET_IMAGE = "/Image/getimagebyslide/";
const URI_GET_GRAPH = "/Graph/getgraphbyslide/";
const URI_PUT_IMAGES = "/Image/updateimage";
const URI_PUT_GRAPHS = "/Graph/updategraph";
const URI_GET_EDITED_IMAGES = "/Graph/getstampgraph";

const URI_IMAGE_RECOMMEDATION = "/Image/recommendation/";
const URI_GRAPH_RECOMMEDATION = "/Graph/recommendation/";

export function ImagesBySlide(result, pptId, isGraph, index) {
  this.index = index;
  const smartOption = result.operationType
    ? CONFIG.CONSTANTS.IMAGE_SMART_OPTION_MAPPER[result.operationType]
    : "";
  this.pptId = pptId;

  this.slideIndex = result.slideNo;
  this.slideId = result.slideId;

  this.savedSmartOption = smartOption;
  this.smartOption = smartOption;

  if (isGraph) {
    this.id = result.graphId;
    this.modifiedImageUrl = result.modifiedGraphUrl;
    this.originalImageUrl = result.originalGraphUrl;
    this.uploadObjectKey = result.uploadObjectKey;
  } else {
    const original = result.originalImageUrl;
    const jpeg = result.jpegImageUrl;
    const modified = result.modifiedImageUrl;
    this.id = result.imageId;
    this.modifiedImageUrl = modified;
    this.originalImageUrl = jpeg || original;
    this.backUpOriginalImageUrl = original;
    this.uploadObjectKey = result.uploadObjectKey;
    this.imageMetadata = result.imageMetadata;
  }
}

export function getImagesBySlide(pptId, slideId, isGraph) {
  const uri = isGraph ? URI_GET_GRAPH : URI_GET_IMAGE;
  return axiosInstance
    .get(`${uri}${pptId}/${slideId}`)
    .then((response) =>
      map(
        extractApiResponse(response),
        (result, index) => new ImagesBySlide(result, pptId, isGraph, index)
      )
    );
}

export function ImageSuggestions(recommendationData) {
  const data = recommendationData.imageRecommendation || {};
  this.redactedImageUrl = data.redactedImage;
  this.imageType = data.imageType;
}

export function GraphSuggestions(recommendationData) {
  this.redactedImageUrl = recommendationData.smartEditedGraphUrl;
}

// From image recommendation service
export function getImageSuggestion(slideId, ids, isGraph) {
  if (!isGraph) {
    return axiosInstance
      .post(
        `${CONFIG.SERVER.BASE_RECOMMENDATION_URL}${URI_IMAGE_RECOMMEDATION}${slideId}`,
        ids
      )
      .then((response) => {
        const smartEditedUrls = {};
        const keyedResults = keyBy(extractApiResponse(response), "imageId");
        ids.forEach((id) => {
          if (keyedResults[id]) {
            smartEditedUrls[id] = new ImageSuggestions(keyedResults[id]);
          }
        });
        return smartEditedUrls;
      });
  } else {
    return axiosInstance
      .post(
        `${CONFIG.SERVER.BASE_RECOMMENDATION_URL}${URI_GRAPH_RECOMMEDATION}${slideId}`,
        ids
      )
      .then((response) => {
        const smartEditedUrls = {};
        const keyedResults = keyBy(extractApiResponse(response), "graphId");
        ids.forEach((id) => {
          if (keyedResults[id]) {
            smartEditedUrls[id] = new GraphSuggestions(keyedResults[id]);
          }
        });
        return smartEditedUrls;
      });
  }
}

function SaveImageData(request, isGraph) {
  this.pptId = request.pptId;
  this.slideId = request.slideId;
  this.slideNo = request.slideIndex;
  this.operationType =
    CONFIG.CONSTANTS.IMAGE_SMART_OPTION_REMAPPER[request.smartOption] || 0;
  if (isGraph) {
    this.graphId = request.id;
    this.modifiedGraphUrl = request.modifiedImageUrl;
  } else {
    this.imageId = request.id;
    this.originalImageUrl = request.backUpOriginalImageUrl;
    this.modifiedImageUrl = request.modifiedImageUrl;
  }
  this.imageMetadata = request.imageMetadata;
}

export function saveUpdatedImages(imageList, isGraph) {
  const uri = isGraph ? URI_PUT_GRAPHS : URI_PUT_IMAGES;
  const payload = imageList.map((image) => new SaveImageData(image, isGraph));
  return axiosInstance.put(`${uri}`, payload).then(() => payload);
}

export function fetchAutoEditedImages(pptId, slideId, ids) {
  return axiosInstance
    .post(`${URI_GET_EDITED_IMAGES}`, {
      pptId,
      slideId,
      graphIds: ids
    })
    .then((response) => {
      const results = {};
      forEach(
        filter(extractApiResponse(response), (result = {}) => result.graphId),
        (stampedImg) => {
          results[stampedImg.graphId] = {
            key: stampedImg.stampedGraphUrl,
            url: stampedImg.url
          };
        }
      );
      return results;
    });
}
