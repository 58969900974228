import { isFunction } from "lodash";
import { useState, useEffect } from "react";

/**
 * Custom hook for window resize
 * @param {Function} helperFn
 * @returns {Boolean}
 */
export const useResizeHandler = (helperFn) => {
  const isFn = isFunction(helperFn);
  const [isTrue, set] = useState(isFn ? helperFn() : false);

  const handleWindowResize = () => {
    set((prevState) => (isFn ? helperFn() : prevState));
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return both the height and width
  return isTrue;
};
