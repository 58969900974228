import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Image, Placeholder } from "semantic-ui-react";
import { find, first, get, groupBy, noop } from "lodash";
import "./EditorSection.scss";
import CONFIG from "configs/config";

// Components
import EditorListHeader from "components/EditorListHeader/EditorListHeader";
import TextListView from "containers/TextListView/TextListView";
import SmartSuggestion from "containers/SmartSuggestion";
import ImageListView from "containers/ImageListView/ImageListView";
import TextPreview from "components/TextPreview/TextPreview";
import ImagePreview from "containers/ImagePreview/ImagePreview";
import ImageManualEditor from "containers/ImageManualEditor";
import GraphPreview from "containers/GraphPreview/GraphPreview";
import TableImagePreview from "containers/TableImagePreview/TableImagePreview";
import TableListView from "containers/TableListView/TableListView";

import {
  setActiveSection,
  setCurrentTextGroupId,
  setEditingCurrentTextGroup,
  toggleIsFilterIdentified
} from "containers/Sanitize/actions";
import {
  saveManuallySanitizedParaByGroup,
  setCurrentWordBeingEdited,
  updateCurrentTextPortions
} from "containers/TextListView/actions";
import {
  isFetchingPpt,
  getActiveSection,
  showManualEditModal,
  getCurrentSlide,
  getCurrentTextGroup
} from "containers/Sanitize/selectors";
import {
  getCurrentText,
  getCurrentWord,
  getGroupedParaDetails
} from "containers/TextListView/selectors";
import { isSmartSuggestionIsLoading } from "containers/SmartSuggestion/selectors";
import {
  getSelectedImages,
  isFetchingAutoEditedImages,
  isMultiSelectMode,
  isSavingMultipleImages
} from "containers/ImageListView/selectors";
import { getSelectedTable } from "containers/TableListView/selectors";
import { imageListType, slideType } from "utils/customPropTypes";
import { getCurrentWordWithBlock, getManualResetPortions } from "utils/helpers";

// Images
import loadingIconWhite from "assets/images/uploading-ppt-icon-white.svg";
import { getUserDetails } from "containers/User/selectors";

const EditorSection = ({
  userDetails,
  currentSlide,
  isFetchingPpt,
  activeSection,
  setActiveSection,
  currentWord,
  isSmartSuggestionIsLoading,
  currentText,
  showImageEditor,
  savingMultipleImages,
  fetchingEditedImages,
  isMultiple,
  selectedImages,
  selectedTable,
  currentTextGroup,
  setCurrentTextGroupId,
  setCurrentWord,
  setInlineEditingGroup,
  updateCurrentTextPortions,
  groupedPara,
  saveManuallySanitizedPara,
  toggleIsFilterIdentified,
  isFilterIdentified
}) => {
  const isTextView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TEXT;
  const isImageView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.IMAGES;
  const isGraphView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.GRAPHS;
  const isTableView = activeSection === CONFIG.CONSTANTS.EDITOR_SECTIONS.TABLES;

  const [currentImagePreviewing, setPreviewingImage] = useState({});

  useEffect(() => {
    if (currentImagePreviewing) {
      setPreviewingImage({});
    }
    if (isMultiple) {
      setPreviewingImage(first(selectedImages) || {});
    }
  }, [isMultiple]);

  const modifiedPortions = get(
    groupBy(currentText.portions, "groupId"),
    currentTextGroup.id,
    []
  );
  const onResetManuallEditedText = () => {
    updateCurrentTextPortions({
      id: currentText.id,
      portions: getManualResetPortions(
        currentText.portions,
        currentTextGroup.id
      ),
      groupId: currentTextGroup.id,
      skipHighlight: false
    });
    const identifiedWord = find(modifiedPortions, (port) => port.isIdentified);
    if (identifiedWord) {
      setCurrentWord({
        ...getCurrentWordWithBlock(identifiedWord, currentText),
        reselectRecommendations: true
      });
    }
  };

  return (
    <div className="editor-section" data-testid="editor-section">
      {((savingMultipleImages && !showImageEditor && isMultiple) ||
        fetchingEditedImages) && (
        <div className="overlay-loader">
          <Image src={loadingIconWhite} alt="" />
        </div>
      )}
      <section className="editor-section__list">
        <EditorListHeader
          activeSection={activeSection}
          changeSection={setActiveSection}
          currentSlide={currentSlide}
          toggleIsFilterIdentified={toggleIsFilterIdentified}
          isFetchingPpt={isFetchingPpt || !userDetails.hrId}
          isFetchingData={isSmartSuggestionIsLoading}
          isFilterIdentified={isFilterIdentified}
          isMultiple={isMultiple}
          isManualEditorOpen={currentTextGroup?.editing}
        />
        {isTextView ? (
          <TextListView
            isFetchingPpt={isFetchingPpt}
            currentGroup={currentTextGroup}
            setCurrentGroupId={setCurrentTextGroupId}
            hasText={currentSlide.totalTexts > 0}
            isFilterIdentified={isFilterIdentified}
          />
        ) : isImageView || isGraphView ? (
          <ImageListView
            activeSection={activeSection}
            hasImages={
              isImageView
                ? currentSlide.totalImages > 0
                : currentSlide.totalGraphs > 0
            }
            isFilterIdentified={isFilterIdentified}
            currentImagePreviewing={currentImagePreviewing}
            setPreviewingImage={setPreviewingImage}
          />
        ) : isTableView ? (
          <TableListView
            activeSection={activeSection}
            hasTables={currentSlide.totalTables > 0}
            isFilterIdentified={isFilterIdentified}
          />
        ) : (
          <div className="main-block image-container placeholder">
            <Placeholder>
              <Placeholder.Image />
            </Placeholder>
          </div>
        )}
      </section>
      <SmartSuggestion
        activeSection={activeSection}
        disabled={currentTextGroup.editing}
        onResetManuallEditedText={onResetManuallEditedText}
        isFilterIdentified={isFilterIdentified}
      />

      {isTextView ? (
        <TextPreview
          activeSection={activeSection}
          isLoading={isSmartSuggestionIsLoading}
          currentText={currentText}
          currentGroup={currentTextGroup}
          setCurrentGroupId={setCurrentTextGroupId}
          currentWord={currentWord}
          setInlineEditingGroup={setInlineEditingGroup}
          groupedPara={groupedPara}
          saveManuallySanitizedPara={saveManuallySanitizedPara}
        />
      ) : isImageView ? (
        <ImagePreview
          activeSection={activeSection}
          isLoading={isSmartSuggestionIsLoading}
          currentImagePreviewing={currentImagePreviewing}
          setPreviewingImage={setPreviewingImage}
        />
      ) : isGraphView ? (
        <GraphPreview
          activeSection={activeSection}
          isLoading={isSmartSuggestionIsLoading}
          currentImagePreviewing={currentImagePreviewing}
          setPreviewingImage={setPreviewingImage}
        />
      ) : isTableView ? (
        <TableImagePreview
          selectedTable={selectedTable}
          isLoading={isSmartSuggestionIsLoading}
        />
      ) : (
        <section className="editor-section__editor">
          <div className="header-stripe editor-header">
            <span className="editor-header__title">Preview</span>
          </div>
          <div className="main-block editor-container">
            <div className="p-3">
              <Placeholder data-testid="text-area-loader" fluid>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </div>
          </div>
        </section>
      )}

      {showImageEditor && isImageView && <ImageManualEditor />}
    </div>
  );
};

EditorSection.propTypes = {
  userDetails: PropTypes.object,
  isFetchingPpt: PropTypes.bool,
  activeSection: PropTypes.string,
  setActiveSection: PropTypes.func,
  currentWord: PropTypes.object,
  isSmartSuggestionIsLoading: PropTypes.bool,
  currentText: PropTypes.object,
  showImageEditor: PropTypes.bool,
  savingMultipleImages: PropTypes.bool,
  fetchingEditedImages: PropTypes.bool,
  isMultiple: PropTypes.bool,
  currentSlide: slideType,
  selectedImages: PropTypes.arrayOf(imageListType),
  selectedTable: PropTypes.object,
  currentTextGroup: PropTypes.object,
  groupedPara: PropTypes.object,
  setCurrentTextGroupId: PropTypes.func,
  setCurrentWord: PropTypes.func,
  updateCurrentTextPortions: PropTypes.func,
  setInlineEditingGroup: PropTypes.func,
  saveManuallySanitizedPara: PropTypes.func,
  toggleIsFilterIdentified: PropTypes.func,
  isFilterIdentified: PropTypes.bool
};

EditorSection.defaultProps = {
  userDetails: "",
  activeSection: "",
  setActiveSection: noop,
  currentWord: {},
  currentText: {},
  currentSlide: {},
  selectedImages: [],
  selectedTable: {},
  currentTextGroup: {},
  groupedPara: {},
  setCurrentTextGroupId: noop,
  setCurrentWord: noop,
  updateCurrentTextPortions: noop,
  setInlineEditingGroup: noop,
  saveManuallySanitizedPara: noop,
  toggleIsFilterIdentified: noop,
  isFilterIdentified: noop
};

const mapStateToProps = (state) => {
  return {
    userDetails: getUserDetails(state),
    isFetchingPpt: isFetchingPpt(state),
    activeSection: getActiveSection(state),
    currentWord: getCurrentWord(state),
    isSmartSuggestionIsLoading: isSmartSuggestionIsLoading(state),
    currentText: getCurrentText(state),
    showImageEditor: showManualEditModal(state),
    savingMultipleImages: isSavingMultipleImages(state),
    fetchingEditedImages: isFetchingAutoEditedImages(state),
    currentSlide: getCurrentSlide(state),
    selectedImages: getSelectedImages(state),
    selectedTable: getSelectedTable(state),
    isMultiple: isMultiSelectMode(state),
    currentTextGroup: getCurrentTextGroup(state),
    groupedPara: getGroupedParaDetails(state)
  };
};
export default connect(mapStateToProps, {
  setActiveSection,
  setCurrentTextGroupId,
  updateCurrentTextPortions,
  setCurrentWord: setCurrentWordBeingEdited,
  setInlineEditingGroup: setEditingCurrentTextGroup,
  saveManuallySanitizedPara: saveManuallySanitizedParaByGroup,
  toggleIsFilterIdentified
})(EditorSection);
