import * as at from "../types";

export default (state = {}, { type, payload }) => {
  switch (type) {
    case at.SHOW_REDUX_CONFIRMATION_MODAL: {
      if (payload.modalId) {
        return {
          ...state,
          [payload.modalId]: payload.modalProps
        };
      }
      return state;
    }
    case at.CLOSE_REDUX_CONFIRMATION_MODAL: {
      if (payload.modalId) {
        return {
          ...state,
          [payload.modalId]: {
            show: false
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};
