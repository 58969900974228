import { requestedString, succeededString } from "utils/common";

export const TEXT_FETCH_LIST = "TEXT_FETCH_LIST";
export const TEXT_FETCH_LIST_REQUESTED = requestedString(TEXT_FETCH_LIST);

export const TEXT_FETCH_SUGGESTIONS = "TEXT_FETCH_SUGGESTIONS";
export const TEXT_FETCH_SUGGESTIONS_REQUESTED = requestedString(
  TEXT_FETCH_SUGGESTIONS
);
export const TEXT_FETCH_SUGGESTIONS_SUCCEEDED = succeededString(
  TEXT_FETCH_SUGGESTIONS
);
export const TEXT_FETCH_SANITIZED_PARA = "TEXT_FETCH_SANITIZED_PARA";
export const TEXT_FETCH_SANITIZED_PARA_SUCCEEDED = succeededString(
  TEXT_FETCH_SANITIZED_PARA
);

export const TEXT_SET_CURRENT_TEXT = "TEXT_SET_CURRENT_TEXT";
export const TEXT_STORE_CURRENT_TEXT = "TEXT_STORE_CURRENT_TEXT";
export const TEXT_RESET_TEXT = "TEXT_RESET_TEXT";
export const TEXT_SET_CURRENT_WORD = "TEXT_SET_CURRENT_WORD";
export const TEXT_UPDATE_CURRENT_TEXT_POTIONS =
  "TEXT_UPDATE_CURRENT_TEXT_POTIONS";

export const TEXT_TRIGGER_SAVE_SANITIZED_PARA =
  "TEXT_TRIGGER_SAVE_SANITIZED_PARA";

export const TEXT_SAVE_MANUALLY_SANITIZED_PARA =
  "TEXT_SAVE_MANUALLY_SANITIZED_PARA";

export const TEXT_SAVE_SANITIZED_PARA = "TEXT_SAVE_SANITIZED_PARA";
export const TEXT_SAVE_SANITIZED_PARA_SUCCEEDED = succeededString(
  TEXT_SAVE_SANITIZED_PARA
);

export const TEXT_SELECT_SMART_SUGGESTION = "TEXT_SELECT_SMART_SUGGESTION";
export const TEXT_DESELECT_SMART_SUGGESTION = "TEXT_DESELECT_SMART_SUGGESTION";
export const TEXT_SET_REPLACEMENT_TEXT = "TEXT_SET_REPLACEMENT_TEXT";

// Local text dictionary
export const SET_SUGGESTION_BY_WORD = "SET_SUGGESTION_BY_WORD";
export const REMOVE_SUGGESTION_BY_WORD = "REMOVE_SUGGESTION_BY_WORD";
export const CLEAR_ALL_LOCAL_SUGGESTIONS = "CLEAR_ALL_LOCAL_SUGGESTIONS";
